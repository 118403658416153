import styled, { css } from 'styled-components';
import tw from 'twin.macro';

export const Container = styled.div`
  ${tw`
    flex
    flex-col
    flex-shrink-0
  `}

  label {
    font-size: 13px;
    letter-spacing: 0.26px;
    line-height: 15px;
    color: ${({ theme }) => theme.colors.input.placeholder};
  }

  p {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.32px;
    line-height: 19px;
  }
`;

export const Header = styled.div`
  ${tw`
    flex
    items-center
    py-8
  `}

  & > div {
    margin-left: 50px;

    &:first-of-type {
      margin-left: 0;
    }
  }
`;

export const RejectionInfo = styled.div`
  ${tw`
    flex
    flex-col
    mt-5
  `}
`;

export const ErrorSection = styled.div`
  ${tw`
    flex
    flex-col
  `}
`;

export const BusinessRejectionRow = styled.div`
  ${tw`
    flex
  `};
  align-items: flex-start;
  margin-bottom: 30px;

  & > div {
    margin-left: 50px;

    &:first-of-type {
      margin-left: 0;
    }
  }
`;

export const FunctionalErrorRow = styled.div`
  ${tw`
    flex
    flex-col
  `};
  border-top: 1px solid #dedede;
`;

export const FunctionalErrorTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.36px;
  line-height: 21px;
  opacity: 0.75;
  padding: 20px 0;
`;

export const FunctionalErrorItem = styled.div`
  margin-right: 30px;
  margin-bottom: 30px;
  display: flex;
  & > div {
    margin-left: 50px;

    &:first-of-type {
      margin-left: 0;
    }
  }
`;

export const InvalidationInfoContainer = styled.div`
  ${tw`
  flex
  items-start
`}

  & > div {
    margin-left: 50px;

    &:first-of-type {
      margin-left: 0;
      width: 50%;
    }
  }
`;

export const InvalidationRequestForm = styled.div`
  ${tw`
    flex
    flex-col
  `}

  textarea {
    margin: 10px 0;
    border: 1px solid ${({ theme }) => theme.colors.input.border};
    border-radius: 5px;
    padding: 10px;
  }

  button {
    margin-top: 15px;
  }
`;

export const InvalidationDecisionContainer = styled.div`
  ${tw`
    flex
    flex-col
  `};

  & > div:last-of-type {
    margin-top: 30px;
    textarea {
      max-width: 50%;
    }
  }
`;

export const InvalidationDecisionStatusRow = styled.div`
  ${tw`
    flex
    items-start
  `}

  & > div {
    margin-left: 50px;

    &:first-of-type {
      margin-left: 0;
    }
  }
`;

export const Calculations = styled.section`
  ${tw`
    flex
    items-start
`}
`;

export const CalculationItems = styled.section`
  & {
    flex-basis: 50%;
  }

  h3 {
    flex-basis: 100%;
    font-weight: bold;
    font-size: 26px;
  }
`;

export const CalculationItem = styled.section`
  ${tw`
    flex
    items-start
    flex-wrap
  `}

  & {
    padding-bottom: 1rem;
  }

  & > div {
    margin-left: 40px;

    &:first-of-type {
      margin-left: 0;
    }
  }
`;

interface PageContainerProps {
  isRelative?: boolean;
  overflowY?: boolean;
}

export const Title = styled.div<PageContainerProps>`
  ${tw`
    flex
    flex-col
    flex-1
    h-full
  `};
  position: ${({ isRelative }) => (isRelative ? 'relative' : 'unset')};
  overflow-y: ${({ overflowY }) => (overflowY ? 'auto' : 'unset')};
`;

interface SectionProps {
  noTopPadding?: boolean;
  verticalPadding?: number;
  topPadding?: number;
  paddingBottom?: number;
}

export const Section = styled.div<SectionProps>`
  ${tw`
    flex
    flex-col
  `};
  padding: ${({ noTopPadding, verticalPadding }) =>
    noTopPadding
      ? `0 0 ${verticalPadding !== undefined ? verticalPadding : 40}px 0`
      : `${verticalPadding !== undefined ? verticalPadding : 40}px 0`};

  ${({ topPadding }) => {
    if (topPadding) {
      return css`
        padding-top: ${topPadding}px;
      `;
    }
    return '';
  }}

  ${({ paddingBottom }) => {
    if (paddingBottom !== undefined) {
      return css`
        padding-bottom: ${paddingBottom}px;
      `;
    }
    return '';
  }}
`;

interface SectionTitleProps {
  noTopBorder?: boolean;
  spaceBetween?: boolean;
}

export const SectionTitle = styled.div<SectionTitleProps>`
  ${tw`
    font-extrabold
  `}
  border-top: ${({ noTopBorder }) => (noTopBorder ? 'none' : '1px solid #dedede')};
  padding: ${({ noTopBorder }) => (noTopBorder ? '10px 0 30px 0' : '25px 0 30px 0')};
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.36px;
  justify-content: ${({ spaceBetween }) => (spaceBetween ? 'space-between' : 'unset')};
`;

export const Row = styled.div`
  ${tw`flex`};

  margin-bottom: 10px;

  & > div {
    margin-right: 50px;

    &:last-of-type {
      margin-right: 0;
    }
  }
`;
