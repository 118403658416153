/* eslint-disable consistent-return */
/* eslint-disable complexity */
import { FormikProps } from 'formik';
import { get } from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';

import { MultilingualCodeSystems } from '@e-origin/shared';

import trashIcon from '../../../../../assets/icons/trash-icon.svg';
import { Autocomplete, Button, DatePicker, Input, MultiLangCodes } from '../../../../../shared/components';
import { FormRow } from '../../../../../styles/common';
import * as Style from './transit-duplicate-row.style';

interface InputConfig {
  type: 'Autocomplete' | 'MultiLang' | 'Input' | 'DatePicker';
  name: string;
  inputName?: string;
  code?: string;
  label: string;
  width?: number;
  search?: (searchText: string) => Promise<{ value: string; label: string }[]>;
  displayCondition?: (row) => boolean;
}

interface TransitDuplicateRowProps {
  form: FormikProps<any>;
  inputs: InputConfig[];
  compact?: boolean;
  arrayPath: string;
  addButtonLabel: string;
  inputsPerRow?: number;
  disabled?: boolean;
  maxRows?: number;
  cleanupAdditionalData?: (row) => void;
}

export const TransitDuplicateRow: React.FC<TransitDuplicateRowProps> = ({
  form,
  inputs,
  arrayPath,
  addButtonLabel,
  compact,
  inputsPerRow = 3,
  disabled = false,
  maxRows = 9999,
  cleanupAdditionalData,
}) => {
  const addRow = () => {
    const rows = [...form.getFieldProps(arrayPath).value];
    form.setFieldValue(arrayPath, [...rows, {}]);
  };

  const removeRow = (rowIdx: number) => {
    const rows = [...form.getFieldProps(arrayPath).value];
    const value = rows.splice(rowIdx, 1)[0];
    form.setFieldValue(arrayPath, rows);
    if (cleanupAdditionalData) {
      cleanupAdditionalData(value);
    }
  };

  const chunkInputs = (inputList: InputConfig[], chunkSize: number) => {
    const chunks = [];
    for (let i = 0; i < inputList.length; i += chunkSize) {
      chunks.push(inputList.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const renderInputField = (inputConfig: InputConfig, rowIdx: number, inputConfigIdx: number) => {
    const itemValue = form.getFieldProps(`${arrayPath}[${rowIdx}]`)?.value || '';
    if (inputConfig.displayCondition && inputConfig.displayCondition(itemValue) === false) {
      return;
    }

    const inputName = inputConfig.inputName || `${arrayPath}[${rowIdx}].${inputConfig.name}`;
    const inputValue = form.getFieldProps(inputName)?.value || '';

    const commonInputAttributes = {
      key: inputConfigIdx,
      placeholder: `${inputConfig.label} ${rowIdx + 1}`,
      width: 100,
      widthUnit: '%' as any,
      compact: !!compact,
      disabled,
    };

    return (
      <Style.InputWrapper width={inputConfig.width || 100} key={inputConfigIdx}>
        {inputConfig.type === 'MultiLang' && (
          <MultiLangCodes
            system={MultilingualCodeSystems.NCTS}
            code={inputConfig.code}
            name={inputName}
            onChange={form.handleChange}
            value={inputValue}
            {...commonInputAttributes}
          />
        )}
        {inputConfig.type === 'Autocomplete' && (
          <Autocomplete
            fetchOptions={inputConfig.search}
            onChange={(selectedOption) => form.setFieldValue(inputName, selectedOption.value)}
            value={{
              value: inputValue,
              label: inputValue,
            }}
            {...commonInputAttributes}
          />
        )}
        {inputConfig.type === 'DatePicker' && (
          <DatePicker
            name={inputName}
            value={form.getFieldProps(inputName).value && new Date(form.getFieldProps(inputName).value)}
            onChange={(value) => form.setFieldValue(inputName, `${moment(value).format('YYYY-MM-DD')}T00:00:00`)}
            {...commonInputAttributes}
          />
        )}
        {inputConfig.type === 'Input' && (
          <Input
            onChange={(evt) => form.setFieldValue(inputName, evt.target.value)}
            name={inputName}
            defaultBehaviour
            value={inputValue}
            {...commonInputAttributes}
          />
        )}
        {get(form.errors, inputName) && <Style.ErrorMessage>{get(form.errors, inputName)}</Style.ErrorMessage>}
      </Style.InputWrapper>
    );
  };

  const renderInputs = (inputList: InputConfig[], rowIdx: number) => {
    const inputChunks = chunkInputs(inputList, inputsPerRow);

    return (
      <>
        {inputChunks.map((chunk, chunkIdx) => (
          <FormRow key={chunkIdx}>
            {chunk.map((inputConfig, inputConfigIdx) => renderInputField(inputConfig, rowIdx, inputConfigIdx))}
            {chunkIdx === 0 && !disabled && (
              <Style.DeleteButton type="button" onClick={() => removeRow(rowIdx)}>
                <img src={trashIcon} alt="Remove" />
              </Style.DeleteButton>
            )}
          </FormRow>
        ))}
      </>
    );
  };

  const arrayPathValue = form.getFieldProps(arrayPath).value || [];

  useEffect(() => {
    if (!arrayPathValue.length) {
      addRow();
    }
  }, [form.values]);

  return (
    <>
      {arrayPathValue.map((_, rowIdx) => (
        <div key={`${arrayPathValue.length}-${rowIdx}`}>
          {renderInputs(inputs, rowIdx)}
          {rowIdx < arrayPathValue.length - 1 && <Style.Separator widthPercentage={inputs.length === 2 ? 49 : 74} />}
        </div>
      ))}
      {!disabled && maxRows > arrayPathValue.length && (
        <Style.Item>
          <Button type="button" outline onClick={addRow}>
            {addButtonLabel}
          </Button>
        </Style.Item>
      )}
    </>
  );
};
