/* eslint-disable complexity */
/* eslint-disable max-statements */
import * as math from 'mathjs';

import { provide, TYPES } from '@e-origin/ioc';
import { Declaration, DeclarationGoods, TemplateType } from '@e-origin/shared';

import { store } from '../../../shared/stores';
import {
  saveDeclarationDetails,
  saveDeclarationMultipleGoodItemsDetails,
} from '../../../shared/stores/declarationsSlice';
import { templateType } from '../../../utils';

export interface ISaveMultipleGoods {
  declaration: Declaration;
  updatedGoods: DeclarationGoods[];
  aiModuleMustRunAfterSave?: boolean;
  newPriceSaved?: number;
  storeSavedDeclaration?: boolean;
}

export interface ISaveDeclarationDetails {
  updatedDeclaration: Declaration;
  propertiesToSend: string[];
  updatedGoodItems?: DeclarationGoods[];
  aiModuleMustRunAfterSave?: boolean;
  newPriceSaved?: number;
  storeSavedDeclaration?: boolean;
}

@provide(TYPES.DeclarationService)
export class DeclarationService {
  private store = store;

  public async saveMultipleGoods({
    declaration,
    updatedGoods,
    aiModuleMustRunAfterSave,
    newPriceSaved,
    storeSavedDeclaration = true,
  }: ISaveMultipleGoods): Promise<void> {
    const updatedDeclaration = this.computeGoodShipmentItemsCalculations(declaration, updatedGoods);
    await this.saveDeclarationDetails({
      updatedDeclaration,
      propertiesToSend:
        templateType() === TemplateType.HIGH_VALUE_H1 ? ['consignment'] : ['consignment', 'goodsShipmentItems'],
      updatedGoodItems: updatedGoods,
      aiModuleMustRunAfterSave,
      newPriceSaved,
      storeSavedDeclaration,
    });
  }

  public async saveDeclarationDetails({
    updatedDeclaration,
    propertiesToSend = [],
    updatedGoodItems,
    aiModuleMustRunAfterSave,
    newPriceSaved,
    storeSavedDeclaration = true,
  }: ISaveDeclarationDetails): Promise<void> {
    if (templateType() === TemplateType.HIGH_VALUE_H1) {
      const webMatchingDescription = updatedDeclaration.goodsShipmentItems?.every((goodsShipmentItem: any) => {
        return !!goodsShipmentItem.evalMethodDescription?.webMatchingDescription;
      });
      const hsDescriptionOK = updatedDeclaration.goodsShipmentItems?.every((goodsShipmentItem: any) => {
        return !!goodsShipmentItem.evalMethodAiHsCode?.hsCodeOk;
      });

      const updatedDeclarationToSend = {
        ...updatedDeclaration,
        goodShipmentGlobal: {
          ...updatedDeclaration.goodShipmentGlobal,
          webMatchingDescription,
          hsDescriptionOK,
        },
      };

      await this.store.dispatch(
        saveDeclarationDetails(
          updatedDeclarationToSend,
          propertiesToSend,
          !updatedGoodItems?.length ? storeSavedDeclaration : false,
        ),
      );

      if (updatedGoodItems?.length) {
        this.store.dispatch(
          saveDeclarationMultipleGoodItemsDetails(
            updatedGoodItems,
            String(updatedDeclaration._id),
            aiModuleMustRunAfterSave,
            newPriceSaved,
            storeSavedDeclaration,
          ),
        );
      }

      return;
    }
    await this.store.dispatch(saveDeclarationDetails(updatedDeclaration, propertiesToSend, storeSavedDeclaration));
  }

  private computeGoodShipmentItemsCalculations(
    declaration: Declaration,
    updatedGoods: DeclarationGoods[],
  ): Declaration {
    const updatedDeclaration = { ...declaration };
    let updatedGrossMass = 0;
    let updatedTransportCost = 0;

    updatedDeclaration.goodsShipmentItems = updatedDeclaration.goodsShipmentItems?.map((item) => {
      const updatedGood = updatedGoods.find((good) => good._id === item._id);
      if (updatedGood) {
        if (item.goodsMeasure && item.goodsMeasure.grossMass) {
          updatedGrossMass = math.add(math.bignumber(updatedGood.goodsMeasure.grossMass), updatedGrossMass) as number;
        }
        if (item.transportInsurance && item.transportInsurance.amount) {
          updatedTransportCost = math.add(
            math.bignumber(updatedGood.transportInsurance.amount),
            updatedTransportCost,
          ) as number;
        }
        return updatedGood;
      }
      if (item.goodsMeasure && item.goodsMeasure.grossMass) {
        updatedGrossMass = math.add(math.bignumber(item.goodsMeasure.grossMass), updatedGrossMass) as number;
      }
      if (item.transportInsurance && item.transportInsurance.amount) {
        updatedTransportCost = math.add(math.bignumber(item.transportInsurance.amount), updatedTransportCost) as number;
      }
      if (
        updatedGoods[0].evalMethodAiHsCode.hsCodeOk ||
        updatedGoods[0].evalMethodAiHsCode.suggestedHsCode?.toString().length
      ) {
        return {
          ...item,
          evalMethodDescription: { ...updatedGoods[0].evalMethodDescription },
          evalMethodAiHsCode: {
            ...item.evalMethodAiHsCode,
            hsCodeOk: true,
            suggestedHsCode: !updatedGoods[0].evalMethodAiHsCode.validatedHSCodeCheck
              ? updatedGoods[0].evalMethodAiHsCode.suggestedHsCode
              : '',
            validatedHSCodeCheck: updatedGoods[0].evalMethodAiHsCode.validatedHSCodeCheck,
          },
        };
      }
      return {
        ...item,
        evalMethodDescription: { ...updatedGoods[0].evalMethodDescription },
      };
    });
    if (updatedDeclaration.consignment) {
      updatedDeclaration.consignment = {
        ...updatedDeclaration.consignment,
        grossMass: updatedGrossMass.valueOf(),
        transportCost: { ...updatedDeclaration.consignment.transportCost, amount: updatedTransportCost.valueOf() },
      };
    }

    return updatedDeclaration;
  }
}
