/* eslint-disable max-statements */
/* eslint-disable complexity */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

/* eslint-disable no-param-reassign */
import { Batch, SortDirection, VerticalViewColumns } from '@e-origin/shared';

import { removeUndefined, request } from '../../utils';
import { AppThunk, RootState } from './';

interface IBoardState {
  data: {
    [key: string]: Batch[];
  };
  filters: { [key: string]: any };
  paginations: {
    [columnName: string]: { page: number; size: number };
  };
}

const defaultPagination = {
  page: 0,
  size: 10,
};

const defaultBatchesCriteria = {
  filters: {},
  pagination: { page: 1, size: 10 },
  sorting: { field: 'counter', direction: SortDirection.DESC },
};

const initialState: IBoardState = {
  data: {
    [VerticalViewColumns.FAILED]: [],
    [VerticalViewColumns.RISK_ANALYSIS]: [],
    [VerticalViewColumns.VALIDATION_IN_PROGRESS]: [],
    [VerticalViewColumns.BEGATE_NOT_RELEASED]: [],
    [VerticalViewColumns.BEGATE_RELEASED]: [],
    [VerticalViewColumns.NOT_SENT]: [],
    [VerticalViewColumns.NOT_RELEASED]: [],
    [VerticalViewColumns.IN_CONTROL]: [],
    [VerticalViewColumns.REJECTED]: [],
    [VerticalViewColumns.RELEASED]: [],
    [VerticalViewColumns.INVALIDATED]: [],
  },
  filters: {},
  paginations: {
    [VerticalViewColumns.FAILED]: defaultPagination,
    [VerticalViewColumns.RISK_ANALYSIS]: defaultPagination,
    [VerticalViewColumns.VALIDATION_IN_PROGRESS]: defaultPagination,
    [VerticalViewColumns.BEGATE_NOT_RELEASED]: defaultPagination,
    [VerticalViewColumns.BEGATE_RELEASED]: defaultPagination,
    [VerticalViewColumns.NOT_SENT]: defaultPagination,
    [VerticalViewColumns.NOT_RELEASED]: defaultPagination,
    [VerticalViewColumns.IN_CONTROL]: defaultPagination,
    [VerticalViewColumns.REJECTED]: defaultPagination,
    [VerticalViewColumns.RELEASED]: defaultPagination,
    [VerticalViewColumns.INVALIDATED]: defaultPagination,
  },
};

export const batchesBoardSlice = createSlice({
  name: 'batches',
  initialState,
  reducers: {
    incrementPagination: (state: IBoardState, action: PayloadAction<{ column: string }>) => {
      state.paginations[action.payload.column].page++;
    },
    resetPagination: (state: IBoardState, action: PayloadAction<{ column: string }>) => {
      state.paginations[action.payload.column].page = 0;
    },
    setColumnData: (
      state: IBoardState,
      action: PayloadAction<{ column: string; data: Batch[]; resetColumn?: boolean }>,
    ) => {
      state.data[action.payload.column] = action.payload.resetColumn
        ? action.payload.data
        : [...state.data[action.payload.column], ...action.payload.data];
    },
    setFilters: (state: IBoardState, action: PayloadAction<any>) => {
      state.filters = action.payload;
    },
  },
});

export const selectBatchesBoard = (state: RootState) => state.batchesBoard.data;

export const selectBatchesBoardColumn = (columnName: string) => (state: RootState) =>
  state.batchesBoard.data[columnName];

export const selectBatchesBoardFilters = (state: RootState) => state.batchesBoard.filters;

export const fetchBatchesBoardColumn =
  (column: string, resetColumn = false): AppThunk<Promise<void>> =>
  async (dispatch: any, getState: any) => {
    try {
      const pagination = { ...getState().batchesBoard.paginations[column] };
      const filters = { ...getState().batchesBoard.filters };

      if (resetColumn) {
        pagination.page = 0;
        dispatch(batchesBoardSlice.actions.resetPagination({ column }));
      } else if (getState().batchesBoard.data[column].length) {
        pagination.page++;
        dispatch(batchesBoardSlice.actions.incrementPagination({ column }));
      }

      const {
        data: { batches },
      } = await request({
        path: `batches/board-column`,
        method: 'POST',
        authenticate: true,
        dataObject: {
          column,
          pagination,
          filters,
        },
      });

      dispatch(batchesBoardSlice.actions.setColumnData({ column, data: batches, resetColumn }));
    } catch (error) {
      toast.error('Error fetching the batches!');
    }
  };

export const setBatchesBoardFilters =
  (filters: Partial<{ [key: string]: any }>, replaceFilters = false): AppThunk<Promise<void>> =>
  async (dispatch: any, getState: any) => {
    const currentFilters = getState().batchesBoard.filters;
    if (replaceFilters) {
      dispatch(
        batchesBoardSlice.actions.setFilters({
          ...defaultBatchesCriteria.filters,
          ...removeUndefined(filters),
        }),
      );
      return;
    }

    const mergedFilters = removeUndefined({
      ...currentFilters,
      ...filters,
    });

    dispatch(batchesBoardSlice.actions.setFilters(mergedFilters));
  };
