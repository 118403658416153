/* eslint-disable max-lines */
/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
/* eslint-disable complexity */
/* eslint-disable max-statements */
import { useFormik } from 'formik';
import _ from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Batch, DeclarationStatus, DeclarationTraceDataChange, FunctionalError } from '@e-origin/shared';

import { Button, Dropdown, Input, Modal, Spinner } from '../../../../shared/components';
import { IDropDownOption } from '../../../../shared/interfaces/dropdown-option.interface';
import { bulkUpdateBatch, updateBatchStatistics } from '../../../../shared/stores/batchesSlice';
import {
  getCustomErrors,
  selectCustomsErrors,
  selectCustomsErrorsLoading,
} from '../../../../shared/stores/customsErrorsSlice';
import {
  fetchDeclarationsByBatchIdAndStatus,
  selectDeclarations,
  selectLoadingDeclarations,
} from '../../../../shared/stores/declarationsSlice';
import { FormContainer, FormRow, FormSection } from '../../../../styles/common';
import { BatchLoadingContainer, NewBatchError } from './bulk-update-batch-modal.styles';

interface IBulkUpdateBatchModalProps {
  afterCreate(): void;
  onHide(): void;
  show: boolean;
  updatedBatch?: Batch;
}

export interface INewBulUpdateData {
  errorReason: string;
  errorPointer: string;
  errorRemark: string;
  initialValue: string;
  modifyValue: string;
}

export interface IDeclarationsForUpdateData {
  declarationId: string;
  dataChanges: DeclarationTraceDataChange[];
}

const BULK_UPDATE = 'Bulk update';
const CANCEL_BUTTON = 'Cancel';
const CONTINUE_BUTTON = 'Continue';
const ERROR_MESSAGE_PLACEHOLDER = 'Error message';
const ERROR_POINTER_PLACEHOLDER = 'Error pointer';
const ERROR_REMARKS_PLACEHOLDER = 'Error remarks';
const INITIAL_VALUE_PLACEHOLDER = 'Initial value';
const MODIFY_VALUE_PLACEHOLDER = 'Modify value';

const BulkUpdateBatchModal: FC<IBulkUpdateBatchModalProps> = (props) => {
  const { afterCreate, onHide, show, updatedBatch } = props;
  const dispatch = useDispatch();

  const [error, setError] = useState('');
  const [errorPointers, setErrorPointers] = useState<IDropDownOption[]>([]);
  const [errorRemarks, setErrorRemarks] = useState<IDropDownOption[]>([]);
  const [initialValues, setInitialValues] = useState<IDropDownOption[]>([]);

  const customsErrors = useSelector(selectCustomsErrors);
  const declarationsLoading = useSelector(selectLoadingDeclarations);
  const customsErrorsLoading = useSelector(selectCustomsErrorsLoading);
  const declarations = useSelector(selectDeclarations);

  const isLoading = declarationsLoading || customsErrorsLoading;

  useEffect(() => {
    dispatch(fetchDeclarationsByBatchIdAndStatus(updatedBatch?._id || '', DeclarationStatus.REJECTED));
  }, [updatedBatch]);

  useEffect(() => {
    dispatch(getCustomErrors());
  }, []);

  const errorReasons = _.uniqWith(
    declarations.reduce<IDropDownOption[]>((accumulator, rejectedDeclaration) => {
      rejectedDeclaration?.customsState?.functionalError.forEach((err) => {
        const foundedMessage = customsErrors.find((customsError) => customsError.errorReason === err.errorReason);

        accumulator.push({
          label: foundedMessage?.errorMessage || err.errorReason,
          value: err.errorReason,
        });
      });

      return accumulator;
    }, []),
    _.isEqual,
  );

  const formik = useFormik<INewBulUpdateData>({
    initialValues: {
      errorReason: '',
      errorPointer: '',
      errorRemark: '',
      initialValue: '',
      modifyValue: '',
    },
    onSubmit: (values) => {
      const declarationsForUpdateData: IDeclarationsForUpdateData[] = [];

      if (!!values.errorReason && !!values.errorPointer && !!values.errorRemark && !!values.modifyValue) {
        declarations.forEach((declaration) => {
          declaration?.customsState?.functionalError.forEach((err) => {
            const foundedMessage = customsErrors.find(
              (customsError) => customsError.errorReason === values.errorReason,
            );

            const existingDeclarationForUpdateData = declarationsForUpdateData.find(
              (d) => d.declarationId === declaration._id,
            );

            if (
              err.remarks === values.errorRemark &&
              err.errorPointer === values.errorPointer &&
              (err.errorPointer.includes('IE001.GoodsShipment.GoodsShipmentItem') ||
                err.errorPointer.includes('GoodsShipment/*:GovernmentAgencyGoodsItem')) &&
              foundedMessage
            ) {
              // it extracts idx index from IE001.GoodsShipment.GoodsShipmentItem[idx]
              // where idx starts from 1 (we must shift left by 1 position)
              const idx = Number(errorPointer.split('[')[1][0]) - 1;
              if (
                initialValue ===
                _.get(declaration, foundedMessage.declarationAttributePointer.replace('?', idx.toString()))
              ) {
                const dataChanges = [
                  {
                    fieldName: `goodsShipmentItems[${idx}].commodityCode.hsCode`,
                    newFieldValue: values.modifyValue,
                    oldFieldValue: _.get(declaration, `goodsShipmentItems[${idx}].commodityCode.hsCode`),
                  },
                  {
                    fieldName: `goodsShipmentItems[${idx}].commodityCode.hsCodeCustomsSystem`,
                    newFieldValue: values.modifyValue,
                    oldFieldValue: _.get(declaration, `goodsShipmentItems[${idx}].commodityCode.hsCodeCustomsSystem`),
                  },
                ];
                if (!existingDeclarationForUpdateData) {
                  declarationsForUpdateData.push({
                    declarationId: declaration?._id || '',
                    dataChanges,
                  });
                } else {
                  existingDeclarationForUpdateData.dataChanges.push(...dataChanges);
                }
              }
              return;
            }

            if (
              err.remarks === values.errorRemark &&
              err.errorPointer === values.errorPointer &&
              foundedMessage &&
              initialValue === _.get(declaration, foundedMessage.declarationAttributePointer)
            ) {
              const dataChange = {
                fieldName: foundedMessage.declarationAttributePointer,
                newFieldValue: values.modifyValue,
                oldFieldValue: _.get(declaration, foundedMessage.declarationAttributePointer),
              };
              if (!existingDeclarationForUpdateData) {
                declarationsForUpdateData.push({
                  declarationId: declaration?._id || '',
                  dataChanges: [dataChange],
                });
              } else {
                existingDeclarationForUpdateData.dataChanges.push(dataChange);
              }
            }
          });
        });
        for (let index = 0; index < declarationsForUpdateData.length; index += 100) {
          const nextIndex = index + 100;
          const arrayToSend = declarationsForUpdateData.slice(index, nextIndex);

          dispatch(bulkUpdateBatch(updatedBatch?._id || '', arrayToSend));
        }

        const declarationsIdsForUpdate = declarationsForUpdateData.map((declarationForUpdate) => {
          return declarationForUpdate.declarationId;
        });

        dispatch(updateBatchStatistics(updatedBatch?._id || '', declarationsIdsForUpdate));

        afterCreate();
        onHide();
      }
    },
    enableReinitialize: true,
  });

  const { values, setFieldValue, handleChange, handleSubmit, handleBlur, setValues, submitForm } = formik;
  const { errorRemark, errorReason, errorPointer, initialValue, modifyValue } = values;

  const functionalErrors = _.uniqWith(
    declarations.reduce<FunctionalError[]>((accumulator, declaration) => {
      declaration?.customsState?.functionalError.forEach((err) => {
        if (err.errorReason === errorReason) {
          accumulator.push(err);
        }
      });

      return accumulator;
    }, []),
    _.isEqual,
  );

  const handleSetErrorRemarks = () => {
    const errRemarks = _.uniqWith(
      functionalErrors.map((functionalError) => {
        return {
          label: functionalError.remarks,
          value: functionalError.remarks,
        };
      }),
      _.isEqual,
    );
    setErrorRemarks(errRemarks);
  };

  const handleSetErrorPointers = () => {
    const pointers = new Set<string>();
    functionalErrors
      .filter(({ remarks }) => errorRemark === remarks)
      .forEach(({ errorPointer: pointer }) => pointers.add(pointer));

    setErrorPointers([...pointers].map((p) => ({ label: p, value: p })));
  };

  const handleSetInitialValues = () => {
    const initialValuesData: IDropDownOption[] = [];

    let declarationsCounter = 0;

    declarations.forEach((declaration) => {
      declaration?.customsState?.functionalError.forEach((err) => {
        customsErrors.forEach((customsError) => {
          if (customsError.errorReason !== err.errorReason) {
            return;
          }
          const isAddInitialValue =
            customsError.errorReason === errorReason &&
            err.remarks === errorRemark &&
            err.errorPointer === errorPointer;

          if (isAddInitialValue) {
            if (
              errorPointer.includes('IE001.GoodsShipment.GoodsShipmentItem') ||
              errorPointer.includes('GoodsShipment/*:GovernmentAgencyGoodsItem')
            ) {
              // it extracts idx index from IE001.GoodsShipment.GoodsShipmentItem[idx]
              // where idx starts from 1 (we must shift left by 1 position)
              const idx = Number(errorPointer.split('[')[1][0]) - 1;
              initialValuesData.push({
                label: _.get(declaration, customsError.declarationAttributePointer.replace('?', idx.toString())),
                value: _.get(declaration, customsError.declarationAttributePointer.replace('?', idx.toString())),
              });
            } else {
              initialValuesData.push({
                label: _.get(declaration, customsError.declarationAttributePointer),
                value: _.get(declaration, customsError.declarationAttributePointer),
              });
            }
          }
        });

        if (err.remarks === errorRemark) {
          declarationsCounter += 1;
        }
      });
    });

    const isInitialValuesDataEmpty = initialValuesData.length === 0;

    if (!isInitialValuesDataEmpty) {
      setInitialValues(_.uniqWith(initialValuesData, _.isEqual));
    }

    if (initialValues.length === 1) {
      setFieldValue('initialValue', initialValues[0].value);
    }

    if (isInitialValuesDataEmpty) {
      const wordEnding = declarationsCounter === 1 ? ' is' : 's are';

      const bulkUpdateError = `${declarationsCounter} declaration${wordEnding} having this error but the correction cannot be handled here. Please use the batch file to modify your declarations`;

      setError(bulkUpdateError);
    }
  };

  const isErrorPointerEmpty = errorPointers.length === 0;
  const isErrorRemarksEmpty = errorRemarks.length === 0;
  const isInitialValuesEmpty = initialValues.length === 0;
  const isInitialValuesMultiple = initialValues.length > 1;

  useEffect(() => {
    if (errorReason) {
      handleSetErrorRemarks();
    }

    if (errorRemark) {
      handleSetErrorPointers();
    }

    if (errorPointer && errorReason && errorRemark) {
      handleSetInitialValues();
    }
  }, [errorReason, errorPointer, errorRemark, isErrorPointerEmpty, isErrorRemarksEmpty, isInitialValuesEmpty]);

  const isProcessButtonDisabled = !errorReason || !errorPointer || !errorRemark || !modifyValue;

  const errorPointerData = errorPointer.split('.');

  const getInitialValuePlaceholder = () => {
    let placeholder = errorPointerData[errorPointerData.length - 1];
    if (errorPointer.includes('IE001.GoodsShipment.GoodsShipmentItem')) {
      placeholder = 'HS Code';
    }
    return _.capitalize(placeholder);
  };
  const initialValuePlaceholder = getInitialValuePlaceholder();

  return (
    <Modal
      title={BULK_UPDATE}
      show={show}
      onHide={onHide}
      buttons={[
        <Button type="button" key="cancel-batch-modal-btn" onClick={onHide}>
          {CANCEL_BUTTON}
        </Button>,
        <Button
          type="button"
          primary
          key="create-batch-modal-btn"
          onClick={submitForm}
          disabled={isProcessButtonDisabled}
        >
          {CONTINUE_BUTTON}
        </Button>,
      ]}
      buttonsJustifyAlign="center"
    >
      {isLoading ? (
        <BatchLoadingContainer>
          <Spinner />
        </BatchLoadingContainer>
      ) : (
        <FormContainer onSubmit={handleSubmit}>
          <FormSection verticalPadding={20} topPadding={40} paddingBottom={60}>
            <FormRow>
              <Dropdown
                placeholder={ERROR_MESSAGE_PLACEHOLDER}
                options={errorReasons}
                relativeDropdown={true}
                onChange={(selectedOption) => {
                  setValues({
                    errorReason: selectedOption?.value,
                    errorPointer: '',
                    errorRemark: '',
                    initialValue: '',
                    modifyValue: '',
                  });

                  setError('');
                  setErrorPointers([]);
                  setErrorRemarks([]);
                  setInitialValues([]);
                }}
                maxMenuHeight={200}
              />
            </FormRow>
            {!isErrorRemarksEmpty && (
              <FormRow>
                <Dropdown
                  placeholder={ERROR_REMARKS_PLACEHOLDER}
                  options={errorRemarks}
                  relativeDropdown={true}
                  onChange={(selectedOption) => {
                    setValues({
                      errorReason,
                      errorRemark: selectedOption?.value,
                      errorPointer: '',
                      initialValue: '',
                      modifyValue: '',
                    });

                    setError('');
                    setErrorPointers([]);
                    setInitialValues([]);
                  }}
                  maxMenuHeight={200}
                />
              </FormRow>
            )}
            {!isErrorPointerEmpty && (
              <FormRow>
                <Dropdown
                  placeholder={ERROR_POINTER_PLACEHOLDER}
                  options={errorPointers}
                  relativeDropdown={true}
                  onChange={(selectedOption) => {
                    setValues({
                      errorReason,
                      errorRemark,
                      errorPointer: selectedOption?.value,
                      initialValue: '',
                      modifyValue: '',
                    });
                    setError('');
                    setInitialValues([]);
                  }}
                  maxMenuHeight={200}
                />
              </FormRow>
            )}
            {!isInitialValuesEmpty && !isInitialValuesMultiple && (
              <>
                <FormRow>
                  <Input
                    disabled={true}
                    name="initialValue"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder={initialValuePlaceholder}
                    value={initialValues[0].value}
                    width={100}
                    widthUnit="%"
                  />
                </FormRow>
                <FormRow>
                  <Input
                    name="modifyValue"
                    placeholder={MODIFY_VALUE_PLACEHOLDER}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={modifyValue}
                    width={100}
                    widthUnit="%"
                  />
                </FormRow>
              </>
            )}
            {!isInitialValuesEmpty && isInitialValuesMultiple && (
              <>
                <FormRow>
                  <Dropdown
                    placeholder={INITIAL_VALUE_PLACEHOLDER}
                    options={initialValues}
                    relativeDropdown={true}
                    onChange={(selectedOption) => {
                      setValues({
                        errorReason,
                        errorRemark,
                        errorPointer,
                        initialValue: selectedOption?.value,
                        modifyValue,
                      });
                    }}
                    maxMenuHeight={200}
                  />
                </FormRow>
                <FormRow>
                  <Input
                    name="modifyValue"
                    placeholder={MODIFY_VALUE_PLACEHOLDER}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={modifyValue}
                    width={100}
                    widthUnit="%"
                  />
                </FormRow>
              </>
            )}
          </FormSection>
          {!!error && <NewBatchError>{error}</NewBatchError>}
        </FormContainer>
      )}
    </Modal>
  );
};

export default BulkUpdateBatchModal;
