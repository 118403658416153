import { useFormik } from 'formik';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';

/* eslint-disable complexity */
import { BatchTypeEnum } from '@e-origin/shared';

import BackgroundImage from '../../../../assets/images/bg.png';
import LogoImage from '../../../../assets/images/logo.png';
import { Button, Input } from '../../../../shared/components';
import { clearUserError, selectUserError, signin } from '../../../../shared/stores/userSlice';
import { STORAGE_KEYS } from '../../../../utils';
import { Background, Container, Error, Form, LoginForm, Logo, SubmitButtonWrapper, Title } from './login.style';

interface IFormData {
  email?: string;
  password?: string;
}

const Login = () => {
  const loginError = useSelector(selectUserError);
  const dispatch = useDispatch();
  const history = useHistory();

  const validate = (values: IFormData) => {
    const errors: IFormData = {};
    if (!values.email) {
      errors.email = 'Email Required';
    } else if (!isEmail(values.email)) {
      errors.email = 'Invalid email address';
    }
    if (!values.password) {
      errors.password = 'Password required';
    }
    return errors;
  };

  const navigationCallback = () => {
    history.push('/batches');
  };

  const handleClearLoginError = () => {
    dispatch(clearUserError());
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validate,
    onSubmit: (values) => {
      const callBack = () => {
        if (!Cookies.get(STORAGE_KEYS.COOKIES.BATCH_VIEW)) {
          Cookies.set(STORAGE_KEYS.COOKIES.BATCH_VIEW, BatchTypeEnum.HIGH_VALUES);
        }

        if (Cookies.get('sending') === undefined) {
          Cookies.set('sending', 'true');
        }

        if (Cookies.get('riskAnalysis') === undefined) {
          Cookies.set('riskAnalysis', 'true');
        }

        if (Cookies.get('showAll') === undefined) {
          Cookies.set('showAll', 'true');
        }

        if (Cookies.get('informations') === undefined) {
          Cookies.set('informations', 'true');
        }
      };
      handleClearLoginError();
      dispatch(signin(values.email, values.password, navigationCallback, callBack));
    },
  });

  return (
    <Container>
      <Background src={BackgroundImage} />
      <LoginForm>
        <Logo>
          <img src={LogoImage} alt="" />
        </Logo>
        <Title>Sign in</Title>
        <Form onSubmit={formik.handleSubmit}>
          <Input
            name="email"
            placeholder="Email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
          />
          <Input
            name="password"
            placeholder="Password"
            type="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
          />
          <SubmitButtonWrapper>
            {formik.touched.email && formik.errors.email && <Error>{formik.errors.email}</Error>}
            {!formik.errors.email && formik.touched.password && formik.errors.password && (
              <Error>{formik.errors.password}</Error>
            )}
            {loginError && <Error>{loginError}</Error>}
            <Button type="submit" primary>
              Sign in
            </Button>
          </SubmitButtonWrapper>
          <Link to={'/forgot-password'} onClick={handleClearLoginError}>
            Forgot password?
          </Link>
        </Form>
      </LoginForm>
    </Container>
  );
};

export default Login;
