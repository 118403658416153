/* eslint-disable max-statements */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import _ from 'lodash';
import { toast } from 'react-toastify';

/* eslint-disable complexity */
import { Declarant, GoodLocation, GoodsLocationQualifierIdentification } from '@e-origin/shared';

import { request } from '../../utils';
import { AppThunk, RootState } from './index';
import { getUserInfo } from './userSlice';

const initialState: Declarant = {} as Declarant;

export const declarantSlice = createSlice({
  name: 'declarant',
  initialState,
  reducers: {
    setDeclarant: (state: Declarant, action: PayloadAction<Declarant>) => {
      state._id = action.payload._id;
      state.accountName = action.payload.accountName;
      state.address = action.payload.address;
      state.authorisedIdentity = action.payload.authorisedIdentity;
      state.language = action.payload.language;
      state.contactPerson = action.payload.contactPerson;
      state.customsAuthentication = action.payload.customsAuthentication;
      state.goodsLocations = action.payload.goodsLocations || [];
      state.identificationNumber = action.payload.identificationNumber;
      state.name = action.payload.name;
      state.payments = action.payload.payments;
      state.representative = action.payload.representative;
      state.risksCalculation = action.payload.risksCalculation;
      state.validationSettings = action.payload.validationSettings;
      state.hasExternalSystemCustoms = action.payload.hasExternalSystemCustoms;
      state.hasInvoiceTemplate = action.payload.hasInvoiceTemplate;
      state.hasWallet = action.payload.hasWallet;
    },
    setGoodsLocations: (state: Declarant, action: PayloadAction<GoodLocation[]>) => {
      state.goodsLocations = action.payload.map((location: any) => {
        let city = 'N/A';
        if (location.address && location.address.city) {
          city = location.address.city;
        }
        let postcode = 'N/A';
        if (
          location.qualifierOfIdentification === GoodsLocationQualifierIdentification.POSTCODE_ADDRESS &&
          location.postcode?.postcode
        ) {
          postcode = location.postcode.postcode;
        } else if (
          location.qualifierOfIdentification === GoodsLocationQualifierIdentification.ADDRESS &&
          location.address?.postcode
        ) {
          postcode = location.address.postcode;
        }
        return { ...location, displayedCity: city, displayedPostcode: postcode };
      });
    },
  },
});

export const { setDeclarant, setGoodsLocations } = declarantSlice.actions;

export const selectDeclarant = (state: RootState): Declarant => state.declarant;

export const selectDeclarantName = (state: RootState): Declarant['name'] => state.declarant.name;

export const selectDeclarantPayments = (state: RootState): Declarant['payments'] => state.declarant.payments;

export const selectAccountName = (state: RootState) => state.declarant.accountName;

export const selectDeclarantGoodsLocations = (state: RootState) => state.declarant.goodsLocations as GoodLocation[];

export const createWallet = (): AppThunk => async (dispatch: any, getState: any) => {
  try {
    await request({
      path: 'declarant/create-wallet',
      method: 'POST',
      authenticate: true,
      dataObject: {},
    });

    dispatch(
      setDeclarant({
        ...getState().declarant,
        hasWallet: true,
      }),
    );
    toast.success('Wallet created!');
  } catch (error) {
    toast.error('Error creating the wallet!');
  }
};

export const updateDeclarantBasicData =
  (declarantData: Partial<Declarant>): AppThunk =>
  async (dispatch: any, getState: any) => {
    try {
      await request({
        path: 'declarant',
        method: 'PATCH',
        authenticate: true,
        dataObject: { _id: getState().declarant._id, ...declarantData },
      });
      dispatch(getUserInfo());
      toast.success('Declarant was updated!');
    } catch (error) {
      toast.error('Error updating the declarant!');
    }
  };

export const fetchGoodsLocation = (): AppThunk<Promise<void>> => async (dispatch: any, getState: any) => {
  try {
    const { data } = await request({
      path: `declarant/${getState().declarant._id}/goods-locations`,
      method: 'GET',
      authenticate: true,
    });
    dispatch(setGoodsLocations(data));
  } catch (error) {
    toast.error('Error fetching the goods locations!');
  }
};

export const saveGoodsLocation =
  (goodLocation: GoodLocation): AppThunk<Promise<void>> =>
  async (dispatch: any, getState: any) => {
    const isUpdate = goodLocation._id && goodLocation._id.length > 0;
    if (!isUpdate) {
      goodLocation = _.omit(goodLocation, ['_id']) as GoodLocation;
    }
    try {
      await request({
        path: `declarant/${getState().declarant._id}/good-location`,
        method: isUpdate ? 'PATCH' : 'POST',
        authenticate: true,
        dataObject: goodLocation,
      });
      dispatch(fetchGoodsLocation());
      toast.success(`${isUpdate ? 'Updated' : 'Added'} the good location!`);
    } catch (error) {
      const axiosError = error as AxiosError;

      toast.error(
        `Error ${isUpdate ? 'updating' : 'adding'} the good location! ${
          axiosError.response?.data?.message ? axiosError.response?.data?.message : ''
        }`,
      );
    }
  };

export default declarantSlice.reducer;
