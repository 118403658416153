/* eslint-disable max-statements */
import { FC, useEffect, useState } from 'react';

import { container, TYPES } from '@e-origin/ioc';
/* eslint-disable complexity */
import { Declaration, DeclarationGoods, IRiskAnalysisPriceNewValues } from '@e-origin/shared';

import { FormatValueTypes, InputErrorStatus } from '../../../../../../../../enums';
import { InputsComparator } from '../../../../../../../../shared/components';
import { formatValue } from '../../../../../../../../utils';
import { RiskAnalysisService } from '../../../../../../services';
import { ManuallySetPriceModal } from '../../modals';
import { ValidateAllStateEnum } from '../../risk-analysis-modal.component';
import {
  Checkbox,
  CheckboxContainer,
  HorizontalLine,
  RiskAnalysisSection,
  RiskAnalysisSectionContent,
  RiskAnalysisSectionHeader,
} from '../../risk-analysis-modal.styles';
import * as Style from './risk-analysis-price.style';

const VALIDATE_VALUES = 'Validate the values';
const EVAL_METHOD_WEB_VALUE = 'EvalMethod Web Value';
const INVOICE_ITEM_VALUE = 'Invoiced item value';
const INVOICED_VALUE = 'Invoiced value';
const WEB_VALUE = 'Web value';
const MANUALLY_SET_THE_PRICE = 'Manually set the price';
const EVAL_METHOD_STATISTICAL_HIGHEST_VALUE = 'EvalMethod Statistical Highest Value';
const EVAL_METHOD_STATISTICAL_LOWEST_VALUE = 'EvalMethod Statistical Lowest Value';
const EVAL_METHOD_STATISTICAL_VALUE = 'EvalMethod Statistical Value';

interface IRiskAnalysisPriceProps {
  goodDetails: DeclarationGoods;
  declaration: Declaration;
  update: (obj: IRiskAnalysisPriceNewValues) => void;
  validate: ValidateAllStateEnum;
}

export const RiskAnalysisPrice: FC<IRiskAnalysisPriceProps> = ({ declaration, goodDetails, update, validate }) => {
  const riskAnalysisService: RiskAnalysisService = container.get(TYPES.RiskAnalysisService);
  const [newPriceSaved, setNewPriceSaved] = useState(null);
  const [validatedValueCheck, setValidatedValueCheck] = useState(!!goodDetails.evalMethodWebValue?.validatedValueCheck);

  const validatedValuesCheckDisabled = riskAnalysisService.evalMethodWebValueCheckDisabled(goodDetails);

  const evalMethodWebInvoicedValue = formatValue(
    goodDetails?.evalMethodWebValue?.invoicedAmountPerKg || 0,
    FormatValueTypes.VALUE,
  );

  const invoicedItemValue = formatValue(
    goodDetails.itemAmountInvoiced / goodDetails.exchangeRate || 0,
    FormatValueTypes.TOTAL_VALUE,
  );

  const [invoicedRateOk, setInvoicedRateOk] = useState(goodDetails.evalMethodWebValue?.invoicedRateOk);
  const [invoicedRateValue, setInvoicedRateValue] = useState(goodDetails.evalMethodWebValue?.invoicedRate || 0);
  const evalMethodStatisticalRateLowestOk =
    goodDetails.evalMethodAiHsCode.suggestedHsCode && goodDetails.evalMethodStatisticalValue?.suggestedLowest
      ? goodDetails.evalMethodStatisticalValue?.suggestedLowest?.madbInvoicedRateOk
      : goodDetails.evalMethodStatisticalValue?.lowest?.madbInvoicedRateOk;
  const evalMethodStatisticalRateHighestOk =
    goodDetails.evalMethodAiHsCode.suggestedHsCode && goodDetails.evalMethodStatisticalValue?.suggestedHighest
      ? goodDetails.evalMethodStatisticalValue?.suggestedHighest?.madbInvoicedRateOk
      : goodDetails.evalMethodStatisticalValue?.highest?.madbInvoicedRateOk;

  const isEvalMethodStatisticalLowestValueExist =
    goodDetails.evalMethodAiHsCode.suggestedHsCode && goodDetails.evalMethodStatisticalValue?.suggestedLowest
      ? !!goodDetails.evalMethodStatisticalValue?.suggestedLowest
      : !!goodDetails.evalMethodStatisticalValue?.lowest;
  const isEvalMethodStatisticalHighestValueExist =
    goodDetails.evalMethodAiHsCode.suggestedHsCode && goodDetails.evalMethodStatisticalValue?.suggestedHighest
      ? !!goodDetails.evalMethodStatisticalValue?.suggestedHighest
      : !!goodDetails.evalMethodStatisticalValue?.highest;
  const isOnlyEvalMethodStatisticalLowestValueExist =
    isEvalMethodStatisticalLowestValueExist && isEvalMethodStatisticalHighestValueExist;

  const IMADB_VALUE = `IMADB value for
    ${goodDetails.evalMethodAiHsCode.suggestedHsCode || goodDetails.commodityCode.hsCode}`;

  const evalMethodStatisticalLowestValue = isOnlyEvalMethodStatisticalLowestValueExist
    ? EVAL_METHOD_STATISTICAL_VALUE
    : EVAL_METHOD_STATISTICAL_LOWEST_VALUE;

  const evalMethodStatisticalIMADBLowestValue = formatValue(
    (goodDetails.evalMethodAiHsCode.suggestedHsCode && isEvalMethodStatisticalLowestValueExist
      ? goodDetails.evalMethodStatisticalValue?.suggestedLowest?.madbAmountPerKg
      : goodDetails.evalMethodStatisticalValue?.lowest?.madbAmountPerKg) || 0,
    FormatValueTypes.VALUE,
  );

  const evalMethodStatisticalLowestInvoicedRate = formatValue(
    (goodDetails.evalMethodAiHsCode.suggestedHsCode && isEvalMethodStatisticalLowestValueExist
      ? goodDetails.evalMethodStatisticalValue?.suggestedLowest?.madbInvoicedRate
      : goodDetails.evalMethodStatisticalValue?.lowest?.madbInvoicedRate) || 0,
    FormatValueTypes.RATE,
  );

  const evalMethodStatisticalIMADBHighestValue = formatValue(
    (goodDetails.evalMethodAiHsCode.suggestedHsCode &&
    isEvalMethodStatisticalHighestValueExist &&
    goodDetails.evalMethodStatisticalValue?.suggestedHighest?.madbAmountPerKg
      ? goodDetails.evalMethodStatisticalValue?.suggestedHighest?.madbAmountPerKg
      : goodDetails.evalMethodStatisticalValue?.highest?.madbAmountPerKg) || 0,
    FormatValueTypes.VALUE,
  );

  const evalMethodStatisticalHighestInvoicedRate = formatValue(
    (goodDetails.evalMethodAiHsCode.suggestedHsCode && isEvalMethodStatisticalHighestValueExist
      ? goodDetails.evalMethodStatisticalValue?.suggestedHighest?.madbInvoicedRate
      : goodDetails.evalMethodStatisticalValue?.highest?.madbInvoicedRate) || 0,
    FormatValueTypes.RATE,
  );

  const [manuallySetPrice, setManuallySetPrice] = useState(false);

  const toggleManuallySetPriceModal = async (newPrice?: number) => {
    if (newPrice) {
      setNewPriceSaved(newPrice);
    }
    setManuallySetPrice(!manuallySetPrice);
  };

  useEffect(() => {
    setValidatedValueCheck(!!goodDetails.evalMethodWebValue?.validatedValueCheck);
    setNewPriceSaved(null);
    setInvoicedRateOk(goodDetails.evalMethodWebValue?.invoicedRateOk);
    setInvoicedRateValue(goodDetails.evalMethodWebValue?.invoicedRate || 0);
  }, [goodDetails]);

  useEffect(() => {
    if (validate === null || validate === ValidateAllStateEnum.SOME_SELECTED) {
      return;
    }
    setValidatedValueCheck(validate === ValidateAllStateEnum.ALL_SELECTED && !validatedValuesCheckDisabled);
  }, [validate]);

  useEffect(() => {
    update({
      invoicedRate: invoicedRateValue,
      invoicedRateOk,
      newPriceSaved,
      validatedValueCheck,
      validatedValuesCheckDisabled,
    });
  }, [validatedValueCheck, invoicedRateValue, invoicedRateOk]);

  return (
    <>
      {manuallySetPrice && (
        <ManuallySetPriceModal
          onHide={(newPrice?: any) => {
            toggleManuallySetPriceModal(newPrice);
          }}
          declaration={declaration}
          goodDetails={goodDetails}
        />
      )}
      <RiskAnalysisSection>
        <RiskAnalysisSectionHeader>
          <h4>Values</h4>
          <CheckboxContainer>
            <Checkbox
              name={'validatedValueCheck'}
              checked={validatedValueCheck}
              onChange={() => {
                setValidatedValueCheck(!validatedValueCheck);
              }}
              type={'checkbox'}
              disabled={validatedValuesCheckDisabled}
            />
            <p style={{ color: '#555454' }}>{VALIDATE_VALUES}</p>
          </CheckboxContainer>
        </RiskAnalysisSectionHeader>
        <RiskAnalysisSectionContent>
          <InputsComparator
            compact={true}
            title={EVAL_METHOD_WEB_VALUE}
            placeholders={[INVOICE_ITEM_VALUE, WEB_VALUE]}
            values={[
              invoicedItemValue,
              formatValue(
                newPriceSaved || goodDetails.webScraping.webItemAmountResale || 0,
                FormatValueTypes.TOTAL_VALUE,
              ),
            ]}
            badge={{
              status: invoicedRateOk ? InputErrorStatus.SUCCESS : InputErrorStatus.ERROR,
              text: (
                <div>
                  <div>
                    Calculated rate{' '}
                    {formatValue(goodDetails.evalMethodWebValue.invoicedRate * 100 || 0, FormatValueTypes.PERCENTAGE)}%
                  </div>
                  {!invoicedRateOk && (
                    <div>
                      Reasonable Value {goodDetails.suggestedValues?.unitValue}€{'  '}
                      {formatValue(goodDetails.evalMethodWebValue.webValueRate * 100 || 0, FormatValueTypes.PERCENTAGE)}
                      %
                    </div>
                  )}
                </div>
              ),
            }}
            disabled={[true, true]}
          />
          <Style.SetPriceContainer>
            <Style.SetPriceButton onClick={() => toggleManuallySetPriceModal()}>
              {MANUALLY_SET_THE_PRICE}
            </Style.SetPriceButton>
          </Style.SetPriceContainer>
          {!isEvalMethodStatisticalLowestValueExist && !isEvalMethodStatisticalHighestValueExist && (
            <>
              <HorizontalLine />
              <InputsComparator
                compact={true}
                title={EVAL_METHOD_STATISTICAL_VALUE}
                placeholders={[INVOICED_VALUE, IMADB_VALUE]}
                values={[evalMethodWebInvoicedValue, formatValue(undefined, FormatValueTypes.VALUE)]}
                disabled={[true, true]}
              />
            </>
          )}
          {isEvalMethodStatisticalLowestValueExist && (
            <>
              <HorizontalLine />
              <InputsComparator
                compact={true}
                title={evalMethodStatisticalLowestValue}
                placeholders={[INVOICED_VALUE, IMADB_VALUE]}
                values={[evalMethodWebInvoicedValue, evalMethodStatisticalIMADBLowestValue]}
                badge={{
                  status: evalMethodStatisticalRateLowestOk ? InputErrorStatus.SUCCESS : InputErrorStatus.ERROR,
                  text: `Calculated rate ${evalMethodStatisticalLowestInvoicedRate}`,
                }}
                disabled={[true, true]}
              />
            </>
          )}
          {isEvalMethodStatisticalHighestValueExist && (
            <>
              <HorizontalLine />
              <InputsComparator
                compact={true}
                title={EVAL_METHOD_STATISTICAL_HIGHEST_VALUE}
                placeholders={[INVOICED_VALUE, IMADB_VALUE]}
                values={[evalMethodWebInvoicedValue, evalMethodStatisticalIMADBHighestValue]}
                badge={{
                  status: evalMethodStatisticalRateHighestOk ? InputErrorStatus.SUCCESS : InputErrorStatus.ERROR,
                  text: `Calculated rate ${evalMethodStatisticalHighestInvoicedRate}`,
                }}
                disabled={[true, true]}
              />
            </>
          )}
        </RiskAnalysisSectionContent>
      </RiskAnalysisSection>
    </>
  );
};
