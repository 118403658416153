import { Declaration } from '@e-origin/shared';

import { StatusIcons } from '../../../../../../../assets';
import { Container, SvgIcon } from '../declaration-status-indicator.style';

interface IDeclarationLVStatusIndicator {
  declaration: Declaration;
}

const DeclarationLVStatusIndicator: React.FC<IDeclarationLVStatusIndicator> = ({ declaration }) => {
  if (declaration.generalInfo.complianceCheck) {
    return (
      <Container>
        {declaration.goodShipmentGlobal?.splitShipment ? (
          <SvgIcon>
            <img src={StatusIcons.YELLOW_WARNING} alt={'anomalies-detected-value'} />
          </SvgIcon>
        ) : (
          <SvgIcon>
            <img src={StatusIcons.GREEN_CIRCLE} alt={'no-anomalies-detected-value'} />
          </SvgIcon>
        )}
      </Container>
    );
  }

  if (declaration.goodShipmentGlobal?.wrongIOSS || declaration.goodShipmentGlobal?.undervaluation) {
    return (
      <Container>
        <SvgIcon>
          <img src={StatusIcons.YELLOW_WARNING} alt={'anomalies-detected-value'} />
        </SvgIcon>
      </Container>
    );
  }

  return (
    <Container>
      <SvgIcon>
        <img src={StatusIcons.NOT_SENT} alt={'no-risk-analysis-done'} />
      </SvgIcon>
    </Container>
  );
};

export default DeclarationLVStatusIndicator;
