/* eslint-disable complexity */
import { FormikProps } from 'formik';

import { FormContainer, FormSection, FormSectionTitle } from '../../../../../../../../../../styles/common';
import { TransitDuplicateRow } from '../../../../../../transit-duplicate-row';
import { HouseItemFormInitializerReturn } from '../house-form-initializer';

interface HouseTransportProps {
  form: FormikProps<HouseItemFormInitializerReturn>;
  disabled: boolean;
  editDisabledForConsignment: boolean;
}

export const HouseTransport: React.FC<HouseTransportProps> = (props) => {
  const { form, editDisabledForConsignment } = props;

  return (
    <>
      <FormContainer>
        <FormSection verticalPadding={0} noTopPadding>
          <FormSectionTitle noTopBorder>Departure transport means</FormSectionTitle>
          <TransitDuplicateRow
            form={form}
            addButtonLabel="Add Departure Transport Means"
            inputs={[
              {
                type: 'MultiLang',
                name: 'typeOfIdentification',
                label: 'Type of identification',
                code: 'CL750',
              },
              {
                type: 'Input',
                name: 'identificationNumber',
                label: 'Identification number',
              },
              {
                type: 'MultiLang',
                name: 'nationality',
                label: 'Nationality',
                code: 'CL165',
              },
            ]}
            compact
            arrayPath="DepartureTransportMeans"
            disabled={editDisabledForConsignment}
          />
        </FormSection>
      </FormContainer>
    </>
  );
};
