import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';

/* eslint-disable no-param-reassign */
import { Chart } from '@e-origin/shared';

import { request } from '../../utils';
import { AppThunk, RootState } from './index';

interface IChartsState {
  token: string;
  list: Chart[];
  loadingList: boolean;
  loadingToken: boolean;
}

const initialState: IChartsState = {
  token: '',
  list: [],
  loadingList: false,
  loadingToken: false,
};

export const chartsSlice = createSlice({
  name: 'charts',
  initialState,
  reducers: {
    setList: (state: IChartsState, action: PayloadAction<Chart[]>) => {
      state.list = action.payload.map((item: any) => ({
        ...item,
        updatedAt: moment(item.updatedAt).format('DD-MM-YY @ HH:mm'),
      }));
      state.loadingList = false;
    },
    setToken: (state: IChartsState, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setLoadingList: (state: IChartsState, action: PayloadAction<boolean>) => {
      state.loadingList = action.payload;
    },
    setLoadingToken: (state: IChartsState, action: PayloadAction<boolean>) => {
      state.loadingToken = action.payload;
    },
  },
});

export const { setList, setToken, setLoadingList, setLoadingToken } = chartsSlice.actions;

export const selectCharts = (state: RootState) => state.charts.list;

export const selectToken = (state: RootState) => state.charts.token;

export const selectLoadingCharts = (state: RootState) => state.charts.loadingList;

export const selectLoadingToken = (state: RootState) => state.charts.loadingToken;

export const fetchCharts = (): AppThunk<Promise<void>> => async (dispatch: any) => {
  try {
    dispatch(setLoadingList(true));
    const { data } = await request({
      path: `chart`,
      method: 'GET',
      authenticate: true,
    });
    dispatch(setList(data.sort((c1, c2) => c1.order - c2.order)));
    dispatch(setLoadingList(false));
  } catch (error) {
    dispatch(setLoadingList(false));
    const axiosError = error as AxiosError;
    toast.error(axiosError.response?.data.message || 'Something went wrong');
  }
};

export const fetchToken = (): AppThunk<Promise<void>> => async (dispatch: any) => {
  try {
    dispatch(setLoadingToken(true));
    const token = await request({
      path: `chart/auth`,
      method: 'GET',
      authenticate: true,
    });
    dispatch(setToken(token));
    dispatch(setLoadingToken(false));
  } catch (error) {
    dispatch(setLoadingToken(false));
    const axiosError = error as AxiosError;
    toast.error(axiosError.response?.data.message || 'Something went wrong');
  }
};

export default chartsSlice.reducer;
