/* eslint-disable max-classes-per-file, max-lines, camelcase */
import { prop, Severity } from '@typegoose/typegoose';
import mongoose, { Types } from 'mongoose';

import { DeclarationCustomsType, DeclarationStatus } from '../enums';
import { BaseModel } from './base.model';
import { Declarant } from './declarant';
import {
  AdditionalFiscalReference,
  AdditionalInformation,
  AdditionsAndDeductionsDocument,
  CalculationResult,
  PreviousDocument,
  SupportingDocument,
  TransportDocument,
} from './declaration';
import { DomainCollection } from './domain.collection';

export class HvExtractMRNItems {
  @prop(() => [AdditionalFiscalReference])
  documents_additionalFiscalReference: AdditionalFiscalReference[];

  @prop(() => [AdditionsAndDeductionsDocument])
  documents_additionsAndDeductionsDocuments: AdditionsAndDeductionsDocument[];

  @prop(() => [AdditionalInformation])
  documents_additionalInformations: AdditionalInformation[];

  @prop(() => [SupportingDocument])
  documents_supportingDocuments: SupportingDocument[];

  @prop(() => [PreviousDocument])
  documents_previousDocuments: PreviousDocument[];

  @prop(() => TransportDocument)
  documents_transportDocuments: TransportDocument;

  @prop()
  goodShipmentGlobal_totalQuantitySupplementaryUnits: number;

  @prop()
  goodsShipmentItems_evalMethodAiHsCode_taricSupplementaryUnits_codeQualifyer: string;

  @prop()
  goodShipmentGlobal_firstItemData_hsCode: string;

  @prop()
  goodsShipmentItems_packaging_shippingMarks: string;

  @prop()
  consignment_countryOfOrigin: string;

  @prop()
  goodShipmentGlobal_totalGrossMass: number;

  @prop()
  goodShipmentGlobal_totalNetMass: number;

  @prop()
  goodShipmentGlobal_totalAmountInvoiced: number;

  @prop()
  total_customsState_calculationResults_items_dutyBase_amount: number;

  @prop()
  total_customsState_calculationResults_items_vatBase_amount: number;

  @prop(() => [CalculationResult])
  customsState_calculationResults_items: CalculationResult[];

  @prop()
  valuationInformationTaxes_chargesimport_CustomsCharges_charges: number;

  @prop()
  valuationInformationTaxes_chargesimport_VATcharges_charges: number;

  @prop()
  goodsShipmentGlobal_totalQuantity: number;

  @prop()
  goodsShipmentGlobal_totalNumberOfPackages: number;

  @prop()
  goodsShipmentGlobal_firstItemData_invoiceDescription: string;

  @prop()
  goodsShipmentGlobal_exchangeRate: number;

  @prop()
  goodsShipmentItems_packaging_typeOfPackages: string;

  @prop()
  customsReport_exchangeRate: number;
}

@DomainCollection({
  schemaOptions: { strict: false, collection: 'HVExtractMRN' },
  options: { allowMixed: Severity.ALLOW },
})
export class HvExtractMRN extends BaseModel {
  @prop()
  messageInfo_LRN: string;

  @prop()
  messageInfo_batch_lookup_batches_name: string;

  @prop()
  messageInfo_batch_lookup_batches_createdAt: Date;

  @prop()
  consignment_referenceNumberUCR: string;

  @prop()
  messageInfo_batch_lookup_batches_customer_lookup_customers_name: string;

  @prop()
  messageInfo_declarationMainType: string;

  @prop()
  messageInfo_declarationType: string;

  @prop({ ref: () => Declarant })
  declarant_id: Types.ObjectId;

  @prop({ enum: DeclarationStatus, type: String })
  generalInfo_status: DeclarationStatus;

  @prop({ enum: DeclarationCustomsType, type: String })
  customsState_sentTo: DeclarationCustomsType;

  @prop()
  customsState_movementReference: string;

  @prop()
  customsState_releaseForImport_dateOfRelease: Date;

  @prop()
  goodShipmentGlobal_customsProcedure: string;

  @prop()
  consignment_countryOfDestination: string;

  @prop()
  customsReport_declarant_representative_status: string;

  @prop()
  stakeholders_exporter_name: string;

  @prop()
  stakeholders_exporter_address_street: string;

  @prop()
  stakeholders_exporter_postcode: string;

  @prop()
  stakeholders_exporter_city: string;

  @prop()
  stakeholders_exporter_country: string;

  @prop()
  stakeholders_importer_identificationNumber: string;

  @prop()
  stakeholders_importer_address_country: string;

  @prop()
  stakeholders_intracom_name: string;

  @prop()
  stakeholders_intracom_address_street: string;

  @prop()
  stakeholders_intracom_address_postcode: string;

  @prop()
  stakeholders_intracom_address_city: string;

  @prop()
  stakeholders_intracom_address_country: string;

  @prop()
  stakeholders_intracom_identificationNumber: string;

  @prop()
  valuationInformationTaxes_deliveryTerms_incotermCode: string;

  @prop()
  messageInfo_methodOfPayment: string;

  @prop()
  messageInfo_deferredPayment: string;

  @prop()
  goodShipmentGlobal_invoiceCurrency: string;

  @prop()
  otherDataElements_registrationNumber: string;

  @prop()
  customsState_calculationResults_total: number;

  @prop()
  messageInfo_batch_counter: number;

  @prop()
  consignment_customsOfficePresentation_issuingPlaceCode: string;

  @prop()
  countOfArticles: number;

  @prop()
  total_max_customsState_calculationResults_items_vatBase_amount_42: number;

  @prop()
  total_max_customsState_calculationResults_items_vatBase_amount_40: number;

  @prop()
  total_max_customsState_calculationResults_items_vatBase_amount: number;

  @prop()
  transportInformation_inlandModeOfTransport: string;

  @prop({ type: () => [mongoose.Schema.Types.Mixed], allowMixed: Severity.ALLOW })
  items: HvExtractMRNItems[];
}
