/* eslint-disable max-statements */
/* eslint-disable complexity */
import { useEffect, useRef, useState } from 'react';

import closeIcon from '../../../assets/icons/close-icon.png';
import { Backdrop, ModalBody, ModalButtons, ModalContainer, ModalHeader, StyledModal } from './modal.style';

interface IModalProps {
  show: boolean;
  /**
   * The width of modal in percentage
   */
  width?: number;
  /**
   * max allowed width in px
   */
  maxWidth?: number;
  title: string;
  onHide: () => void;
  buttons?: React.ReactNode[];
  buttonsJustifyAlign?: string;
  selectedTab?: any;
  setSelectedTab?: any;
  isDescriptionError?: boolean;
  isHcCodeError?: boolean;
  isValuesError?: boolean;
  isWeightError?: boolean;
}

const DEFAULT_WIDTH = 600;
const LEFT_POSITION = 50;
const TOP_POSITION = 50;

/**
 * @deprecated Use CustomModal instead
 */
export const Modal: React.FC<IModalProps> = (props: any) => {
  const [width, setWidth] = useState(0);
  const [maxHeight, setMaxHeight] = useState(0);

  const bodyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (props.show) {
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;

      if (bodyRef && bodyRef.current !== null) {
        const maxModalHeight = windowHeight - windowHeight * 0.2;

        if (bodyRef.current.scrollHeight + 150 > maxModalHeight) {
          setMaxHeight(maxModalHeight - 150);
        }
      }

      let modalWidth = 0;
      if (props.width) {
        modalWidth = (windowWidth * props.width) / 100;
        if (props.maxWidth && modalWidth > props.maxWidth) {
          modalWidth = props.maxWidth;
        }
      } else {
        modalWidth = DEFAULT_WIDTH;
      }
      setWidth(modalWidth);
    }
  }, [props.show]);

  const renderBackdrop = (properties: any) => <Backdrop {...properties} />;

  return (
    <StyledModal
      show={props.show}
      onHide={props.onHide}
      renderBackdrop={renderBackdrop}
      aria-labelledby="modal-label"
      width={width}
      left={LEFT_POSITION}
      top={TOP_POSITION}
    >
      <ModalContainer>
        <ModalHeader>
          <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            <h4 id="modal-label">{props.title}</h4>
            <button onClick={props.onHide}>
              <img src={closeIcon} alt="" />
            </button>
          </div>
        </ModalHeader>
        <ModalBody ref={bodyRef} maxHeight={maxHeight}>
          {props.children}
        </ModalBody>
        {props.buttons && <ModalButtons justifyAlign={props.buttonsJustifyAlign}>{props.buttons}</ModalButtons>}
      </ModalContainer>
    </StyledModal>
  );
};
