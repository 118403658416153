import { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '../';
import BackgroundImage from '../../../assets/images/bg.png';
import LogoImage from '../../../assets/images/logo.png';
import { Background, Container, Logo, ResetPasswordForm, Title } from './password-updated-container.style';

export const PasswordUpdatedContainer: FC = () => {
  const history = useHistory();

  const signInCallback = () => {
    history.push('/signin');
  };

  return (
    <Container>
      <Background src={BackgroundImage} />
      <ResetPasswordForm>
        <Logo>
          <img src={LogoImage} alt="" />
        </Logo>
        <Title>Password successfully updated</Title>
        <Button type="submit" primary onClick={signInCallback}>
          Go to sign in
        </Button>
      </ResetPasswordForm>
    </Container>
  );
};
