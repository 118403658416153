/* eslint-disable complexity */
/* eslint-disable max-statements */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Spinner } from '../../../shared/components';
import {
  fetchCharts,
  fetchToken,
  selectCharts,
  selectLoadingCharts,
  selectLoadingToken,
  selectToken,
} from '../../../shared/stores/chartsSlice';
import { PageContainer, PageHeader, PageTitle } from '../../../styles/common';
import { MongoDashboardChart } from './mongo-dashboard-chart';
import { Container, List, LoadingAndNoDataContainer } from './mongo-dashboard.style';

export const MongoDashboard = () => {
  const dispatch = useDispatch();
  const chartsList = useSelector(selectCharts);
  const isChartsLoading = useSelector(selectLoadingCharts);
  const token = useSelector(selectToken);
  const isTokenLoading = useSelector(selectLoadingToken);

  useEffect(() => {
    dispatch(fetchCharts());
  }, []);

  useEffect(() => {
    if (chartsList?.length) {
      dispatch(fetchToken());
    }
  }, [chartsList]);

  return (
    <PageContainer isRelative className="batch-list">
      <>
        <PageHeader>
          <PageTitle>Dashboard</PageTitle>
        </PageHeader>

        <Container>
          {(isChartsLoading || isTokenLoading) && (
            <LoadingAndNoDataContainer>
              <Spinner />
            </LoadingAndNoDataContainer>
          )}
          {!isChartsLoading && !isTokenLoading && (
            <>
              {!!chartsList.length && (
                <List>
                  {chartsList.map((chart) => (
                    <MongoDashboardChart key={chart.name + chart.order} token={token} chart={chart} />
                  ))}
                </List>
              )}
              {!chartsList.length && (
                <LoadingAndNoDataContainer>
                  <div>No data found</div>
                </LoadingAndNoDataContainer>
              )}
            </>
          )}
        </Container>
      </>
    </PageContainer>
  );
};
