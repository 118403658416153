/* eslint-disable complexity */
import { useSelector } from 'react-redux';

import { Tab, Tabs } from '../../../shared/components';
import { useConfig } from '../../../shared/hooks/use-config';
import { selectSettings } from '../../../shared/stores/settingsSlice';
import { PageContainer, PageHeader, PageTitle } from '../../../styles/common';
import { B2CReports } from './b2c-reports/b2c-reports.component';
import { BrokerWallet } from './broker-wallet/broker-wallet.component';
import CustomsAuthenticationForm from './customs-authentication-form.component';
import DeclarantForm from './declarant-form.component';
import GoodsLocation from './goods-location/goods-location.component';
import PaymentsForm from './payments/payments-form.component';
import RepresentativeForm from './representative-form.component';
import RisksCalculationForm from './risks-calculation/risks-calculation-form.component';
import ValidationSettingsForm from './validation-settings-form.component';
import { VATReports } from './vat-reports/vat-reports.component';

const ManageAccount = () => {
  const settings = useSelector(selectSettings);
  const { config } = useConfig();

  return (
    <PageContainer>
      <PageHeader>
        <PageTitle>Manage Account</PageTitle>
      </PageHeader>

      <Tabs
        style={{ padding: '0 50px', marginTop: 20 }}
        contentStyle={{ padding: '0 50px', overflow: 'auto', height: '100%' }}
      >
        {!config?.IS_FORWARDER && (
          <Tab label="Declarant (LV)">
            <DeclarantForm />
          </Tab>
        )}

        {!config?.IS_FORWARDER && (
          <Tab label="Representative (LV)">
            <RepresentativeForm />
          </Tab>
        )}

        {!config?.IS_FORWARDER && (
          <Tab label="Customs authentication">
            <CustomsAuthenticationForm />
          </Tab>
        )}
        <Tab label="Location of goods">
          <GoodsLocation />
        </Tab>
        {!config?.IS_FORWARDER && (
          <Tab label="Payments">
            <PaymentsForm />
          </Tab>
        )}
        <Tab label="Risks calculation">
          <RisksCalculationForm />
        </Tab>
        <Tab label="Validation Settings">
          <ValidationSettingsForm />
        </Tab>
        {!config?.IS_FORWARDER && (
          <Tab label="VAT reports">
            <VATReports />
          </Tab>
        )}
        {!config?.IS_FORWARDER && (
          <Tab label="Historical data">
            <B2CReports />
          </Tab>
        )}
        {settings.walletEnabled && (
          <Tab label="Wallet">
            <BrokerWallet />
          </Tab>
        )}
      </Tabs>
    </PageContainer>
  );
};

export default ManageAccount;
