import { useFormik } from 'formik';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/* eslint-disable complexity */
import { DeclarantRiskCalculation } from '@e-origin/shared';

import { Button, Input } from '../../../../shared/components';
import { selectDeclarant, updateDeclarantBasicData } from '../../../../shared/stores/declarantSlice';
import { selectSettings } from '../../../../shared/stores/settingsSlice';
import { FormCheckbox, FormContainer, FormRow, FormSection } from '../../../../styles/common';
import { RisksCalculationAdditionalText, RisksCalculationTitle } from './risks-calculation-form.styles';

const INPUT_WIDTH = 387;

const RisksCalculationForm: FC = () => {
  const dispatch = useDispatch();
  const declarant = useSelector(selectDeclarant);
  const settings = useSelector(selectSettings);

  const formik = useFormik({
    initialValues: {
      risksCalculation: {
        riskAnalysisEnabled: declarant.risksCalculation?.riskAnalysisEnabled || false,
        amazonFeesCalculationEnabled: declarant.risksCalculation?.amazonFeesCalculationEnabled || false,
        screenshotsEnabled: declarant.risksCalculation?.screenshotsEnabled || false,
        aiModuleEnabled: declarant.risksCalculation?.aiModuleEnabled || false,
        penaltyDescription: declarant.risksCalculation?.penaltyDescription || 0,
        penaltyValue: declarant.risksCalculation?.penaltyValue || 0,
        penaltyHSCode: declarant.risksCalculation?.penaltyHSCode || 0,
        requestProofEnabled: declarant.risksCalculation?.requestProofEnabled || false,
        vatValidationEnabled: declarant.risksCalculation?.vatValidationEnabled || false,
        eoriValidationEnabled: declarant.risksCalculation?.eoriValidationEnabled || false,
      } as DeclarantRiskCalculation,
    },
    onSubmit: (values: any) => {
      dispatch(updateDeclarantBasicData(values));
    },
  });

  const displayCustomsProof = settings.walletEnabled && declarant.hasWallet;

  return (
    <FormContainer onSubmit={formik.handleSubmit}>
      <FormSection topPadding={40} paddingBottom={0}>
        <RisksCalculationTitle>Web method value, description & snapshot</RisksCalculationTitle>
        <RisksCalculationAdditionalText>
          If you enable Risk analysis, you will run the risk analysis for your declarations, enhanced with the web
          values and web descriptions.
        </RisksCalculationAdditionalText>
        <FormRow>
          <FormCheckbox>
            <input
              name="risksCalculation.riskAnalysisEnabled"
              type="checkbox"
              checked={formik.values.risksCalculation.riskAnalysisEnabled}
              onChange={formik.handleChange}
            />
            <span>Risk analysis enabled</span>
          </FormCheckbox>
        </FormRow>
        <RisksCalculationAdditionalText>
          If you enable amazon fees calculations, you will run the amazon calculator for each declarations with an
          amazon web url.
        </RisksCalculationAdditionalText>
        <FormRow>
          <FormCheckbox>
            <input
              name="risksCalculation.amazonFeesCalculationEnabled"
              type="checkbox"
              checked={formik.values.risksCalculation.amazonFeesCalculationEnabled}
              onChange={formik.handleChange}
            />
            <span>Amazon Fees Calculation</span>
          </FormCheckbox>
        </FormRow>
        <RisksCalculationAdditionalText>
          If you enable screenshots, you will take screenshots for each of the goods items in your declarations.
        </RisksCalculationAdditionalText>
        <FormRow>
          <FormCheckbox>
            <input
              name="risksCalculation.screenshotsEnabled"
              type="checkbox"
              checked={formik.values.risksCalculation.screenshotsEnabled}
              onChange={formik.handleChange}
            />
            <span>Screenshots enabled</span>
          </FormCheckbox>
        </FormRow>
        <RisksCalculationAdditionalText>
          If you enable AI module, you will run the HS code analysis for each of the goods items in your declarations.
        </RisksCalculationAdditionalText>
        <FormRow>
          <FormCheckbox>
            <input
              name="risksCalculation.aiModuleEnabled"
              type="checkbox"
              checked={formik.values.risksCalculation.aiModuleEnabled}
              onChange={formik.handleChange}
            />
            <span>AI module enabled</span>
          </FormCheckbox>
        </FormRow>
      </FormSection>

      <FormSection>
        <RisksCalculationTitle>VAT and EORI validation</RisksCalculationTitle>
        <RisksCalculationAdditionalText>
          If you enable VAT verification, you will run the VIES VAT number validation before processing the batch upload
          or batch update
        </RisksCalculationAdditionalText>
        <FormRow>
          <FormCheckbox>
            <input
              name="risksCalculation.vatValidationEnabled"
              type="checkbox"
              checked={formik.values.risksCalculation.vatValidationEnabled}
              onChange={formik.handleChange}
            />
            <span>VAT verification enabled</span>
          </FormCheckbox>
        </FormRow>

        <RisksCalculationAdditionalText>
          If you enable EORI verification, you will run the EORI number validation before processing the batch upload or
          batch update
        </RisksCalculationAdditionalText>

        <FormRow>
          <FormCheckbox>
            <input
              name="risksCalculation.eoriValidationEnabled"
              type="checkbox"
              checked={formik.values.risksCalculation.eoriValidationEnabled}
              onChange={formik.handleChange}
            />
            <span>EORI verification enabled</span>
          </FormCheckbox>
        </FormRow>
      </FormSection>

      {displayCustomsProof && (
        <FormSection>
          <RisksCalculationTitle>Customs Proof</RisksCalculationTitle>
          <FormRow>
            <FormCheckbox>
              <input
                name="risksCalculation.requestProofEnabled"
                type="checkbox"
                checked={formik.values.risksCalculation.requestProofEnabled}
                onChange={formik.handleChange}
              />
              <span>Request proof (only low values)</span>
            </FormCheckbox>
          </FormRow>
        </FormSection>
      )}

      <FormSection>
        <RisksCalculationTitle>Penalty value</RisksCalculationTitle>
        <RisksCalculationAdditionalText>
          Amount in € to pay when the value is underestimated
        </RisksCalculationAdditionalText>
        <FormRow>
          <Input
            name="risksCalculation.penaltyValue"
            placeholder="Penalty value"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.risksCalculation.penaltyValue}
            width={INPUT_WIDTH}
          />
        </FormRow>
      </FormSection>
      <FormSection noTopPadding={true}>
        <RisksCalculationTitle>Penalty description</RisksCalculationTitle>
        <RisksCalculationAdditionalText>
          Amount in € to pay when the description is not matching with the commercial description on web
        </RisksCalculationAdditionalText>
        <FormRow>
          <Input
            name="risksCalculation.penaltyDescription"
            placeholder="Penalty description"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.risksCalculation.penaltyDescription}
            width={INPUT_WIDTH}
          />
        </FormRow>
      </FormSection>
      <FormSection noTopPadding={true}>
        <RisksCalculationTitle>Penalty HS code</RisksCalculationTitle>
        <RisksCalculationAdditionalText>
          Amount in € to pay when the HS code is not matching with the commercial description
        </RisksCalculationAdditionalText>
        <FormRow>
          <Input
            name="risksCalculation.penaltyHSCode"
            placeholder="Penalty HS code"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.risksCalculation.penaltyHSCode}
            width={INPUT_WIDTH}
          />
        </FormRow>
      </FormSection>
      <div>
        <Button type="submit" primary>
          Save
        </Button>
      </div>
    </FormContainer>
  );
};

export default RisksCalculationForm;
