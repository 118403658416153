/* eslint-disable no-param-reassign */
/* eslint-disable max-lines */
/* eslint-disable max-statements */
import { useFormik } from 'formik';
import { cloneDeep, omit } from 'lodash';
import { ChangeEvent, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/* eslint-disable complexity */
import { Declaration, DeclarationGoods, RecursivePartial } from '@e-origin/shared';

import { Button, Input, Modal } from '../../../../../../shared/components';
import { Autocomplete } from '../../../../../../shared/components/autocomplete/autocomplete.component';
import { selectDeclarant } from '../../../../../../shared/stores/declarantSlice';
import { savePartialDataToDeclaration, selectDetails } from '../../../../../../shared/stores/declarationsSlice';
import { retrieveCodes } from '../../../../../../shared/stores/settingsSlice';
import { FormContainer, FormRow, FormSection, FormSectionTitle } from '../../../../../../styles/common';

interface IDeclarationDetailsGoodsModalHighValuesProps {
  editDisabled?: boolean;
  declaration: Declaration;
  goodDetails: DeclarationGoods;
  onHide(): void;
}

interface IDeclarationDetailsGoodsModalHighValuesFormData extends RecursivePartial<Declaration> {
  declarationGoods: RecursivePartial<DeclarationGoods>;
}

const DeclarationDetailsGoodsModalHighValues: FC<IDeclarationDetailsGoodsModalHighValuesProps> = ({
  declaration,
  goodDetails,
  onHide,
  editDisabled,
}) => {
  const dispatch = useDispatch();
  const declarationDetails = useSelector(selectDetails);
  const declarant = useSelector(selectDeclarant);

  const formik = useFormik<IDeclarationDetailsGoodsModalHighValuesFormData>({
    initialValues: {
      goodShipmentGlobal: {
        totalQuantitySupplementaryUnits: declarationDetails.goodShipmentGlobal.totalQuantitySupplementaryUnits,
        firstItemData: {
          nationalAdditionalCode: declarationDetails.goodShipmentGlobal.firstItemData.nationalAdditionalCode,
          nationalAdditionalCode2: declarationDetails.goodShipmentGlobal.firstItemData.nationalAdditionalCode2,
          nationalAdditionalCode3: declarationDetails.goodShipmentGlobal.firstItemData.nationalAdditionalCode3,
          taricAdditionalCode: declarationDetails.goodShipmentGlobal.firstItemData.taricAdditionalCode,
          taricAdditionalCode2: declarationDetails.goodShipmentGlobal.firstItemData.taricAdditionalCode2,
          hsCode: declarationDetails.goodShipmentGlobal.firstItemData.hsCode,
          typeOfGoods: declarationDetails.goodShipmentGlobal.firstItemData.typeOfGoods,
          cusCode: declarationDetails.goodShipmentGlobal.firstItemData.cusCode,
          invoiceDescription: declarationDetails.goodShipmentGlobal.firstItemData.invoiceDescription,
        },
        totalQuantity: declaration.goodShipmentGlobal.totalQuantity,
        totalNumberOfPackages: declaration.goodShipmentGlobal.totalNumberOfPackages,
        totalNetMass: declaration.goodShipmentGlobal.totalNetMass,
        totalGrossMass: declaration.goodShipmentGlobal.totalGrossMass,
        totalAmountInvoiced: declaration.goodShipmentGlobal.totalAmountInvoiced,
      },
      otherDataElements: {
        quotaOrderNumber: declarationDetails.otherDataElements.quotaOrderNumber,
      },
      consignment: {
        countryOfOrigin: declaration.consignment.countryOfOrigin,
        regionOfOrigin: declaration.consignment.regionOfOrigin,
        countryOfPreferentialOrigin: declaration.consignment.countryOfPreferentialOrigin,
      },
      declarationGoods: {
        evalMethodAiHsCode: {
          taricSupplementaryUnits: declarationDetails.goodsShipmentItems[0].evalMethodAiHsCode.taricSupplementaryUnits,
        },
        dutiesAndTaxes: cloneDeep(goodDetails.dutiesAndTaxes),
      },
    },
    onSubmit: async (values) => {
      dispatch(
        savePartialDataToDeclaration(declaration._id, omit(values, 'declarationGoods'), {
          ...values.declarationGoods,
          commodityCode: {
            nationalAdditionalCode: values.goodShipmentGlobal.firstItemData.nationalAdditionalCode,
          },
        }),
      );
      onHide();
    },
  });

  const { handleBlur, handleChange, handleSubmit, submitForm } = formik;

  const startFormSubmit = () => {
    submitForm();
  };

  const handleTax100 = (event: ChangeEvent<HTMLInputElement>) => {
    const { dutiesAndTaxes } = formik.values.declarationGoods;
    let tax100 = dutiesAndTaxes?.items?.find((item) => item.taxType === '100');

    if (!tax100) {
      tax100 = { taxType: '100' };
      dutiesAndTaxes?.items.push(tax100);
    }

    if (event.target.value) {
      tax100.taxBase = [{ measurementUnitAndQualifier: event.target.value }];
    } else {
      dutiesAndTaxes.items = dutiesAndTaxes?.items.filter((item) => item.taxType !== '100');
    }
    formik.setFieldValue('declarationGoods.dutiesAndTaxes', dutiesAndTaxes);
  };

  const handleTaricSupplementaryUnits = (e: ChangeEvent<HTMLInputElement>) => {
    let { taricSupplementaryUnits } = formik.values.declarationGoods.evalMethodAiHsCode;
    taricSupplementaryUnits = cloneDeep(taricSupplementaryUnits) || [];

    const firstTaricSupplementaryUnit = {
      ...taricSupplementaryUnits[0],
      codeQualifyer: e.target.value,
    };

    if (!taricSupplementaryUnits[0]) {
      taricSupplementaryUnits.unshift(firstTaricSupplementaryUnit);
    } else {
      taricSupplementaryUnits[0] = firstTaricSupplementaryUnit;
    }

    formik.setFieldValue('declarationGoods.evalMethodAiHsCode.taricSupplementaryUnits', taricSupplementaryUnits);
  };

  const handleSupplementaryTotalQuantity = () => {
    const codeQualifyer = (declaration.goodsShipmentItems[0].evalMethodAiHsCode?.taricSupplementaryUnits ||
      [])[0]?.codeQualifyer
      ?.split(' ')
      .join('')
      .trim()
      .slice(0, 4);

    if (!codeQualifyer) {
      return undefined;
    }

    let supplementaryUnits = declaration.goodShipmentGlobal.totalQuantity;

    if (declaration.goodShipmentGlobal.totalQuantitySupplementaryUnits) {
      supplementaryUnits = declaration.goodShipmentGlobal.totalQuantitySupplementaryUnits;
    } else if (['KGM', 'LTR'].includes(codeQualifyer)) {
      supplementaryUnits = declaration.goodShipmentGlobal.totalNetMass;
    }

    return supplementaryUnits;
  };

  return (
    <Modal
      title={`Good details ${goodDetails ? `- ${goodDetails.goodsItemNumber}` : ''}`}
      show={true}
      onHide={() => onHide()}
      buttons={
        !editDisabled
          ? [
              <Button type="button" primary key="save-modal-btn" onClick={startFormSubmit}>
                Save
              </Button>,
            ]
          : []
      }
      buttonsJustifyAlign="center"
    >
      <FormContainer onSubmit={handleSubmit}>
        <FormSection verticalPadding={10} topPadding={0} paddingBottom={0}>
          <FormSectionTitle noTopBorder>Commodity</FormSectionTitle>
          <FormRow>
            <Input
              name="goodShipmentGlobal.firstItemData.invoiceDescription"
              placeholder="Overrule Description of Goods"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.goodShipmentGlobal.firstItemData.invoiceDescription}
              width={50}
              widthUnit="%"
              disabled={editDisabled}
            />
            <Input
              name="goodShipmentGlobal.firstItemData.typeOfGoods"
              placeholder="Type of goods"
              onChange={handleChange}
              onBlur={handleBlur}
              value={formik.values.goodShipmentGlobal.firstItemData.typeOfGoods}
              width={50}
              widthUnit="%"
              disabled={editDisabled}
            />
          </FormRow>
          <FormRow>
            <Autocomplete
              width={50}
              widthUnit="%"
              placeholder="CUS code"
              fetchOptions={(search: string) => retrieveCodes('CL016', search, declarant.language)}
              onChange={(selectedOption) =>
                formik.setFieldValue('goodShipmentGlobal.firstItemData.cusCode', selectedOption?.value)
              }
              value={{
                value: formik.values.goodShipmentGlobal.firstItemData.cusCode,
                label: formik.values.goodShipmentGlobal.firstItemData.cusCode,
              }}
              disabled={editDisabled}
            />
            <Input
              name="otherDataElements.quotaOrderNumber"
              placeholder="Quota order number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={formik.values.otherDataElements?.quotaOrderNumber}
              width={50}
              widthUnit="%"
              disabled={editDisabled}
            />
          </FormRow>
        </FormSection>
        <FormSection verticalPadding={10} topPadding={0} paddingBottom={0}>
          <FormSectionTitle noTopBorder>Commodity code</FormSectionTitle>
          <FormRow>
            <Input
              name="hsCode"
              placeholder="HS Code"
              value={goodDetails.commodityCode.hsCode}
              width={50}
              widthUnit="%"
              defaultBehaviour
              disabled
            />
            <Input
              name="goodShipmentGlobal.firstItemData.hsCode"
              placeholder="Overrule HS Code"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.goodShipmentGlobal.firstItemData.hsCode}
              width={50}
              widthUnit="%"
              disabled={editDisabled}
            />
          </FormRow>
          <FormRow>
            <Input
              name="goodShipmentGlobal.firstItemData.taricAdditionalCode"
              placeholder="Taric Additional Code 1"
              onChange={handleChange}
              onBlur={handleBlur}
              value={formik.values.goodShipmentGlobal.firstItemData.taricAdditionalCode}
              width={50}
              widthUnit="%"
              disabled={editDisabled}
            />
            <Input
              name="goodShipmentGlobal.firstItemData.taricAdditionalCode2"
              placeholder="Taric Additional Code 2"
              onChange={handleChange}
              onBlur={handleBlur}
              value={formik.values.goodShipmentGlobal.firstItemData.taricAdditionalCode2}
              width={50}
              widthUnit="%"
              disabled={editDisabled}
            />
          </FormRow>
          <FormRow>
            <Input
              name="goodShipmentGlobal.firstItemData.nationalAdditionalCode"
              placeholder="National Additional Code 1"
              onChange={handleChange}
              onBlur={handleBlur}
              value={formik.values.goodShipmentGlobal.firstItemData.nationalAdditionalCode}
              width={50}
              widthUnit="%"
              disabled={editDisabled}
            />
            <Input
              name="goodShipmentGlobal.firstItemData.nationalAdditionalCode2"
              placeholder="National Additional Code 2"
              onChange={handleChange}
              onBlur={handleBlur}
              value={formik.values.goodShipmentGlobal.firstItemData.nationalAdditionalCode2}
              width={50}
              widthUnit="%"
              disabled={editDisabled}
            />
          </FormRow>
          <FormRow>
            <Input
              name="goodShipmentGlobal.firstItemData.nationalAdditionalCode3"
              placeholder="National Additional Code 3"
              onChange={handleChange}
              onBlur={handleBlur}
              value={formik.values.goodShipmentGlobal.firstItemData.nationalAdditionalCode3}
              width={50}
              widthUnit="%"
              disabled={editDisabled}
              defaultBehaviour
            />
          </FormRow>
        </FormSection>
        <FormSection verticalPadding={20} topPadding={30}>
          <FormSectionTitle>Packaging</FormSectionTitle>
          <FormRow>
            <Input
              placeholder="Shipping marks"
              value={goodDetails.packaging.shippingMarks}
              width={100}
              widthUnit="%"
              disabled
            />
          </FormRow>
          <FormRow>
            <Input
              name="typeOfPackage"
              placeholder="Package type"
              onChange={handleChange}
              onBlur={handleBlur}
              value={goodDetails.packaging.typeOfPackages}
              width={50}
              widthUnit="%"
              disabled
            />
            {['CT', 'PX', 'PK'].includes(goodDetails.packaging.typeOfPackages) ? (
              <Input
                placeholder="Package quantity"
                type="number"
                value={declarationDetails?.goodShipmentGlobal?.totalNumberOfPackages}
                width={50}
                widthUnit="%"
                disabled
              />
            ) : (
              <Input
                placeholder="Package quantity"
                type="number"
                value={declarationDetails?.goodShipmentGlobal?.totalQuantity}
                width={50}
                widthUnit="%"
                disabled
              />
            )}
          </FormRow>
          <FormRow>
            <Input
              name="goodShipmentGlobal.totalNumberOfPackages"
              type="number"
              placeholder="Overrule Total Number Of Packages"
              onChange={handleChange}
              onBlur={handleBlur}
              value={formik.values.goodShipmentGlobal.totalNumberOfPackages}
              width={50}
              widthUnit="%"
              disabled={editDisabled}
            />
          </FormRow>
        </FormSection>
        <FormSection verticalPadding={10} topPadding={0} paddingBottom={0}>
          {goodDetails.dutiesAndTaxes?.items
            ?.filter((dutyAndTax) => dutyAndTax.taxType !== '100')
            .map((dutyAndTax, index) => {
              return dutyAndTax.taxBase.map((tax, taxIndex) => {
                return (
                  <div key={taxIndex}>
                    <FormSectionTitle>{`Tax ${dutyAndTax.taxType}${[0, 1].includes(index) ? '0' : ''}`}</FormSectionTitle>
                    <FormRow>
                      <Input
                        placeholder="Tax Rate"
                        value={goodDetails.dutiesAndTaxes?.items?.[index].taxBase[taxIndex]?.taxRate}
                        width={50}
                        widthUnit="%"
                        disabled
                      />
                      <Input
                        placeholder="Quantity"
                        onBlur={handleBlur}
                        value={goodDetails.dutiesAndTaxes?.items?.[index].taxBase[taxIndex]?.quantity}
                        width={50}
                        widthUnit="%"
                        disabled
                      />
                    </FormRow>
                  </div>
                );
              });
            })}
        </FormSection>
        <FormSection verticalPadding={10} topPadding={0} paddingBottom={0}>
          <FormSectionTitle>Tax 100</FormSectionTitle>
          <FormRow>
            <Input
              placeholder="Unit code"
              value={
                goodDetails.dutiesAndTaxes?.items?.find((item) => item.taxType === '100')?.taxBase?.[0]
                  ?.measurementUnitAndQualifier
              }
              onChange={handleTax100}
              width={50}
              widthUnit="%"
            />
          </FormRow>
        </FormSection>

        <FormSection verticalPadding={10} topPadding={0} paddingBottom={0}>
          <FormSectionTitle>Overrule Total Quantity</FormSectionTitle>
          <FormRow>
            <Input
              name="goodShipmentGlobal.totalQuantity"
              type="number"
              placeholder="Overrule Total Quantity"
              onChange={handleChange}
              onBlur={handleBlur}
              value={formik.values.goodShipmentGlobal.totalQuantity}
              width={50}
              widthUnit="%"
              disabled={editDisabled}
            />
          </FormRow>
        </FormSection>

        <FormSection verticalPadding={10} topPadding={0} paddingBottom={0}>
          <FormSectionTitle>Item amount invoiced</FormSectionTitle>
          <FormRow>
            <Input
              placeholder="Item amount invoiced"
              onChange={handleChange}
              onBlur={handleBlur}
              value={goodDetails.itemAmountInvoiced || 0}
              width={50}
              widthUnit="%"
              disabled
            />
          </FormRow>
        </FormSection>

        <FormSection verticalPadding={10} topPadding={0} paddingBottom={0}>
          <FormSectionTitle>Amount invoiced</FormSectionTitle>
          <FormRow>
            <Input
              placeholder="Amount invoiced"
              value={goodDetails.totalAmountInvoiced || 0}
              width={100}
              widthUnit="%"
              type="number"
              disabled
            />
          </FormRow>
          <FormRow>
            <Input
              placeholder="Invoice currency"
              value={declaration.goodShipmentGlobal.invoiceCurrency}
              width={50}
              widthUnit="%"
              disabled
            />
            <Input
              placeholder="Exchange rate"
              onChange={handleChange}
              onBlur={handleBlur}
              value={declaration.goodShipmentGlobal.exchangeRate}
              width={50}
              widthUnit="%"
              type="number"
              disabled
            />
          </FormRow>
          <FormRow>
            <Input
              name="goodShipmentGlobal.totalAmountInvoiced"
              type="number"
              placeholder="Overrule Total Amount Invoiced"
              onChange={handleChange}
              onBlur={handleBlur}
              value={formik.values.goodShipmentGlobal.totalAmountInvoiced}
              width={50}
              widthUnit="%"
              disabled={editDisabled}
            />
          </FormRow>
        </FormSection>

        <FormSection verticalPadding={10} topPadding={0} paddingBottom={0}>
          <FormSectionTitle>Total Mass</FormSectionTitle>
          <FormRow>
            <Input
              name="goodShipmentGlobal.totalGrossMass"
              type="number"
              placeholder="Overrule Total Gross Mass"
              onChange={handleChange}
              onBlur={handleBlur}
              value={formik.values.goodShipmentGlobal.totalGrossMass}
              width={50}
              widthUnit="%"
              disabled={editDisabled}
            />
            <Input
              name="goodShipmentGlobal.totalNetMass"
              type="number"
              placeholder="Overrule Total Net Mass"
              onChange={handleChange}
              onBlur={handleBlur}
              value={formik.values.goodShipmentGlobal.totalNetMass}
              width={50}
              widthUnit="%"
              disabled={editDisabled}
            />
          </FormRow>
        </FormSection>

        <FormSection verticalPadding={10} topPadding={0} paddingBottom={0}>
          <FormSectionTitle>Supplementary Units</FormSectionTitle>
          <FormRow>
            <Input
              placeholder="Units code"
              value={(goodDetails.evalMethodAiHsCode.taricSupplementaryUnits || [])[0]?.codeQualifyer}
              width={50}
              widthUnit="%"
              disabled={editDisabled}
              onChange={handleTaricSupplementaryUnits}
            />
            <Input
              placeholder="Total quantity in Units"
              value={handleSupplementaryTotalQuantity()}
              width={50}
              widthUnit="%"
              type="number"
              defaultBehaviour
              disabled
            />
          </FormRow>
          <FormRow>
            <Input
              name="goodShipmentGlobal.totalQuantitySupplementaryUnits"
              type="number"
              placeholder="Overrule total quantity in Units"
              onChange={handleChange}
              onBlur={handleBlur}
              value={formik.values.goodShipmentGlobal.totalQuantitySupplementaryUnits}
              width={50}
              widthUnit="%"
              disabled={editDisabled}
            />
          </FormRow>
        </FormSection>

        <FormSection verticalPadding={10} topPadding={0} paddingBottom={0}>
          <FormSectionTitle>Transport</FormSectionTitle>
          <FormRow>
            <Autocomplete
              width={45}
              widthUnit="%"
              placeholder="Country of Origin"
              fetchOptions={(search: string) =>
                retrieveCodes(declarationDetails.generalInfo.isExport ? 'CL008' : 'CL199', search, declarant.language)
              }
              onChange={(selectedOption) => formik.setFieldValue('consignment.countryOfOrigin', selectedOption?.value)}
              value={{
                value: formik.values.consignment.countryOfOrigin,
                label: formik.values.consignment.countryOfOrigin,
              }}
              disabled={editDisabled}
            />
            <Input
              name="consignment.regionOfOrigin"
              placeholder="Region of Origin"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.consignment.regionOfOrigin}
              width={55}
              widthUnit="%"
              disabled={editDisabled}
            />
            <Input
              name="consignment.countryOfPreferentialOrigin"
              placeholder="Country of Preferential Origin"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.consignment.countryOfPreferentialOrigin}
              width={55}
              widthUnit="%"
              disabled={editDisabled}
            />
          </FormRow>
        </FormSection>

        <FormSection verticalPadding={10} topPadding={0} paddingBottom={0}>
          <FormSectionTitle>Web URL</FormSectionTitle>
          <FormRow>
            <Input placeholder="Web URL" value={goodDetails.webScraping.webUrl} width={100} widthUnit="%" disabled />
          </FormRow>
        </FormSection>
      </FormContainer>
    </Modal>
  );
};

export default DeclarationDetailsGoodsModalHighValues;
