import { FormikProps } from 'formik';

import { Autocomplete } from '../../../../../../../../../../../shared/components';
import { searchBatchName } from '../../../../../../../../../../../shared/stores/batchesSlice';
import { HouseItemFormInitializerReturn } from '../../house-form-initializer';
import * as Style from './house-batches.style';

interface HouseBatchesProps {
  form: FormikProps<HouseItemFormInitializerReturn>;
  disabled: boolean;
}

export const HouseBatches: React.FC<HouseBatchesProps> = ({ form, disabled }) => {
  const search = async (searchText: string) => {
    if (!searchText) {
      return [];
    }

    const results = await searchBatchName(searchText);

    return results.map((result) => ({
      label: result.name,
      value: result.id,
    }));
  };

  const { batches } = form.values;

  const setBatches = (newBatches) => {
    form.setFieldValue('batches', newBatches);
  };

  const addBatch = (selectedOption) => {
    if (!batches.find((item) => item.id === selectedOption.value)) {
      setBatches([...batches, { id: selectedOption.value, name: selectedOption.label }]);
    }
  };

  return (
    <Style.Container>
      <Style.ActionButton>
        {!disabled && <Autocomplete placeholder="Search and add batch" fetchOptions={search} onSelect={addBatch} />}
      </Style.ActionButton>
      <Style.BatchList>
        {batches.map((item, itemIdx) => (
          <Style.BatchItem key={(batches?.length || 0) + itemIdx}>{item.name}</Style.BatchItem>
        ))}
      </Style.BatchList>
    </Style.Container>
  );
};
