/* eslint-disable complexity */
import { FC } from 'react';

import { DeclarationGoods } from '@e-origin/shared';

import { FormatValueTypes, InputErrorStatus } from '../../../../../../../../enums';
import { InputsComparator } from '../../../../../../../../shared/components';
import { formatValue } from '../../../../../../../../utils';
import {
  HorizontalLine,
  RiskAnalysisSection,
  RiskAnalysisSectionContent,
  RiskAnalysisSectionHeader,
} from '../../risk-analysis-modal.styles';

const INVOICED_ITEM_WEIGHT = 'Invoiced item weight';
const WEB_ITEM_WEIGHT = 'Web item weight';
const RATIO = 'Ratio';
const CALCULATED_RATIO = 'Invoiced net weight per unit';
const TARIC_RATIO = 'TARIC net weight per unit';

interface IRiskAnalysisHSCodeProps {
  goodDetails: DeclarationGoods;
}

export const RiskAnalysisOthers: FC<IRiskAnalysisHSCodeProps> = ({ goodDetails }) => {
  const invoicedItemWeight = formatValue(
    goodDetails.evalMethodWebValue?.invoicedMassCoefficient
      ? 1 / goodDetails.evalMethodWebValue.invoicedMassCoefficient
      : 0,
    FormatValueTypes.KG,
  );

  const webWeight = formatValue(Number(goodDetails.evalMethodWebValue?.webWeight || 0), FormatValueTypes.KG);

  const ratioToCheck = !!goodDetails.evalMethodAiHsCode?.taricRatioToCheck;

  const invoicedNetWeightPerUnit = formatValue(
    goodDetails.evalMethodAiHsCode?.calculatedRatio || 0,
    FormatValueTypes.KG,
  );

  const getTaricNetWeightPerUnit = () => {
    const measureFound = (goodDetails.evalMethodAiHsCode?.taricOtherMeasures || []).find(
      (measure) =>
        measure.measureType ===
        'Declaration of subheading submitted to physical restrictions (net weight/supplementary unit)',
    );

    return (
      (measureFound || (goodDetails.evalMethodAiHsCode?.taricOtherMeasures || [])[0])?.duty
        ?.replace('Cond: ', '')
        ?.replace('B cert:', '')
        ?.replace('C cert:', '')
        ?.replace('Y cert:', '')
        ?.trim() || ''
    );
  };
  const taricNetWeightPerUnit = getTaricNetWeightPerUnit();

  const isWebWeightOk = () => {
    return (
      goodDetails.evalMethodWebValue === undefined ||
      goodDetails.evalMethodWebValue.webWeightOk === undefined ||
      !!goodDetails.evalMethodWebValue.webWeightOk
    );
  };

  return (
    <RiskAnalysisSection>
      <RiskAnalysisSectionHeader>
        <h4>Others</h4>
      </RiskAnalysisSectionHeader>
      <RiskAnalysisSectionContent>
        <InputsComparator
          compact={true}
          title={INVOICED_ITEM_WEIGHT}
          placeholders={[INVOICED_ITEM_WEIGHT, WEB_ITEM_WEIGHT]}
          values={[invoicedItemWeight, webWeight]}
          badge={
            !isWebWeightOk() && {
              status: InputErrorStatus.ERROR,
              text: '',
            }
          }
          disabled={[true, true]}
        />
        {ratioToCheck && (
          <>
            <HorizontalLine />
            <InputsComparator
              compact={true}
              title={RATIO}
              placeholders={[CALCULATED_RATIO, TARIC_RATIO]}
              values={[invoicedNetWeightPerUnit, taricNetWeightPerUnit]}
              disabled={[true, true]}
            />
          </>
        )}
      </RiskAnalysisSectionContent>
    </RiskAnalysisSection>
  );
};
