/* eslint-disable complexity */
import { FormikProps } from 'formik';
import { cloneDeep, flatMapDeep, sum } from 'lodash';
import { Fragment } from 'react';

import { MultilingualCodeSystems } from '@e-origin/shared';

import trashIcon from '../../../../../../../assets/icons/trash-icon.svg';
import { Button, Input, MultiLangCodes } from '../../../../../../../shared/components';
import { FormRow } from '../../../../../../../styles/common';
import { TransitDetailsFormProps } from '../../../transit-details.component';
import * as Style from './transit-guarantees.style';

interface TransitGuaranteesProps {
  form: FormikProps<TransitDetailsFormProps>;
  disabled: boolean;
}

export const TransitGuarantees: React.FC<TransitGuaranteesProps> = ({ form, disabled }) => {
  const guarantees = form.values.Guarantee;

  const handleAddGuarantee = () => {
    form.setFieldValue('Guarantee', [
      ...guarantees,
      {
        guaranteType: '',
        GuaranteeReference: [
          {
            accessCode: '',
            currency: '',
            GRN: '',
            amountToBeCovered: '',
          },
        ],
      },
    ]);
  };

  const handleRemoveGuarantee = (guaranteeIdx: number) => {
    const newGuarantees = guarantees.filter((_, idx) => idx !== guaranteeIdx);
    form.setFieldValue('Guarantee', newGuarantees);
  };

  const handleAddReference = (guaranteeIdx: number) => {
    const guarantee = guarantees[guaranteeIdx];
    const newReference = {
      accessCode: '',
      currency: '',
      GRN: '',
      amountToBeCovered: '',
    };
    guarantee.GuaranteeReference.push(newReference);
    form.setFieldValue('Guarantee', [...guarantees]);
  };

  const handleDeleteReference = (guaranteeIdx: number, referenceIdx: number) => {
    const guarantee = guarantees[guaranteeIdx];
    guarantee.GuaranteeReference = guarantee.GuaranteeReference.filter((_, idx) => idx !== referenceIdx);
    form.setFieldValue('Guarantee', [...guarantees]);
  };

  const calculateAmount = (guaranteeIdx, referenceIdx) => {
    const Consignment = form.getFieldProps('Consignment').value;

    const guaranteesCopy = cloneDeep(guarantees);
    guaranteesCopy[guaranteeIdx].GuaranteeReference[referenceIdx].amountToBeCovered = sum(
      flatMapDeep(Consignment.HouseConsignment || [], (houseConsignment) =>
        (houseConsignment.ConsignmentItem || []).map(
          (consignmentItem) =>
            Number(consignmentItem.Commodity.CommodityCode.initialAmountToPayForDuties || 0) +
            Number(consignmentItem.Commodity.CommodityCode.initialAmountToPayForVat || 0),
        ),
      ),
    ).toFixed(1);

    form.setFieldValue('Guarantee', [...guaranteesCopy]);
  };

  return (
    <>
      {guarantees.map((guarantee, guaranteeIdx) => (
        <Fragment key={`${guarantees.length}-${guaranteeIdx}`}>
          <FormRow>
            <MultiLangCodes
              name={`Guarantee[${guaranteeIdx}].guaranteeType`}
              placeholder="Guarantee Type"
              width={24}
              system={MultilingualCodeSystems.NCTS}
              value={guarantee.guaranteeType}
              onChange={form.handleChange}
              code="CL251"
              disabled={disabled}
            />
            {!disabled && (
              <Style.ButtonContainer>
                <Button type="button" onClick={() => handleRemoveGuarantee(guaranteeIdx)}>
                  <img src={trashIcon} alt={'delete'} />
                </Button>
              </Style.ButtonContainer>
            )}
          </FormRow>
          {guarantee.GuaranteeReference.map((reference, referenceIdx) => (
            <Fragment key={referenceIdx}>
              <FormRow>
                <Input
                  name={`Guarantee[${guaranteeIdx}].GuaranteeReference[${referenceIdx}].accessCode`}
                  placeholder={`Access Code ${referenceIdx + 1}`}
                  width={24}
                  widthUnit="%"
                  value={reference.accessCode}
                  onChange={form.handleChange}
                  disabled={disabled}
                />
                <MultiLangCodes
                  name={`Guarantee[${guaranteeIdx}].GuaranteeReference[${referenceIdx}].currency`}
                  placeholder={`Currency ${referenceIdx + 1}`}
                  width={20}
                  system={MultilingualCodeSystems.NCTS}
                  value={reference.currency}
                  onChange={form.handleChange}
                  code="CL048"
                  disabled={disabled}
                />
                <Input
                  name={`Guarantee[${guaranteeIdx}].GuaranteeReference[${referenceIdx}].GRN`}
                  placeholder={`GRN ${referenceIdx + 1}`}
                  width={15}
                  widthUnit="%"
                  value={reference.GRN}
                  onChange={form.handleChange}
                  disabled={disabled}
                />
                <Input
                  name={`Guarantee[${guaranteeIdx}].GuaranteeReference[${referenceIdx}].amountToBeCovered`}
                  placeholder={`AmountToBeCovered ${referenceIdx + 1}`}
                  width={20}
                  widthUnit="%"
                  value={
                    form.getFieldProps(
                      `Guarantee[${guaranteeIdx}].GuaranteeReference[${referenceIdx}].amountToBeCovered`,
                    ).value
                  }
                  onChange={form.handleChange}
                  disabled={disabled}
                  defaultBehaviour
                />
                {!disabled && (
                  <Style.ButtonContainer>
                    <Button
                      style={{ height: 50 }}
                      primary
                      type="button"
                      onClick={() => calculateAmount(guaranteeIdx, referenceIdx)}
                    >
                      Calculate guarantee amount
                    </Button>
                  </Style.ButtonContainer>
                )}
                {!disabled && referenceIdx === guarantee.GuaranteeReference.length - 1 && (
                  <Style.ButtonContainer>
                    <Button
                      style={{ fontSize: 30 }}
                      primary
                      type="button"
                      onClick={() => handleAddReference(guaranteeIdx)}
                    >
                      +
                    </Button>
                  </Style.ButtonContainer>
                )}
                {!disabled && referenceIdx < guarantee.GuaranteeReference.length - 1 && (
                  <Style.ButtonContainer>
                    <Button type="button" onClick={() => handleDeleteReference(guaranteeIdx, referenceIdx)}>
                      <img src={trashIcon} alt={'delete'} />
                    </Button>
                  </Style.ButtonContainer>
                )}
              </FormRow>
            </Fragment>
          ))}
          {guaranteeIdx < guarantees.length - 1 && <Style.Separator widthPercentage={100} />}
        </Fragment>
      ))}
      {!disabled && (
        <Style.ButtonContainer>
          <Button type="button" primary onClick={handleAddGuarantee} disabled={disabled}>
            Add Guarantee
          </Button>
        </Style.ButtonContainer>
      )}
    </>
  );
};
