import { Spinner } from '..';
import { Container } from './button.style';

export interface IButtonProps {
  primary?: boolean;
  isDelete?: boolean;
  outline?: boolean;
  onClick?: (...args: any) => void;
  type?: 'button' | 'submit';
  disabled?: boolean;
  showLoading?: boolean;
  style?: any;
}

export const Button: React.FC<IButtonProps> = (props) => {
  return (
    <Container {...props}>
      {props.showLoading && <Spinner isContrast />}
      {props.children}
    </Container>
  );
};
