/* eslint-disable max-statements */
import { useEffect, useRef } from 'react';
import { IDataTableColumn } from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Transit, TransitGroup, TransitStatus } from '@e-origin/shared';

import { TableActionsIcons } from '../../../../../assets';
import customsSyncIcon from '../../../../../assets/icons/customs-sync-icon.svg';
import { CustomTableActionsComponent } from '../../../../../shared/components';
import {
  customsSync,
  downloadTransitFollowLetter,
  downloadTransitReport,
  fetchTransits,
  generateNCTSResponse,
  generateTransitFollowLetter,
  generateTransitReport,
  selectTransitPagination,
  sendOne,
} from '../../../../../shared/stores/transitsSlice';
import { TransitModalFactoryEnum } from '../modals/transit-modal-factory.component';
import TransitStatusIndicator from '../transit-status-indicator/transit-status-indicator.component';

export const useTransitsTableColumns = ({ openModal, transitView }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const pagination = useSelector(selectTransitPagination);

  const actions = (row: Transit) => {
    const finalizeAllowed =
      transitView === TransitGroup.ARRIVAL &&
      row.TransitOperation.simplifiedProcedure === '1' &&
      ![TransitStatus.NOT_SENT, TransitStatus.REJECTED, TransitStatus.SENT].includes(row.generalInfo?.status);

    return [
      {
        icon: TableActionsIcons.VIEW,
        text: 'View details',
        onClick: () => {
          history.push(`/transits-details/${row._id}`);
        },
        preventDefaultClick: true,
      },
      {
        if: [TransitStatus.NOT_SENT, TransitStatus.REJECTED].includes(row.generalInfo?.status),
        icon: TableActionsIcons.DELETE,
        text: 'Delete transit',
        onClick: () => openModal(row, TransitModalFactoryEnum.DELETE),
        disableAfterClick: false,
        preventDefaultClick: true,
      },
      {
        if:
          [TransitStatus.NOT_SENT, TransitStatus.REJECTED, TransitStatus.UNLOAD_PERMISSION].includes(
            row.generalInfo?.status,
          ) && !finalizeAllowed,
        icon: TableActionsIcons.SEND,
        text: 'Send',
        onClick: async () => {
          await sendOne(row._id);
          dispatch(fetchTransits({ persistPagination: true }));
        },
        preventDefaultClick: true,
      },
      {
        if: finalizeAllowed,
        icon: TableActionsIcons.FINALIZE,
        text: 'Finalize',
        onClick: () => openModal(row, TransitModalFactoryEnum.FINALIZE),
        disableAfterClick: false,
        preventDefaultClick: true,
      },
      {
        if: [TransitStatus.SENT, TransitStatus.ACCEPTED, TransitStatus.RELEASED, TransitStatus.REMARKS_SENT].includes(
          row.generalInfo?.status,
        ),
        icon: customsSyncIcon,
        text: 'Customs sync',
        onClick: async () => {
          await customsSync(row._id);
          dispatch(fetchTransits({ persistPagination: true }));
        },
        preventDefaultClick: true,
      },
    ];
  };

  const secondaryActions = (row: Transit) => {
    return [
      {
        icon: TableActionsIcons.REPORT,
        text: 'Generate customs report',
        onClick: () => {
          dispatch(generateTransitReport(row._id));
        },
        preventDefaultClick: true,
      },
      {
        icon: TableActionsIcons.REPORT,
        text: 'Generate follow letter',
        onClick: () => {
          dispatch(generateTransitFollowLetter(row._id));
        },
        preventDefaultClick: true,
      },
      {
        if: transitView === TransitGroup.DEPARTURE && !!row.TransitOperation?.MRN,
        icon: TableActionsIcons.REPORT,
        text: 'Download customs report',
        onClick: () => {
          dispatch(downloadTransitReport(row._id));
        },
        preventDefaultClick: true,
      },
      {
        if: transitView === TransitGroup.DEPARTURE && !!row.TransitOperation?.MRN,
        icon: TableActionsIcons.REPORT,
        text: 'Download follow letter',
        onClick: () => {
          dispatch(downloadTransitFollowLetter(row._id));
        },
        preventDefaultClick: true,
      },
      {
        if: transitView === TransitGroup.DEPARTURE,
        icon: TableActionsIcons.COPY,
        text: 'Duplicate transit',
        onClick: () => openModal(row, TransitModalFactoryEnum.DUPLICATE),
        preventDefaultClick: true,
      },
    ];
  };

  const handleTableAction = async (actionType: string, row: Transit) => {
    const [, responseType] = actionType.match(/generateNCTSResponse-(.*)/);
    await generateNCTSResponse(row._id, responseType);
    dispatch(fetchTransits({ persistPagination: true }));
  };

  const buildColumns = (transitViewParam): IDataTableColumn<Transit>[] => [
    {
      name: 'Status',
      width: '75px',
      selector: 'generalInfo.status',
      sortable: true,
      cell: (row) => (
        <div className="whitespace-nowrap overflow-hidden overflow-ellipsis">
          <TransitStatusIndicator status={row.generalInfo.status} isArrival={transitView === TransitGroup.ARRIVAL} />
        </div>
      ),
    },
    {
      name: 'Transit ID',
      width: '100px',
      selector: 'counter',
      sortable: true,
    },
    {
      name: 'Name',
      selector: 'name',
      minWidth: '150px',
      sortable: true,
    },
    {
      name: 'Decl. type',
      width: '120px',
      selector: 'TransitOperation.declarationType',
    },
    {
      name: 'LRN',
      selector: 'TransitOperation.LRN',
      minWidth: '200px',
      sortable: true,
    },
    {
      name: 'MRN',
      selector: 'TransitOperation.MRN',
      minWidth: '200px',
      sortable: true,
    },
    {
      name: 'Holder of transit proc.',
      cell: (row) => row.HolderOfTheTransitProcedure?.name,
      selector: 'HolderOfTheTransitProcedure.name',
      minWidth: '200px',
      sortable: true,
    },
    {
      omit: transitViewParam === TransitGroup.DEPARTURE,
      name: 'Incident',
      cell: (row) => (Number(row.TransitOperation?.incidentFlag) === 1 ? 'True' : 'False'),
      selector: 'TransitOperation.incidentFlag',
      minWidth: '100px',
      sortable: true,
    },
    {
      name: 'Discrepancies',
      cell: (row) =>
        Number(row.UnloadingRemark?.conform) === 0 || row.customsState?.discrepancies?.length ? 'True' : 'False',
      selector: 'UnloadingRemark.conform',
      minWidth: '150px',
    },
    {
      name: 'C.O. of Departure',
      cell: (row) => row.CustomsOfficeOfDeparture?.referenceNumber,
      selector: 'CustomsOfficeOfDeparture.referenceNumber',
      minWidth: '200px',
      sortable: true,
    },
    {
      name: 'C.O. of Destination',
      cell: (row) => row.CustomsOfficeOfDestinationDeclared?.referenceNumber,
      selector: 'CustomsOfficeOfDestinationDeclared.referenceNumber',
      minWidth: '200px',
      sortable: true,
    },
    {
      name: 'Amount to be covered',
      selector: 'generalInfo.totalAmountGuaranteeToBeCovered',
      minWidth: '200px',
      sortable: true,
    },
    {
      name: 'Packages',
      selector: 'Consignment.totalNumberOfPackages',
      minWidth: '200px',
      sortable: true,
    },
    {
      name: 'Gross Mass',
      selector: 'Consignment.grossMass',
      sortable: true,
    },
    {
      name: 'Seals',
      selector: 'Consignment.totalNumberOfSeals',
      sortable: true,
    },
    {
      name: 'Deadline',
      cell: (row) => row.CustomsOfficeOfTransitDeclared?.[0]?.arrivalDateAndTimeEstimated || 'N/A',
      selector: 'CustomsOfficeOfTransitDeclared.[0].arrivalDateAndTimeEstimated',
      minWidth: '200px',
      sortable: true,
    },
    {
      name: 'Customer',
      selector: (row) => row.customer?.name,
      minWidth: '200px',
    },
    {
      name: 'Template',
      selector: (row) => row.template?.name,
      minWidth: '200px',
    },
    {
      name: 'Last update',
      selector: 'updatedAt',
      minWidth: '200px',
      sortable: true,
    },
    {
      name: 'Actions',
      minWidth: '180px',
      right: true,
      cell: (rowIdx, rowIndex) => (
        <CustomTableActionsComponent
          actions={actions(rowIdx)}
          secondaryActions={secondaryActions(rowIdx)}
          onClick={(param?: string) => handleTableAction(param, rowIdx)}
          disableAfterClick={true}
          transitView={transitViewParam}
          showDebugMenu={true}
          moveMenuUpwards={pagination.size - rowIndex <= 3}
        />
      ),
    },
  ];

  const columns = useRef<IDataTableColumn<Transit>[]>([]);

  useEffect(() => {
    columns.current = buildColumns(transitView);
  }, [transitView]);

  return columns;
};
