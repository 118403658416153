import { useState } from 'react';

import { RelatedResource, RelatedResourceStatus } from '@e-origin/shared';

import { StatusIcons } from '../../../../../assets';
import ImporterDetailsModal from '../importer-details-modal/importer-details-identification-modal.component';
import * as Style from './identification-status-icon.style';

interface IdentificationStatusProps {
  rowData: RelatedResource;
}

export const IdentificationStatusIcon: React.FC<IdentificationStatusProps> = ({ rowData }) => {
  const [showErrorDeatils, setShowErrorDeatils] = useState(false);

  const statusIconMapper = new Map([
    [
      RelatedResourceStatus.NEW,
      <Style.StatusWrapper key={0}>
        <img src={StatusIcons.NOT_SENT} />
        <span>No document provided</span>
      </Style.StatusWrapper>,
    ],
    [
      RelatedResourceStatus.EMPTY,
      <Style.StatusWrapper key={1}>
        <img src={StatusIcons.NOT_SENT} />
        <span>No document provided</span>
      </Style.StatusWrapper>,
    ],
    [
      RelatedResourceStatus.NOK,
      <Style.StatusWrapper key={2}>
        <img src={StatusIcons.REJECTED} />
        <Style.ViewErrorsButton
          onClick={() => {
            setShowErrorDeatils(true);
          }}
        >
          View Errors
        </Style.ViewErrorsButton>
        {showErrorDeatils && (
          <ImporterDetailsModal
            title={'Errors'}
            list={rowData.errorsStack || []}
            isUpdate={true}
            onHide={() => {
              setShowErrorDeatils(false);
            }}
            show={showErrorDeatils}
          />
        )}
      </Style.StatusWrapper>,
    ],
    [
      RelatedResourceStatus.OK,
      <Style.StatusWrapper key={3}>
        <img src={StatusIcons.RELEASED} />
        <span>Check OCR ok</span>
      </Style.StatusWrapper>,
    ],
  ]);

  return <>{statusIconMapper.get(rowData.status)}</>;
};
