import { NotificationModal } from '../../../../../../shared/components';
import { duplicateAcceptedDeclarationsByLRN } from '../../../../../../shared/stores/declarationsSlice';

interface IDeclarationDuplicateModalProps {
  LRN: string;
  referenceNumberUCR: string;
  open: boolean;
  onHide: () => void;
  onFinish: () => void;
}

export const DeclarationDuplicateModal: React.FC<IDeclarationDuplicateModalProps> = (props) => {
  return (
    <NotificationModal
      title="Confirm Duplication"
      confirmButtonText="Confirm"
      show={props.open}
      onHide={props.onHide}
      onConfirm={() => duplicateAcceptedDeclarationsByLRN(props.LRN).then(() => props.onFinish())}
    >
      <div>Do you want to duplicate {props.referenceNumberUCR}?</div>
    </NotificationModal>
  );
};
