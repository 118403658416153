/* eslint-disable complexity */
/* eslint-disable max-statements */
import { Batch, TemplateType } from '@e-origin/shared';

import BatchHVStatusIndicator from './batch-hv-status-indicator/batch-hv-status-indicator.component';
import BatchLVStatusIndicator from './batch-lv-status-indicator/batch-lv-status-indicator.component';

interface IBatchStatusIndicator {
  batch: Batch;
}

const BatchStatusIndicator: React.FC<IBatchStatusIndicator> = ({ batch }) => {
  if (!batch) {
    return null;
  }

  return batch.templateType === TemplateType.HIGH_VALUE_H1 ? (
    <BatchHVStatusIndicator batch={batch} />
  ) : (
    <BatchLVStatusIndicator batch={batch} />
  );
};

export default BatchStatusIndicator;
