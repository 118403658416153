/* eslint-disable complexity */
/* eslint-disable max-statements */
import { CSSProperties, InputHTMLAttributes, useEffect, useRef, useState, WheelEventHandler } from 'react';

import Icons from '../../../assets';
import { InputErrorStatus } from '../../../enums';
import { Container, InputImage, Label, MessageContainer } from './input.style';

interface InputMessage {
  status: InputErrorStatus;
  text: string;
  hideIcon?: boolean;
}

export interface IInputProps {
  placeholder?: string;
  type?: React.HTMLInputTypeAttribute;
  value?: string | number;
  onChange?: InputHTMLAttributes<HTMLInputElement>['onChange'];
  onBlur?: any;
  onClick?: any;
  onFocus?: any;
  onEnter?: any;
  name?: string;
  id?: string;
  invalid?: boolean;
  width?: number;
  /**
   * widthUnit can be "px" or "%"
   */
  widthUnit?: 'px' | '%' | 'em' | 'rem';
  disabled?: boolean;
  picture?: string;
  defaultBehaviour?: boolean;
  onPictureClick?(): void;
  screenshotLink?: string;
  isShowPicture?: boolean;
  isShowPictureDisabled?: boolean;
  webScrapingDone?: boolean;
  step?: string;
  message?: InputMessage;
  compact?: boolean;
  style?: CSSProperties;
  autoComplete?: string;
}

export const Input: React.FC<IInputProps> = (props: IInputProps) => {
  const [value, setValue] = useState(props.value?.toString() || '');
  const [placeholderOnTop, setPlaceholderOnTop] = useState(false);
  const inputRef = useRef(null);

  const initialPropValueSet = useRef(false);

  useEffect(() => {
    if (props.placeholder) {
      setPlaceholderOnTop(value.length > 0);
    }
  }, [value, props.placeholder]);

  useEffect(() => {
    setPlaceholderOnTop(!!props.value?.toString() && !!props.placeholder);
    if (props.value && !initialPropValueSet.current) {
      setValue(props.value.toString());
      initialPropValueSet.current = true;
    }
  }, [props.value]);

  const handleKeyUp = (e: any) => {
    setValue(e.target.value);
    if (props.onChange) {
      props.onChange(e);
    }
  };

  const wheelHandler: WheelEventHandler<HTMLInputElement> = (event) => (event.target as HTMLInputElement).blur();

  const focusInput = () => {
    inputRef?.current?.focus();
  };

  const renderMessage = () => {
    if (props.message) {
      return (
        <>
          <MessageContainer status={props.message.status}>
            {!props.message.hideIcon && (
              <img src={Icons.ErrorIcon} style={{ width: '18px', height: '16px', fill: 'transparent' }} />
            )}
            {props.message.text}
          </MessageContainer>
        </>
      );
    }

    return null;
  };

  const keyDownHandler = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (evt.key === 'Enter') {
      props.onEnter?.(evt);
    }
  };

  return (
    <Container
      width={props.width}
      widthUnit={props.widthUnit}
      invalid={props.invalid}
      disabled={props.disabled}
      compact={!!props.compact}
      style={props.style ? props.style : {}}
    >
      {props.placeholder && (
        <Label onClick={focusInput} placeholderOnTop={placeholderOnTop} disabled={props.disabled}>
          {props.placeholder}
        </Label>
      )}
      <input
        ref={inputRef}
        disabled={props.disabled}
        value={props.defaultBehaviour ? props.value?.toString() : value}
        onChange={handleKeyUp}
        onBlur={props.onBlur}
        onClick={props.onClick}
        onKeyDown={keyDownHandler}
        onFocus={props.onFocus}
        type={props.type}
        name={props.name}
        id={props.id}
        step={props.step}
        onWheel={wheelHandler}
        autoComplete={props.autoComplete}
      />
      {!!props.isShowPicture && (
        <InputImage
          alt={'web service'}
          isButtonDisabled={!props.isShowPictureDisabled}
          onClick={() => props.onPictureClick && props.onPictureClick()}
          src={props.picture}
          webScrapingDone={props?.webScrapingDone || !!props?.onPictureClick}
        />
      )}
      {renderMessage()}
    </Container>
  );
};
