/* eslint-disable complexity */
import { FormikProps } from 'formik';
import { cloneDeep } from 'lodash';
import { useParams } from 'react-router-dom';

/* eslint-disable max-statements */
import { Customer, DeclarationDocumentsEnum } from '@e-origin/shared';

import { EditCustomerType } from '../../../../../enums';
import {
  Button,
  DeclarationDocumentsComponent,
  DocumentChangeProps,
  getDeclarationDocumentById,
  getDeclarationDocumentPos,
  Input,
} from '../../../../../shared/components';
import { MutateDeclarationDocumentOperation } from '../../../../../shared/stores/declarationsSlice';
import { FormRow, FormSection, FormSectionTitle } from '../../../../../styles/common';
import { CustomerDetailsParamsType, CustomerDetailsTabType } from '../customer-details.component';
import * as Style from './customer-details-documents.styles';

interface CustomerDetailsDeclarantProps extends CustomerDetailsTabType {
  form: FormikProps<Customer>;
}

const CustomerDetailsDocuments: React.FC<CustomerDetailsDeclarantProps> = (props) => {
  const { handleSubmitCustomer, isButtonDisabled, form } = props;

  const { documents, personProvidingGuarantee } = form.values;

  const { handleBlur, handleChange } = form;
  const { customerId } = useParams<CustomerDetailsParamsType>();
  const context = customerId === 'add' ? 'CREATE' : 'UPDATE';

  const handleDocumentChange = (doc: DocumentChangeProps) => {
    const refreshedDocuments = cloneDeep(documents);

    switch (doc.operation) {
      case MutateDeclarationDocumentOperation.CREATE: {
        refreshedDocuments[doc.type] = [...(refreshedDocuments[doc.type] || []), doc.data];
        break;
      }
      case MutateDeclarationDocumentOperation.UPDATE: {
        const documentToUpdate = getDeclarationDocumentById(doc.id, refreshedDocuments[doc.type]);
        const documentPos = refreshedDocuments[doc.type].findIndex((d) => d === documentToUpdate);
        refreshedDocuments[doc.type][documentPos] = doc.data;
        break;
      }
      case MutateDeclarationDocumentOperation.DELETE: {
        const index = getDeclarationDocumentPos(doc.data, doc.type, refreshedDocuments[doc.type]);

        if (index !== -1) {
          refreshedDocuments[doc.type].splice(index, 1);
        }
        break;
      }
      default:
        break;
    }

    form.setFieldValue('documents', refreshedDocuments);
  };

  return (
    <Style.Container>
      <FormSection>
        <FormSectionTitle noTopBorder>Person providing a guarantee</FormSectionTitle>
        <FormRow>
          <Input
            name="personProvidingGuarantee.identificationNumber"
            placeholder="Identification number"
            onChange={handleChange}
            onBlur={handleBlur}
            value={personProvidingGuarantee.identificationNumber}
            width={387}
          />
        </FormRow>
      </FormSection>
      <Style.Title>Manage Guarantees</Style.Title>
      <DeclarationDocumentsComponent
        documents={documents as any}
        onDocumentChange={handleDocumentChange}
        includeDocumentTypes={[DeclarationDocumentsEnum.GUARANTEE_DOCUMENTS]}
        editDisabled={false}
        isHighValue={true}
      />
      <Style.SubmitSection>
        <Button
          disabled={isButtonDisabled}
          onClick={() => handleSubmitCustomer(EditCustomerType.DOCUMENTS)}
          primary
          type="submit"
        >
          {context === 'CREATE' ? 'Create' : 'Save'}
        </Button>
      </Style.SubmitSection>
    </Style.Container>
  );
};

export default CustomerDetailsDocuments;
