import { useState } from 'react';
import { Prompt, useHistory, useLocation } from 'react-router-dom';

import Icons from '../../../../../assets';
import { Button, CustomModal } from '../../../../../shared/components';
import ModalStyle from '../../../../../styles/modal';
import * as Style from './leave-page-modal.style';

interface LeavePageModalProps {
  hasUnsavedChanges: boolean;
}

export const LeavePageModal: React.FC<LeavePageModalProps> = ({ hasUnsavedChanges }) => {
  const history = useHistory();
  const location = useLocation();
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [pendingLocation, setPendingLocation] = useState<string | null>(null);

  const handlePrompt = (nextLocation: any): boolean => {
    if (hasUnsavedChanges && location.pathname !== nextLocation.pathname) {
      setPendingLocation(nextLocation.pathname);
      setShowSaveModal(true);
      return false;
    }
    return true;
  };

  const handleCancel = () => {
    setShowSaveModal(false);
    setPendingLocation(null);
  };

  const handleContinue = () => {
    if (pendingLocation) {
      history.block(() => {
        // pass
      });
      history.push(pendingLocation);
      setShowSaveModal(false);
      setPendingLocation(null);
    }
  };

  return (
    <>
      <Prompt when={hasUnsavedChanges} message={handlePrompt} />

      {showSaveModal && (
        <CustomModal show={showSaveModal} onHide={handleCancel} width={50} maxWidth={400}>
          <ModalStyle.Header>
            <ModalStyle.Title></ModalStyle.Title>
            <ModalStyle.HeaderActions>
              <button onClick={handleCancel}>
                <img src={Icons.CloseIcon} alt="Close" />
              </button>
            </ModalStyle.HeaderActions>
          </ModalStyle.Header>

          <ModalStyle.Content>
            <Style.ModalContent>
              You have unsaved changes. Would you like to continue without saving?
            </Style.ModalContent>
          </ModalStyle.Content>

          <ModalStyle.Footer>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button primary onClick={handleContinue}>
              Continue
            </Button>
          </ModalStyle.Footer>
        </CustomModal>
      )}
    </>
  );
};
