/* eslint-disable no-param-reassign */
import { FormikProps } from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Dropdown } from '../../../../../../../shared/components';
import {
  fetchCustomers,
  selectCustomers,
  selectCustomersNamesAndIds,
} from '../../../../../../../shared/stores/customersSlice';
import { TransitDetailsFormProps } from '../../../transit-details.component';
import * as Style from './transit-customer-input.style';

interface TransitCustomerInputProps {
  form: FormikProps<TransitDetailsFormProps>;
  disabled: boolean;
}

export const TransitCustomerInput: React.FC<TransitCustomerInputProps> = ({ form, disabled = false }) => {
  const dispatch = useDispatch();

  const customersNamesAndIds = useSelector(selectCustomersNamesAndIds);
  const customers = useSelector(selectCustomers);

  const getCustomerValue = customersNamesAndIds.find((customer) => customer.value === form.values.customer);

  const updateCustomerHandler = (selectedOption) => {
    form.setFieldValue('customer', selectedOption?.value);

    const newCustomer = customers.find((customer) => customer._id === selectedOption.value);

    form.setFieldValue('messageInfo.methodOfPayment', newCustomer.payments.methodOfPayment);
    form.setFieldValue('messageInfo.deferredPayment', newCustomer.payments.deferredPayment);
    form.setFieldValue('messageInfo.methodOfPaymentVAT', newCustomer.payments.methodOfPaymentVAT);

    form.touched.customer = true;
  };

  useEffect(() => {
    dispatch(fetchCustomers());
  }, []);

  const setRefreshCustomerData = () => {
    form.setFieldValue('refreshCustomerData', true);
  };

  return (
    <Style.Container>
      <Style.InputWrapper>
        <Dropdown
          menuOverflow={true}
          placeholder="Select customer"
          options={customersNamesAndIds}
          value={getCustomerValue}
          onChange={updateCustomerHandler}
          invalid={form.touched.customer && form.errors.customer !== undefined && form.errors.customer.length > 0}
          maxMenuHeight={200}
          compact
          disabled={disabled}
        />
        {form.values.refreshCustomerData && (
          <Style.MessageInfo>You need to save the form in order to apply the changes!</Style.MessageInfo>
        )}
      </Style.InputWrapper>
      {!disabled && (
        <Button type="button" style={{ height: 50 }} primary onClick={setRefreshCustomerData}>
          Refresh customer data
        </Button>
      )}
    </Style.Container>
  );
};
