/* eslint-disable complexity */
import { Incident } from '@e-origin/shared';

import { Input } from '../../../../../../shared/components';
import { FormContainer, FormRow, FormSection, FormSectionTitle } from '../../../../../../styles/common';
import * as Style from './transit-details-incident-customs.style';

interface TransitDetailsIncidentProps {
  incident: Incident;
  index: number;
}

export const TransitDetailsIncident: React.FC<TransitDetailsIncidentProps> = ({ incident, index }) => {
  return (
    <Style.Box key={incident.id}>
      <FormSectionTitle noTopBorder>#{index + 1} Incident</FormSectionTitle>
      <FormContainer>
        <FormSection paddingBottom={10}>
          <FormRow>
            <Input value={incident.code} placeholder="Code" width={24} widthUnit="%" disabled />
            <Input value={incident.text} placeholder="Additional explanation" width={24} widthUnit="%" disabled />
          </FormRow>
        </FormSection>

        {incident.Endorsement && (
          <FormSection paddingBottom={10}>
            <FormSectionTitle noTopBorder>Endorsement</FormSectionTitle>
            <FormRow>
              <Input value={incident.Endorsement?.date} placeholder="Date" disabled width={24} widthUnit="%" />
              <Input
                value={incident.Endorsement?.authority}
                placeholder="Authority"
                disabled
                width={24}
                widthUnit="%"
              />
              <Input value={incident.Endorsement?.place} placeholder="Place" disabled width={24} widthUnit="%" />
              <Input value={incident.Endorsement?.country} placeholder="Country" disabled width={24} widthUnit="%" />
            </FormRow>
          </FormSection>
        )}

        {incident.Location && (
          <FormSection paddingBottom={10}>
            <FormSectionTitle noTopBorder>Location</FormSectionTitle>
            <FormRow>
              <Input
                value={incident.Location?.qualifierOfIdentification}
                placeholder="Qualifier of Identification"
                disabled
                width={24}
                widthUnit="%"
              />
              <Input value={incident.Location?.UNLocode} placeholder="UNLocode" disabled width={24} widthUnit="%" />
              <Input value={incident.Location?.country} placeholder="Country" disabled width={24} widthUnit="%" />
            </FormRow>

            {incident.Location.GNSS && (
              <FormRow>
                <Input
                  value={incident.Location?.GNSS?.latitude}
                  placeholder="Latitude"
                  disabled
                  width={24}
                  widthUnit="%"
                />
                <Input
                  value={incident.Location?.GNSS?.longitude}
                  placeholder="Longitude"
                  disabled
                  width={24}
                  widthUnit="%"
                />
              </FormRow>
            )}

            {incident.Location.Address && (
              <FormRow>
                <Input
                  value={incident.Location?.Address?.streetAndNumber}
                  placeholder="Street and Number"
                  disabled
                  width={24}
                  widthUnit="%"
                />
                <Input value={incident.Location?.Address?.city} placeholder="City" disabled width={24} widthUnit="%" />
                <Input
                  value={incident.Location?.Address?.postcode}
                  placeholder="Postal code"
                  disabled
                  width={24}
                  widthUnit="%"
                />
              </FormRow>
            )}
          </FormSection>
        )}

        {incident.TransportEquipment?.length && (
          <FormSection paddingBottom={10}>
            <FormSectionTitle noTopBorder>Transport Equipment</FormSectionTitle>
            {incident.TransportEquipment.map((equipment, equipmentIdx) => (
              <div key={equipmentIdx}>
                <FormRow>
                  <Input
                    value={equipment.containerIdentificationNumber}
                    placeholder="Container Number"
                    disabled
                    width={24}
                    widthUnit="%"
                  />
                  <Input
                    value={equipment.numberOfSeals}
                    placeholder="Number of Seals"
                    disabled
                    width={24}
                    widthUnit="%"
                  />
                </FormRow>

                {equipment.Seal?.length > 0 && (
                  <FormRow style={{ display: 'flex', flexDirection: 'row' }}>
                    {equipment.Seal.map((seal, sealIndex) => (
                      <Input
                        key={seal.sequenceNumber || sealIndex}
                        value={seal.identifier}
                        placeholder="Seal Identifier"
                        disabled
                        width={24}
                        widthUnit="%"
                      />
                    ))}
                  </FormRow>
                )}

                {equipment.GoodsReference?.map((goodsReference, goodsReferenceIndex) => (
                  <Input
                    key={goodsReference.declarationGoodsItemNumber || goodsReferenceIndex}
                    value={goodsReference.declarationGoodsItemNumber}
                    placeholder="Goods Reference"
                    disabled
                    width={24}
                    widthUnit="%"
                  />
                ))}
              </div>
            ))}
          </FormSection>
        )}

        {incident.Transhipment && (
          <FormSection paddingBottom={10}>
            <FormSectionTitle noTopBorder>Transhipment</FormSectionTitle>
            <FormRow>
              <Input
                value={incident.Transhipment?.containerIndicator}
                placeholder="Container Indicator"
                disabled
                width={24}
                widthUnit="%"
              />
              {incident.Transhipment?.TransportMeans && (
                <>
                  <Input
                    value={incident.Transhipment?.TransportMeans?.typeOfIdentification}
                    placeholder="Transport Means ID Type"
                    disabled
                    width={24}
                    widthUnit="%"
                  />
                  <Input
                    value={incident.Transhipment?.TransportMeans?.identificationNumber}
                    placeholder="Transport Means ID"
                    disabled
                    width={24}
                    widthUnit="%"
                  />
                  <Input
                    value={incident.Transhipment?.TransportMeans?.nationality}
                    placeholder="Transport Means Nationality"
                    disabled
                    width={24}
                    widthUnit="%"
                  />
                </>
              )}
            </FormRow>
          </FormSection>
        )}
      </FormContainer>
    </Style.Box>
  );
};
