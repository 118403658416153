/* eslint-disable max-statements */
import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';

import { container, TYPES } from '@e-origin/ioc';
import { Declaration, Document, TaricOtherMeasures } from '@e-origin/shared';

import Icons from '../../../../../../../../../../assets';
import {
  Button,
  CustomModal,
  IManageSupportingDocumentsRow,
  ManageSupportingDocumentsTable,
  Spinner,
} from '../../../../../../../../../../shared/components';
import ModalStyle from '../../../../../../../../../../styles/modal';
import { DeclarationService } from '../../../../../../../../services';

interface IManageSupportingDocumentsProps {
  documents: Document[];
  declaration: Declaration;
  taricOtherMeasures: TaricOtherMeasures[];
  onHide(): void;
}

export const ManageSupportingDocuments: React.FC<IManageSupportingDocumentsProps> = (props) => {
  const declarationService: DeclarationService = container.get(TYPES.DeclarationService);
  const [rows, setRows] = useState<IManageSupportingDocumentsRow[]>(null);
  const [isSaving, setIsSaving] = useState(false);

  const extractCodesFromTaricOtherMeasures = (taricOtherMeasures: TaricOtherMeasures[]) => {
    const codes: string[] = [];
    taricOtherMeasures.forEach((measure) => {
      if (!measure.measureType.toLowerCase().includes('import') || !measure.duty) {
        return;
      }

      codes.push(...measure.duty.match(/[A-Z]-[0-9]+/g).map((code) => code.replace('-', '')));
    });

    return codes;
  };

  useEffect(() => {
    if (props.documents) {
      const supportingDocuments = cloneDeep(props.documents);
      const additionalCodes = extractCodesFromTaricOtherMeasures(props.taricOtherMeasures);

      const additionalDocuments = additionalCodes
        .map((code) => {
          if (supportingDocuments.find((doc) => doc.type === code)) {
            return undefined;
          }

          return {
            type: code,
            referenceNumber: '.',
            dateOfValidity: undefined,
          };
        })
        .filter((doc) => !!doc);

      setRows([
        ...supportingDocuments.map((doc) => ({ selected: true, document: doc })),
        ...additionalDocuments.map((doc) => ({ selected: false, document: doc })),
      ]);
    }
  }, [props.documents]);

  const save = async () => {
    setIsSaving(true);
    await declarationService.saveDeclarationDetails({
      updatedDeclaration: {
        ...props.declaration,
        documents: {
          ...props.declaration.documents,
          supportingDocuments: rows.filter((d) => d.selected).map((d) => d.document),
        },
      },
      propertiesToSend: ['documents'],
    });
    setIsSaving(false);
    props.onHide();
  };

  const handleTableUpdate = (data: IManageSupportingDocumentsRow[]) => {
    setRows(data);
  };

  return (
    <CustomModal width={70} show={true} onHide={props.onHide}>
      <ModalStyle.Header>
        <ModalStyle.Title>Manage supporting documents</ModalStyle.Title>
        <ModalStyle.HeaderActions>
          <button onClick={props.onHide}>
            <img src={Icons.CloseIcon} alt="" />
          </button>
        </ModalStyle.HeaderActions>
      </ModalStyle.Header>
      <ModalStyle.Content>
        <ManageSupportingDocumentsTable documents={rows} onChange={handleTableUpdate} />
      </ModalStyle.Content>
      <ModalStyle.Footer>
        <Button type="button" onClick={props.onHide}>
          Cancel
        </Button>
        <Button type="button" primary onClick={save}>
          {isSaving ? (
            <>
              <Spinner isContrast />
              Saving
            </>
          ) : (
            'Save'
          )}
        </Button>
      </ModalStyle.Footer>
    </CustomModal>
  );
};
