import { Container, SelectedIndicator } from './menu-item.style';

export interface IMenuItemProps {
  selected: boolean;
}

const MenuItem: React.FC<IMenuItemProps> = (props) => {
  return (
    <Container selected={props.selected}>
      {props.selected && <SelectedIndicator />}
      {props.children}
    </Container>
  );
};

export default MenuItem;
