import { useState } from 'react';

import { Declaration, DeclarationCustomsType } from '@e-origin/shared';

import Icons from '../../../../../../assets';
import { Button, CustomModal, Input } from '../../../../../../shared/components';
import { generateBatchByLRN } from '../../../../../../shared/stores/declarationsSlice';
import ModalStyle from '../../../../../../styles/modal';
import * as Style from './declaration-regularization-modal.style';

interface IDeclarationRegularizationModalProps {
  declaration: Declaration;
  open: boolean;
  onHide: () => void;
  onFinish: () => void;
}

export const DeclarationRegularizationModal: React.FC<IDeclarationRegularizationModalProps> = ({
  declaration,
  open,
  onHide,
  onFinish,
}) => {
  const showRegularization = declaration.customsState?.sentTo === DeclarationCustomsType.PLDA;
  const regularizationText = showRegularization ? 'Regularization' : 'Amendment';
  const batchNamePrefix = showRegularization ? 'REGUL' : 'AMEND';
  const [batchName, setBatchName] = useState(batchNamePrefix + declaration.consignment.referenceNumberUCR);

  const handleRegularization = () => {
    generateBatchByLRN(declaration.messageInfo.LRN, batchName, true).then(() => {
      onFinish();
      onHide();
    });
  };

  const handleDuplicate = () => {
    generateBatchByLRN(declaration.messageInfo.LRN, batchName, false).then(() => {
      onFinish();
      onHide();
    });
  };

  return (
    <CustomModal show={open} onHide={onHide}>
      <ModalStyle.Header>
        <ModalStyle.Title>{regularizationText} or Duplication</ModalStyle.Title>
        <ModalStyle.HeaderActions>
          <button onClick={onHide}>
            <img src={Icons.CloseIcon} alt="" />
          </button>
        </ModalStyle.HeaderActions>
      </ModalStyle.Header>
      <ModalStyle.Content>
        <Input
          defaultBehaviour={true}
          placeholder="Batch name"
          value={batchName}
          onChange={(event) => setBatchName(event.target.value)}
        />
        <Style.ButtonsContainer>
          <Button type="button" primary onClick={handleDuplicate}>
            Duplicate
          </Button>
          <Button type="button" primary onClick={handleRegularization}>
            {regularizationText}
          </Button>
        </Style.ButtonsContainer>
      </ModalStyle.Content>
    </CustomModal>
  );
};
