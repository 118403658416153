import { EnvironmentCountries, InControl } from '@e-origin/shared';

import { useConfig } from '../../../../../shared/hooks/use-config';
import * as Style from './control-notification.style';

interface ControlNotificationProps {
  inControl: InControl;
}

const getControlType = (controlType: string, country?: string) => {
  if (country === EnvironmentCountries.NL) {
    const controlTypeMap: Record<string, string> = {
      '10': 'Document control',
      '40': 'Physical checks',
    };

    return controlTypeMap[controlType] ? `${controlType} - ${controlTypeMap[controlType]}` : controlType;
  }

  return controlType;
};

const getNotificationType = (notificationType: string, country?: string) => {
  if (country === EnvironmentCountries.NL) {
    const notificationTypeMap: Record<string, string> = {
      '0': 'Decision to audit (and requested documents if necessary)',
      '1': 'Request for additional documents',
    };

    return notificationTypeMap[notificationType]
      ? `${notificationType} - ${notificationTypeMap[notificationType]}`
      : notificationType;
  }

  return notificationType;
};

export const ControlNotification: React.FC<ControlNotificationProps> = ({ inControl }) => {
  const { config } = useConfig();

  return (
    <Style.Section verticalPadding={20}>
      <Style.SectionTitle>Control notification</Style.SectionTitle>
      <Style.Row>
        {inControl.customsRegistrationNumber && (
          <div>
            <label>Customs registration number</label>
            <p>{inControl.customsRegistrationNumber}</p>
          </div>
        )}
        <div>
          <label>Notification date</label>
          <p>{inControl.notificationDate}</p>
        </div>
        <div>
          <label>Notification Type</label>
          <p>{getNotificationType(inControl.notificationType, config?.COUNTRY)}</p>
        </div>
        <div>
          <label>Anticipated control date</label>
          <p>{inControl.anticipatedControlDate}</p>
        </div>
      </Style.Row>
      {inControl.text && (
        <Style.SubSection>
          <Style.SubSectionTitle>Additional information</Style.SubSectionTitle>
          <Style.Row>
            <div>
              <label>Examination result comment</label>
              <p>{inControl.text}</p>
            </div>
          </Style.Row>
        </Style.SubSection>
      )}
      {inControl.typeOfControls?.length && (
        <Style.SubSection>
          <Style.SubSectionTitle>Type of controls</Style.SubSectionTitle>
          {inControl.typeOfControls.map((controlType, index) => (
            <Style.Row key={index}>
              <div>
                <label>Type</label>
                <p>{getControlType(controlType.type, config?.COUNTRY)}</p>
              </div>
              <div>
                <label>Remarks</label>
                <p>{controlType.remarks}</p>
              </div>
            </Style.Row>
          ))}
        </Style.SubSection>
      )}
      {inControl.requestedDocuments?.length && (
        <Style.SubSection>
          <Style.SubSectionTitle>Requested documents</Style.SubSectionTitle>
          {inControl.requestedDocuments.map((controlType, index) => (
            <Style.Row key={index}>
              <div>
                <label>Type</label>
                <p>{controlType.type}</p>
              </div>
              {controlType.referenceNumber && (
                <div>
                  <label>Reference number</label>
                  <p>{controlType.referenceNumber}</p>
                </div>
              )}
              <div>
                <label>Description</label>
                <p>{controlType.description}</p>
              </div>
            </Style.Row>
          ))}
        </Style.SubSection>
      )}
    </Style.Section>
  );
};
