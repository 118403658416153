import { get } from 'lodash';
import { useMemo } from 'react';

import { MultilingualCodeSystems } from '@e-origin/shared';

import { Input, MultiLangCodes } from '../../../../../../shared/components';

interface TransitAutocompleteHeaderProps {
  form: any;
  parents: any[];
  name: string;
  disabled: boolean;
  width?: number;
  placeholder?: string;
  code: string;
}

export const TransitAutocompleteHeader: React.FC<TransitAutocompleteHeaderProps> = ({
  form,
  parents,
  name,
  disabled,
  width = 50,
  placeholder,
  code,
}) => {
  const value = get(form.values, name);
  const parentValue = useMemo(() => parents.find((parent) => !!parent), [parents]);

  return (
    <>
      {!parentValue ? (
        <MultiLangCodes
          name={name}
          code={code}
          width={width}
          placeholder={placeholder}
          disabled={disabled}
          onChange={form.handleChange}
          value={value}
          system={MultilingualCodeSystems.NCTS}
          compact
        />
      ) : (
        <Input width={width} widthUnit="%" placeholder={placeholder} value={parentValue} disabled compact />
      )}
    </>
  );
};
