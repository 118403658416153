import { Batch } from '@e-origin/shared';

import { Button, Modal } from '../../../../shared/components';
import { ErrorText, MessageRow, MessageWrapper } from './failed-details-modal-modal.style';

interface IFailedDetailsModalProps {
  show: boolean;
  onHide: () => void;
  batch?: Batch;
}

const FailedDetailsModal: React.FC<IFailedDetailsModalProps> = (props) => {
  const errorText = props.batch?.status.details?.replace(/Error: /g, '');

  return (
    <Modal
      title={`Batch error details`}
      show={props.show}
      onHide={() => {
        props.onHide();
      }}
      buttons={[
        <Button type="button" outline key="cancel-modal-btn" onClick={props.onHide}>
          Close
        </Button>,
      ]}
      buttonsJustifyAlign="center"
    >
      <MessageWrapper>
        <MessageRow>
          <div>
            <label>Batch id</label>
            <p>{props.batch?.counter}</p>
          </div>
          <div>
            <label>Batch name</label>
            <p>{props.batch?.name}</p>
          </div>
          <div>
            <label>Date</label>
            <p>{props.batch?.updatedAt}</p>
          </div>
        </MessageRow>
        <MessageRow>
          <div>
            <label>Error details</label>
            <ErrorText>{errorText}</ErrorText>
          </div>
        </MessageRow>
      </MessageWrapper>
    </Modal>
  );
};

export default FailedDetailsModal;
