import { PageContainer, PageHeader, PageTitle } from '../../styles/common';

import * as Style from './help.styles';
import { helpData, HelpSectionEnum } from './helpData';
import { HelpSection } from './HelpSection';

export const Help: React.FC = () => {
  const rowStructure = [
    [HelpSectionEnum.BATCH_STATUS],
    [HelpSectionEnum.DECLARATION_STATUS, HelpSectionEnum.GOOD_ITEMS_STATUS],
    [HelpSectionEnum.ACTIONS],
    [HelpSectionEnum.TRANSIT_STATUS],
  ];

  return (
    <PageContainer isRelative overflowY>
      <PageHeader>
        <PageTitle>Help</PageTitle>
      </PageHeader>
      <Style.HelpContainer>
        {rowStructure.map((sectionsInRow, rowIndex) => (
          <Style.RowContainer key={`row-${rowIndex}`}>
            {sectionsInRow.map((sectionKey) => (
              <HelpSection key={sectionKey} sectionTitle={sectionKey} {...helpData[sectionKey]} />
            ))}
          </Style.RowContainer>
        ))}
      </Style.HelpContainer>
    </PageContainer>
  );
};
