import { ArrowRight, Container, Dot } from './sending-indicator.style';

interface ISsendingIndicatorProps {
  isContrast?: boolean;
  inSyncMode?: boolean;
}

export const SendingIndicator: React.FC<ISsendingIndicatorProps> = ({ isContrast, inSyncMode }) => {
  return (
    <Container>
      {Array(5)
        .fill(0)
        .map((item, index) => (
          <Dot key={`spinner-dot-${index}`} isContrast={isContrast} inSyncMode={inSyncMode}></Dot>
        ))}
      <ArrowRight isContrast={isContrast} inSyncMode={inSyncMode} />
    </Container>
  );
};
