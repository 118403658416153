import { useEffect, useState } from 'react';

import { container, TYPES } from '@e-origin/ioc';
import { Declaration, DeclarationGoods, ISaveNotes } from '@e-origin/shared';

import Icons from '../../../../../../../../assets';
import { Button, CustomModal } from '../../../../../../../../shared/components';
import { RiskAnalysisService } from '../../../../../../services';
import { Checkbox, ModalOverlay } from '../../risk-analysis-modal.styles';
import {
  CheckboxContainer,
  RiskAnalysisNotesActions,
  RiskAnalysisNotesContent,
  RiskAnalysisNotesHeader,
  StyledRiskAnalysisNotesModal,
} from './risk-analysis-notes-modal.style';

interface IRiskAnalysisNotesModalProps {
  onHide(data?: ISaveNotes): void;
  goodDetails: DeclarationGoods;
  declaration: Declaration;
}

export const RiskAnalysisNotesModal: React.FC<IRiskAnalysisNotesModalProps> = (props) => {
  const riskAnalysisService: RiskAnalysisService = container.get(TYPES.RiskAnalysisService);
  const [customsDecisionChecked, setCustomsDecisionChecked] = useState(
    !!props.goodDetails.evalMethodAiHsCode.annotationCustomsDecision,
  );
  const [notes, setNotes] = useState(props.goodDetails.evalMethodAiHsCode.annotationNote || '');
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);

  const [cursorPosition, setCursorPosition] = useState(
    props.goodDetails.evalMethodAiHsCode.annotationNote?.length || 0,
  );

  useEffect(() => {
    setSaveButtonDisabled(
      notes === props.goodDetails.evalMethodAiHsCode.annotationNote &&
        customsDecisionChecked === !!props.goodDetails.evalMethodAiHsCode.annotationCustomsDecision,
    );
  }, [notes, customsDecisionChecked]);

  const saveNotes = async () => {
    if (saveButtonDisabled) {
      props.onHide();
      return;
    }

    if (props.declaration?._id && props.goodDetails) {
      const webUrl = props.goodDetails.webScraping?.webUrl;
      const goodItemsIds = props.declaration.goodsShipmentItems
        .filter((good) => good.webScraping?.webUrl === webUrl)
        .map((good) => good._id);

      await riskAnalysisService.saveNotes(goodItemsIds, {
        notes,
        customsDecisionChecked,
      });
      props.onHide({ notes, customsDecisionChecked });
      return;
    }
    props.onHide();
  };

  return (
    <>
      <ModalOverlay />
      <CustomModal show={true} onHide={() => null}>
        <StyledRiskAnalysisNotesModal>
          <RiskAnalysisNotesHeader>
            <CheckboxContainer>
              <Checkbox
                type={'checkbox'}
                onChange={(event) => setCustomsDecisionChecked(event.target.checked)}
                checked={customsDecisionChecked}
              />
              <p>Customs decision</p>
            </CheckboxContainer>
            <RiskAnalysisNotesActions>
              <Button
                style={{ height: 'auto', padding: '5px 10px' }}
                key="save-notes"
                primary
                type="button"
                onClick={async () => saveNotes()}
                disabled={saveButtonDisabled}
              >
                <img style={{ width: '24px' }} src={Icons.CheckMarkIcon} />
              </Button>
              <button onClick={async () => props.onHide()}>
                <img src={Icons.CloseIcon} alt="" />
              </button>
            </RiskAnalysisNotesActions>
          </RiskAnalysisNotesHeader>
          <RiskAnalysisNotesContent>
            <textarea
              placeholder="Write some notes here.."
              autoFocus={true}
              onChange={(event) => {
                setCursorPosition(event.target.selectionStart);
                setNotes(event.target.value);
              }}
              onFocus={(event) => {
                // eslint-disable-next-line no-param-reassign
                event.target.selectionStart = cursorPosition;
              }}
              value={notes}
            ></textarea>
          </RiskAnalysisNotesContent>
        </StyledRiskAnalysisNotesModal>
      </CustomModal>
    </>
  );
};
