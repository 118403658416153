import { useEffect, useRef, useState } from 'react';
import { IDataTableColumn } from 'react-data-table-component';
import { TabPanel } from 'react-headless-tabs';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

/* eslint-disable complexity */
import { Customer, RequestFilters } from '@e-origin/shared';

import { AdditionalFiscalReferenceColumns, CustomersColumns } from '../../../../enums';
/* eslint-disable max-statements */
import {
  Button,
  NotificationModal,
  SearchBox,
  Spinner,
  Table,
  TableActionButtons,
  TableActions,
} from '../../../../shared/components';
import {
  clearCustomerDetails,
  deleteCustomer,
  fetchCustomers,
  selectCustomers,
  selectCustomersLoading,
  selectTotalCustomersCount,
  setCustomersLoading,
} from '../../../../shared/stores/customersSlice';
import { DataTableControls, TabPanelContainer } from '../../../../styles/common';
import { BatchLoadingContainer } from '../../../batches/components/bulk-update-batch-modal/bulk-update-batch-modal.styles';
import { CustomersHeader, CustomersPageContainer, CustomersTitle } from './customers.styles';

const ADD_NEW_CUSTOMER = 'New customer';
const PAGE_TITLE = 'Customers';
const SEARCH_TEXT = 'Search by customer name';

const DELETE_CUSTOMER_BUTTON_TEXT = 'Delete';
const DELETE_CUSTOMER_MODAL_TEXT = "Are you sure you want to delete the customer? Action can't be undone!";
const DELETE_CUSTOMER_TITLE = 'Confirm customer delete';

const Customers = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isFirstRun = useRef(true);

  const [showModal, setShowModal] = useState(false);
  const [tableSearchQuery, setTableSearchQuery] = useState('');
  const [sortField, setSortField] = useState('');
  const [sortDirection, setSortDirection] = useState('');
  const [tablePageSize, setTablePageSize] = useState(10);
  const [showCustomerDeleteNotificationModal, setShowCustomerDeleteNotificationModal] = useState(false);
  const [customerDeleteId, setCustomerDeleteId] = useState('');

  const totalCustomersCount = useSelector(selectTotalCustomersCount);
  const customersList = useSelector(selectCustomers);
  const isCustomersLoading = useSelector(selectCustomersLoading);

  const getRequestFilters = (searchQuery?: string, tableSortField = '', tableSortDirection = ''): RequestFilters => {
    const filter: RequestFilters = {
      searchQuery: searchQuery || tableSearchQuery,
    };

    if (tableSortField.length && tableSortDirection.length) {
      filter.sorting = {
        sortField: tableSortField,
        sortDirection: tableSortDirection,
      };
    } else if (sortField.length && sortDirection.length) {
      filter.sorting = {
        sortField,
        sortDirection,
      };
    }
    return filter;
  };

  const loadData = () => {
    dispatch(setCustomersLoading());
    dispatch(fetchCustomers({}, 1, 10));
  };

  const loadCustomers = (page = 1, pageSize: number = tablePageSize, tableSortField = '', tableSortDirection = '') => {
    setTablePageSize(pageSize);
    setSortField(tableSortField);
    setSortDirection(tableSortDirection);

    const requestFilter = getRequestFilters(undefined, tableSortField, tableSortDirection);
    dispatch(fetchCustomers(requestFilter, page, pageSize));
  };

  useEffect(() => {
    if (isFirstRun.current) {
      loadData();
      isFirstRun.current = false;
    } else {
      dispatch(setCustomersLoading());
      loadCustomers();
    }

    dispatch(clearCustomerDetails());
  }, []);

  const toggleAddNewCustomerModal = () => {
    setShowModal(!showModal);

    history.push('/customers/add');
  };

  const searchCustomers = (searchQuery: string) => {
    const requestFilter = getRequestFilters(searchQuery);

    setTableSearchQuery(searchQuery);
    dispatch(setCustomersLoading());
    dispatch(fetchCustomers(requestFilter, 1, 10));
  };

  const toggleDeleteCustomerNotificationModal = () => {
    setShowCustomerDeleteNotificationModal(!showCustomerDeleteNotificationModal);
  };

  const handleTableAction = (actionType: string, row: any) => {
    const customerId = row._id;

    if (actionType === 'view') {
      if (customerId) {
        history.push(`/customers/${customerId}`);
      } else {
        history.push('/customers/add');
      }
    }

    if (actionType === 'delete') {
      setCustomerDeleteId(customerId);
      toggleDeleteCustomerNotificationModal();
    }
  };

  const handleCustomerDelete = () => {
    dispatch(deleteCustomer(customerDeleteId));
  };

  const columns: IDataTableColumn<Customer>[] = [
    {
      name: CustomersColumns.NAME,
      selector: 'name',
      sortable: true,
    },
    {
      name: CustomersColumns.DATE,
      selector: 'updatedAt',
      sortable: true,
      width: '160px',
    },
    {
      name: AdditionalFiscalReferenceColumns.ACTIONS,
      right: true,
      cell: (row) => (
        <TableActionButtons
          actions={[TableActions.Edit, TableActions.Delete]}
          noSpaceBetweenButtons
          onClick={(actionType) => {
            handleTableAction(actionType, row);
          }}
        />
      ),
    },
  ];

  const handleSetLoading = () => {
    dispatch(setCustomersLoading());
  };

  return (
    <CustomersPageContainer isRelative={true}>
      <>
        <CustomersHeader>
          <CustomersTitle>{PAGE_TITLE}</CustomersTitle>
          <DataTableControls>
            <Button outline onClick={toggleAddNewCustomerModal}>
              {ADD_NEW_CUSTOMER}
            </Button>
            <SearchBox handleSearch={searchCustomers} placeholder={SEARCH_TEXT} />
          </DataTableControls>
        </CustomersHeader>

        {isCustomersLoading && (
          <BatchLoadingContainer>
            <Spinner />
          </BatchLoadingContainer>
        )}
        <TabPanelContainer>
          <TabPanel hidden={false}>
            <Table<Customer>
              columns={columns}
              list={customersList}
              isDataLoading={isCustomersLoading}
              loadData={loadCustomers}
              totalListCount={totalCustomersCount}
              setLoading={handleSetLoading}
            />
          </TabPanel>
        </TabPanelContainer>
      </>

      <NotificationModal
        confirmButtonText={DELETE_CUSTOMER_BUTTON_TEXT}
        isDelete={true}
        onConfirm={handleCustomerDelete}
        onHide={toggleDeleteCustomerNotificationModal}
        show={showCustomerDeleteNotificationModal}
        title={DELETE_CUSTOMER_TITLE}
      >
        <div>{DELETE_CUSTOMER_MODAL_TEXT}</div>
      </NotificationModal>
    </CustomersPageContainer>
  );
};

export default Customers;
