import { FormikConfig, useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Transit, TransitCreate } from '@e-origin/shared';

import Icons from '../../../../../../assets';
import { Button, CustomModal, Input } from '../../../../../../shared/components';
import { duplicateTransit } from '../../../../../../shared/stores/transitsSlice';
import { FormContainer, FormRow, FormSection } from '../../../../../../styles/common';
import ModalStyle from '../../../../../../styles/modal';

interface DuplicateTransitModalProps {
  transit: Transit;
  onHide(): void;
}

export const DuplicateTransitModal: FC<DuplicateTransitModalProps> = ({ transit, onHide }) => {
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);

  const validate: FormikConfig<Partial<TransitCreate>>['validate'] = (values) => {
    const errors: Partial<Record<keyof TransitCreate, string>> = {};

    if (!values.name) {
      errors.name = 'Required';
    }

    // check if the filename contains only alphanumeric and dash characters
    if (!/^[a-zA-Z0-9-]*$/g.test(values.name || '')) {
      errors.name = 'Name is invalid';
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validate,
    onSubmit: async (values) => {
      dispatch(duplicateTransit(transit._id, values.name));
      onHide();
    },
    enableReinitialize: false,
  });

  useEffect(() => {
    if (transit) {
      formik.setFieldValue('name', `DUPL-${transit.name}`);
    }
  }, [transit]);

  const startFormSubmit = () => {
    if (!submitting) {
      formik.submitForm();
    }
  };

  const isProcessButtonDisabled = !!formik.errors.name;

  return (
    <CustomModal
      show={true}
      onHide={() => {
        setSubmitting(false);
        onHide();
      }}
    >
      <ModalStyle.Header>
        <ModalStyle.Title>Duplicate Transit</ModalStyle.Title>
        <ModalStyle.HeaderActions>
          <button onClick={onHide}>
            <img src={Icons.CloseIcon} alt="" />
          </button>
        </ModalStyle.HeaderActions>
      </ModalStyle.Header>

      <ModalStyle.Content>
        <FormContainer onSubmit={formik.handleSubmit}>
          <FormSection verticalPadding={20} topPadding={40} paddingBottom={60}>
            <FormRow>
              <Input
                name="name"
                placeholder="Transit name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                width={100}
                widthUnit="%"
                invalid={formik.touched.name && formik.errors.name !== undefined && formik.errors.name?.length > 0}
              />
            </FormRow>
          </FormSection>
        </FormContainer>
        <ModalStyle.Footer>
          <Button type="button" key="cancel-transit-modal-btn" onClick={onHide} disabled={submitting}>
            Cancel
          </Button>
          <Button
            type="button"
            primary
            key="create-transit-modal-btn"
            onClick={startFormSubmit}
            showLoading={submitting}
            disabled={submitting || isProcessButtonDisabled}
          >
            Duplicate
          </Button>
        </ModalStyle.Footer>
      </ModalStyle.Content>
    </CustomModal>
  );
};
