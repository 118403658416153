import { ControlResult } from '@e-origin/shared';

import * as Style from './control-result-details.style';

interface ControlResultProps {
  controlResult: ControlResult[];
}

const renderField = (label: string, value?: string | number) =>
  value && (
    <div>
      <label>{label}</label>
      <p>{value}</p>
    </div>
  );

export const ControlResultDMS: React.FC<ControlResultProps> = ({ controlResult }) => {
  return (
    <>
      <Style.Section>
        <Style.SectionTitle>Overall control</Style.SectionTitle>
        {controlResult
          .filter((result) => !result.resultsOfControl?.length)
          .map((item, index) => (
            <Style.SubSection key={`control-result-${index}`}>
              <Style.Row>
                {renderField('Date', item.date)}
                {renderField('Control code', item.code)}
                {renderField('Remarks', item.remarks)}
              </Style.Row>
            </Style.SubSection>
          ))}
      </Style.Section>
      <Style.Section>
        <Style.SectionTitle>Detailed control data</Style.SectionTitle>
        {controlResult
          .filter((result) => result.resultsOfControl?.length)
          .map((item, index) => (
            <Style.SubSection key={`control-result-${index}`}>
              {(item.resultsOfControl || []).map((result, resultIndex) => (
                <Style.SubSection key={`result-of-control-${index}-${resultIndex}`}>
                  <Style.Row>
                    <Style.Row>{renderField('Item number', result.goodsItemNumber)}</Style.Row>
                    {renderField('Risk area code', result.riskAreaCode)}
                    {renderField('Control type', result.controlType)}
                    {renderField('Control date', result.controlDate)}
                    {renderField('Remarks', result.remarks)}
                  </Style.Row>
                  {(result.controlDetails || []).map((controlDetail, controlDetailIndex) => (
                    <Style.SubSection key={`control-result-detail-${index}-${resultIndex}-${controlDetailIndex}`}>
                      <Style.SubSectionTitle>Control Detail</Style.SubSectionTitle>
                      <Style.Row>
                        {renderField('Type of discrepancies', controlDetail.typeOfDiscrepancies)}
                        {renderField('Attribute pointer', controlDetail.attributePointer)}
                        {renderField('Corrected value', controlDetail.correctedValue)}
                        {renderField('Remarks', controlDetail.remarks)}
                      </Style.Row>
                    </Style.SubSection>
                  ))}
                </Style.SubSection>
              ))}
            </Style.SubSection>
          ))}
      </Style.Section>
    </>
  );
};
