/* eslint-disable complexity */
import { FormikProps, getIn } from 'formik';
import { GenericSliderProps } from 'rc-slider/lib/interface';

import { Customer } from '@e-origin/shared';

import { ReactComponent as ActiveIcon } from '../../../../../../assets/icons/active-icon.svg';
import { ReactComponent as InactiveIcon } from '../../../../../../assets/icons/inactive-icon.svg';
import { Input } from '../../../../../../shared/components';
// todo: do not the style from the components
import { Error, FormSection } from '../../../../../../styles/common';
import * as GeneralStyle from '../evaluation-methods.styles';
import * as Style from './evaluation-method-ai-hs-code.styles';

const EVAL_METHOD_AI_HS_CODE = 'EvalMethod AI HS code';
const FROM = 'From';
const RANGE_OF_ACCEPTANCE = 'Range of acceptance';
const TO = 'To';

const DOT_STYLE: GenericSliderProps['dotStyle'] = {
  backgroundColor: '#e5e5e5',
  border: 'none',
  height: 9,
  width: 9,
};

const HANDLE_STYLE: GenericSliderProps['handleStyle'] = {
  backgroundColor: '#1870a3',
  border: 'none',
  height: 9,
  marginTop: '-3px',
  width: 9,
};

const INITIAL_MARK_STYLE: React.CSSProperties = {
  color: '#848484',
  left: '0.5%',
};

const LIMIT_MARK_STYLE: React.CSSProperties = {
  color: '#848484',
  left: '99.5%',
};

const ACTIVE_TRACK_STYLE: GenericSliderProps['trackStyle'] = {
  backgroundColor: '#1870a3',
};

const INACTIVE_TRACK_STYLE: GenericSliderProps['trackStyle'] = {
  backgroundColor: '#e5e5e5',
};

interface EvaluationMethodAIHsCodeProps {
  form: FormikProps<Customer>;
}

export const EvaluationMethodAIHsCode: React.FC<EvaluationMethodAIHsCodeProps> = ({ form }) => {
  const { setFieldValue, handleBlur, handleChange, errors } = form;
  const renderActiveMethodButton = (activeMethod: boolean, fieldName: string) => (
    <button onClick={() => setFieldValue(fieldName, !activeMethod)}>
      {activeMethod ? <ActiveIcon /> : <InactiveIcon />}
    </button>
  );

  const { evaluationMethods } = form.values;

  const evalMethodAIHSCodeErrors = getIn(errors, 'evaluationMethods.evalMethodAIHSCode');

  return (
    <GeneralStyle.EvaluationMethodContainer
      activeMethod={evaluationMethods.evalMethodAIHSCode?.activateMethod || false}
    >
      <GeneralStyle.EvaluationMethodContainerHeader>
        <GeneralStyle.EvaluationMethodContainerHeaderBody>
          <GeneralStyle.EvaluationMethodTitle>{EVAL_METHOD_AI_HS_CODE}</GeneralStyle.EvaluationMethodTitle>
        </GeneralStyle.EvaluationMethodContainerHeaderBody>
        {renderActiveMethodButton(
          evaluationMethods.evalMethodAIHSCode?.activateMethod || false,
          'evaluationMethods.evalMethodAIHSCode.activateMethod',
        )}
      </GeneralStyle.EvaluationMethodContainerHeader>
      <GeneralStyle.EvaluationMethodText>{RANGE_OF_ACCEPTANCE}</GeneralStyle.EvaluationMethodText>
      <Style.RangeSliderContainer>
        <Style.RangeSlider
          trackStyle={[ACTIVE_TRACK_STYLE, INACTIVE_TRACK_STYLE]}
          handleStyle={[HANDLE_STYLE, HANDLE_STYLE]}
          min={0.0}
          max={1.0}
          step={0.01}
          defaultValue={[
            evaluationMethods.evalMethodAIHSCode?.range?.from || 0.0,
            evaluationMethods.evalMethodAIHSCode?.range?.to || 1.0,
          ]}
          value={[
            evaluationMethods.evalMethodAIHSCode?.range?.from || 0,
            evaluationMethods.evalMethodAIHSCode?.range?.to || 1,
          ]}
          onChange={(values) => {
            setFieldValue('evaluationMethods.evalMethodAIHSCode.range.from', values[0]);
            setFieldValue('evaluationMethods.evalMethodAIHSCode.range.to', values[1]);
          }}
          dotStyle={DOT_STYLE}
          marks={{
            0.0: { style: INITIAL_MARK_STYLE, label: '0.0' },
            1.0: { style: LIMIT_MARK_STYLE, label: '1.0' },
          }}
        />
      </Style.RangeSliderContainer>
      <FormSection topPadding={25} paddingBottom={30}>
        <GeneralStyle.EvaluationMethodFormRow>
          <Input
            defaultBehaviour={true}
            name="evaluationMethods.evalMethodAIHSCode.range.from"
            placeholder={FROM}
            onChange={handleChange}
            onBlur={handleBlur}
            value={evaluationMethods.evalMethodAIHSCode?.range?.from}
            width={100}
            widthUnit={'%'}
            type="number"
            step="0.01"
          />
          <Input
            defaultBehaviour={true}
            name="evaluationMethods.evalMethodAIHSCode.range.to"
            placeholder={TO}
            onChange={handleChange}
            onBlur={handleBlur}
            value={evaluationMethods.evalMethodAIHSCode?.range?.to}
            width={100}
            widthUnit={'%'}
            type="number"
            step="0.01"
          />
        </GeneralStyle.EvaluationMethodFormRow>
        {!!evalMethodAIHSCodeErrors &&
          evalMethodAIHSCodeErrors.range &&
          (evalMethodAIHSCodeErrors.range.from.length > 0 || evalMethodAIHSCodeErrors.range.to.length > 0) && (
            <div className="flex p-0">
              <div className="flex-1 text-sm pl-4">
                <Error>{evalMethodAIHSCodeErrors.range.from}</Error>
              </div>
              <div className="flex-1 text-sm pl-4">
                <Error>{evalMethodAIHSCodeErrors.range.to}</Error>
              </div>
            </div>
          )}
      </FormSection>
    </GeneralStyle.EvaluationMethodContainer>
  );
};
