import { useFormik } from 'formik';
/* eslint-disable complexity */
import moment from 'moment';
import { useSelector } from 'react-redux';

import { Document, EORIGIN_DATE_FORMAT } from '@e-origin/shared';

import { Button, CustomModal, Input } from '../..';
import Icons from '../../../../assets';
import { FormContainer, FormRow, FormSection } from '../../../../styles/common';
import ModalStyle from '../../../../styles/modal';
import { selectDeclarant } from '../../../stores/declarantSlice';
import { retrieveCodes } from '../../../stores/settingsSlice';
import { Autocomplete } from '../../autocomplete/autocomplete.component';
import { DatePicker } from '../../date-picker/date-picker.component';

interface IDeclarationDetailsDocumentsModalProps {
  onHide: () => void;
  docDetails?: Document;
  onSave: (savedDocument: Document, originalDocument: Document) => void;
  editDisabled: boolean;
}

export const ManageSupportingDocumentModal: React.FC<IDeclarationDetailsDocumentsModalProps> = (props) => {
  const declarant = useSelector(selectDeclarant);
  const formik = useFormik({
    initialValues: {
      code: props.docDetails?.type || '',
      referenceNumber: props.docDetails?.referenceNumber || '',
      dateOfValidity: props.docDetails?.dateOfValidity
        ? moment(props.docDetails?.dateOfValidity, EORIGIN_DATE_FORMAT).toDate()
        : new Date(),
    },
    onSubmit: async (values) => {
      props.onSave(
        {
          ...props.docDetails,
          type: values.code,
          referenceNumber: values.referenceNumber,
          dateOfValidity: values.dateOfValidity
            ? moment(values.dateOfValidity, EORIGIN_DATE_FORMAT).format(EORIGIN_DATE_FORMAT)
            : undefined,
        },
        props.docDetails,
      );
      props.onHide();
    },
    enableReinitialize: false,
  });

  const handleSubmit = () => {
    formik.submitForm();
  };

  return (
    <CustomModal
      show={true}
      onHide={() => {
        props.onHide();
      }}
    >
      <ModalStyle.Header>
        <ModalStyle.Title>Edit additional reference document</ModalStyle.Title>
        <ModalStyle.HeaderActions>
          <button onClick={props.onHide}>
            <img src={Icons.CloseIcon} alt="" />
          </button>
        </ModalStyle.HeaderActions>
      </ModalStyle.Header>
      <ModalStyle.Content>
        <FormContainer onSubmit={formik.handleSubmit}>
          <FormSection verticalPadding={20} topPadding={0}>
            <FormRow>
              <Autocomplete
                placeholder="Code"
                fetchOptions={(search: string) => retrieveCodes('CL380', search, declarant.language)}
                onChange={(selectedOption) => {
                  formik.setFieldValue('code', selectedOption?.value);
                }}
                value={{
                  value: formik.values.code,
                  label: formik.values.code,
                }}
                disabled={!!props.docDetails || props.editDisabled}
              />
            </FormRow>
            <FormRow>
              <Input
                name="referenceNumber"
                placeholder="Reference"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.referenceNumber}
                width={100}
                widthUnit="%"
                disabled={props.editDisabled}
              />
            </FormRow>
            <FormRow>
              <DatePicker
                name="dateOfValidity"
                placeholder="Date of validity"
                value={formik.values.dateOfValidity}
                onChange={(value: Date) => formik.setFieldValue('dateOfValidity', value)}
              />
            </FormRow>
          </FormSection>
        </FormContainer>
      </ModalStyle.Content>
      <ModalStyle.Footer>
        <Button type="button" onClick={props.onHide}>
          Cancel
        </Button>
        <Button type="button" primary onClick={handleSubmit}>
          Save
        </Button>
      </ModalStyle.Footer>
    </CustomModal>
  );
};
