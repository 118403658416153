import { useState } from 'react';
import { Dropdown, useDropdownMenu, useDropdownToggle } from 'react-overlays';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import AccountCircleIcon from '../../../assets/icons/account_circle.svg';
import { selectAccountName } from '../../stores/declarantSlice';
import { selectUserDisplayName, signOutUser } from '../../stores/userSlice';
import { Container, DropWrapper } from './signedin-user.style';

const Menu: React.FC<any> = ({ onLogout }) => {
  const [props, { toggle, show }] = useDropdownMenu({
    flip: true,
    offset: [0, 8],
  }) as any;
  const display = show ? 'flex' : 'none';
  return (
    <div {...props} className={`${display} w-48 py-2 flex-col shadow-lg border-gray-200 bg-white z-10 rounded`}>
      <button
        type="button"
        onClick={() => {
          toggle(false);
          onLogout();
        }}
        className="text-left hover:bg-brand-100 px-6 py-2"
      >
        Logout
      </button>
    </div>
  );
};

const Toggle: React.FC<any> = ({ id, children }) => {
  const [props] = useDropdownToggle();
  return (
    <button type="button" className="btn" id={id} {...props}>
      {children}
    </button>
  );
};

const DropdownButton: React.FC<any> = ({ show, onToggle, drop, alignEnd, onLogout, accountName, userName }) => (
  <Dropdown show={show} onToggle={onToggle} drop={drop} alignEnd={alignEnd} itemSelector="button:not(:disabled)">
    <span>
      <Toggle id="example-toggle">
        <Container>
          <img src={AccountCircleIcon} alt="" />
          <div>
            <span>{accountName}</span>
            <span>{userName}</span>
          </div>
        </Container>
      </Toggle>
      <Menu onLogout={onLogout} />
    </span>
  </Dropdown>
);

export const SignedInUser = () => {
  const [show, setShow] = useState(false);
  const user = useSelector(selectUserDisplayName);
  const accountName = useSelector(selectAccountName);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogout = () => {
    const navigationCallback = () => {
      history.push('/signin');
    };

    dispatch(signOutUser(navigationCallback));
  };

  return (
    <DropWrapper>
      <DropdownButton
        show={show}
        onToggle={(nextShow: boolean) => setShow(nextShow)}
        userName={user}
        accountName={accountName}
        onLogout={handleLogout}
      />
    </DropWrapper>
  );
};
