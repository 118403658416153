/* eslint-disable no-restricted-syntax */
/* eslint-disable max-statements */
import { FormikProps } from 'formik';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

/* eslint-disable complexity */
import { TransitDocumentsEnum, TransitHouseConsignment } from '@e-origin/shared';

import { selectTransit } from '../../../../../../../../../../shared/stores/transitsSlice';
import { TransitDocumentsComponent } from '../../../../../../transit-documents-component';
import { GoodsItemFormInitializerReturn } from '../goods-item-form-initializer';

interface GoodsItemDocumentsProps {
  form: FormikProps<GoodsItemFormInitializerReturn>;
  houseConsignment: TransitHouseConsignment;
  editDisabledForConsignment: boolean;
  isArrival: boolean;
}

export interface DocumentItemProps {
  type: TransitDocumentsEnum;
  label?: string;
  code: string;
  referenceNumber?: string;
  dateOfValidity?: string;
  dataSource: any;
  dataSourceIndex: number;
}

export const GoodsItemDocuments: React.FC<GoodsItemDocumentsProps> = ({
  form,
  houseConsignment,
  editDisabledForConsignment,
  isArrival,
}) => {
  const transit = useSelector(selectTransit);

  const handleDocumentDelete = (documentType: any, documentIndex: number) => {
    form.setFieldValue(`documents.${documentType}`, [
      ...(form.values.documents[documentType] || []).filter((_, idx) => idx !== documentIndex),
    ]);
  };

  const handleSavingDocument = (savedDocument) => {
    if (savedDocument.docIndex >= 0) {
      const updatedDocuments = form.values.documents[savedDocument.type].map((doc, idx) => {
        if (idx === savedDocument.docIndex) {
          return savedDocument.data;
        }
        return doc;
      });
      form.setFieldValue(`documents.${savedDocument.type}`, updatedDocuments);
    } else {
      form.setFieldValue(`documents.${savedDocument.type}`, [
        ...(form.values.documents[savedDocument.type] || []),
        savedDocument.data,
      ]);
    }
  };

  const previewDocuments = useMemo(
    () => ({
      PreviousDocument: [
        ...(transit?.Consignment?.PreviousDocument || []),
        ...(houseConsignment?.PreviousDocument || []),
      ],
      SupportingDocument: [
        ...(transit?.Consignment?.SupportingDocument || []),
        ...(houseConsignment?.SupportingDocument || []),
      ],
      AdditionalReference: [
        ...(transit?.Consignment?.AdditionalReference || []),
        ...(houseConsignment?.AdditionalReference || []),
      ],
      AdditionalInformation: [
        ...(transit?.Consignment?.AdditionalInformation || []),
        ...(houseConsignment?.AdditionalInformation || []),
      ],
    }),
    [transit, houseConsignment],
  );

  return (
    <TransitDocumentsComponent
      previewDocuments={previewDocuments}
      documents={form.values.documents}
      onDelete={handleDocumentDelete}
      onSave={handleSavingDocument}
      disabledDocuments={
        !isArrival
          ? [TransitDocumentsEnum.TRANSPORT_DOCUMENT]
          : [TransitDocumentsEnum.ADDITIONAL_INFORMATION, TransitDocumentsEnum.TRANSPORT_DOCUMENT]
      }
      disabled={editDisabledForConsignment}
      forGoodsItem={true}
    />
  );
};
