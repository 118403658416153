import { useFormik } from 'formik';
import _ from 'lodash';
/* eslint-disable complexity */
import { FC } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { Button, IInputProps, Input, PasswordValidationRules } from '../';
import LogoImage from '../../../assets/images/logo.png';
import { Error } from '../../../styles/common';
import { validatePassword } from '../../../utils';
import { clearUserError, resetPassword, selectUserError } from '../../stores/userSlice';
import { Form, Logo, ResetPasswordForm, SubmitButtonWrapper, Title } from './reset-password-container.style';

interface IResetPasswordContainerProps {
  successCallback(): void;
  resetToken: string;
}

export const ResetPasswordContainer: FC<IResetPasswordContainerProps> = (props) => {
  const { resetToken, successCallback } = props;

  const dispatch = useDispatch();

  const resetPasswordError = useSelector(selectUserError);

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      newPasswordConfirmation: '',
    },
    validate: validatePassword,
    onSubmit: (values) => {
      batch(() => {
        dispatch(clearUserError());
        dispatch(
          resetPassword({
            newPassword: values.newPassword,
            newPasswordConfirmation: values.newPasswordConfirmation,
            resetToken,
            successCallback,
          }),
        );
      });
    },
  });

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } = formik;

  const isResetPasswordButtonDisabled =
    _.isEmpty(values.newPassword) || _.isEmpty(values.newPasswordConfirmation) || !_.isEmpty(errors);
  const isShowNewPasswordConfirmationError = touched.newPasswordConfirmation && errors.newPasswordConfirmation;
  const isShowNewPasswordError = touched.newPassword && errors.newPassword;

  const handleChangeInput: IInputProps['onChange'] = (event) => {
    dispatch(clearUserError());
    handleChange(event);
  };

  return (
    <ResetPasswordForm>
      <Logo>
        <img src={LogoImage} alt="" />
      </Logo>
      <Title>Reset password</Title>
      <Form onSubmit={handleSubmit}>
        <Input
          name="newPassword"
          onBlur={handleBlur}
          onChange={handleChangeInput}
          placeholder="Enter New Password"
          type="password"
          value={values.newPassword}
        />
        <Input
          name="newPasswordConfirmation"
          onBlur={handleBlur}
          onChange={handleChangeInput}
          placeholder="Enter New Password Confirmation"
          type="password"
          value={values.newPasswordConfirmation}
        />
        <PasswordValidationRules />
        <SubmitButtonWrapper>
          {isShowNewPasswordError && <Error>{formik.errors.newPassword}</Error>}
          {isShowNewPasswordConfirmationError && <Error>{formik.errors.newPasswordConfirmation}</Error>}
          {resetPasswordError && <Error>{resetPasswordError}</Error>}

          <Button type="submit" primary disabled={isResetPasswordButtonDisabled}>
            Update password
          </Button>
        </SubmitButtonWrapper>
      </Form>
    </ResetPasswordForm>
  );
};
