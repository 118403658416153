import * as Style from './help.styles';
import { HelpCategoryItem, HelpDataConfig, HelpSectionEnum } from './helpData';
import { SyncingIndicator } from '../../shared/components';

interface CategoryItemProps {
  item: HelpCategoryItem;
}

const CategoryItem: React.FC<CategoryItemProps> = ({ item }) => {
  const { animationType, icon, text } = item;

  const renderAnimation = () => {
    if (animationType === 'syncing') {
      return <SyncingIndicator />;
    }
    return null;
  };

  return (
    <Style.CategoryContainer>
      {renderAnimation()}
      {icon && <Style.CategoryImage src={icon} alt={text} />}
      <Style.CategoryText>{text}</Style.CategoryText>
    </Style.CategoryContainer>
  );
};

interface HelpSectionProps extends HelpDataConfig {
  sectionTitle: HelpSectionEnum;
}

export const HelpSection: React.FC<HelpSectionProps> = ({ sectionTitle, data, subsections, fullWidth, flexRow }) => {
  return (
    <Style.SectionContainer fullWidth={fullWidth}>
      <p className={subsections ? 'mb-4' : ''}>{sectionTitle}</p>

      {data && (
        <Style.CategoriesContainer flexRow={flexRow}>
          {data.map((item, idx) => (
            <CategoryItem key={idx} item={item} />
          ))}
        </Style.CategoriesContainer>
      )}

      {subsections &&
        subsections.map((subsection, idx) => (
          <div key={idx}>
            {idx > 0 && <hr className="my-8" />}
            <p className="mb-4">{subsection.title}</p>
            <Style.CategoriesContainer flexRow={flexRow}>
              {subsection.data.map((item, itemIdx) => (
                <CategoryItem key={itemIdx} item={item} />
              ))}
            </Style.CategoriesContainer>
          </div>
        ))}
    </Style.SectionContainer>
  );
};
