/* eslint-disable complexity */
import { FormikProps } from 'formik';
import { useSelector } from 'react-redux';

import { TransitGroup } from '@e-origin/shared';

import { TransitAutocompleteHeader, TransitInputHeader } from '../../../..';
import { Input } from '../../../../../../../../../../shared/components';
import { selectTransit } from '../../../../../../../../../../shared/stores/transitsSlice';
import { FormContainer, FormRow, FormSection } from '../../../../../../../../../../styles/common';
import { HouseItemFormInitializerReturn } from '../house-form-initializer';

interface HouseConsignmentDataProps {
  form: FormikProps<HouseItemFormInitializerReturn>;
  disabled: boolean;
  editDisabledForConsignment: boolean;
}

export const HouseConsignmentData: React.FC<HouseConsignmentDataProps> = (props) => {
  const { form } = props;
  const transit = useSelector(selectTransit);
  const isArrival = transit?.generalInfo?.group === TransitGroup.ARRIVAL;

  return (
    <FormContainer>
      <FormSection verticalPadding={0}>
        <FormRow>
          {!isArrival && (
            <TransitAutocompleteHeader
              form={form}
              parents={[transit.Consignment.countryOfDispatch]}
              name="countryOfDispatch"
              disabled={props.disabled}
              width={25}
              placeholder="Country Of Dispatch"
              code="CL008"
            />
          )}

          <Input
            name="grossMass"
            placeholder="Gross Mass"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.grossMass}
            width={25}
            widthUnit="%"
            compact
            disabled={props.editDisabledForConsignment}
          />
          {!isArrival && (
            <TransitInputHeader
              form={form}
              parents={[transit.Consignment.referenceNumberUCR]}
              name="referenceNumberUCR"
              disabled={props.disabled}
              width={25}
              placeholder="Reference Number UCR"
            />
          )}
        </FormRow>
      </FormSection>
    </FormContainer>
  );
};
