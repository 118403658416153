/* eslint-disable no-control-regex */
/* eslint-disable no-unused-expressions */
/* eslint-disable complexity */
import { FormikConfig, useFormik } from 'formik';
import { FC, useState } from 'react';
import Dropzone from 'react-dropzone';

import Icons from '../../../../../../../../assets';
import uploadFileIcon from '../../../../../../../../assets/icons/upload-file-icon.png';
import { Button, CustomModal } from '../../../../../../../../shared/components';
import { FormContainer, FormRow, FormSection } from '../../../../../../../../styles/common';
import ModalStyle from '../../../../../../../../styles/modal';
import * as Style from './upload-modal.style';

interface IUploadItemsModalProps {
  show: boolean;
  onHide: () => void;
  onFileSubmit: (file: File) => void;
}

const UploadItemsModal: FC<IUploadItemsModalProps> = ({ show, onHide, onFileSubmit }) => {
  const [submitting, setSubmitting] = useState(false);

  const validate: FormikConfig<{ file: File | null }>['validate'] = (values) => {
    const errors: Partial<Record<'file', string>> = {};

    if (!values.file) {
      errors.file = 'File is required';
    } else if (!/^[\x00-\x7F]*$/g.test(values.file.name)) {
      errors.file = 'File name contains invalid characters.';
    }

    return errors;
  };

  const formik = useFormik<{ file: File | null }>({
    initialValues: { file: null },
    validate,
    onSubmit: async (values) => {
      if (!values.file) return;

      setSubmitting(true);
      try {
        onFileSubmit(values.file);
        formik.resetForm();
      } finally {
        setSubmitting(false);
        onHide();
      }
    },
  });

  const handleDrop = (files: File[]) => {
    if (files && files.length > 0) {
      const [firstFile] = files;
      formik.setFieldValue('file', firstFile);
    }
  };

  const handleUploadClick = () => {
    if (!submitting) {
      formik.handleSubmit();
    }
  };

  return (
    <CustomModal width={25} show={show} onHide={onHide}>
      <ModalStyle.Header>
        <ModalStyle.Title>Upload file</ModalStyle.Title>
        <ModalStyle.HeaderActions>
          <button onClick={onHide}>
            <img src={Icons.CloseIcon} alt="close-icon" />
          </button>
        </ModalStyle.HeaderActions>
      </ModalStyle.Header>

      <FormContainer onSubmit={formik.handleSubmit}>
        <FormSection verticalPadding={20} topPadding={40} paddingBottom={60}>
          <FormRow>
            <Dropzone
              accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              onDrop={handleDrop}
            >
              {({ getRootProps, getInputProps }) => (
                <Style.UploadZoneContainer invalid={formik.touched.file && !!formik.errors.file}>
                  <Style.UploadZoneInner {...getRootProps()}>
                    <input {...getInputProps()} />
                    <img src={uploadFileIcon} alt="upload-file-icon" />
                    {formik.values.file ? (
                      <p>{formik.values.file.name}</p>
                    ) : (
                      <p>Drag 'n' drop an excel file or click to select your file</p>
                    )}
                  </Style.UploadZoneInner>
                </Style.UploadZoneContainer>
              )}
            </Dropzone>
          </FormRow>
          {formik.touched.file && formik.errors.file && (
            <Style.FileUploadError>{formik.errors.file}</Style.FileUploadError>
          )}
        </FormSection>
      </FormContainer>

      <ModalStyle.Footer>
        <Button type="button" onClick={onHide} disabled={submitting}>
          Cancel
        </Button>
        <Button
          type="button"
          primary
          onClick={handleUploadClick}
          showLoading={submitting}
          disabled={submitting || !formik.values.file}
        >
          Upload
        </Button>
      </ModalStyle.Footer>
    </CustomModal>
  );
};

export default UploadItemsModal;
