/* eslint-disable complexity */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { toast } from 'react-toastify';
import { w3cwebsocket as W3CWebSocket } from 'websocket';

import { RealTimeEvents } from '@e-origin/shared';

import ForgotPassword from './features/auth/components/forgot-password/forgot-password.component';
import Login from './features/auth/components/login/login.component';
import ResetPassword from './features/auth/components/reset-password/reset-password.component';
import Layout from './features/layout/layout.component';
import { ProtectedRoute } from './shared/components';
import {
  updateBatchProgressRealTime,
  updateBatchStatusRealTime,
  updatedInvalidatingReleasedRealTime,
  updatedSendingStatusRealTime,
  updatedSyncingBatchesStatusRealTime,
  updatedSyncingStatusRealTime,
  updateScrapingProgressRealTime,
  updateScreenshotProgressRealTime,
} from './shared/stores/batchesSlice';
import { fetchSettings, selectSettings } from './shared/stores/settingsSlice';

const App = () => {
  const settings = useSelector(selectSettings);
  const dispatch = useDispatch();

  const getSettings = async () => {
    dispatch(fetchSettings());
  };

  const connectToWebsocket = () => {
    const { protocol, hostname, port } = window.location;

    const client = new W3CWebSocket(
      `${protocol === 'https:' ? 'wss' : 'ws'}://${hostname !== 'localhost' ? `ws.${hostname}` : 'localhost'}:${
        hostname !== 'localhost' ? port : process.env.REACT_APP_WS_PORT
      }`,
      ['echo-protocol'],
    );

    client.onerror = (err) => {
      throw err;
    };

    client.onclose = () => {
      // reconnect
      connectToWebsocket();
    };

    // eslint-disable-next-line max-statements
    client.onmessage = (payload) => {
      const message: { event: RealTimeEvents; data: any } = JSON.parse(payload.data as any);

      if (message.event === RealTimeEvents.BATCH_UPDATE_RESULT && message.data?.error) {
        toast.error('Something went wrong!');
      }

      if (
        [
          RealTimeEvents.FILE_UPLOAD_RESULT,
          RealTimeEvents.BATCH_SEND_ALL_FINISHED,
          RealTimeEvents.BATCH_SYNC_ALL_FINISHED,
          RealTimeEvents.BATCH_UPDATE_RESULT,
          RealTimeEvents.STARTING_TO_SEND,
        ].includes(message.event)
      ) {
        dispatch(updateBatchStatusRealTime());
      } else if (message.event === 'file-process-progress' && message.data) {
        dispatch(updateBatchProgressRealTime(message.data));
      } else if (message.event === 'file-process-failed' && message.data) {
        toast.error(message.data || 'Something went wrong!');
      } else if (['scraping_progress', 'saving-db', 'scraping-finished'].includes(message.event) && message.data) {
        dispatch(updateScrapingProgressRealTime(message.data));
      } else if (message.event === 'screenshot_progress' && message.data) {
        dispatch(updateScreenshotProgressRealTime(message.data));
      } else if (message.event === 'batches-sync-status' && message.data) {
        dispatch(updatedSyncingBatchesStatusRealTime(message.data));
      } else if (message.event === RealTimeEvents.SENDING_PROGRESS && message.data) {
        dispatch(updatedSendingStatusRealTime(message.data));
      } else if (message.event === RealTimeEvents.INVALIDATING_PROGRESS && message.data) {
        dispatch(updatedInvalidatingReleasedRealTime(message.data));
      } else if (message.event === RealTimeEvents.SYNCING_PROGRESS && message.data) {
        dispatch(updatedSyncingStatusRealTime(message.data));
      }
    };
  };

  useEffect(() => {
    getSettings();
  }, []);

  useEffect(() => {
    if (settings.clientBaseUrl.length) {
      connectToWebsocket();
    }
  }, [settings.clientBaseUrl]);

  return (
    <Router>
      <Switch>
        <Route path="/signin">
          <Login />
        </Route>
        <Route path="/forgot-password">
          <ForgotPassword />
        </Route>
        <Route path="/reset-password">
          <ResetPassword />
        </Route>
        <ProtectedRoute path="/">
          <Layout />
        </ProtectedRoute>
      </Switch>
    </Router>
  );
};

export default App;
