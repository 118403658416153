import {
  TransitControlNotificationType,
  TransitControlType,
  TransitInControl,
  TransitRequestDocumentType,
} from '@e-origin/shared';

import * as Style from './control-notification.style';

interface ControlNotificationProps {
  inControl: TransitInControl;
}

export const ControlNotification = ({ inControl }: ControlNotificationProps) => {
  return (
    <Style.Section verticalPadding={20}>
      <Style.SectionTitle>Control notification</Style.SectionTitle>
      <Style.Row>
        {inControl.customsRegistrationNumber && (
          <div>
            <label>Customs registration number</label>
            <p>{inControl.customsRegistrationNumber}</p>
          </div>
        )}
        <div>
          <label>Notification date</label>
          <p>{inControl.notificationDate}</p>
        </div>
        <div>
          <label>Notification Type</label>
          <p>
            {inControl.notificationType} - {TransitControlNotificationType[inControl.notificationType]}
          </p>
        </div>
        {/* <div>
          <label>Anticipated control date</label>
          <p>{inControl.anticipatedControlDate}</p>
        </div> */}
      </Style.Row>
      {inControl.typeOfControls.length && (
        <Style.SubSection>
          <Style.SubSectionTitle>Type of controls</Style.SubSectionTitle>
          {inControl.typeOfControls.map((controlType, index) => (
            <Style.Row key={index}>
              <div>
                <label>Type</label>
                <p>
                  {controlType.type} - {TransitControlType[controlType.type]}
                </p>
              </div>
              <div>
                <label>Remarks</label>
                <p>{controlType.remarks}</p>
              </div>
            </Style.Row>
          ))}
        </Style.SubSection>
      )}
      {inControl.requestedDocuments.length && (
        <Style.SubSection>
          <Style.SubSectionTitle>Requested documents</Style.SubSectionTitle>
          {inControl.requestedDocuments.map((controlType, index) => (
            <Style.Row key={index}>
              <div>
                <label>Type</label>
                <p>
                  {controlType.type} - {TransitRequestDocumentType[controlType.type]}
                </p>
              </div>
              {controlType.referenceNumber && (
                <div>
                  <label>Reference number</label>
                  <p>{controlType.referenceNumber}</p>
                </div>
              )}
              <div>
                <label>Description</label>
                <p>{controlType.description}</p>
              </div>
            </Style.Row>
          ))}
        </Style.SubSection>
      )}
    </Style.Section>
  );
};
