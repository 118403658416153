/* eslint-disable max-lines */
/* eslint-disable max-statements */
/* eslint-disable complexity */
/* eslint-disable no-restricted-syntax */
import { FormikProps } from 'formik';
import { cloneDeep } from 'lodash';
import { useMemo, useRef, useState } from 'react';
import DataTable, { IDataTableColumn } from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { TransitGroup, TransitHouseConsignment, TransitStatus } from '@e-origin/shared';

import Icons, { TableActionsIcons } from '../../../../../../../assets';
import downloadIcon from '../../../../../../../assets/icons/download-icon.svg';
import uploadIcon from '../../../../../../../assets/icons/upload-icon.svg';
import {
  Button,
  CustomTableActionsComponent,
  NotificationModal,
  SearchBox,
} from '../../../../../../../shared/components';
import {
  downloadHouseConsignmentItems,
  selectTransit,
  uploadHouseConsignmentItems,
} from '../../../../../../../shared/stores/transitsSlice';
import { TransitDetailsFormProps } from '../../../transit-details.component';
import * as GeneralStyle from '../transit-details-house.style';
import * as Style from './house-details.style';
import { HouseModal } from './house-modal/house-modal';
import UploadItemsModal from './upload-modal/upload-modal';

interface TransitDetailsHouseProps {
  form: FormikProps<TransitDetailsFormProps>;
  editDisabled: boolean;
  editDisabledForConsignment: boolean;
}

export const HouseDetails: React.FC<TransitDetailsHouseProps> = ({
  form,
  editDisabled,
  editDisabledForConsignment,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [houseIndexForUpload, setHouseIndexForUpload] = useState<number | null>(null);

  const transit = useSelector(selectTransit);

  const routeLocation = useLocation();
  const queryParams = useRef<URLSearchParams>(new URLSearchParams(routeLocation.search));
  const history = useHistory();
  const dispatch = useDispatch();
  const isArrival = form.values.generalInfo?.group === TransitGroup.ARRIVAL;

  const [searchQuery, setSearchQuery] = useState(
    queryParams.current.get('search') ? queryParams.current.get('search') : '',
  );
  const selectedRow = useRef<{ houseConsigmentIdx: number; houseConsigment: TransitHouseConsignment } | null>(null);

  const houseConsigments = useMemo(
    () =>
      (form.values.Consignment.HouseConsignment || []).filter((house) => {
        if (!searchQuery) {
          return true;
        }

        return house.ConsignmentItem.find(
          (item) =>
            item.Commodity?.descriptionOfGoods?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.Commodity?.CommodityCode?.hsCode?.toLowerCase().includes(searchQuery.toLowerCase()),
        );
      }),
    [form.values.Consignment.HouseConsignment, searchQuery],
  );

  const actionList = (row: TransitHouseConsignment, houseIndex: number) => {
    const isUnloadPermission = transit?.generalInfo?.status === TransitStatus.UNLOAD_PERMISSION;
    const consignmentItems = cloneDeep(form?.values?.Consignment?.HouseConsignment[houseIndex]?.ConsignmentItem);
    const itemsMissing = consignmentItems?.filter((consignmentItem) => consignmentItem.isMissing);
    const isHouseMissingAllowed = () => isUnloadPermission && itemsMissing.length === 0;
    const isHouseFoundAllowed = () => isUnloadPermission && itemsMissing.length === consignmentItems.length;

    return [
      {
        if: isHouseMissingAllowed(),
        icon: TableActionsIcons.DANGEROUS,
        text: 'House missing',
        onClick: () => {
          consignmentItems.forEach((_, index) => {
            const itemPath = `Consignment.HouseConsignment[${houseIndex}].ConsignmentItem[${index}].isMissing`;
            form.setFieldValue(itemPath, true);
          });

          toast.success('Item marked as missing!');
        },
      },
      {
        if: isHouseFoundAllowed(),
        icon: TableActionsIcons.FINALIZE,
        text: 'House found',
        onClick: () => {
          consignmentItems.forEach((_, index) => {
            const itemPath = `Consignment.HouseConsignment[${houseIndex}].ConsignmentItem[${index}].isMissing`;
            form.setFieldValue(itemPath, false);
          });

          toast.success('Item marked as found!');
        },
      },
      {
        icon: TableActionsIcons.VIEW,
        text: 'Edit',
        onClick: () => {
          setShowModal(true);
        },
      },
      {
        icon: TableActionsIcons.LAYERS,
        text: 'View Goods Items',
        onClick: () => {
          queryParams.current.set('context', 'GOODS');
          queryParams.current.set('houseRef', form.values.Consignment.HouseConsignment.indexOf(row).toString());
          history.push({ search: queryParams.current.toString() });
        },
      },
      {
        if: !editDisabled || !editDisabledForConsignment,
        icon: TableActionsIcons.DELETE,
        text: 'Delete',
        onClick: () => {
          setShowDeleteModal(true);
        },
      },
    ];
  };

  const secondaryActions = (houseIndex: number) => {
    return [
      {
        icon: downloadIcon,
        text: 'Download items',
        onClick: () => {
          dispatch(downloadHouseConsignmentItems(transit._id, transit.name, houseIndex));
        },
        preventDefaultClick: true,
      },
      {
        icon: uploadIcon,
        text: 'Upload items',
        onClick: () => {
          setHouseIndexForUpload(houseIndex);
          setShowUploadModal(true);
        },
        preventDefaultClick: true,
      },
    ];
  };

  const columns: IDataTableColumn<TransitHouseConsignment>[] = [
    {
      name: 'Consignor Id',
      cell: (row) => transit.Consignment?.Consignor?.identificationNumber || row.Consignor?.identificationNumber,
      sortable: false,
    },
    {
      name: 'Consignor name',
      cell: (row) => transit.Consignment?.Consignor?.name || row.Consignor?.name,
      sortable: false,
    },
    {
      name: 'Consignee Id',
      cell: (row) => transit.Consignment?.Consignee?.identificationNumber || row.Consignee?.identificationNumber,
      sortable: false,
    },
    {
      omit: transit.generalInfo.group === TransitGroup.DEPARTURE,
      name: 'Is missing',
      cell: (row) =>
        row.ConsignmentItem?.length &&
        row.ConsignmentItem.filter((item) => !!item.isMissing).length === row.ConsignmentItem.length ? (
          <Style.IconWrapper>
            <img src={Icons.BlueCheckMarkIcon} alt="Is missing" />
          </Style.IconWrapper>
        ) : (
          ''
        ),
      selector: 'isMissing',
      sortable: false,
    },
    {
      name: 'Goods item',
      cell: (row) => row.ConsignmentItem?.length || 0,
      sortable: false,
    },
    {
      name: 'Consignee name',
      cell: (row) => transit.Consignment?.Consignee?.name || row.Consignee?.name,
      sortable: false,
    },
    {
      name: 'Country Of Dispatch',
      cell: (row) => row.countryOfDispatch,
      sortable: false,
      omit: isArrival,
    },
    {
      name: 'Reference Number UCR',
      cell: (row) => row.referenceNumberUCR,
      sortable: false,
      omit: isArrival,
    },
    {
      name: 'Gross mass',
      cell: (row) => row.grossMass || 'N/A',
      sortable: false,
    },
    {
      name: 'Actions',
      right: true,
      cell: (row, rowIndex) => (
        <CustomTableActionsComponent
          actions={actionList(row, rowIndex)}
          secondaryActions={secondaryActions(rowIndex)}
          onClick={() => {
            selectedRow.current = {
              houseConsigmentIdx: houseConsigments.indexOf(row),
              houseConsigment: row,
            };
          }}
          disableAfterClick={true}
        />
      ),
    },
  ];

  const handleSave = (houseConsignment) => {
    const { houseConsigmentIdx = -1 } = selectedRow.current || {};

    if (houseConsigmentIdx >= 0) {
      const oldHouse = form.values.Consignment.HouseConsignment[houseConsigmentIdx];
      form.setFieldValue(`Consignment.HouseConsignment.${houseConsigmentIdx}`, {
        ...houseConsignment,
        ConsignmentItem: oldHouse.ConsignmentItem,
      });
    } else {
      form.setFieldValue('Consignment.HouseConsignment', [
        ...form.values.Consignment.HouseConsignment,
        houseConsignment,
      ]);
    }

    setShowModal(false);
    selectedRow.current = null;
  };

  const handleHouseDelete = () => {
    const { houseConsigmentIdx } = selectedRow.current;
    form.setFieldValue(
      'Consignment.HouseConsignment',
      form.values.Consignment.HouseConsignment.filter((_, idx) => idx !== houseConsigmentIdx),
    );
    setShowDeleteModal(false);
    selectedRow.current = null;
  };

  const handleHide = () => {
    setShowModal(false);
    selectedRow.current = null;
  };

  const handleHideDeleteModal = () => {
    setShowDeleteModal(false);
    selectedRow.current = null;
  };

  const handleHideWarningModal = () => {
    setShowWarningModal(false);
    selectedRow.current = null;
  };

  const handleSearch = (value: string) => {
    setSearchQuery(value);

    queryParams.current.set('search', value);
    history.push({ search: queryParams.current.toString() });
  };

  const handleHideUploadModal = () => {
    setShowUploadModal(false);
    setHouseIndexForUpload(null);
    selectedRow.current = null;
  };

  const handleFileSubmit = async (file: File) => {
    if (houseIndexForUpload !== null) {
      const updatedHouseConsignment = await uploadHouseConsignmentItems(transit._id, houseIndexForUpload, file);
      if (updatedHouseConsignment) {
        form.setFieldValue(`Consignment.HouseConsignment.${houseIndexForUpload}`, updatedHouseConsignment);
      }
    }
  };

  const handleAddNew = () => {
    if (
      houseConsigments.length === 1 &&
      (transit.Consignment.Consignor?.identificationNumber || transit.Consignment.Consignor?.name) &&
      !houseConsigments[0].Consignor?.identificationNumber &&
      !houseConsigments[0].Consignor?.name
    ) {
      setShowWarningModal(true);
    } else {
      setShowModal(true);
    }
  };

  return (
    <>
      <GeneralStyle.ActionButton>
        <SearchBox
          handleSearch={handleSearch}
          value={searchQuery}
          placeholder="Search goods by HS code, description of goods"
        />
        {!editDisabledForConsignment && (
          <Button outline onClick={handleAddNew}>
            Add new
          </Button>
        )}
      </GeneralStyle.ActionButton>
      <GeneralStyle.TableWrapper>
        <DataTable columns={columns} data={houseConsigments} pagination paginationPerPage={10} />
      </GeneralStyle.TableWrapper>
      {showModal && (
        <HouseModal
          houseConsignment={selectedRow.current?.houseConsigment}
          onSave={handleSave}
          onHide={handleHide}
          editDisabled={editDisabled}
          editDisabledForConsignment={editDisabledForConsignment}
          isArrival={isArrival}
        />
      )}
      <NotificationModal
        title="Cannot create a house consignment"
        show={showWarningModal}
        onHide={handleHideWarningModal}
        isDelete
      >
        <div>
          To create a new house consignment, the first house consignment must have a consignor, and the consignor should
          not be defined in the Stakeholders tab.
        </div>
      </NotificationModal>
      <NotificationModal
        title="Confirm house delete"
        confirmButtonText="Delete"
        show={showDeleteModal}
        onHide={handleHideDeleteModal}
        onConfirm={handleHouseDelete}
        isDelete
      >
        <div>Are you sure you want to delete this house consignment?</div>
      </NotificationModal>
      <UploadItemsModal show={showUploadModal} onHide={handleHideUploadModal} onFileSubmit={handleFileSubmit} />
    </>
  );
};
