import { FormikProps } from 'formik';
import { useSelector } from 'react-redux';

import { TransitHouseConsignment } from '@e-origin/shared';

import { selectTransit } from '../../../../../../../../../../../shared/stores/transitsSlice';
import { FormRow, FormSection, FormSectionTitle } from '../../../../../../../../../../../styles/common';
import { TransitInputHeader } from '../../../../../transit-input-header.component';

interface TransitConsignorProps {
  form: FormikProps<any>;
  houseConsignment?: TransitHouseConsignment;
  editDisabled: boolean;
}
/* eslint-disable complexity */
export const TransitConsignor: React.FC<TransitConsignorProps> = ({ form, houseConsignment, editDisabled }) => {
  const transit = useSelector(selectTransit);

  const transitConsignor = transit?.Consignment?.Consignor;

  const consignorParent = transitConsignor || houseConsignment?.Consignor;

  const isConsignorDefined = consignorParent && (consignorParent.name || consignorParent?.identificationNumber);

  return (
    <FormSection verticalPadding={0} noTopPadding>
      <FormSectionTitle noTopBorder>Consignor</FormSectionTitle>
      <FormRow>
        <TransitInputHeader
          form={form}
          name="Consignor.identificationNumber"
          placeholder="Identification Number"
          parents={isConsignorDefined ? [consignorParent.identificationNumber] : []}
          disabled={editDisabled || !!isConsignorDefined}
        />
        <TransitInputHeader
          form={form}
          name="Consignor.name"
          placeholder="Name"
          parents={isConsignorDefined ? [consignorParent.name] : []}
          disabled={editDisabled || !!isConsignorDefined}
        />
      </FormRow>
      <FormRow>
        <TransitInputHeader
          form={form}
          name="Consignor.Address.streetAndNumber"
          placeholder="Street and Number"
          parents={isConsignorDefined ? [consignorParent.Address?.streetAndNumber] : []}
          disabled={editDisabled || !!isConsignorDefined}
        />

        <TransitInputHeader
          form={form}
          name="Consignor.Address.postcode"
          placeholder="Postcode"
          parents={isConsignorDefined ? [consignorParent.Address?.postcode] : []}
          disabled={editDisabled || !!isConsignorDefined}
        />

        <TransitInputHeader
          form={form}
          name="Consignor.Address.city"
          placeholder="City"
          parents={isConsignorDefined ? [consignorParent.Address?.city] : []}
          disabled={editDisabled || !!isConsignorDefined}
        />

        <TransitInputHeader
          form={form}
          name="Consignor.Address.country"
          placeholder="Country"
          parents={isConsignorDefined ? [consignorParent.Address?.country] : []}
          disabled={editDisabled || !!isConsignorDefined}
        />
      </FormRow>
    </FormSection>
  );
};
