/* eslint-disable max-statements */
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Transit } from '@e-origin/shared';

import { CustomTable } from '../../../../../shared/components';
import {
  fetchTransits,
  nextTransits,
  selectTransitFilters,
  selectTransitPagination,
  selectTransits,
  selectTransitSorting,
  selectTransitTotaItems,
  selectTransitView,
  setTransitSorting,
} from '../../../../../shared/stores/transitsSlice';
import { TransitModalFactory, TransitModalFactoryEnum } from '../modals/transit-modal-factory.component';
import { useTransitsTableColumns } from './transits-table-columns';

export const TransitsTable: React.FC = () => {
  const dispatch = useDispatch();
  const transitList = useSelector(selectTransits);
  const totalTransits = useSelector(selectTransitTotaItems);
  const transitView = useSelector(selectTransitView);

  const filters = useSelector(selectTransitFilters);
  const sorting = useSelector(selectTransitSorting);
  const pagination = useSelector(selectTransitPagination);

  const criteriaInitialized = useRef<boolean>(false);
  const firstQuery = useRef<boolean>(true);

  useEffect(() => {
    if (!criteriaInitialized.current) {
      return;
    }
    dispatch(fetchTransits({ persistPagination: firstQuery.current }));
    firstQuery.current = false;
  }, [sorting, pagination.size, filters]);

  useEffect(() => {
    dispatch(fetchTransits());
    criteriaInitialized.current = true;
  }, []);

  const [showTransitModalFactory, setShowTransitModalFactory] = useState(false);
  const selectedModal = useRef<TransitModalFactoryEnum>(undefined);
  const selectedRow = useRef<Transit>(null);

  const openModal = (row: Transit, modal: TransitModalFactoryEnum) => {
    selectedModal.current = modal;
    selectedRow.current = row;
    setShowTransitModalFactory(true);
  };

  const columns = useTransitsTableColumns({ openModal, transitView });

  const handleSorting = (updatedSorting) => {
    dispatch(setTransitSorting(updatedSorting));
  };

  const handlePagination = (updatedPagination) => {
    dispatch(nextTransits(updatedPagination));
  };

  return (
    <>
      <TransitModalFactory
        show={showTransitModalFactory}
        selectedData={selectedRow.current}
        modal={selectedModal.current}
        onHide={() => setShowTransitModalFactory(false)}
      />

      <CustomTable
        columns={columns.current}
        pagination={pagination}
        sorting={sorting}
        onSortingChange={handleSorting}
        onPaginationChange={handlePagination}
        data={transitList || []}
        total={totalTransits}
        isLoading={false}
        disablePagination={false}
      />
    </>
  );
};
