/* eslint-disable max-statements */
import { useEffect, useState } from 'react';
import DataTable, { IDataTableColumn } from 'react-data-table-component';

import { User } from '@e-origin/shared';

import trashIcon from '../../../../../../assets/icons/trash-icon.svg';
import { Button, Dropdown } from '../../../../../../shared/components';
// todo: do not the style from the components
import { IconContainer } from '../../../../../../shared/components/table-action-buttons/table-action-buttons.style';
import { retrieveExternalRestrictedUsers } from '../../../../../../shared/stores/customersSlice';
import * as Style from './external-restricted-users.style';

interface ExternalRestrictedUsersProps {
  list: { value: string; label: string }[];
  onUpdate(ids: string[]): void;
}

interface InputValue {
  label: string;
  value: string;
}

const ExternalRestrictedUsers: React.FC<ExternalRestrictedUsersProps> = (props) => {
  const [newValue, setNewValue] = useState<InputValue>({ label: '', value: null });
  const [rows, setRows] = useState(props.list.slice());

  const [externalUsers, setExternalUsers] = useState<User[]>([]);

  const removeItem = (id: string) => {
    const newRows = rows.filter((r) => r.value !== id);
    setRows(newRows);
    props.onUpdate(newRows.map((r) => r.value));
  };

  useEffect(() => {
    retrieveExternalRestrictedUsers('').then((res) => {
      setExternalUsers(res);
    });
  }, []);

  useEffect(() => {
    setRows(props.list.slice());
  }, [props.list]);

  const columns: IDataTableColumn<any>[] = [
    {
      name: 'User',
      selector: (row) => row.label,
    },
    {
      name: 'Actions',
      cell: (row) => (
        <Button type="button" onClick={() => removeItem(row.value)}>
          <IconContainer src={trashIcon} alt={'delete'} />
        </Button>
      ),
    },
  ];

  const onChange = (event: any) => {
    setNewValue(event);
  };

  const addItem = () => {
    if (!newValue.value || rows.findIndex((r) => r.value === newValue.value) !== -1) {
      return;
    }

    const newRows = [...rows, newValue];
    props.onUpdate(newRows.map((r) => r.value));
    setRows(newRows);
    setNewValue({ label: '', value: '' });
  };

  return (
    <Style.Container>
      <DataTable columns={columns} data={rows || []} noHeader={true} />
      <Style.DropdownWrapper>
        <Dropdown
          placeholder="Insert an user.."
          onChange={onChange}
          options={externalUsers
            .filter((user) => !rows.find((userInList) => userInList.value === user._id))
            .map((usr) => ({
              value: usr._id,
              label: `${usr.firstName} ${usr.lastName}`,
            }))}
          value={{ value: newValue.label, label: newValue.label }}
          width={80}
          widthUnit="%"
        />
        <Button type="button" onClick={addItem} disabled={!newValue.value} primary>
          +
        </Button>
      </Style.DropdownWrapper>
    </Style.Container>
  );
};

export default ExternalRestrictedUsers;
