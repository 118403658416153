/* eslint-disable complexity */
import axios, { AxiosRequestConfig, ResponseType } from 'axios';
import Cookies from 'js-cookie';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import _ from 'lodash';

import {
  BatchTypeEnum,
  DeclarationDocumentsAdditionalFiscalReferenceRole,
  MethodsOfPayment,
  TemplateType,
} from '@e-origin/shared';

import { getConfig } from './config';
import { FormatValueTypes } from './enums';
import { refreshToken } from './shared/stores/userSlice';

interface IRequest {
  path: string;
  method: any;
  authenticate: boolean;
  dataObject?: any;
  headers?: any;
  fileDownload?: boolean;
  responseType?: ResponseType;
}

export interface IResetPasswordFormData {
  newPassword?: string;
  newPasswordConfirmation?: string;
}

// eslint-disable-next-line max-statements, complexity
const request = async (props: IRequest, includeHeaders = false) => {
  const config = await getConfig();

  const axiosConfigObject: AxiosRequestConfig = {
    url: `${config.API_URL}/${props.path}`,
    method: props.method,
    data: props.dataObject,
  };
  if (props.fileDownload) {
    axiosConfigObject.responseType = 'blob';
  }

  if (props.responseType) {
    axiosConfigObject.responseType = props.responseType;
  }

  if (props.authenticate) {
    let token = Cookies.get('eorigin-declarations-token');
    const decoded = jwtDecode<JwtPayload>(token);

    if (decoded.exp * 1000 < Date.now() + 30 * 1000) {
      // token expired, refresh
      await (refreshToken() as any)();
      token = Cookies.get('eorigin-declarations-token');
    }
    axiosConfigObject.headers = { Authorization: `Bearer ${token}` };
  }
  if (props.headers) {
    axiosConfigObject.headers = { ...axiosConfigObject.headers, ...props.headers };
  }

  const response = await axios(axiosConfigObject);
  const { data, headers } = response;

  const jwtToken = headers['x-jwt-token'];

  if (jwtToken) {
    Cookies.set('eorigin-declarations-token', jwtToken);
  }
  return !includeHeaders ? data : { data, headers };
};

// eslint-disable-next-line complexity
const validatePassword = (values: IResetPasswordFormData) => {
  const errors: IResetPasswordFormData = {};

  if (!values.newPassword || _.isEmpty(values.newPassword)) {
    errors.newPassword = 'Password required';
  }

  if (!values.newPasswordConfirmation || _.isEmpty(values.newPasswordConfirmation)) {
    errors.newPasswordConfirmation = 'Password confirmation required';
  }

  if (values.newPassword !== values.newPasswordConfirmation) {
    errors.newPasswordConfirmation = 'Passwords must match';
  }

  return errors;
};

const getRoleTypes = () => {
  return [
    {
      label: `${DeclarationDocumentsAdditionalFiscalReferenceRole.IMPORTER} - Importer`,
      value: DeclarationDocumentsAdditionalFiscalReferenceRole.IMPORTER,
    },
    {
      label: `${DeclarationDocumentsAdditionalFiscalReferenceRole.CUSTOMER} - Customer`,
      value: DeclarationDocumentsAdditionalFiscalReferenceRole.CUSTOMER,
    },
    {
      label: `${DeclarationDocumentsAdditionalFiscalReferenceRole.TAX_REPRESENTATIVE} - Tax representative`,
      value: DeclarationDocumentsAdditionalFiscalReferenceRole.TAX_REPRESENTATIVE,
    },
    {
      label: `${DeclarationDocumentsAdditionalFiscalReferenceRole.VENDOR} - Vendor`,
      value: DeclarationDocumentsAdditionalFiscalReferenceRole.VENDOR,
    },
    {
      label: `${DeclarationDocumentsAdditionalFiscalReferenceRole.HOLDER} - Holder`,
      value: DeclarationDocumentsAdditionalFiscalReferenceRole.HOLDER,
    },
    {
      label: `${DeclarationDocumentsAdditionalFiscalReferenceRole.TAXABLE_PERSON} - Taxable reason`,
      value: DeclarationDocumentsAdditionalFiscalReferenceRole.TAXABLE_PERSON,
    },
  ];
};

const getMethodOfPaymentTypes = () => {
  return [
    {
      label: '',
      value: '',
    },
    {
      label: `${MethodsOfPayment.PAYMENT_IN_CASH} - Payment in cash`,
      value: MethodsOfPayment.PAYMENT_IN_CASH,
    },
    {
      label: `${MethodsOfPayment.PAYMENT_BY_CREDIT_CARD} - Payment by credit card`,
      value: MethodsOfPayment.PAYMENT_BY_CREDIT_CARD,
    },
    {
      label: `${MethodsOfPayment.PAYMENT_BY_CHEQUE} - Payment by cheque`,
      value: MethodsOfPayment.PAYMENT_BY_CHEQUE,
    },
    {
      label: `${MethodsOfPayment.OTHER} - Other (e. g. direct debit to agent's cash account)`,
      value: MethodsOfPayment.OTHER,
    },
    {
      label: `${MethodsOfPayment.DEFERRED_OR_POSTPONED_PAYMENT} - Deferred or postponed payment`,
      value: MethodsOfPayment.DEFERRED_OR_POSTPONED_PAYMENT,
    },
    {
      label: `${MethodsOfPayment.POSTPONED_PAYMENT_VAT_SYSTEM} - Postponed payment — VAT system (Article 211 of Directive 2006/112/EC)`,
      value: MethodsOfPayment.POSTPONED_PAYMENT_VAT_SYSTEM,
    },
    {
      label: `${MethodsOfPayment.ELECTRONIC_CREDIT_TRANSFER} - Electronic credit transfer`,
      value: MethodsOfPayment.ELECTRONIC_CREDIT_TRANSFER,
    },
    {
      // eslint-disable-next-line max-len
      label: `${MethodsOfPayment.PAYMENT_THROUGH_POST_OFFICE_ADMINISTRATION} - Payment through post office administration (postal consignments) or other public sector or government department`,
      value: MethodsOfPayment.PAYMENT_THROUGH_POST_OFFICE_ADMINISTRATION,
    },
    {
      label: `${MethodsOfPayment.EXCISE_CREDIT_OR_REBATE} - Excise credit or rebate`,
      value: MethodsOfPayment.EXCISE_CREDIT_OR_REBATE,
    },
    {
      label: `${MethodsOfPayment.GUARANTEE_LODGED_WITH_INTERVATION_AGENCY} - Guarantee lodged with Intervention Agency`,
      value: MethodsOfPayment.GUARANTEE_LODGED_WITH_INTERVATION_AGENCY,
    },
    {
      label: `${MethodsOfPayment.FROM_AGENTS_CASH_ACCOUNT} - From agent's cash account`,
      value: MethodsOfPayment.FROM_AGENTS_CASH_ACCOUNT,
    },
    {
      label: `${MethodsOfPayment.GUARANTEE_OF_THE_AMOUNT_PAYABLE} - Guarantee of the amount payable`,
      value: MethodsOfPayment.GUARANTEE_OF_THE_AMOUNT_PAYABLE,
    },
    {
      label: `${MethodsOfPayment.INDIVIDUAL_GUARANTEE_ACCOUNT} - Individual guarantee account`,
      value: MethodsOfPayment.INDIVIDUAL_GUARANTEE_ACCOUNT,
    },
    {
      label: `${MethodsOfPayment.FROM_AGENT_GUARANTEE_ACCOUNT} - From agent's guarantee account`,
      value: MethodsOfPayment.FROM_AGENT_GUARANTEE_ACCOUNT,
    },
    {
      label: `${MethodsOfPayment.FROM_AGENT_GUARANTEE_STANDING_AUTHORITY} - From agent's guarantee — standing authority`,
      value: MethodsOfPayment.FROM_AGENT_GUARANTEE_STANDING_AUTHORITY,
    },
    {
      label: `${MethodsOfPayment.FROM_AGENT_GUARANTEE_INDIVIDUAL_AUTHORITY} - From agent's guarantee — individual authority`,
      value: MethodsOfPayment.FROM_AGENT_GUARANTEE_INDIVIDUAL_AUTHORITY,
    },
  ];
};

const getValueFromCookie = (value: string) => {
  const cookieValue = Cookies.get(value);
  return cookieValue ? JSON.parse(cookieValue) : '';
};

const STORAGE_KEYS = {
  COOKIES: {
    LIST_BATCHES_CRITERIA: 'list-batches-criteria',
    LIST_DECLARATION_GROUPS_CRITERIA: 'list-declaration-groups-criteria',
    LIST_DECLARATIONS_CRITERIA: 'list-declarations-criteria',
    BATCH_VIEW: 'batchView',
    TRANSIT_VIEW: 'transitView',
  },
  LOCAL_STORAGE: {
    LIST_BATCHES_COLUMNS: 'list-batches-columns',
  },
};

export const toDecimal = (value: number, decimals = 2) => Math.round(value * 10 ** decimals) / 10 ** decimals;

const formatValue = (value: number, type: FormatValueTypes) => {
  switch (type) {
    case FormatValueTypes.PERCENTAGE:
      return `${(Math.floor(value * 100) / 100).toFixed(2)}`;
    case FormatValueTypes.RATE:
      return `${Math.floor(value * 100).toFixed(2)}%`;
    case FormatValueTypes.VALUE: {
      const floatValue = Number((Math.floor(value * 100) / 100).toFixed(2));
      return `${floatValue || '-'}€/kg`;
    }
    case FormatValueTypes.KG:
      return `${(Math.floor(value * 100) / 100).toFixed(2)}kg`;
    case FormatValueTypes.TOTAL_VALUE:
      return `${value.toFixed(2)}€`;
    default:
      return value.toFixed(2);
  }
};

export const removeUndefined = (obj) => {
  if (!obj) return {};
  return Object.keys(obj).reduce((acc, key) => (obj[key] !== undefined ? { ...acc, [key]: obj[key] } : acc), {});
};

const templateType = () => {
  const batchView = Cookies.get(STORAGE_KEYS.COOKIES.BATCH_VIEW);
  if (batchView === BatchTypeEnum.HIGH_VALUES) {
    return TemplateType.HIGH_VALUE_H1;
  }
  return TemplateType.LOW_VALUE_H7;
};

export {
  formatValue,
  getMethodOfPaymentTypes,
  getRoleTypes,
  getValueFromCookie,
  request,
  templateType,
  validatePassword,
  STORAGE_KEYS,
};
