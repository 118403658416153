/* eslint-disable max-lines */
/* eslint-disable no-param-reassign */
/* eslint-disable complexity */
/* eslint-disable max-statements */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

import {
  ITransitsCriteria,
  SortDirection,
  Template,
  TemplateType,
  Transit,
  TransitCreate,
  TransitGroup,
  TransitHouseConsignment,
} from '@e-origin/shared';

import { removeUndefined, request, STORAGE_KEYS } from '../../utils';
import { IDropDownOption } from '../interfaces/dropdown-option.interface';
import { AppThunk, RootState } from './index';

interface ITransitsState {
  selectedTransit?: Transit;
  list: Transit[];
  templates: (IDropDownOption & { type: TemplateType; isExport?: boolean })[];
  totalItems: number;
  filters: ITransitsCriteria['filters'];
  sorting: ITransitsCriteria['sorting'];
  pagination: ITransitsCriteria['pagination'];
  isLoading: boolean;
  transitView: TransitGroup;
}

const initialTransitView =
  Cookies.get(STORAGE_KEYS.COOKIES.TRANSIT_VIEW) === TransitGroup.ARRIVAL
    ? TransitGroup.ARRIVAL
    : TransitGroup.DEPARTURE;

const defaultTransitCriteria: () => ITransitsCriteria = () => ({
  filters: { filterByUsers: [], group: initialTransitView },
  pagination: { page: 1, size: 10, direction: 1, searchToken: '', currentToken: '' },
  sorting: { field: 'counter', direction: SortDirection.DESC },
});

const initialState: ITransitsState = {
  selectedTransit: null,
  list: [],
  templates: [],
  totalItems: 0,
  isLoading: false,
  transitView: initialTransitView,
  ...defaultTransitCriteria(),
};

export const transitsSlice = createSlice({
  name: 'transits',
  initialState,
  reducers: {
    setIsLoading: (state: ITransitsState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setTransitFilters: (state: ITransitsState, action: PayloadAction<ITransitsCriteria['filters']>) => {
      state.filters = { ...action.payload };
    },
    setTransitSorting: (state: ITransitsState, action: PayloadAction<ITransitsCriteria['sorting']>) => {
      state.sorting = { ...action.payload };
    },
    setTransitPagination: (state: ITransitsState, action: PayloadAction<ITransitsCriteria['pagination']>) => {
      state.pagination = { ...action.payload };
    },
    setTransitTotalItems: (state: ITransitsState, action: PayloadAction<number>) => {
      state.totalItems = action.payload;
    },
    setList: (state: ITransitsState, action: PayloadAction<Transit[]>) => {
      state.list = action.payload;
    },
    setTemplates: (state: ITransitsState, action: PayloadAction<Template[]>) => {
      state.templates = action.payload
        .filter((t: any) => t.type === TemplateType.ARRIVAL_TRANSIT || t.type === TemplateType.DEPARTURE_TRANSIT)
        .map((item: any) => ({
          value: item._id,
          label: item.name,
          type: item.type,
        })) as any;
    },
    setTransit: (state: ITransitsState, action: PayloadAction<Transit>) => {
      state.selectedTransit = action.payload;
    },
    setTransitView: (state: ITransitsState, action: PayloadAction<TransitGroup>) => {
      state.transitView = action.payload;
    },
    clearTransit: (state: ITransitsState) => {
      state.selectedTransit = null;
    },
    clearTransits: (state: ITransitsState) => {
      state.list = [];
      state.totalItems = 0;
    },
  },
});

export const { setList, setTransitTotalItems, setTemplates, setTransit, clearTransit, clearTransits, setIsLoading } =
  transitsSlice.actions;

export const selectTransitIsLoading = (state: RootState) => state.transits.isLoading;

export const selectTransit = (state: RootState) => state.transits.selectedTransit;

export const selectTransitView = (state: RootState) => state.transits.transitView;

export const selectTransits = (state: RootState) => state.transits.list;

export const selectTransitFilters = (state: RootState) => state.transits.filters;

export const selectTransitSorting = (state: RootState) => state.transits.sorting;

export const selectTransitPagination = (state: RootState) => state.transits.pagination;

export const selectTemplates = (state: RootState) => state.transits.templates;

export const selectTransitTotaItems = (state: RootState) => state.transits.totalItems;

export const selectCustomersNamesAndIds = (state: RootState) =>
  state.customers.list.map((customer: any) => {
    return {
      label: customer.name || '',
      value: customer._id || '',
    };
  });

export const setTransitFilters =
  (filters: Partial<ITransitsCriteria['filters']>): AppThunk<Promise<void>> =>
  async (dispatch: any, getState: any) => {
    const currentFilters = getState().transits.filters;

    const mergedFilters = removeUndefined({
      ...currentFilters,
      ...filters,
    }) as ITransitsCriteria['filters'];

    dispatch(transitsSlice.actions.setTransitFilters(mergedFilters as any));
  };

export const setTransitSorting =
  (sorting: ITransitsCriteria['sorting']): AppThunk<Promise<void>> =>
  async (dispatch) => {
    dispatch(transitsSlice.actions.setTransitSorting(sorting));
  };

export const setTransitPagination =
  (pagination: ITransitsCriteria['pagination']): AppThunk<Promise<void>> =>
  async (dispatch) => {
    dispatch(transitsSlice.actions.setTransitPagination(pagination));
  };

export const setTransitView =
  (view: TransitGroup): AppThunk<Promise<void>> =>
  async (dispatch) => {
    dispatch(transitsSlice.actions.setTransitView(view));
    Cookies.set(STORAGE_KEYS.COOKIES.TRANSIT_VIEW, view);
  };

export const fetchTransits =
  (options: { persistPagination: boolean } = { persistPagination: false }): AppThunk<Promise<void>> =>
  async (dispatch: any, getState: any) => {
    try {
      const { filters, sorting, pagination } = getState().transits;
      const {
        data: { list, totalItems },
      } = await request({
        path: `transits`,
        method: 'POST',
        authenticate: true,
        dataObject: {
          criteria: {
            filters,
            sorting,
            pagination: options.persistPagination
              ? {
                  ...pagination,
                  direction: pagination.page > 1 ? pagination.direction : 1,
                  searchToken: pagination.currentToken || undefined,
                }
              : {
                  page: 1,
                  direction: 1,
                  size: pagination.size,
                },
          },
        },
      });

      if (!options.persistPagination) {
        dispatch(
          setTransitPagination({
            page: 1,
            direction: 1,
            size: pagination.size,
            searchToken: list[0]?.paginationToken,
          }),
        );
      } else {
        dispatch(
          setTransitPagination({
            ...pagination,
            searchToken: list[0]?.paginationToken,
          }),
        );
      }

      dispatch(setTransitTotalItems(totalItems));
      dispatch(setList(list));
    } catch (error) {
      const axiosError = error as AxiosError;
      toast.error(axiosError.response?.data.message || 'Something went wrong');
    }
  };

export const nextTransits =
  (newPagination: { page: number; size: number; direction: 1 | -1 }): AppThunk<Promise<void>> =>
  async (dispatch: any, getState: any) => {
    try {
      const { filters, sorting, pagination } = getState().transits;

      const {
        data: { list, totalItems },
      } = await request({
        path: `transits`,
        method: 'POST',
        authenticate: true,
        dataObject: {
          criteria: {
            filters,
            sorting,
            pagination: {
              page: newPagination.page,
              searchToken: pagination.searchToken,
              direction: newPagination.direction,
              size: newPagination.size,
            },
          },
        },
      });

      dispatch(
        setTransitPagination({
          page: newPagination.page,
          size: newPagination.size,
          direction: newPagination.direction,
          searchToken: list[0]?.paginationToken,
          currentToken: pagination.searchToken,
        }),
      );

      dispatch(setTransitTotalItems(totalItems));
      dispatch(setList(list));
    } catch (error) {
      toast.error('Error fetching the transits!');
    }
  };

export const fetchTransit =
  (transitId: string): AppThunk<Promise<void>> =>
  async (dispatch: any) => {
    try {
      const { data }: { data: any } = await request({
        path: `transits/${transitId}`,
        method: 'GET',
        authenticate: true,
      });
      dispatch(setTransit(data.transit));
    } catch (error) {
      const axiosError = error as AxiosError;

      toast.error(axiosError.response?.data.message || 'Something went wrong');
    }
  };

const downloadFile = async (path: string) => {
  const response = await request(
    {
      path,
      method: 'GET',
      authenticate: true,
      fileDownload: true,
    },
    true,
  );

  const binaryData = [];
  binaryData.push(response.data);
  const href = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/pdf' }));
  const link = document.createElement('a');
  link.href = href;

  const [, filename] = response.headers['content-disposition'].match(/attachment; filename="(.*)"/i);

  link.setAttribute('download', filename); // or any other extension
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const generateTransitReport =
  (transitId: string): AppThunk<Promise<void>> =>
  async () => {
    try {
      toast.success('File generation initiated!');
      await downloadFile(`transits/${transitId}/generate-ncts-report`);
    } catch (error) {
      toast.error('Error generating the transit report!');
    }
  };

export const generateTransitFollowLetter =
  (transitId: string): AppThunk<Promise<void>> =>
  async () => {
    try {
      toast.success('Letter generation initiated!');
      await downloadFile(`transits/${transitId}/generate-ncts-follow-letter`);
    } catch (error) {
      toast.error('Error generating the follow letter!');
    }
  };

export const downloadTransitReport =
  (transitId: string): AppThunk<Promise<void>> =>
  async () => {
    try {
      toast.success('File download initiated!');
      await downloadFile(`transits/${transitId}/download-ncts-report`);
    } catch (error) {
      toast.error('Error downloading file!');
    }
  };

export const downloadTransitFollowLetter =
  (transitId: string): AppThunk<Promise<void>> =>
  async () => {
    try {
      toast.success('Letter download initiated!');
      await downloadFile(`transits/${transitId}/download-ncts-follow-letter`);
    } catch (error) {
      toast.error('Error downloading file!');
    }
  };

export const deleteTransit =
  (transitId: string): AppThunk<Promise<void>> =>
  async (dispatch: any) => {
    try {
      await request({
        path: `transits/${transitId}`,
        method: 'DELETE',
        authenticate: true,
      });
      dispatch(fetchTransits());
      toast.success('Transit successfully deleted!');
    } catch (error) {
      toast.error('Error deleting the transit!');
    }
  };

export async function createTransit(transit: TransitCreate): Promise<void> {
  try {
    await request({
      path: 'transits/add',
      method: 'POST',
      authenticate: true,
      dataObject: {
        name: transit.name,
        customer: transit.customer,
        template: transit.template,
        MRN: transit.MRN,
        group: transit.group,
      },
    });
    toast.success(`New transit was uploaded, it's in the processing queue now!`);
  } catch (error) {
    toast.error(
      `Error uploading the transit! ${
        (error as any)?.response?.data?.message ? `(${(error as any).response.data.message})` : ''
      }`,
    );
  }
}

export const updateTransit =
  (transit: Transit, options: { refreshCustomerData: boolean }): AppThunk<Promise<void>> =>
  async (dispatch: any) => {
    try {
      dispatch(setIsLoading(true));
      const { data } = await request({
        path: `transits/update`,
        method: 'PATCH',
        authenticate: true,
        dataObject: { transit, refreshCustomerData: options.refreshCustomerData },
      });

      dispatch(fetchTransit(data._id));

      dispatch(setIsLoading(false));

      toast.success('Transit changes were saved!');
    } catch (error) {
      dispatch(setIsLoading(false));

      const axiosError = error as AxiosError;
      toast.error(axiosError.response?.data.message || 'Something went wrong');
    }
  };

export const duplicateTransit =
  (transitId: string, name: string): AppThunk<Promise<void>> =>
  async (dispatch: any) => {
    try {
      await request({
        path: `transits/duplicate`,
        method: 'POST',
        authenticate: true,
        dataObject: { transitId, name },
      });

      dispatch(fetchTransits());
      toast.success('Transit duplicated!');
    } catch (error) {
      const axiosError = error as AxiosError;
      toast.error(axiosError.response?.data.message || 'Something went wrong');
    }
  };

export const fetchTemplates =
  (templateType: TemplateType): AppThunk<Promise<void>> =>
  async (dispatch: any) => {
    try {
      const { data } = await request({
        path: `template/${templateType}`,
        method: 'GET',
        authenticate: true,
      });

      dispatch(setTemplates(data));
    } catch (error) {
      toast.error('Error fetching the transits!');
    }
  };

export const sendOne = async (_id: string) => {
  try {
    await request({
      path: `transits/${_id}/send-transit-to-ncts`,
      method: 'POST',
      authenticate: true,
    });
    toast.success('Transit sent successfully!');
  } catch (error) {
    toast.error('Error senditng the transit!');
  }
};

export const customsSync = async (_id: string) => {
  try {
    const { data: notifications } = await request({
      path: `transits/${_id}/customs-sync`,
      method: 'POST',
      authenticate: true,
    });
    toast.success(notifications?.length ? 'Transit synced successfully!' : 'No messages received');
  } catch (error) {
    toast.error('Error syncing the transit!');
  }
};

export const generateNCTSResponse = async (transitId: string, responseType: string) => {
  try {
    await request(
      {
        path: `transits/generate-ncts-test-response/${transitId}/${responseType}`,
        method: 'POST',
        authenticate: true,
        fileDownload: true,
      },
      true,
    );
    toast.success('Message response generated successfully!');
  } catch (error) {
    toast.error('Error generating response!');
  }
};

export const invalidateTransit =
  (transit: Transit, reason: string): AppThunk<Promise<void>> =>
  async (dispatch: any) => {
    if (transit.customsState) {
      try {
        await request({
          path: `transits/invalidate`,
          method: 'POST',
          authenticate: true,
          dataObject: { transitId: transit._id, reason },
        });
        toast.success('Invalidation request was sent!');
        dispatch(fetchTransit(transit._id));
      } catch (error) {
        toast.error('Error sending the invalidation request!');
        dispatch(fetchTransit(transit._id)); // some fields may have changed, even if sending failed
      }
    }
  };

export const releaseTransit =
  (transit: Transit, approveRevisions: boolean): AppThunk<Promise<void>> =>
  async (dispatch: any) => {
    if (transit.customsState) {
      try {
        await request({
          path: `transits/request-release`,
          method: 'POST',
          authenticate: true,
          dataObject: { transitId: transit._id, approveRevisions },
        });
        toast.success('Release request was sent!');
        dispatch(fetchTransit(transit._id));
      } catch (error) {
        toast.error('Error sending the release request!');
      }
    }
  };

export const sendNonArrivalInfo =
  (transit: Transit, data: any): AppThunk<Promise<void>> =>
  async (dispatch: any) => {
    if (transit.customsState) {
      try {
        await request({
          path: `transits/send-non-arrival-info`,
          method: 'POST',
          authenticate: true,
          dataObject: { transitId: transit._id, data },
        });
        toast.success('Non-arrival info was sent!');
        dispatch(fetchTransit(transit._id));
      } catch (error) {
        toast.error('Error sending the non-arrival info!');
      }
    }
  };

export const downloadHouseConsignmentItems =
  (transitId: string, transitName: string, houseConsignmentIndex: number): AppThunk<Promise<void>> =>
  async () => {
    try {
      const response = await request({
        path: `transits/${transitId}/${houseConsignmentIndex}/download-house-consignment-items`,
        method: 'GET',
        authenticate: true,
        responseType: 'arraybuffer',
      });

      const url = window.URL.createObjectURL(
        new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${transitName}-houseConsignment-${houseConsignmentIndex}.xlsx`);
      document.body.appendChild(link);
      link.click();

      toast.success('House consignment items successfully downloaded!');
    } catch (error) {
      toast.error('Error downloading the house consignment items!');
    }
  };

export const uploadHouseConsignmentItems = async (
  transitId: string,
  houseConsignmentIndex: number,
  file: File,
): Promise<TransitHouseConsignment> => {
  try {
    const formData = new FormData();
    if (file) {
      formData.append('file', file);

      const response = await request({
        path: `transits/${transitId}/${houseConsignmentIndex}/upload-house-consignment-items`,
        method: 'POST',
        authenticate: true,
        dataObject: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      toast.success(`New house consignment items were uploaded!`);
      return response.data;
    }
  } catch (error) {
    const axiosError = error as AxiosError;

    toast.error(axiosError.response?.data.message || 'Error uploading the house consignment items!');

    return null;
  }
  return null;
};

export default transitsSlice.reducer;
