import { FormikProps } from 'formik';
import { useParams } from 'react-router-dom';

import { Customer, MultilingualCodeSystems } from '@e-origin/shared';

import trashIcon from '../../../../../assets/icons/trash-icon.svg';
import { EditCustomerType } from '../../../../../enums';
import { Button, Input, MultiLangCodes } from '../../../../../shared/components';
import { FormRow, FormSection, FormSectionTitle } from '../../../../../styles/common';
import { CustomerDetailsParamsType, CustomerDetailsTabType } from '../customer-details.component';
import * as Style from './customer-details-guarantees-transit.styles';

interface CustomerDetailsGuaranteesTransitProps extends CustomerDetailsTabType {
  form: FormikProps<Customer>;
}

export const CustomerDetailsGuaranteesTransit: React.FC<CustomerDetailsGuaranteesTransitProps> = ({
  form,
  isButtonDisabled,
  handleSubmitCustomer,
}) => {
  const { customerId } = useParams<CustomerDetailsParamsType>();
  const context = customerId === 'add' ? 'CREATE' : 'UPDATE';

  const addGuarantee = () => {
    const guarantees = [...form.values.transitGuarantees];
    form.setFieldValue('transitGuarantees', [
      ...guarantees,
      {
        type: '',
        reference: {
          grn: '',
          accessCode: '',
          currencyCode: '',
        },
      },
    ]);
  };

  const removeGuarantee = (guaranteeIdx: number) => {
    const guarantees = [...form.values.transitGuarantees];
    guarantees.splice(guaranteeIdx, 1);
    form.setFieldValue('transitGuarantees', guarantees);
  };

  return (
    <Style.Container>
      <FormSection topPadding={0}>
        <FormSectionTitle noTopBorder>Manage Guarantees</FormSectionTitle>
        {form.values.transitGuarantees.map((guarantee, guaranteeIdx) => (
          <FormRow key={`${form.values.transitGuarantees.length}-${guaranteeIdx}`}>
            <MultiLangCodes
              name={`transitGuarantees.${guaranteeIdx}.type`}
              placeholder="Type"
              width={387}
              widthUnit="px"
              system={MultilingualCodeSystems.NCTS}
              value={guarantee.type}
              onChange={form.handleChange}
              code="CL251"
            />
            <Input
              name={`transitGuarantees.${guaranteeIdx}.reference.grn`}
              placeholder="GRN"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={guarantee.reference.grn}
              width={387}
            />
            <Input
              name={`transitGuarantees.${guaranteeIdx}.reference.accessCode`}
              placeholder="Access code"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={guarantee.reference.accessCode}
              width={387}
            />
            <Input
              name={`transitGuarantees.${guaranteeIdx}.reference.amountToBeCovered`}
              placeholder="Default guarantee amount"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={guarantee.reference.amountToBeCovered}
              width={387}
            />
            <MultiLangCodes
              name={`transitGuarantees.${guaranteeIdx}.reference.currencyCode`}
              placeholder="Currency"
              onChange={form.handleChange}
              value={guarantee.reference.currencyCode}
              width={387}
              widthUnit="px"
              code="CL048"
              system={MultilingualCodeSystems.NCTS}
            />
            <Button type="button" onClick={() => removeGuarantee(guaranteeIdx)}>
              <img src={trashIcon} />
            </Button>
          </FormRow>
        ))}
        <Style.ButtonWrapper>
          <Button type="button" outline onClick={addGuarantee}>
            Add Guarantee
          </Button>
        </Style.ButtonWrapper>
      </FormSection>
      <Style.SubmitSection>
        <Button
          disabled={isButtonDisabled}
          onClick={() => handleSubmitCustomer(EditCustomerType.GUARANTEES_TRANSIT)}
          primary
          type="submit"
        >
          {context === 'CREATE' ? 'Create' : 'Save'}
        </Button>
      </Style.SubmitSection>
    </Style.Container>
  );
};
