/* eslint-disable max-statements */

// import Cookies from 'js-cookie';
import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TransitGroup, TransitStatus } from '@e-origin/shared';

import {
  Button,
  IListFilterChangeEvent,
  IListFilterDropdownElements,
  ListFilterDropdown,
  SearchBox,
} from '../../../../shared/components';
import {
  fetchTransits,
  selectTransitFilters,
  selectTransitView,
  setTransitFilters,
  setTransitView,
} from '../../../../shared/stores/transitsSlice';
import { PageContainer, PageHeader, PageTitle } from '../../../../styles/common';
import { TransitViewComponent } from '../transit-view/transit-view.component';
import NewTransitModal from './modals/new-transit-modal/new-transit-modal.component';
import { TransitsTable } from './transits-table/transits-table.component';
import * as Style from './transits.style';

const Transits = () => {
  const dispatch = useDispatch();

  const [showNewTransitModal, setShowNewTransitModal] = useState(false);
  const filters = useSelector(selectTransitFilters);
  const transitView = useSelector(selectTransitView);

  const buildStatusesDropDownOptions = () => {
    const dropdownOptions: IListFilterDropdownElements = {
      [TransitStatus.NOT_SENT as string]: {
        label: 'Not sent',
        checked: false,
      },
      [TransitStatus.SENDING as string]: {
        label: 'Sending',
        checked: false,
      },
      [TransitStatus.SENT as string]: {
        label: 'Sent',
        checked: false,
      },
      [TransitStatus.REJECTED as string]: {
        label: 'Rejected',
        checked: false,
      },
      [TransitStatus.UNLOAD_PERMISSION as string]: {
        label: 'Unload permission',
        checked: false,
      },
      [TransitStatus.REMARKS_SENT as string]: {
        label: 'Remarks sent',
        checked: false,
      },
      [TransitStatus.ACCEPTED as string]: {
        label: 'Validation in progress',
        checked: false,
      },

      [TransitStatus.IN_CONTROL as string]: {
        label: 'In control',
        checked: false,
      },
      [TransitStatus.CORR_REFUSED_WAITING as string]: {
        label: 'In control corr refused',
        checked: false,
      },
      [TransitStatus.CORR_OK_WAITING as string]: {
        label: 'In control corr ok',
        checked: false,
      },
      [TransitStatus.NOT_RELEASED as string]: {
        label: 'Not released',
        checked: false,
      },
      [TransitStatus.RELEASED as string]: {
        label: 'Released for transit',
        checked: false,
      },
      [TransitStatus.FINISHED as string]: {
        label: 'Transit finished',
        checked: false,
      },
    };

    return dropdownOptions;
  };

  const [statusesOptions, setStatusesOptions] = useState<IListFilterDropdownElements>(null);

  const setNewFilter = (field: string, value: string | string[]) => {
    dispatch(setTransitFilters({ [field]: value }));
  };

  useEffect(() => {
    setStatusesOptions(buildStatusesDropDownOptions());
  }, []);

  const handleTransitView = (value: TransitGroup) => {
    dispatch(setTransitView(value));
    setNewFilter('group', value);
  };

  const searchTransits = (searchQuery: string) => {
    setNewFilter('searchQuery', searchQuery);
  };

  const toggleNewTransitModal = () => {
    setShowNewTransitModal(!showNewTransitModal);
  };

  const afterCreateTransitModal = () => {
    dispatch(fetchTransits());
  };

  const filterByStatus = (statusOption: IListFilterChangeEvent) => {
    const statusesOptionsCopy = cloneDeep(statusesOptions);
    if (!statusOption.id) {
      Object.keys(statusesOptionsCopy).forEach((option) => {
        statusesOptionsCopy[option].checked = statusOption.checked;
      });
    } else {
      statusesOptionsCopy[statusOption.id as TransitStatus].checked = statusOption.checked;
    }

    const selectedStatuses = Object.keys(statusesOptionsCopy)
      .filter((option) => statusesOptionsCopy[option].checked)
      .map((option) => option);

    setStatusesOptions(statusesOptionsCopy);
    setNewFilter('status', selectedStatuses?.length ? selectedStatuses : null);
  };

  return (
    <PageContainer isRelative className="declaration-list">
      <PageHeader>
        <PageTitle>Transits</PageTitle>
        <Style.DataTableControls>
          <TransitViewComponent value={transitView} onChange={handleTransitView} />
          <Button outline onClick={toggleNewTransitModal}>
            New transit
          </Button>
          <SearchBox
            handleSearch={searchTransits}
            value={filters?.searchQuery}
            placeholder="Seach by Name, LRN, MRN, Holder..."
          />
        </Style.DataTableControls>
        {showNewTransitModal && (
          <NewTransitModal
            onHide={toggleNewTransitModal}
            transitType={transitView}
            afterCreate={afterCreateTransitModal}
          />
        )}
      </PageHeader>
      <Style.ListFilters>
        <ListFilterDropdown title={'Status filter'} values={statusesOptions} onChange={filterByStatus} />
      </Style.ListFilters>
      <TransitsTable />
    </PageContainer>
  );
};

export default Transits;
