import { useFormik } from 'formik';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Declaration, DeclarationGoods } from '@e-origin/shared';

import { Button, Input, Modal } from '../../../../../../../../shared/components';
import { manuallySetPrice } from '../../../../../../../../shared/stores/declarationsSlice';
import { FormContainer, FormRow, FormSection } from '../../../../../../../../styles/common';
import { WebLink } from './manually-set-price-modal.style';

interface IManuallySetPriceModalProps {
  goodDetails: DeclarationGoods;
  declaration: Declaration;
  onHide(newPrice?: number): void;
}

interface IFormErrorData {
  price?: string;
}

export const ManuallySetPriceModal: React.FC<IManuallySetPriceModalProps> = (props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatch();

  const submitPrice = async (price: number) => {
    setIsSubmitting(true);
    if (props.declaration?._id && props.goodDetails?._id) {
      const webUrl = props.goodDetails.webScraping?.webUrl;
      const goodItemsIds = props.declaration.goodsShipmentItems
        .filter((good) => good.webScraping?.webUrl === webUrl)
        .map((good) => good._id);
      dispatch(manuallySetPrice(props.declaration._id, goodItemsIds, price));
      setIsSubmitting(false);
      props.onHide(price);
    }
  };

  const formik = useFormik({
    initialValues: {
      price: 0,
    },
    validate: (values) => {
      const errors: IFormErrorData = {};
      if (!values.price) {
        errors.price = 'Price is required';
      }
      return errors;
    },
    onSubmit: (values) => {
      submitPrice(values.price);
    },
  });
  const { values, handleChange, handleBlur, submitForm, handleSubmit } = formik;

  return (
    <Modal
      title={'Manually set price'}
      show={true}
      onHide={() => {
        if (!isSubmitting) {
          props.onHide();
        }
      }}
      buttons={[
        <Button
          type="button"
          key="cancel-batch-modal-btn"
          onClick={() => props.onHide(values.price)}
          disabled={isSubmitting}
        >
          Cancel
        </Button>,
        <Button
          key="save-modal-btn"
          disabled={!values.price || isSubmitting}
          onClick={submitForm}
          primary
          type="button"
          showLoading={isSubmitting}
        >
          Confirm
        </Button>,
      ]}
      buttonsJustifyAlign="center"
    >
      <FormContainer onSubmit={handleSubmit}>
        <FormSection verticalPadding={20} topPadding={0}>
          <div>
            Automatic risk analysis wasn't able to extract the price form the website:{' '}
            <WebLink href={`${props.goodDetails.webScraping.webUrl}`} rel="noreferrer" target="_blank">
              {props.goodDetails.webScraping.webUrl}
            </WebLink>
          </div>
          <div>You can manually set the web value below</div>
          <FormRow>
            <Input
              defaultBehaviour={true}
              name="price"
              placeholder="Web value"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.price}
              type="number"
              width={100}
              widthUnit="%"
              disabled={isSubmitting}
            />
          </FormRow>
        </FormSection>
      </FormContainer>
    </Modal>
  );
};
