/* eslint-disable complexity */
import { useFormik } from 'formik';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { NCTSMessageType, Transit, TransitStatus } from '@e-origin/shared';

import { InputErrorStatus } from '../../../../../../../enums';
import { Button, DatePicker, Input } from '../../../../../../../shared/components';
import { sendNonArrivalInfo } from '../../../../../../../shared/stores/transitsSlice';
import { FormRow, FormSectionTitle } from '../../../../../../../styles/common';
import { NonArrivalForm, NonArrivalInfoContainer } from './non-arrival-info.style';

const validationSchema = Yup.object({
  CustomsOfficeOfDestinationActualReferenceNumber: Yup.string()
    .length(8, 'Reference number must be exactly 8 characters')
    .required('Required'),
  // TC11DeliveryDate: Yup.string().required('Required'),
  EnquiryText: Yup.string().required('Required'),
  identificationNumber: Yup.string().required('Required'),
  name: Yup.string().required('Required'),
  street: Yup.string().required('Required'),
  postcode: Yup.string().required('Required'),
  city: Yup.string().required('Required'),
  country: Yup.string().length(2, 'Country must be exactly 2 characters').required('Required'),
});

interface INonArrivalInfoProps {
  transit: Transit;
}

export const NonArrivalInfo = ({ transit }: INonArrivalInfoProps) => {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      CustomsOfficeOfDestinationActualReferenceNumber: transit.CustomsOfficeOfDestinationActual?.referenceNumber || '',
      TC11DeliveryDate: transit.enquiry?.TC11DeliveryDate || '',
      EnquiryText: transit.enquiry?.text || '',
      identificationNumber: transit.Consignment?.ConsigneeActual?.identificationNumber || '',
      name: transit.Consignment?.ConsigneeActual?.name || '',
      street: transit.Consignment?.ConsigneeActual?.Address?.streetAndNumber || '',
      postcode: transit.Consignment?.ConsigneeActual?.Address?.postcode || '',
      city: transit.Consignment?.ConsigneeActual?.Address?.city || '',
      country: transit.Consignment?.ConsigneeActual?.Address?.country || '',
    },
    validationSchema,
    onSubmit: (values) => {
      dispatch(
        sendNonArrivalInfo(transit, {
          CustomsOfficeOfDestinationActualReferenceNumber: values.CustomsOfficeOfDestinationActualReferenceNumber,
          TC11DeliveryDate: values.TC11DeliveryDate,
          EnquiryText: values.EnquiryText,
          ConsigneeActual: {
            identificationNumber: values.identificationNumber,
            name: values.name,
            Address: {
              streetAndNumber: values.street,
              postcode: values.postcode,
              city: values.city,
              country: values.country,
            },
          },
        }),
      );
    },
  });

  // errors - if form is sent and customs sends back errors
  const errors = transit.customsState?.functionalError?.filter((error) =>
    error.errorPointer.toLocaleLowerCase().includes(NCTSMessageType.CC141C.toLocaleLowerCase()),
  );

  const isDisabled = transit.generalInfo.status !== TransitStatus.REQUESTED_NON_ARRIVED;

  return (
    <>
      <NonArrivalInfoContainer>
        <form onSubmit={formik.handleSubmit}>
          <NonArrivalForm>
            <FormSectionTitle>Request for non-arrived movement</FormSectionTitle>
            {errors?.length > 0 &&
              errors.map((error, idx) => (
                <FormRow key={idx}>
                  <p>
                    Error pointer: {error.errorPointer} (Error code: {error.errorCode}) {error.originalAttributeValue}
                  </p>
                </FormRow>
              ))}

            <FormRow>
              <Input
                name="requestDate"
                placeholder="Request date"
                value={moment(transit.customsState?.requestNonArrived?.requestDate).format('YYYY-MM-DD')}
                width={150}
                disabled={true}
              />
              <Input
                name="limitForResponseDate"
                placeholder="Response deadline"
                value={moment(transit.customsState?.requestNonArrived?.limitForResponseDate).format('YYYY-MM-DD')}
                width={150}
                disabled={true}
              />
              <Input
                name="customsOfficeOfEnquiryAtDeparture"
                placeholder="Office of enquiry"
                value={transit.customsState?.requestNonArrived?.customsOfficeOfEnquiryAtDeparture?.referenceNumber}
                width={150}
                disabled={true}
              />
            </FormRow>
            <FormSectionTitle noTopBorder>Customs office of destination (Actual)</FormSectionTitle>
            <FormRow>
              <Input
                name="CustomsOfficeOfDestinationActualReferenceNumber"
                placeholder="Reference number"
                value={formik.values.CustomsOfficeOfDestinationActualReferenceNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                width={93}
                widthUnit="%"
                disabled={isDisabled}
                message={
                  formik.touched.CustomsOfficeOfDestinationActualReferenceNumber &&
                  formik.errors.CustomsOfficeOfDestinationActualReferenceNumber
                    ? {
                        status: InputErrorStatus.ERROR,
                        text: formik.errors.CustomsOfficeOfDestinationActualReferenceNumber as string,
                      }
                    : undefined
                }
              />
              <DatePicker
                name="TC11DeliveryDate"
                placeholder="TC11 Delivery Date"
                value={formik.values.TC11DeliveryDate as any}
                onChange={formik.handleChange}
                disabled={isDisabled}
              />

              <Input
                name="EnquiryText"
                placeholder="Text"
                value={formik.values.EnquiryText}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                width={93}
                widthUnit="%"
                disabled={isDisabled}
                message={
                  formik.touched.EnquiryText && formik.errors.EnquiryText
                    ? { status: InputErrorStatus.ERROR, text: formik.errors.EnquiryText as string }
                    : undefined
                }
              />
            </FormRow>
            <FormSectionTitle noTopBorder>Consignee (Actual)</FormSectionTitle>
            <FormRow>
              <Input
                name="identificationNumber"
                placeholder="Identification number"
                value={formik.values.identificationNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                width={93}
                widthUnit="%"
                disabled={isDisabled}
                message={
                  formik.touched.identificationNumber && formik.errors.identificationNumber
                    ? { status: InputErrorStatus.ERROR, text: formik.errors.identificationNumber as string }
                    : undefined
                }
              />
              <Input
                name="name"
                placeholder="Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                width={93}
                widthUnit="%"
                disabled={isDisabled}
                message={
                  formik.touched.name && formik.errors.name
                    ? { status: InputErrorStatus.ERROR, text: formik.errors.name as string }
                    : undefined
                }
              />
            </FormRow>
            <FormRow>
              <Input
                name="street"
                placeholder="Street"
                value={formik.values.street}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                width={93}
                widthUnit="%"
                disabled={isDisabled}
                message={
                  formik.touched.street && formik.errors.street
                    ? { status: InputErrorStatus.ERROR, text: formik.errors.street as string }
                    : undefined
                }
              />
              <Input
                name="postcode"
                placeholder="Postcode"
                value={formik.values.postcode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                width={93}
                widthUnit="%"
                disabled={isDisabled}
                message={
                  formik.touched.postcode && formik.errors.postcode
                    ? { status: InputErrorStatus.ERROR, text: formik.errors.postcode as string }
                    : undefined
                }
              />
              <Input
                name="city"
                placeholder="City"
                value={formik.values.city}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                width={93}
                widthUnit="%"
                disabled={isDisabled}
                message={
                  formik.touched.city && formik.errors.city
                    ? { status: InputErrorStatus.ERROR, text: formik.errors.city as string }
                    : undefined
                }
              />
              <Input
                name="country"
                placeholder="Country"
                value={formik.values.country}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                width={93}
                widthUnit="%"
                disabled={isDisabled}
                message={
                  formik.touched.country && formik.errors.country
                    ? { status: InputErrorStatus.ERROR, text: formik.errors.country as string }
                    : undefined
                }
              />
            </FormRow>
            {!isDisabled && (
              <Button type="submit" primary disabled={!formik.isValid || formik.isSubmitting}>
                Send response
              </Button>
            )}
          </NonArrivalForm>
        </form>
      </NonArrivalInfoContainer>
    </>
  );
};
