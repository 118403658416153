/* eslint-disable max-lines */
/* eslint-disable max-statements */
/* eslint-disable complexity */
/* eslint-disable no-restricted-syntax */
import { FormikProps } from 'formik';
import { useEffect, useRef, useState } from 'react';
import DataTable, { IDataTableColumn } from 'react-data-table-component';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ConsignmentItem, TransitGroup, TransitHouseConsignment, TransitStatus } from '@e-origin/shared';

import Icons, { TableActionsIcons } from '../../../../../../../assets';
import backIcon from '../../../../../../../assets/icons/back-icon.svg';
import {
  Button,
  CustomTableActionsComponent,
  NotificationModal,
  SearchBox,
} from '../../../../../../../shared/components';
import { selectTransit } from '../../../../../../../shared/stores/transitsSlice';
import { TransitDetailsFormProps } from '../../../transit-details.component';
import * as GeneralStyle from '../transit-details-house.style';
import { GoodsItemModal } from './goods-item-modal/goods-item-modal';
import * as Style from './goods-items.style';

interface GoodsItemsProps {
  form: FormikProps<TransitDetailsFormProps>;
  editDisabled: boolean;
  editDisabledForConsignment: boolean;
}

interface GoodsItemTableData {
  declarationGoodsItemNumber: string;
  goodsItemNumber: string;
  isMissing: boolean;
  descriptionOfGoods: string;
  hsCode: string;
  grossMass: string;
  typeOfPackages: string;
  numberOfPackages: string;
  shippingMarks: string;
  houseConsignment: TransitHouseConsignment;
  consignmentItem: ConsignmentItem;
  consignmentItemIdx: number;
}

export const GoodsItems: React.FC<GoodsItemsProps> = ({ form, editDisabled, editDisabledForConsignment }) => {
  const [tableData, setTableData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const transit = useSelector(selectTransit);

  const routeLocation = useLocation();
  const queryParams = useRef<URLSearchParams>(new URLSearchParams(routeLocation.search));
  const houseRef = queryParams.current.get('houseRef') ? Number(queryParams.current.get('houseRef')) : null;
  const history = useHistory();

  const searchQuery = useRef<string>(queryParams.current.get('search') ? queryParams.current.get('search') : '');
  const selectedRow = useRef<GoodsItemTableData | null>(null);

  const buildTableData = () => {
    const data: GoodsItemTableData[] = [];

    const houseConsigments =
      houseRef !== null
        ? [form.values.Consignment.HouseConsignment[houseRef]]
        : form.values.Consignment?.HouseConsignment || [];

    for (const houseItem of houseConsigments) {
      for (const [consignmentItemIdx, consignmentItem] of (houseItem.ConsignmentItem || []).entries()) {
        if (
          !searchQuery.current ||
          consignmentItem.Commodity?.descriptionOfGoods?.toLowerCase().includes(searchQuery.current.toLowerCase()) ||
          consignmentItem.Commodity?.CommodityCode?.hsCode?.toLowerCase().includes(searchQuery.current.toLowerCase())
        ) {
          data.push({
            declarationGoodsItemNumber: consignmentItem.declarationGoodsItemNumber,
            goodsItemNumber: consignmentItem.goodsItemNumber,
            isMissing: consignmentItem.isMissing,
            descriptionOfGoods: consignmentItem.Commodity?.descriptionOfGoods,
            hsCode: consignmentItem.Commodity?.CommodityCode?.hsCode,
            grossMass: consignmentItem.Commodity?.GoodsMeasure?.grossMass,
            typeOfPackages: consignmentItem.Packaging?.[0]?.typeOfPackages,
            numberOfPackages: consignmentItem.Packaging?.[0]?.numberOfPackages,
            shippingMarks: consignmentItem.Packaging?.[0]?.shippingMarks,
            houseConsignment: houseItem,
            consignmentItem,
            consignmentItemIdx,
          });
        }
      }
    }

    return data;
  };

  useEffect(() => {
    if (form.values && routeLocation) {
      setTableData(buildTableData());
    }
  }, [form.values, routeLocation]);

  const actionList = (index) => {
    const isUnloadPermission = transit?.generalInfo?.status === TransitStatus.UNLOAD_PERMISSION;
    const path = `Consignment.HouseConsignment[${houseRef}].ConsignmentItem[${index}].isMissing`;

    const isMissing = form?.values?.Consignment?.HouseConsignment[houseRef]?.ConsignmentItem[index]?.isMissing;
    const isItemMissingAllowed = () => isUnloadPermission && !isMissing;
    const isItemFoundAllowed = () => isUnloadPermission && !!isMissing;

    return [
      {
        if: isItemMissingAllowed(),
        icon: TableActionsIcons.DANGEROUS,
        text: 'Item missing',
        onClick: () => {
          form.setFieldValue(path, true);
          toast.success('Item marked as missing!');
        },
      },
      {
        if: isItemFoundAllowed(),
        icon: TableActionsIcons.FINALIZE,
        text: 'Item found',
        onClick: () => {
          form.setFieldValue(path, false);
          toast.success('Item marked as found!');
        },
      },
      {
        icon: TableActionsIcons.VIEW,
        text: 'Edit',
        onClick: () => {
          setShowModal(true);
        },
      },
      {
        if: !editDisabled || !editDisabledForConsignment,
        icon: TableActionsIcons.DELETE,
        text: 'Delete',
        onClick: () => {
          setShowDeleteModal(true);
        },
      },
    ];
  };

  const columns: IDataTableColumn[] = [
    {
      name: 'Declaration Goods Item Number',
      selector: 'declarationGoodsItemNumber',
      sortable: false,
    },
    {
      name: 'Goods Item Number',
      selector: 'goodsItemNumber',
      sortable: false,
    },
    {
      omit: transit.generalInfo.group === TransitGroup.DEPARTURE,
      name: 'Is missing',
      cell: (row) =>
        row.isMissing ? (
          <Style.IconWrapper>
            <img src={Icons.BlueCheckMarkIcon} alt="Is missing" />
          </Style.IconWrapper>
        ) : (
          ''
        ),
      selector: 'isMissing',
      sortable: false,
    },
    {
      name: 'Description of goods',
      selector: 'descriptionOfGoods',
      sortable: false,
    },
    {
      name: 'HS code',
      selector: 'hsCode',
      sortable: false,
    },
    {
      name: 'Gross mass',
      selector: 'grossMass',
      sortable: false,
    },
    {
      name: 'Type of packages',
      selector: 'typeOfPackages',
      sortable: false,
    },
    {
      name: 'Number of packages',
      selector: 'numberOfPackages',
      sortable: false,
    },
    {
      name: 'Marking',
      selector: 'shippingMarks',
      sortable: false,
    },
    {
      name: 'Actions',
      right: true,
      cell: (row, rowIndex) => (
        <CustomTableActionsComponent
          actions={actionList(rowIndex)}
          secondaryActions={[]}
          onClick={() => {
            selectedRow.current = row;
          }}
          disableAfterClick={true}
        />
      ),
    },
  ];

  const handleSave = (goodsItem) => {
    const { consignmentItemIdx = -1 } = selectedRow.current || {};

    if (consignmentItemIdx === -1) {
      form.setFieldValue(`Consignment.HouseConsignment.${houseRef}.ConsignmentItem`, [
        ...(form.values.Consignment.HouseConsignment[houseRef].ConsignmentItem || []),
        goodsItem,
      ]);
    } else {
      form.setFieldValue(`Consignment.HouseConsignment.${houseRef}.ConsignmentItem.${consignmentItemIdx}`, goodsItem);
    }
    setShowModal(false);
    selectedRow.current = null;
  };

  const handleItemDelete = () => {
    const { consignmentItemIdx = -1 } = selectedRow.current || {};
    form.setFieldValue(
      `Consignment.HouseConsignment.${houseRef}.ConsignmentItem`,
      form.values.Consignment.HouseConsignment[houseRef].ConsignmentItem.filter((_, idx) => idx !== consignmentItemIdx),
    );
    setShowDeleteModal(false);
    selectedRow.current = null;
  };

  const handleHide = () => {
    setShowModal(false);
    selectedRow.current = null;
  };

  const handleHideDeleteModal = () => {
    setShowDeleteModal(false);
    selectedRow.current = null;
  };

  const handleSearch = (value: string) => {
    searchQuery.current = value;
    setTableData(buildTableData());

    queryParams.current.set('search', value);
    history.push({ search: queryParams.current.toString() });
  };

  const handleBack = () => {
    queryParams.current.delete('houseRef');
    queryParams.current.delete('context');
    queryParams.current.delete('search');
    history.push({ search: queryParams.current.toString() });
  };

  return (
    <>
      <Style.BackButton onClick={handleBack}>
        <img src={backIcon} alt="" />
        Back
      </Style.BackButton>
      <Style.Header>
        <Style.Title>Goods items{transit.Consignment.HouseConsignment[houseRef]?.Consignor?.name}</Style.Title>
        <GeneralStyle.ActionButton>
          <SearchBox
            handleSearch={handleSearch}
            value={searchQuery.current}
            placeholder="Search goods by HS code, description of goods"
          />
          {(!editDisabled || !editDisabledForConsignment) && (
            <Button outline onClick={() => setShowModal(true)}>
              Add new
            </Button>
          )}
        </GeneralStyle.ActionButton>
      </Style.Header>
      <GeneralStyle.TableWrapper>
        <DataTable noHeader columns={columns} data={tableData || []} pagination paginationPerPage={10} />
      </GeneralStyle.TableWrapper>
      {showModal && (
        <GoodsItemModal
          onSave={handleSave}
          onHide={handleHide}
          houseConsignment={selectedRow.current?.houseConsignment}
          goodsItem={selectedRow.current?.consignmentItem}
          editDisabled={editDisabled}
          editDisabledForConsignment={editDisabledForConsignment}
        />
      )}
      <NotificationModal
        title="Confirm item delete"
        confirmButtonText="Delete"
        show={showDeleteModal}
        onHide={handleHideDeleteModal}
        onConfirm={handleItemDelete}
        isDelete
      >
        <div>Are you sure you want to delete this goods item?</div>
      </NotificationModal>
    </>
  );
};
