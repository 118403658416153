/* eslint-disable max-statements */
import { useState } from 'react';
import DataTable, { IDataTableColumn, IDataTableProps } from 'react-data-table-component';

import { useConfig } from '../../hooks/use-config';

interface ITableProps<T> {
  columns: IDataTableColumn<T>[];
  isDataLoading: boolean;
  list: T[];
  loadData(page: number, perPage: number, sortField: string, sortDirection: string): void;
  setLoading(): void;
  totalListCount: number;
  className?: string;
}

/**
 * Will be replaced with CustomTable
 * @warning Will be deprecated - use CustomTable instead
 */
export const Table = <T,>(props: ITableProps<T>) => {
  const { columns, className, isDataLoading, list, loadData, totalListCount, setLoading } = props;

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sortField, setSortField] = useState('');
  const [sortDirection, setSortDirection] = useState('');
  const { config } = useConfig();

  const handlePageChange: IDataTableProps['onChangePage'] = (page) => {
    setCurrentPage(page);
    setLoading();
    loadData(page, perPage, sortField, sortDirection);
  };

  const handlePerRowsChange: IDataTableProps['onChangeRowsPerPage'] = (newPerPage, page) => {
    setLoading();
    loadData(page, newPerPage, sortField, sortDirection);
    setCurrentPage(page);
    setPerPage(newPerPage);
  };

  const handleSort: IDataTableProps['onSort'] = async (column, direction) => {
    setSortField(String(column.selector || ''));
    setSortDirection(direction);
    loadData(currentPage, perPage, String(column.selector || ''), direction);
  };

  return (
    <DataTable
      className={className}
      noHeader
      columns={columns}
      data={!isDataLoading ? list || [] : []}
      pagination
      paginationServer
      paginationDefaultPage={currentPage}
      paginationPerPage={perPage}
      paginationTotalRows={totalListCount}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
      paginationRowsPerPageOptions={config?.DATA_TABLE_PAGE_SIZES}
      sortServer
      onSort={handleSort}
    />
  );
};
