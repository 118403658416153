/* eslint-disable max-statements */
import Cookies from 'js-cookie';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Declaration } from '@e-origin/shared';

import { CustomTable } from '../../../../../shared/components';
import {
  fetchDeclarations,
  nextDeclarations,
  selectDeclarations,
  selectDeclarationsFilters,
  selectDeclarationsPagination,
  selectDeclarationsSorting,
  selectLoadingDeclarations,
  selectTotalDeclarationsCount,
  setDeclarationsSorting,
} from '../../../../../shared/stores/declarationsSlice';
import { STORAGE_KEYS } from '../../../../../utils';
import {
  DeclarationModalEnum,
  DeclarationModalFactory,
} from '../../declaration-modal-factory/declaration-modal-factory.component';
import { useDeclarationsTableColumns } from './declarations-table.columns';

export const DeclarationsTable: React.FC = () => {
  const dispatch = useDispatch();

  const filters = useSelector(selectDeclarationsFilters);
  const sorting = useSelector(selectDeclarationsSorting);
  const pagination = useSelector(selectDeclarationsPagination);

  const declarationsList = useSelector(selectDeclarations);
  const totalDeclarationsCount = useSelector(selectTotalDeclarationsCount);
  const isLoading = useSelector(selectLoadingDeclarations);

  const [showModalFactory, setShowModalFactory] = useState(false);
  const selectedModal = useRef<DeclarationModalEnum>(undefined);
  const selectedRow = useRef<Declaration>(null);

  const openModal = (row: Declaration, modal: DeclarationModalEnum) => {
    selectedModal.current = modal;
    selectedRow.current = row;
    setShowModalFactory(true);
  };

  const fetchData = () => {
    dispatch(fetchDeclarations());
  };

  const onSelectedRow = (row) => {
    selectedRow.current = row;
  };

  const columns = useDeclarationsTableColumns({ openModal, fetchData, onSelectedRow });

  const handleSorting = (updatedSorting) => {
    dispatch(setDeclarationsSorting(updatedSorting));
  };

  const handlePagination = (updatedPagination) => {
    Cookies.set(STORAGE_KEYS.COOKIES.LIST_DECLARATIONS_CRITERIA, JSON.stringify({ filters, sorting, pagination }));
    dispatch(nextDeclarations(updatedPagination));
  };

  return (
    <>
      <DeclarationModalFactory
        show={showModalFactory}
        selectedData={selectedRow.current}
        modal={selectedModal.current}
        onHide={() => setShowModalFactory(false)}
        onFinish={fetchData}
      />
      <CustomTable
        columns={columns.current}
        pagination={pagination}
        sorting={sorting}
        onSortingChange={handleSorting}
        onPaginationChange={handlePagination}
        data={declarationsList || []}
        total={totalDeclarationsCount}
        isLoading={isLoading}
        disablePagination={isLoading}
        disableJumpToLastPage={true}
      />
    </>
  );
};
