/* eslint-disable max-lines */
/* eslint-disable complexity */
import _ from 'lodash';
/* eslint-disable max-statements */
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { container, TYPES } from '@e-origin/ioc';
import { Declaration, DeclarationGoods, IRiskAnalysisDescriptionNewValues } from '@e-origin/shared';

import Icons from '../../../../../../../../assets';
import { ReactComponent as LeftArrowIcon } from '../../../../../../../../assets/icons/left-arrow.svg';
import pictureIcon from '../../../../../../../../assets/icons/picture.svg';
import { InputErrorStatus } from '../../../../../../../../enums';
import { Button, Chip, Input } from '../../../../../../../../shared/components';
import { downloadScreenshotFromLink } from '../../../../../../../../shared/stores/declarationsSlice';
import { FormRow } from '../../../../../../../../styles/common';
import { RiskAnalysisService } from '../../../../../../services';
import GoodsItemUploadScreenshotModal from '../../../goods-item-upload-screenshot-modal/goods-item-upload-screenshot-modal.component';
import { ValidateAllStateEnum } from '../../risk-analysis-modal.component';
import {
  ArrowContainer,
  Checkbox,
  CheckboxContainer,
  RiskAnalysisSection,
  RiskAnalysisSectionContent,
  RiskAnalysisSectionHeader,
} from '../../risk-analysis-modal.styles';
import {
  ChipHeaderText,
  ChipsContainer,
  RiskAnalysisNewDescription,
  RiskAnalysisTranslationContainer,
} from './risk-analysis-description.styles';

interface IRiskAnalysisDescriptionProps {
  declaration: Declaration;
  goodDetailsOriginal: DeclarationGoods;
  goodDetails: DeclarationGoods;
  update: (obj: IRiskAnalysisDescriptionNewValues) => void;
  runAiModule: () => void;
  validate: ValidateAllStateEnum;
}

interface IChips {
  [key: string]: {
    value: boolean;
    customColor?: string;
  };
}

const INVOICE_DESCRIPTION = 'Invoice description';
const WEB_DESCRIPTION_PLACEHOLDER = 'Web description';
const VALIDATE_DESCRIPTION = 'Validate the description';
const SELECT_WORDS_BELOW_TO_SET_THE_NEW_DESCRIPTION = 'Select words below to set the new description:';
const SUGGESTED_INVOICE_DESCRIPTION = 'Suggested invoice description';
const MATCH_NOT_OK = 'Match not ok';
const MATCH_SUGGESTED = 'Suggested';
const MATCH_OK = 'Match ok';

export const RiskAnalysisDescription: FC<IRiskAnalysisDescriptionProps> = ({
  declaration,
  goodDetails,
  goodDetailsOriginal,
  update,
  runAiModule,
  validate,
}) => {
  const riskAnalysisService: RiskAnalysisService = container.get(TYPES.RiskAnalysisService);
  const initialWebDescription: string =
    (goodDetails.webScraping?.webDescriptionOfGoods || '')
      .split(' ')
      .filter((word: string) => word.trim().length)
      .slice(0, 10)
      .join(' ') || '';

  const suggestedDescriptionOfGoods = goodDetails?.evalMethodDescription?.suggestedDescriptionOfGoods || '';
  const initialSuggestedDescriptionOfGoods =
    suggestedDescriptionOfGoods.length === 0 ? [] : suggestedDescriptionOfGoods.split(' ');
  const chipsValues = initialWebDescription.split(' ').reduce<IChips>(
    (acc: IChips, curr: string) => {
      if (curr) {
        acc[curr] = { value: initialSuggestedDescriptionOfGoods.includes(curr) };
      }
      return acc;
    },
    goodDetails.commodity?.material
      ? ({
          [goodDetails.commodity?.material]: {
            value: initialSuggestedDescriptionOfGoods.includes(goodDetails.commodity?.material),
            customColor: '#1770A3',
          },
        } as IChips)
      : {},
  );

  const [validatedDescriptionCheck, setValidatedDescriptionCheck] = useState(
    !!goodDetails.evalMethodDescription?.validatedDescriptionCheck,
  );
  const validatedDescriptionCheckDisabled = riskAnalysisService.evalMethodDescriptionCheckDisabled(goodDetailsOriginal);

  const [chips, setChips] = useState(chipsValues);
  const [newDescription, setNewDescription] = useState<string>(suggestedDescriptionOfGoods);

  const emptyDescription = (value: string) => {
    if (!value) {
      setChips(
        _.mapValues(chips, (chip) => ({
          ...chip,
          value: false,
        })),
      );
    }
    setNewDescription(value || '');
  };

  useEffect(() => {
    setNewDescription(suggestedDescriptionOfGoods);
    setValidatedDescriptionCheck(!!goodDetails.evalMethodDescription?.validatedDescriptionCheck);
    setChips(chipsValues);
  }, [goodDetails]);

  useEffect(() => {
    if (validate === null || validate === ValidateAllStateEnum.SOME_SELECTED) {
      return;
    }
    setValidatedDescriptionCheck(validate === ValidateAllStateEnum.ALL_SELECTED && !validatedDescriptionCheckDisabled);
  }, [validate]);

  useEffect(() => {
    const newDescriptionWords = newDescription.replace(/[^a-zA-Z0-9 ]+/g, '').split(' ');
    Object.keys(chipsValues).forEach((chip) => {
      chipsValues[chip].value = newDescriptionWords.includes(chip);
    });
    setChips(chipsValues);
  }, [newDescription]);

  useEffect(() => {
    update({
      newDescription,
      validatedDescriptionCheck,
      validatedDescriptionCheckDisabled,
    });
  }, [newDescription, validatedDescriptionCheck]);

  const webScrapingDone = !!declaration?.generalInfo?.webScrapingDone;

  const dispatch = useDispatch();
  const [showUploadScreenshotModal, setShowUploadScreenshotModal] = useState(false);

  const toggleChip = (chipName: string) => {
    const newChips = {
      ...chips,
      [chipName]: {
        ...chips[chipName],
        value: !chips[chipName],
      },
    };
    setChips(newChips);
    if (newDescription.split(' ').indexOf(chipName) !== -1) {
      const newDescriptionWords = newDescription.split(' ');
      setNewDescription(newDescriptionWords.filter((item: any) => item !== chipName).join(' '));
    } else {
      setNewDescription(`${newDescription} ${chipName}`);
    }
  };

  const toggleShowUploadScreenshotModal = () => {
    setShowUploadScreenshotModal(!showUploadScreenshotModal);
  };

  const screenshotLink =
    goodDetails.webScraping.webAdditionalFiles.find((webAdditionalFile: any) => {
      return webAdditionalFile.type === 'screenshot';
    })?.url || '';

  const downloadScreenshot = () => {
    if (screenshotLink) {
      dispatch(downloadScreenshotFromLink(screenshotLink));
    }

    if (!screenshotLink && webScrapingDone) {
      toggleShowUploadScreenshotModal();
    }
  };

  const isShowPictureDisabled = !!goodDetails?.webScraping?.webAdditionalFiles?.find((item: any) => {
    return item.type === 'screenshot';
  });

  const computeWebMatchingDescriptionStatus = () => {
    if (!goodDetails.evalMethodDescription?.webMatchingDescription) {
      return {
        status: InputErrorStatus.ERROR,
        text: MATCH_NOT_OK,
      };
    }

    if (goodDetails.evalMethodDescription?.suggestedDescriptionOfGoods) {
      return {
        status: InputErrorStatus.SUCCESS,
        text: MATCH_SUGGESTED,
      };
    }

    return {
      status: InputErrorStatus.SUCCESS,
      text: MATCH_OK,
    };
  };

  const webMatchingDescriptionStatus = computeWebMatchingDescriptionStatus();

  const RenderChips = () => {
    return (
      <ChipsContainer>
        {Object.keys(chips).map((chipName: any) => {
          return (
            <Chip
              isActive={chips[chipName].value}
              key={chipName}
              onClick={() => toggleChip(chipName)}
              customColor={chips[chipName].customColor}
            >
              <span>{chipName}</span>
            </Chip>
          );
        })}
      </ChipsContainer>
    );
  };

  return (
    <>
      {showUploadScreenshotModal && (
        <GoodsItemUploadScreenshotModal
          onHide={toggleShowUploadScreenshotModal}
          show={showUploadScreenshotModal}
          url={goodDetails?.webScraping?.webUrl || ''}
          goodDetailsId={goodDetails?._id || ''}
          afterCreate={() => null}
        />
      )}
      <RiskAnalysisSection>
        <RiskAnalysisSectionHeader>
          <h4>Description</h4>
          <CheckboxContainer>
            <Checkbox
              name={'validatedDescriptionCheck'}
              checked={validatedDescriptionCheck}
              disabled={validatedDescriptionCheckDisabled}
              onChange={() => {
                setValidatedDescriptionCheck(!validatedDescriptionCheck);
                emptyDescription('');
              }}
              type={'checkbox'}
            />
            <p style={{ color: '#555454' }}>{VALIDATE_DESCRIPTION}</p>
          </CheckboxContainer>
        </RiskAnalysisSectionHeader>
        <RiskAnalysisSectionContent>
          <FormRow>
            <Input
              defaultBehaviour={true}
              compact={true}
              name="invoiceDescription"
              placeholder={INVOICE_DESCRIPTION}
              value={goodDetails.descriptionOfGoods || ''}
              width={50}
              widthUnit="%"
              disabled={true}
              message={webMatchingDescriptionStatus}
            />
            <ArrowContainer>
              <LeftArrowIcon style={{ transform: 'rotate(180deg)' }} />
              <LeftArrowIcon />
            </ArrowContainer>
            <Input
              defaultBehaviour={true}
              compact={true}
              name="webDescription"
              placeholder={WEB_DESCRIPTION_PLACEHOLDER}
              value={initialWebDescription}
              width={50}
              widthUnit="%"
              disabled={true}
              onPictureClick={downloadScreenshot}
              picture={pictureIcon}
              screenshotLink={screenshotLink}
              isShowPicture={true}
              isShowPictureDisabled={isShowPictureDisabled}
              webScrapingDone={webScrapingDone}
            />
          </FormRow>
          <FormRow>
            {goodDetails.commodity?.descriptionOfGoodsOther && (
              <RiskAnalysisTranslationContainer>
                <a
                  href={`https://www.google.com/search?tbm=isch&q=${goodDetails.commodity?.descriptionOfGoodsOther}`}
                  target="_blank"
                >
                  {goodDetails.commodity?.descriptionOfGoodsOther}
                </a>
                <img src={Icons.TranslationIcon} />
                {goodDetails.commodity?.descriptionOfGoodsOtherTranslated}
              </RiskAnalysisTranslationContainer>
            )}
          </FormRow>
          {!validatedDescriptionCheck && (
            <>
              <RiskAnalysisNewDescription
                style={
                  !goodDetails?.webScraping?.webUrl || !initialWebDescription ? { padding: 0, border: 'none' } : {}
                }
              >
                {goodDetails?.webScraping?.webUrl && (initialWebDescription || goodDetails.commodity?.material) && (
                  <FormRow style={{ flexDirection: 'column' }}>
                    <ChipHeaderText>{SELECT_WORDS_BELOW_TO_SET_THE_NEW_DESCRIPTION}</ChipHeaderText>
                    <RenderChips />
                  </FormRow>
                )}
                <FormRow>
                  <Input
                    defaultBehaviour={true}
                    compact={true}
                    name="newInvoiceDescription"
                    placeholder={SUGGESTED_INVOICE_DESCRIPTION}
                    value={newDescription}
                    onChange={(event) => setNewDescription(event.target.value)}
                    width={100}
                    widthUnit="%"
                  />
                </FormRow>
              </RiskAnalysisNewDescription>
              <FormRow style={{ justifyContent: 'flex-end' }}>
                <Button
                  primary
                  type="button"
                  style={{
                    height: 'auto',
                    padding: '10px 15px',
                    borderRadius: '5px',
                    fontWeight: '400',
                  }}
                  onClick={() => runAiModule()}
                >
                  Recheck HS Code
                </Button>
              </FormRow>
            </>
          )}
        </RiskAnalysisSectionContent>
      </RiskAnalysisSection>
    </>
  );
};
