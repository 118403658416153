import { useFormik } from 'formik';
import { FC } from 'react';

import { Button, Dropdown, Input, Modal } from '../';
import { FormContainer, FormRow, FormSection } from '../../../styles/common';
import { getRoleTypes } from '../../../utils';
import { IAdditionalFiscalReferenceItem } from '../../interfaces/declarant.interface';

const ROLE_LABEL = 'Role type';
const ROLE_PLACEHOLDER = 'Select role type';
const SAVE_BUTTON_TITLE = 'Save';
const VAT_IDENTIFICATION_NUMBER_PLACEHOLDER = 'Vat identification number';
const ADD_ADDITIONAL_FISCAL_REFERENCE = 'Add additional fiscal reference';
const EDIT_ADDITIONAL_FISCAL_REFERENCE = 'Edit additional fiscal reference';

const roleTypes = getRoleTypes();

interface IPaymentsFormModalProps {
  additionalFiscalReferenceEdit: IAdditionalFiscalReferenceItem | null;
  isShow: boolean;
  onHide(): void;
  onSave(updatedAdditionalFiscalReference: IAdditionalFiscalReferenceItem): void;
}

export const PaymentsFormModal: FC<IPaymentsFormModalProps> = (props) => {
  const { additionalFiscalReferenceEdit, isShow, onHide, onSave } = props;

  const formik = useFormik<IAdditionalFiscalReferenceItem>({
    initialValues: {
      role: additionalFiscalReferenceEdit?.role || '',
      vatIdentificationNumber: additionalFiscalReferenceEdit?.vatIdentificationNumber || '',
    },
    onSubmit: (values) => {
      const { role, vatIdentificationNumber } = values;

      const newAdditionalFiscalReference = {
        role,
        vatIdentificationNumber,
      };

      onSave(newAdditionalFiscalReference);
      onHide();
    },
    enableReinitialize: false,
  });

  const startFormSubmit = async () => {
    await formik.submitForm();
  };

  const roleValue = roleTypes.find((role) => {
    return role.value === formik.values.role;
  });

  const modalTitle = additionalFiscalReferenceEdit ? EDIT_ADDITIONAL_FISCAL_REFERENCE : ADD_ADDITIONAL_FISCAL_REFERENCE;

  return (
    <Modal
      title={modalTitle}
      show={isShow}
      onHide={() => onHide()}
      buttons={[
        <Button type="button" primary key="save-modal-btn" onClick={startFormSubmit}>
          {SAVE_BUTTON_TITLE}
        </Button>,
      ]}
      buttonsJustifyAlign="center"
    >
      <FormContainer onSubmit={formik.handleSubmit}>
        <FormSection verticalPadding={20} topPadding={40}>
          <FormRow>
            <Dropdown
              label={ROLE_LABEL}
              placeholder={ROLE_PLACEHOLDER}
              options={roleTypes}
              onChange={(selectedOption) => {
                formik.setFieldValue('role', selectedOption?.value);
              }}
              value={roleValue}
              width={100}
              widthUnit="%"
            />
          </FormRow>
          <FormRow>
            <Input
              name="vatIdentificationNumber"
              placeholder={VAT_IDENTIFICATION_NUMBER_PLACEHOLDER}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.vatIdentificationNumber}
              width={100}
              widthUnit="%"
            />
          </FormRow>
        </FormSection>
      </FormContainer>
    </Modal>
  );
};
