import { useEffect, useRef, useState } from 'react';

import { TransitDocumentsEnum } from '@e-origin/shared';

import Icons from '../../../../../assets';
import { Button, CustomModal, Dropdown } from '../../../../../shared/components';
/* eslint-disable complexity */
import { FormContainer, FormRow, FormSection } from '../../../../../styles/common';
import ModalStyle from '../../../../../styles/modal';
import { TransitDocumentFormFactory } from './transit-document-form-factory';

interface DocDetailsProps {
  type: TransitDocumentsEnum;
  dataSource: any;
  dataSourceIndex: number;
}
interface TransitDocumentModalProps {
  onHide: () => void;
  docDetails?: DocDetailsProps;
  editDisabled: boolean;
  disabledDocuments: TransitDocumentsEnum[];
  onSave: (savedDocument: any) => void;
  forGoodsItem?: boolean;
}

export const documentsLabels = {
  [TransitDocumentsEnum.PREVIOUS_DOCUMENT]: 'Previous document',
  [TransitDocumentsEnum.SUPPORTING_DOCUMENT]: 'Supporting document',
  [TransitDocumentsEnum.TRANSPORT_DOCUMENT]: 'Transport document',
  [TransitDocumentsEnum.ADDITIONAL_REFFERENCE]: 'Additional reference',
  [TransitDocumentsEnum.ADDITIONAL_INFORMATION]: 'Additional information',
};

export const TransitDocumentModal: React.FC<TransitDocumentModalProps> = ({
  onHide,
  docDetails,
  editDisabled = false,
  disabledDocuments = [],
  onSave,
  forGoodsItem = false,
}) => {
  const [documentType, setDocumentType] = useState<TransitDocumentsEnum>(null);

  const [documentData, setDocumentData] = useState<any>(undefined);

  const documentsTypesList = useRef(
    Object.keys(documentsLabels)
      .filter((docType) => !disabledDocuments.includes(docType as TransitDocumentsEnum))
      .map((docType) => ({ value: docType, label: documentsLabels[docType] })),
  );

  const handleDocumentChange = (data) => {
    setDocumentData({ ...data });
  };

  const onSubmit = () => {
    onSave({
      type: documentType,
      operation: documentData.operation,
      docIndex: docDetails?.dataSourceIndex,
      data: {
        ...documentData.data,
      },
    });
    onHide();
  };

  useEffect(() => {
    if (docDetails) {
      // initialObject.current = (props.docDetails.dataSource as any) || null;
      setDocumentType(docDetails.type);
      // setHeader(typeof props.docDetails.dataSource.header === 'boolean' ? props.docDetails.dataSource.header : true);
      setDocumentData(docDetails.dataSource);
    }
  }, []);

  return (
    <CustomModal show={true} onHide={() => onHide()}>
      <ModalStyle.Header>
        <ModalStyle.Title>{docDetails ? 'Edit document' : 'Add new document'}</ModalStyle.Title>
        <ModalStyle.HeaderActions>
          <button onClick={() => onHide()}>
            <img src={Icons.CloseIcon} alt="" />
          </button>
        </ModalStyle.HeaderActions>
      </ModalStyle.Header>
      <ModalStyle.Content style={documentType ? { overflow: 'auto' } : {}}>
        <FormContainer>
          <FormSection verticalPadding={20} topPadding={0}>
            <FormRow>
              <Dropdown
                placeholder="Type of document"
                options={documentsTypesList.current}
                onChange={(selectedOption) => setDocumentType(selectedOption?.value)}
                value={documentsTypesList.current.find((item) => item.value === documentType)}
                width={100}
                widthUnit="%"
                maxMenuHeight={150}
                disabled={
                  editDisabled ||
                  !!Object.keys(documentData?.id || {}).filter((key) => !!documentData?.id?.[key]).length
                }
              />
            </FormRow>
            {documentType && (
              <TransitDocumentFormFactory
                type={documentType}
                data={documentData}
                onChange={handleDocumentChange}
                editDisabled={editDisabled}
                forGoodsItem={forGoodsItem}
              />
            )}
          </FormSection>
        </FormContainer>
      </ModalStyle.Content>
      <ModalStyle.Footer>
        {!editDisabled && (
          <Button type="button" primary key="save-modal-btn" onClick={onSubmit}>
            Add
          </Button>
        )}
      </ModalStyle.Footer>
    </CustomModal>
  );
};
