/* eslint-disable no-unused-expressions */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
/* eslint-disable no-param-reassign */
import axios, { AxiosError } from 'axios';
import Cookies from 'js-cookie';

import { getConfig } from '../../config';
import { request } from '../../utils';
import { fetchGoodsLocation, setDeclarant } from './declarantSlice';
import { AppThunk, RootState } from './index';

interface IUserState {
  _id?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  error?: string;
  role?: {
    name?: string;
  };
}

interface IUserErrorAction {
  errorText?: IUserState['error'];
}

interface IUserResetPasswordAction {
  newPassword: string;
  newPasswordConfirmation: string;
  resetToken: string;
  successCallback(): void;
}

interface IUserCheckResetPasswordAction {
  resetToken: string;
  successCallback(): void;
}

const initialState: IUserState = {
  _id: undefined,
  email: undefined,
  firstName: undefined,
  lastName: undefined,
  error: undefined,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IUserState>) => {
      state.email = action.payload.email;
      state._id = action.payload._id;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.role = action.payload.role;
    },
    signOut: (state) => {
      state.email = undefined;
    },
    setError: (state, action: PayloadAction<IUserErrorAction>) => {
      state.error = action.payload.errorText || undefined;
    },
  },
});

export const { setUser, signOut, setError } = userSlice.actions;

export const getUserInfo =
  (navigationCallback?: () => void): AppThunk =>
  async (dispatch) => {
    try {
      const { data } = await request({
        path: `user/me`,
        method: 'GET',
        authenticate: true,
      });
      dispatch(setUser(data.user));
      if (data.declarant) {
        dispatch(setDeclarant(data.declarant));
        dispatch(fetchGoodsLocation());
      }
      navigationCallback && navigationCallback();
    } catch (error) {
      const axiosError = error as AxiosError;

      dispatch(setError({ errorText: axiosError.response?.data.message || 'Something went wrong' }));
    }
  };

export const signin =
  (email: string, password: string, navigationCallback: () => void, callBack: () => void): AppThunk =>
  // eslint-disable-next-line max-statements
  async (dispatch) => {
    try {
      const dataObject = { email, password };
      const config = await getConfig();
      const { data } = (await axios.post(`${config.API_URL}/user/login`, dataObject)) as any;
      // save the token
      if (data.token) {
        localStorage.setItem('user-email', email);
        Cookies.set('eorigin-declarations-token', data.token);
        callBack();
        dispatch(getUserInfo(navigationCallback));
      }
    } catch (error) {
      const axiosError = error as AxiosError;

      dispatch(setError({ errorText: axiosError.response?.data.message || 'Something went wrong' }));
    }
  };

export const signOutUser =
  (navigationCallback: () => void): AppThunk =>
  (dispatch) => {
    Cookies.set('eorigin-declarations-token', '');
    localStorage.removeItem('user-email');
    navigationCallback();
    dispatch(signOut());
  };

export const clearUserError = (): AppThunk => (dispatch) => {
  dispatch(setError({ errorText: undefined }));
};

export const forgotPassword =
  (email: string, handleEmailSubmit: () => void): AppThunk =>
  async (dispatch) => {
    try {
      const dataObject = { email };

      const config = await getConfig();
      await axios.post<string>(`${config.API_URL}/user/forgot-password`, dataObject);

      handleEmailSubmit();
    } catch (error) {
      const axiosError = error as AxiosError;

      dispatch(setError({ errorText: axiosError.response?.data.message || 'Something went wrong' }));
    }
  };

export const resetPassword =
  ({ newPassword, newPasswordConfirmation, resetToken, successCallback }: IUserResetPasswordAction): AppThunk =>
  async (dispatch) => {
    try {
      const dataObject = { newPassword, newPasswordConfirmation, resetToken };
      const config = await getConfig();

      await axios.post<string>(`${config.API_URL}/user/reset-password`, dataObject);

      successCallback();
    } catch (error) {
      const axiosError = error as AxiosError;

      dispatch(setError({ errorText: axiosError.response?.data.message || 'Something went wrong' }));
    }
  };

export const checkResetPassword =
  ({ resetToken, successCallback }: IUserCheckResetPasswordAction): AppThunk =>
  async (dispatch) => {
    try {
      const dataObject = { resetToken };

      const config = await getConfig();
      await axios.post<string>(`${config.API_URL}/user/check-reset-password`, dataObject);

      successCallback();
    } catch (error) {
      const axiosError = error as AxiosError;

      dispatch(setError({ errorText: axiosError.response?.data.message || 'Something went wrong' }));
    }
  };

export const refreshToken = (): AppThunk => async (dispatch) => {
  try {
    const email = localStorage.getItem('user-email');

    const { refreshedToken } = await request({
      path: 'user/refresh-token',
      method: 'POST',
      authenticate: false,
      dataObject: { email },
    });

    Cookies.set('eorigin-declarations-token', refreshedToken);
  } catch (error) {
    const axiosError = error as AxiosError;

    dispatch(setError({ errorText: axiosError.response?.data.message || 'Something went wrong' }));
  }
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectUser = (state: RootState) => state.user;

export const selectUserRole = (state: RootState) => state.user.role?.name;

export const selectUserDisplayName = (state: RootState) => {
  if (state.user.firstName || state.user.lastName) {
    return `${state.user.firstName} ${state.user.lastName}`.trim();
  }
  return state.user.email;
};

export const selectUserError = (state: RootState) => state.user.error;

export default userSlice.reducer;
