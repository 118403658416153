import sendIcon from '../../assets/icons/action-icon.svg';
import anomaliesHandledIcon from '../../assets/icons/anomalies-handled-icon.svg';
import anomaliesHSCodeOrDescriptionIcon from '../../assets/icons/anomaly-hs-code-icon.svg';
import anomalyValueIcon from '../../assets/icons/anomaly-value-icon.svg';
import archiveIcon from '../../assets/icons/archive-icon.svg';
import actionsIcon from '../../assets/icons/contextual-icon.svg';
import customsProcessingIcon from '../../assets/icons/customs-processing-icon.svg';
import customsSyncIcon from '../../assets/icons/customs-sync-icon.svg';
import downloadIcon from '../../assets/icons/download-icon.svg';
import greenRejectedIcon from '../../assets/icons/green-rejected-icon.svg';
import lightGreenSuccessIcon from '../../assets/icons/light-green.svg';
import mailIcon from '../../assets/icons/mail.svg';
import noSentAnomaliesIcon from '../../assets/icons/not-sent-no-anomalies-icon.svg';
import viewImageIcon from '../../assets/icons/picture.svg';
import refactorIcon from '../../assets/icons/refactor.svg';
import reportIcon from '../../assets/icons/report-icon.svg';
import riskAnalysisReportIcon from '../../assets/icons/risk-analysis-report-icon.svg';
import sendRiskAnalysisToCustomerIcon from '../../assets/icons/send-risk-analysis-to-customer-icon.svg';
import sendingIcon from '../../assets/icons/sending-icon.svg';
import failedIcon from '../../assets/icons/status-failed-icon.svg';
import invalidatedIcon from '../../assets/icons/status-invalidated.svg';
import notReleasedIcon from '../../assets/icons/status-not-released-icon.svg';
import notSentIcon from '../../assets/icons/status-not-sent-icon.svg';
import processingIcon from '../../assets/icons/status-processing-icon.svg';
import rejectedIcon from '../../assets/icons/status-rejected-icon.svg';
import releasedIcon from '../../assets/icons/status-released-icon.svg';
import deleteIcon from '../../assets/icons/trash-icon.svg';
import updateIcon from '../../assets/icons/update-icon.svg';
import uploadingIcon from '../../assets/icons/upload-icon.svg';
import viewIcon from '../../assets/icons/view-icon.svg';

export enum HelpSectionEnum {
  BATCH_STATUS = 'Batch status',
  DECLARATION_STATUS = 'Declaration status',
  GOOD_ITEMS_STATUS = 'Good items status',
  ACTIONS = 'Batch/ Declaration actions',
  TRANSIT_STATUS = 'Transit status',
}

export interface HelpCategoryItem {
  icon?: string;
  text: string;
  animationType?: string;
}

export interface HelpSubsection {
  data: HelpCategoryItem[];
  title: string;
}

export interface HelpDataConfig {
  data?: HelpCategoryItem[];
  subsections?: HelpSubsection[];
  fullWidth?: boolean;
  flexRow?: boolean;
}

export const helpData: Record<string, HelpDataConfig> = {
  [HelpSectionEnum.BATCH_STATUS]: {
    data: [
      { icon: uploadingIcon, text: 'Uploading file, saving declaration and risk analysis. Updating batch' },
      { icon: anomaliesHandledIcon, text: 'All anomalies handled with proposal' },
      { icon: sendingIcon, text: 'Sending' },
      { icon: failedIcon, text: 'Invalid file' },
      { icon: customsProcessingIcon, text: 'Validation in progress (waiting for customer answer)' },
      { icon: releasedIcon, text: 'Declarations released' },
      { icon: anomaliesHSCodeOrDescriptionIcon, text: 'Anomalie(s) detected: HS codes and/or description' },
      { icon: noSentAnomaliesIcon, text: 'Not sent, risk analysis done, no anomalies detected' },
      { icon: rejectedIcon, text: 'Declarations rejected' },
      { icon: anomalyValueIcon, text: 'Anomalie(s) detected: value' },
      { icon: notSentIcon, text: 'Not sent' },
      { icon: notReleasedIcon, text: 'Declarations not-released' },
      { icon: invalidatedIcon, text: 'Declarations invalidated' },
      { icon: processingIcon, text: 'Validation in progress (waiting for customs answer)' },
      { animationType: 'syncing', text: 'Customs notifications synchronization' },
    ],
    fullWidth: true,
    flexRow: true,
  },
  [HelpSectionEnum.DECLARATION_STATUS]: {
    data: [
      { icon: notSentIcon, text: 'No anomalies - No risk analysis done' },
      { icon: anomaliesHSCodeOrDescriptionIcon, text: 'Anomalie(s) detected: HS codes and/or description' },
      { icon: anomalyValueIcon, text: 'Anomalie(s) detected: value' },
      { icon: anomaliesHandledIcon, text: 'Anomalies detected, all handled by user' },
      { icon: noSentAnomaliesIcon, text: 'No anomalies detected' },
    ],
  },
  [HelpSectionEnum.GOOD_ITEMS_STATUS]: {
    data: [
      { icon: notSentIcon, text: 'No anomalies - No risk analysis' },
      { icon: anomaliesHSCodeOrDescriptionIcon, text: 'Anomalie(s) detected: HS codes and/or description' },
      { icon: anomalyValueIcon, text: 'Anomalie(s) detected: value' },
      { icon: anomaliesHandledIcon, text: 'Anomalies detected, no action required' },
      { icon: noSentAnomaliesIcon, text: 'No anomalies detected' },
    ],
  },
  [HelpSectionEnum.ACTIONS]: {
    data: [
      { icon: sendIcon, text: 'Sent' },
      { icon: actionsIcon, text: 'Batch/ Declaration actions' },
      { icon: deleteIcon, text: 'Delete' },
      { icon: archiveIcon, text: 'Archive/Unarchive' },
      { icon: viewIcon, text: 'View details' },
      { icon: mailIcon, text: 'Send by email' },
      { icon: updateIcon, text: 'Update' },
      { icon: downloadIcon, text: 'Download file' },
      { icon: reportIcon, text: 'Generate customs report' },
      { icon: refactorIcon, text: 'Edit' },
      { icon: viewImageIcon, text: 'View image' },
      { icon: customsSyncIcon, text: 'Customs sync' },
      { icon: riskAnalysisReportIcon, text: 'Risk analysis report' },
      { icon: sendRiskAnalysisToCustomerIcon, text: 'Send risk analysis to customer' },
    ],
    fullWidth: true,
    flexRow: true,
  },
  [HelpSectionEnum.TRANSIT_STATUS]: {
    subsections: [
      {
        title: 'Departure',
        data: [
          { icon: notSentIcon, text: 'Not sent' },
          { icon: sendingIcon, text: 'Sending' },
          { icon: sendIcon, text: 'Sent' },
          { icon: processingIcon, text: 'Accepted' },
          { icon: rejectedIcon, text: 'Rejected' },
          { icon: processingIcon, text: 'In control' },
          { icon: processingIcon, text: 'Corr refused waiting' },
          { icon: notReleasedIcon, text: 'No release for transit' },
          { icon: processingIcon, text: 'Corr ok waiting' },
          { icon: processingIcon, text: 'Invalidation pending' },
          { icon: invalidatedIcon, text: 'Transits invalidated' },
          { icon: notReleasedIcon, text: 'Guarantee not valid' },
          { icon: lightGreenSuccessIcon, text: 'Unload permission' },
          { icon: lightGreenSuccessIcon, text: 'Released' },
          { icon: processingIcon, text: 'Remarks sent' },
          { icon: releasedIcon, text: 'Finished' },
          { icon: greenRejectedIcon, text: 'Discrepancies' },
          { icon: anomalyValueIcon, text: 'Requested non arrived' },
        ],
      },
      {
        title: 'Arrival',
        data: [
          { icon: notSentIcon, text: 'Not sent' },
          { icon: processingIcon, text: 'Sent' },
          { icon: processingIcon, text: 'Accepted' },
          { icon: processingIcon, text: 'Remarks sent' },
          { icon: sendingIcon, text: 'Sending' },
          { icon: rejectedIcon, text: 'Rejected' },
          { icon: lightGreenSuccessIcon, text: 'Unload permission received' },
          { icon: releasedIcon, text: 'Released' },
        ],
      },
    ],
    fullWidth: true,
    flexRow: true,
  },
};
