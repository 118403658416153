import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectDetails } from '../../../../../../shared/stores/declarationsSlice';
import * as Style from './declaration-details-customs-proof.style';

interface IDeclarationDetailsCustoms {
  status?: string;
}

export const DeclarationDetailsCustomsProof: React.FC<IDeclarationDetailsCustoms> = () => {
  const declarationDetails = useSelector(selectDetails);
  const [proof, setProof] = useState(null);

  useEffect(() => {
    if (declarationDetails?.customsProof?.decodedProof) {
      setProof(JSON.stringify(declarationDetails?.customsProof?.decodedProof, null, 2));
    }
  }, [declarationDetails]);

  return (
    <Style.Container>
      <Style.Title>Proof</Style.Title>
      {proof ? (
        <Style.ContentWrapper>
          <Style.Content>{proof}</Style.Content>
        </Style.ContentWrapper>
      ) : (
        <p>No proof generated!</p>
      )}
    </Style.Container>
  );
};
