/* eslint-disable complexity */
import { FormikProps, getIn } from 'formik';

import { Customer } from '@e-origin/shared';

import { ReactComponent as ActiveIcon } from '../../../../../../assets/icons/active-icon.svg';
import { ReactComponent as InactiveIcon } from '../../../../../../assets/icons/inactive-icon.svg';
import { Input, Tooltip } from '../../../../../../shared/components';
// todo: do not the style from the components
import { Error, FormSection } from '../../../../../../styles/common';
import { toDecimal } from '../../../../../../utils';
import * as GeneralStyle from '../evaluation-methods.styles';

interface EvaluationCalculatorProps {
  form: FormikProps<Customer>;
}

export const EvaluationCalculator: React.FC<EvaluationCalculatorProps> = ({ form }) => {
  const { handleBlur, setFieldValue, errors, touched } = form;

  const { evaluationMethods } = form.values;

  const globalError = getIn(errors, 'evaluationMethods.evalMethodCalculator.globalError');

  const marketplaceFeesError = getIn(errors, 'evaluationMethods.evalMethodCalculator.marketplaceFees');

  const marketplaceFeesTouched = getIn(touched, 'evaluationMethods.evalMethodCalculator.marketplaceFees');

  const renderActiveMethodButton = (activeMethod: boolean, fieldName: string) => (
    <button onClick={() => setFieldValue(fieldName, !activeMethod)}>
      {activeMethod ? <ActiveIcon /> : <InactiveIcon />}
    </button>
  );

  return (
    <GeneralStyle.EvaluationMethodContainer
      hasError={!!globalError}
      activeMethod={evaluationMethods.evalMethodCalculator?.activateMethod || false}
    >
      <GeneralStyle.EvaluationMethodContainerHeader>
        <GeneralStyle.EvaluationMethodContainerHeaderBody>
          <GeneralStyle.EvaluationMethodTitle>EvalMethod Calculator</GeneralStyle.EvaluationMethodTitle>
        </GeneralStyle.EvaluationMethodContainerHeaderBody>
        {renderActiveMethodButton(
          evaluationMethods.evalMethodCalculator?.activateMethod || false,
          'evaluationMethods.evalMethodCalculator.activateMethod',
        )}
      </GeneralStyle.EvaluationMethodContainerHeader>
      <FormSection topPadding={25} paddingBottom={0}>
        <GeneralStyle.EvaluationMethodFormRow>
          <Input
            name="evaluationMethods.evalMethodCalculator.marketplaceFees"
            placeholder="MarketplaceFees (%)"
            onChange={(event) => {
              setFieldValue('evaluationMethods.evalMethodCalculator.marketplaceFees', Number(event.target.value) / 100);
            }}
            onBlur={handleBlur}
            value={toDecimal(Number(evaluationMethods.evalMethodCalculator?.marketplaceFees) * 100)}
            width={100}
            widthUnit={'%'}
            type={'number'}
            invalid={marketplaceFeesTouched && !!marketplaceFeesError}
          />
          <Tooltip content="MarketplaceFees (%) = Referral Fee + Fixed closing fee + Variable closing fee" />
        </GeneralStyle.EvaluationMethodFormRow>
        {!!marketplaceFeesError && <Error>{marketplaceFeesError}</Error>}
      </FormSection>
      <FormSection noTopPadding paddingBottom={0}>
        <GeneralStyle.EvaluationMethodFormRow>
          <Input
            name="evaluationMethods.evalMethodCalculator.fullfillmentCost"
            placeholder="Fullfillment cost (€/Unit)"
            onChange={(event) => {
              setFieldValue('evaluationMethods.evalMethodCalculator.fullfillmentCost', Number(event.target.value));
            }}
            onBlur={handleBlur}
            value={Number(evaluationMethods.evalMethodCalculator?.fullfillmentCost)}
            width={100}
            widthUnit={'%'}
            type={'number'}
            // invalid={marketplaceFeesTouched && !!marketplaceFeesError}
          />
          <Tooltip content="Fullfillment cost (€/Unit) = Last mile shipment costs to consumer + Packaging fee for shipment" />
        </GeneralStyle.EvaluationMethodFormRow>
        {/* {!!marketplaceFeesError && (
          <ErrorsWrapper>
            <Error>{marketplaceFeesError}</Error>
          </ErrorsWrapper>
        )} */}
      </FormSection>
      <FormSection noTopPadding paddingBottom={0}>
        <GeneralStyle.EvaluationMethodFormRow>
          <Input
            name="evaluationMethods.evalMethodCalculator.storageCost"
            placeholder="Storage costs (€/Unit)"
            onChange={(event) => {
              setFieldValue('evaluationMethods.evalMethodCalculator.storageCost', Number(event.target.value));
            }}
            onBlur={handleBlur}
            value={Number(evaluationMethods.evalMethodCalculator?.storageCost)}
            width={100}
            widthUnit={'%'}
            type={'number'}
            // invalid={marketplaceFeesTouched && !!marketplaceFeesError}
          />
          <Tooltip content="Storage costs (€/Unit) = 0.06 * Average Inventory units stored / Estimated monthly units stored" />
        </GeneralStyle.EvaluationMethodFormRow>
        {/* {!!marketplaceFeesError && (
          <ErrorsWrapper>
            <Error>{marketplaceFeesError}</Error>
          </ErrorsWrapper>
        )} */}
      </FormSection>
      <FormSection noTopPadding paddingBottom={30}>
        <GeneralStyle.EvaluationMethodFormRow>
          <Input
            name="evaluationMethods.evalMethodCalculator.otherCost"
            placeholder="Other costs ( % )"
            onChange={(event) => {
              setFieldValue('evaluationMethods.evalMethodCalculator.otherCost', Number(event.target.value) / 100);
            }}
            onBlur={handleBlur}
            value={toDecimal(Number(evaluationMethods.evalMethodCalculator?.otherCost) * 100)}
            width={100}
            widthUnit={'%'}
            type={'number'}
            // invalid={marketplaceFeesTouched && !!marketplaceFeesError}
          />
          <Tooltip content="Other costs ( % ) = Costs of goods sold + Miscellaneous costs" />
        </GeneralStyle.EvaluationMethodFormRow>
        {/* {!!marketplaceFeesError && (
          <ErrorsWrapper>
            <Error>{marketplaceFeesError}</Error>
          </ErrorsWrapper>
        )} */}
      </FormSection>
      {!!globalError && <Error>{globalError}</Error>}
    </GeneralStyle.EvaluationMethodContainer>
  );
};
