import { useState } from 'react';
import { IDataTableProps } from 'react-data-table-component';

/* eslint-disable max-statements */
import { CustomerPayments, Declarant, DeclarantPayments } from '@e-origin/shared';

import { AdditionalFiscalReferenceColumns } from '../../enums';
import { getMethodOfPaymentTypes } from '../../utils';
import { TableActionButtons, TableActions } from '../components';
import { IAdditionalFiscalReferenceItem } from '../interfaces/declarant.interface';
import { IDropDownOption } from '../interfaces/dropdown-option.interface';

type SelectedReferenceType = IAdditionalFiscalReferenceItem | null;
type PaymentColumnsType = IDataTableProps['columns'];

interface IUsePaymentsProps {
  handleUpdate(updatedData: CustomerPayments | DeclarantPayments | Partial<Declarant>): void;
  isCustomerFlow: boolean;
  methodOfPayment: string | null;
  methodOfPaymentVAT: string | null;
  payments: CustomerPayments | DeclarantPayments | undefined;
}

interface IUsePaymentsReturn {
  columns: PaymentColumnsType;
  handleAdditionalFiscalReferenceDelete(): void;
  handleSavingAdditionalFiscalReference(updatedAdditionalFiscalReference: IAdditionalFiscalReferenceItem): void;
  isAddNewAdditionalFiscalReferenceDisable: boolean;
  methodOfPaymentValue: IDropDownOption | undefined;
  methodOfPaymentVATValue: IDropDownOption | undefined;
  selectedReference: SelectedReferenceType;
  showAdditionalFiscalReferenceDeleteNotificationModal: boolean;
  showModal: boolean;
  toggleDeleteAdditionalFiscalReferenceNotificationModal(): void;
  toggleShowReferenceModal(): void;
}

const methodOfPaymentTypes = getMethodOfPaymentTypes();

const usePayments = (props: IUsePaymentsProps): IUsePaymentsReturn => {
  const { handleUpdate, isCustomerFlow, methodOfPayment, methodOfPaymentVAT, payments } = props;

  const [showModal, setShowModal] = useState(false);
  const [selectedReference, setSelectedReference] = useState<SelectedReferenceType>(null);
  const [
    showAdditionalFiscalReferenceDeleteNotificationModal,
    setShowAdditionalFiscalReferenceDeleteNotificationModal,
  ] = useState(false);

  const handleAdditionalFiscalReferenceDelete = () => {
    const initialUpdatedData = {
      ...payments,
      additionalFiscalReference: [],
    };

    const updatedData = (isCustomerFlow ? initialUpdatedData : { payments: initialUpdatedData }) as
      | CustomerPayments
      | Partial<Declarant>;

    handleUpdate(updatedData);
  };

  const toggleShowReferenceModal = () => {
    setShowModal(!showModal);

    if (!showModal) {
      setSelectedReference(null);
    }
  };

  const toggleDeleteAdditionalFiscalReferenceNotificationModal = () => {
    setShowAdditionalFiscalReferenceDeleteNotificationModal(!showAdditionalFiscalReferenceDeleteNotificationModal);
  };

  const handleTableAction = (actionType: string, row: IAdditionalFiscalReferenceItem) => {
    if (actionType === 'view') {
      setSelectedReference(row);
      setShowModal(true);
    }

    if (actionType === 'delete') {
      toggleDeleteAdditionalFiscalReferenceNotificationModal();
    }
  };

  const columns: IDataTableProps['columns'] = [
    {
      name: AdditionalFiscalReferenceColumns.ROLE,
      selector: 'role',
      sortable: false,
    },
    {
      name: AdditionalFiscalReferenceColumns.VAT_IDENTIFICATION_NUMBER,
      selector: 'vatIdentificationNumber',
      sortable: false,
    },
    {
      name: AdditionalFiscalReferenceColumns.ACTIONS,
      right: true,
      cell: (row: IAdditionalFiscalReferenceItem) => (
        <TableActionButtons
          actions={[TableActions.Edit, TableActions.Delete]}
          noSpaceBetweenButtons
          onClick={(actionType) => {
            handleTableAction(actionType, row);
          }}
        />
      ),
    },
  ];

  const handleSavingAdditionalFiscalReference = (updatedAdditionalFiscalReference: IAdditionalFiscalReferenceItem) => {
    const newAdditionalFiscalReference = {
      role: updatedAdditionalFiscalReference.role,
      vatIdentificationNumber: updatedAdditionalFiscalReference.vatIdentificationNumber,
    };

    const initialUpdatedData = {
      ...payments,
      additionalFiscalReference: [newAdditionalFiscalReference],
    };

    const updatedData = (isCustomerFlow ? initialUpdatedData : { payments: initialUpdatedData }) as
      | CustomerPayments
      | Partial<Declarant>;

    handleUpdate(updatedData);
  };

  const methodOfPaymentValue = methodOfPaymentTypes.find((methodOfPaymentType) => {
    return methodOfPaymentType.value === methodOfPayment;
  });

  const methodOfPaymentVATValue = methodOfPaymentTypes.find((methodOfPaymentType) => {
    return methodOfPaymentType.value === methodOfPaymentVAT;
  });

  const isAddNewAdditionalFiscalReferenceDisable = (payments?.additionalFiscalReference?.length || []) >= 1;

  return {
    columns,
    handleAdditionalFiscalReferenceDelete,
    handleSavingAdditionalFiscalReference,
    isAddNewAdditionalFiscalReferenceDisable,
    methodOfPaymentValue,
    methodOfPaymentVATValue,
    selectedReference,
    showAdditionalFiscalReferenceDeleteNotificationModal,
    showModal,
    toggleDeleteAdditionalFiscalReferenceNotificationModal,
    toggleShowReferenceModal,
  };
};

export default usePayments;
