import { FormikProps } from 'formik';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Declarant, Declaration, EnvironmentCountries } from '@e-origin/shared';

import { Autocomplete, Input } from '../../../../shared/components';
import { useConfig } from '../../../../shared/hooks/use-config';
import { selectDeclarant } from '../../../../shared/stores/declarantSlice';
import { retrieveCodes } from '../../../../shared/stores/settingsSlice';
import { FormRow, FormSection, FormSectionTitle } from '../../../../styles/common';

interface IDeclarantFormProps {
  declarationDetails: Declaration;
  formik: FormikProps<any>;
  editDisabled: boolean;
}

// eslint-disable-next-line complexity
export const DeclarantForm: React.FC<IDeclarantFormProps> = ({ declarationDetails, formik, editDisabled }) => {
  const declarant = useSelector(selectDeclarant);

  const { config } = useConfig();

  const isHighValue = declarationDetails?.generalInfo?.group === 'H1';
  const isHighValueNL = config?.COUNTRY === EnvironmentCountries.NL && isHighValue;
  const isHighValueBE = config?.COUNTRY === EnvironmentCountries.BE && isHighValue;

  const declarationDeclarant = useMemo((): Declarant => {
    if (isHighValueNL) {
      return formik.values.stakeholders.declarant;
    }

    if (config?.COUNTRY === EnvironmentCountries.NL) {
      return declarationDetails?.declarant;
    }

    return declarationDetails?.customsReport?.declarant || declarationDetails?.customer?.declarant;
  }, [formik.values.stakeholders.declarant, config?.COUNTRY, declarationDetails, isHighValueNL]);

  if (!config || !declarationDeclarant) {
    return null;
  }

  return (
    <FormSection verticalPadding={20} topPadding={0} paddingBottom={0}>
      <FormSectionTitle spaceBetween>Declarant</FormSectionTitle>
      <FormRow>
        <Input
          name="stakeholders.declarant.name"
          placeholder="Name"
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          value={declarationDeclarant.name}
          width={33}
          widthUnit="%"
          disabled={!isHighValueNL || editDisabled}
          defaultBehaviour
        />
        <Input
          name="stakeholders.declarant.identificationNumber"
          placeholder="Identification number"
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          value={declarationDeclarant.identificationNumber || declarationDeclarant.representative?.identificationNumber}
          width={33}
          widthUnit="%"
          disabled={!isHighValueNL || editDisabled}
          defaultBehaviour
        />
        {isHighValueBE && (
          <Input
            name="declarationdetails.customer.declarant.identificationNumberIDMS"
            placeholder="Identification number IDMS"
            value={
              declarationDetails.customsReport?.declarant?.identificationNumber ||
              declarationDetails.customer.declarant.identificationNumberIDMS
            }
            width={33}
            widthUnit="%"
            disabled
            defaultBehaviour
          />
        )}
      </FormRow>
      <FormRow>
        <Input
          name="stakeholders.declarant.address.street"
          placeholder="Street and number"
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          value={declarationDeclarant.address?.street}
          width={40}
          widthUnit="%"
          disabled={!isHighValueNL || editDisabled}
          defaultBehaviour
        />
        <Input
          name="stakeholders.declarant.address.postcode"
          placeholder="Postcode"
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          value={declarationDeclarant.address?.postcode}
          width={30}
          widthUnit="%"
          disabled={!isHighValueNL || editDisabled}
          defaultBehaviour
        />
        <Input
          name="stakeholders.declarant.address.city"
          placeholder="City"
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          value={declarationDeclarant.address?.city}
          width={30}
          widthUnit="%"
          disabled={!isHighValueNL || editDisabled}
          defaultBehaviour
        />
      </FormRow>
      <FormRow>
        {isHighValueNL ? (
          <Autocomplete
            width={30}
            widthUnit="%"
            placeholder="Country"
            fetchOptions={(search) => retrieveCodes('CL199', search, declarant.language)}
            onChange={(selectedOption) =>
              formik.setFieldValue('stakeholders.declarant.address.country', selectedOption?.value)
            }
            value={{
              value: formik.values.stakeholders.declarant.address.country,
              label: formik.values.stakeholders.declarant.address.country,
            }}
            disabled={editDisabled}
          />
        ) : (
          <Input disabled placeholder="Country" value={declarationDeclarant.address?.country} />
        )}
      </FormRow>
      <FormSection>
        <FormSectionTitle spaceBetween>Declarant Contact Person</FormSectionTitle>
        <FormRow>
          <Input
            name="stakeholders.declarant.contactPerson.name"
            placeholder="Name"
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            value={declarationDeclarant.contactPerson?.name}
            width={33}
            widthUnit="%"
            disabled={!isHighValueNL || editDisabled}
          />
          <Input
            name="stakeholders.declarant.contactPerson.phoneNumber"
            placeholder="Phone number"
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            value={declarationDeclarant.contactPerson?.phoneNumber}
            width={33}
            widthUnit="%"
            disabled={!isHighValueNL || editDisabled}
          />
          <Input
            name="declarant.contactPerson.email"
            placeholder="Email"
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            value={declarationDeclarant.contactPerson?.email}
            width={33}
            widthUnit="%"
            disabled={!isHighValueNL || editDisabled}
          />
        </FormRow>
      </FormSection>
    </FormSection>
  );
};

export default DeclarantForm;
