import { FormikProps } from 'formik';
import moment from 'moment';
import { useEffect, useRef } from 'react';

import { DatePicker, Dropdown, Input } from '../../../../../../../shared/components';
import { IDropDownOption } from '../../../../../../../shared/interfaces/dropdown-option.interface';
import { FormContainer, FormRow, FormSection } from '../../../../../../../styles/common';

interface SummaryTabInputs {
  field: string;
  label: string;
  disabled: boolean;
  type: 'input' | 'datepicker' | 'dropdown';
  options?: IDropDownOption[];
}

interface ISummaryTabProps {
  form: FormikProps<any>;
  editDisabled: boolean;
  stateOfSealsRequired: boolean;
}

export const SummaryTab: React.FC<ISummaryTabProps> = (props) => {
  const { form, editDisabled, stateOfSealsRequired } = props;

  const computeInputs = (): SummaryTabInputs[] => [
    {
      field: 'conform',
      label: 'Conform',
      disabled: true,
      type: 'dropdown',
      options: [
        {
          value: '0',
          label: 'No',
        },
        {
          value: '1',
          label: 'Yes',
        },
      ],
    },
    {
      field: 'unloadingCompletion',
      label: 'Unloading completion',
      disabled: editDisabled,
      type: 'dropdown',
      options: [
        {
          value: '0',
          label: 'No',
        },
        {
          value: '1',
          label: 'Yes',
        },
      ],
    },
    {
      field: 'unloadingDate',
      label: 'Unloading date',
      disabled: editDisabled,
      type: 'datepicker',
    },
    {
      field: 'stateOfSeals',
      label: 'State of seals',
      disabled: editDisabled || !stateOfSealsRequired,
      type: 'dropdown',
      options: [
        {
          value: '',
          label: '',
        },
        {
          value: '0',
          label: '0 - NOT OK (missing, damaged, discrepancies)',
        },
        {
          value: '1',
          label: '1 - OK',
        },
      ],
    },
    {
      field: 'unloadingRemark',
      label: 'Unloading remark',
      disabled: editDisabled,
      type: 'input',
    },
  ];

  const inputs = useRef<SummaryTabInputs[]>(computeInputs());

  useEffect(() => {
    inputs.current = computeInputs();
  }, [editDisabled, stateOfSealsRequired]);

  const handleChange = (e) => {
    form.setFieldValue(e.target.name, e.target.value);
    if (e.target.name === 'UnloadingRemark.stateOfSeals') {
      form.setFieldValue(
        'UnloadingRemark.conform',
        Number(e.target.value !== '0' && !form.getFieldProps('trace').value?.length).toString(),
      );
    }
  };

  return (
    <FormContainer>
      <FormSection topPadding={8} paddingBottom={16}>
        {inputs.current.map((input) => (
          <FormRow
            key={`RowUnloadingRemark.${input.field}`}
            style={{ alignItems: 'center', justifyContent: 'space-between', width: '500px' }}
          >
            <label style={{ marginRight: '16px' }}>{input.label}</label>
            {input.type === 'input' && (
              <Input
                name={`UnloadingRemark.${input.field}`}
                onChange={handleChange}
                value={form.getFieldProps(`UnloadingRemark.${input.field}`).value}
                width={280}
                widthUnit="px"
                disabled={input.disabled}
              />
            )}
            {input.type === 'datepicker' && (
              <DatePicker
                name={`UnloadingRemark.${input.field}`}
                placeholder=""
                value={
                  form.getFieldProps(`UnloadingRemark.${input.field}`).value
                    ? new Date(form.getFieldProps(`UnloadingRemark.${input.field}`).value)
                    : new Date()
                }
                onChange={(value) =>
                  handleChange({
                    target: {
                      name: `UnloadingRemark.${input.field}`,
                      value: `${moment(value).format('YYYY-MM-DD')}T00:00:00`,
                    },
                  })
                }
                width={280}
                widthUnit="px"
                disabled={input.disabled}
                hideClearButton={true}
              />
            )}
            {input.type === 'dropdown' && (
              <Dropdown
                placeholder=""
                options={input.options}
                onChange={(selectedOption) =>
                  handleChange({
                    target: {
                      name: `UnloadingRemark.${input.field}`,
                      value: selectedOption?.value,
                    },
                  })
                }
                value={(input.options as any).find(
                  (item) => item.value === form.getFieldProps(`UnloadingRemark.${input.field}`).value,
                )}
                width={280}
                widthUnit="px"
                maxMenuHeight={150}
                disabled={input.disabled}
              />
            )}
          </FormRow>
        ))}
      </FormSection>
    </FormContainer>
  );
};
