/* eslint-disable max-statements */
import { useFormik } from 'formik';
import { cloneDeep, omit } from 'lodash';
import { useState } from 'react';

import { Declaration } from '@e-origin/shared';

import { Button, Spinner, Tab, Tabs } from '../../../../../shared/components';
import { saveFinalizeDataToAllDeclarations } from '../../../../../shared/stores/batchesSlice';
import ModalStyle from '../../../../../styles/modal';
import { beforeSendingBatchFormIntializer, IBeforeSendingFormData } from './before-sending-batch-form-initializer';
import * as Style from './before-sending-batch.style';
import { FinalizeCommodityCodes } from './commodity-codes/finalize-commodity-codes';
import { FinalizeCustomsTreatment } from './customs-treatment/finalize-customs-treatment';
import { FinalizeDeclarationDocuments } from './declaration-documents/finalize-declaration-documents';
import { FinalizeGlobalParameter } from './finalize-global-parameter/finalize-global-parameter';
import { FinalizeProcedure } from './procedure/finalize-procedure.component';
import { FinalizeTransport } from './transport/finalize-transport';

interface IBeforeSendingBatchProps {
  declaration: Declaration;
  onHide(): void;
  disabled: boolean;
}

export const BeforeSendingBatch: React.FC<IBeforeSendingBatchProps> = ({ declaration, onHide, disabled }) => {
  const [isSaving, setIsSaving] = useState(false);

  const form = useFormik<IBeforeSendingFormData>({
    initialValues: beforeSendingBatchFormIntializer(declaration),
    onSubmit: async (values) => {
      setIsSaving(true);

      const partialDeclaration = cloneDeep(omit(values, ['commodityCodes', 'declarationGoods']));
      if (partialDeclaration.transportInformation?.transportEquipment?.seals?.length) {
        partialDeclaration.transportInformation.transportEquipment.seals =
          partialDeclaration.transportInformation.transportEquipment.seals.filter((s) => !!s.identificationNumber);
      }

      if (partialDeclaration.transportInformation?.transportEquipment?.containers.length) {
        partialDeclaration.transportInformation.transportEquipment.containers =
          partialDeclaration.transportInformation.transportEquipment.containers.filter((s) => !!s.identificationNumber);
      }

      if (partialDeclaration.transportInformation?.countryOfRoutingOfConsignment?.length) {
        partialDeclaration.transportInformation.countryOfRoutingOfConsignment =
          partialDeclaration.transportInformation.countryOfRoutingOfConsignment.filter((c) => !!c.country);
      }

      await saveFinalizeDataToAllDeclarations(
        declaration.messageInfo.batch,
        partialDeclaration,
        values.declarationGoods,
        values.commodityCodes.map((c) => ({
          ...c,
          additionalReference: (c.additionalReference || []).filter((sd) => sd.selected).map((sd) => sd.document),
        })),
      );
      onHide();
      setIsSaving(false);
    },
  });

  const handleSubmit = async () => {
    if (!isSaving) {
      await form.submitForm();
    }
  };

  return (
    <Style.Container>
      <Tabs style={{ margin: '0 15px 15px 15px' }} contentStyle={{ padding: '0 50px', overflow: 'auto' }}>
        <Tab label="Global Parameter">
          <FinalizeGlobalParameter form={form} declaration={declaration} disabled={disabled} />
        </Tab>
        <Tab label="Procedure">
          <FinalizeProcedure form={form} disabled={disabled} />
        </Tab>
        <Tab label="Transport">
          <FinalizeTransport form={form} declaration={declaration} disabled={disabled} />
        </Tab>
        <Tab label="Customs Treatment">
          <FinalizeCustomsTreatment form={form} declaration={declaration} disabled={disabled} />
        </Tab>
        <Tab label="Documents">
          <FinalizeDeclarationDocuments declaration={declaration} form={form} disabled={disabled} />
        </Tab>
        <Tab label="Commodity code">
          <FinalizeCommodityCodes form={form} declaration={declaration} disabled={disabled} />
        </Tab>
      </Tabs>
      <ModalStyle.Footer>
        <Button type="button" onClick={onHide}>
          Cancel
        </Button>
        {!disabled && (
          <Button type="button" primary onClick={handleSubmit}>
            {isSaving ? (
              <>
                <Spinner isContrast />
                Saving
              </>
            ) : (
              'Save'
            )}
          </Button>
        )}
      </ModalStyle.Footer>
    </Style.Container>
  );
};
