/* eslint-disable complexity */
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { RouteNames } from '../../../enums';
import { useConfig } from '../../hooks/use-config';
import { selectSettings } from '../../stores/settingsSlice';
import { selectUserRole } from '../../stores/userSlice';
import MenuItem from './menu-item/menu-item.component';
import { Container } from './navigation.style';

export const Navigation = () => {
  const location = useLocation();
  const { walletEnabled, transitEnabled } = useSelector(selectSettings);
  const roleName = useSelector(selectUserRole);
  const { config } = useConfig();

  const locationString = location.pathname.split('/');
  const locationStringLength = locationString.length;
  const batchesString = locationString[locationStringLength - 1];
  const isLocationStringIncludesBatches = batchesString === 'batches';

  return (
    <Container>
      <Link to="/batches">
        <MenuItem selected={location.pathname === '/batches' || isLocationStringIncludesBatches}>
          {RouteNames.BATCHES}
        </MenuItem>
      </Link>
      <Link to="/declaration-groups">
        <MenuItem selected={location.pathname.includes('/declaration-groups')}>{RouteNames.DECLARATIONS}</MenuItem>
      </Link>
      <Link to="/declarations">
        <MenuItem selected={location.pathname.includes('/declarations')}>{RouteNames.DECLARATION_LINES}</MenuItem>
      </Link>
      {!config?.IS_FORWARDER && transitEnabled && (
        <Link to="/transits">
          <MenuItem selected={location.pathname.includes('/transits')}>{RouteNames.TRANSITS}</MenuItem>
        </Link>
      )}
      {walletEnabled && (
        <Link to="/importers">
          <MenuItem selected={location.pathname.includes('/importers')}>{RouteNames.IMPORTERS}</MenuItem>
        </Link>
      )}

      <Link to="/customers">
        <MenuItem selected={location.pathname.includes('/customers')}>{RouteNames.CUSTOMERS}</MenuItem>
      </Link>
      <Link to="/manage-account">
        <MenuItem selected={location.pathname === '/manage-account'}>{RouteNames.MANAGE_ACCOUNT}</MenuItem>
      </Link>
      {roleName === 'admin' && (
        <Link to="/manage-templates">
          <MenuItem selected={location.pathname === '/manage-templates'}>{RouteNames.MANAGE_TEMPLATES}</MenuItem>
        </Link>
      )}
      <Link to="/mongo-dashboard">
        <MenuItem selected={location.pathname === '/mongo-dashboard'}>{RouteNames.MONGO_DASHBOARD}</MenuItem>
      </Link>
      <Link to="/help">
        <MenuItem selected={location.pathname === '/help'}>{RouteNames.HELP}</MenuItem>
      </Link>
    </Container>
  );
};
