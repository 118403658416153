import { useDispatch } from 'react-redux';

import { NotificationModal } from '../../../../../../shared/components';
import { deleteTransit } from '../../../../../../shared/stores/transitsSlice';

interface TransitDeleteModalProps {
  transitId: string;
  open: boolean;
  onHide: () => void;
}

export const TransitDeleteModal: React.FC<TransitDeleteModalProps> = (props) => {
  const dispatch = useDispatch();
  return (
    <NotificationModal
      title="Confirm transit delete"
      confirmButtonText="Delete"
      show={props.open}
      onHide={props.onHide}
      onConfirm={() => {
        dispatch(deleteTransit(props.transitId));
      }}
      isDelete={true}
    >
      <div>Are you sure you want to delete this transit? This action cannot be undone!</div>
    </NotificationModal>
  );
};
