/* eslint-disable consistent-return */
/* eslint-disable max-lines */
/* eslint-disable complexity */
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';

import { Menu, MenuItem } from '@szhsin/react-menu';
import { FC, useRef, useState } from 'react';

import { BatchTypeEnum, EnvironmentCountries } from '@e-origin/shared';

import Icons from '../../../assets';
import actionIcon from '../../../assets/icons/action-icon.svg';
import archiveIcon from '../../../assets/icons/archive-icon.svg';
import cleanupIcon from '../../../assets/icons/cleanup-icon.svg';
import contextualIcon from '../../../assets/icons/contextual-icon.svg';
import customsSyncIcon from '../../../assets/icons/customs-sync-icon.svg';
import downloadIcon from '../../../assets/icons/download-icon.svg';
import finalizeIcon from '../../../assets/icons/finalize-batch.svg';
import downloadImageIcon from '../../../assets/icons/picture.svg';
import refactorIcon from '../../../assets/icons/refactor.svg';
import refreshIcon from '../../../assets/icons/refresh-icon.svg';
import reportIcon from '../../../assets/icons/report-icon.svg';
import riskAnalysisReportIcon from '../../../assets/icons/risk-analysis-report-icon.svg';
import sendRiskAnalysisReportIcon from '../../../assets/icons/send-risk-analysis-to-customer-icon.svg';
import trashIcon from '../../../assets/icons/trash-icon.svg';
import updateIcon from '../../../assets/icons/update-icon.svg';
import viewIcon from '../../../assets/icons/view-icon.svg';
import { BatchesActions } from '../../../enums';
import { useConfig } from '../../hooks/use-config';
import { Container, DownloadImageButton, IconContainer } from './table-action-buttons.style';

export enum TableActions {
  Archive = 'archive',
  Cleanup = 'cleanup',
  CustomsSync = 'customsSync',
  Delete = 'delete',
  DownloadFile = 'downloadFile',
  DownloadImage = 'downloadImage',
  DownloadImageDisabled = 'downloadImageDisabled',
  Edit = 'edit',
  ExecuteAction = 'executeAction',
  GenerateCustomsReport = 'generateCustomsReport',
  GenerateRiskAnalysisReport = 'generateRiskAnalysisReport',
  GenerateInvoice = 'generateInvoice',
  ExportScreenshots = 'exportScreenshots',
  ReExecuteAction = 'reExecuteAction',
  Refactor = 'refactor',
  SendRiskAnalysisReport = 'sendRiskAnalysisReport',
  GenerateCustomerReport = 'generateCustomerReport',
  Unarchive = 'unarchive',
  Update = 'update',
  Finalize = 'finalize',
  RiskAnalysisIteration = 'RiskAnalysisIteration',
  ReSendAction = 'ReSendAction',
  Regularization = 'Regularization',
  DuplicateDeclarations = 'DuplicateDeclarations',
  SetCustomsControl = 'SetCustomsControl',
  RiskAnalysisCalculations = 'RiskAnalysisCalculations',
}

interface ITableActionButtonsProps {
  actions: TableActions[];
  moveMenuUpwards?: boolean;
  noSpaceBetweenButtons?: boolean;
  onClick: (type: string) => void;
  secondaryActions?: TableActions[];
  smallIcons?: boolean;
  webScrapingDone?: boolean;
  inColumnView?: boolean;
  showDebugMenu?: boolean;
  batchView?: BatchTypeEnum;
}

const BUTTON_TEXTS = {
  ARCHIVE: 'Archive',
  CLEANUP: 'Cleanup',
  CUSTOMS_SYNC: 'Customs sync',
  DELETE: 'Delete',
  DOWNLOAD_FILE: 'Download file',
  DOWNLOAD_IMAGE: 'Download image',
  DOWNLOAD_IMAGE_DISABLED: 'Download image disabled',
  EDIT: 'View details',
  GENERATE_CUSTOMS_REPORT: 'Generate customs report',
  GENERATE_RISK_ANALYSIS_REPORT: 'Generate risk analysis report',
  GENERATE_CUSTOMER_REPORT: 'Generate customer report',
  GENERATE_INVOICE: 'Generate invoice',
  EXPORT_SCREENSHOTS: 'Export screenshots',
  REFACTOR: 'Refactor',
  RISK_ANALYSIS_ITERATOR: 'Update Risk Analysis',
  RESEND: 'Resend',
  SEND: 'Send',
  SEND_RISK_ANALYSIS_REPORT: 'Send risk analysis report',
  UNARCHIVE: 'Unarchive',
  UPDATE: 'Update',
  FINALIZE: 'Finalize',
  REGULARIZATION: 'Regularization',
  DUPLICATE_DECLARATIONS: 'Duplicate because of customs invalidation',
  SET_CUSTOMS_CONTROL: 'Move to customs control',
  RISK_ANALYSIS_CALCULATIONS: 'Run risk analysis calculations',
};

export const TableActionButtons: FC<ITableActionButtonsProps> = (props) => {
  const [sendDisabled, setSendDisabled] = useState(false);
  const [cleanupDisabled, setCleanupDisabled] = useState(false);
  const contextMenuRef = useRef(null);
  const { config } = useConfig();

  const generateButton = (action: TableActions, key?: string, showButtonText = false) => {
    switch (action) {
      case TableActions.Refactor:
        return (
          <button
            key={key}
            title={!showButtonText ? BUTTON_TEXTS.RISK_ANALYSIS_ITERATOR : undefined}
            className={`svg-icon ${showButtonText ? 'with-text' : ''}`}
            onClick={() => {
              props.onClick('refactor');
            }}
          >
            <IconContainer src={refactorIcon} alt={'refactor'} />
            {showButtonText && <div>{BUTTON_TEXTS.EDIT}</div>}
          </button>
        );
      case TableActions.Edit:
        return (
          <button
            key={key}
            title={!showButtonText ? BUTTON_TEXTS.EDIT : undefined}
            className={`svg-icon ${showButtonText ? 'with-text' : ''}`}
            onClick={() => {
              props.onClick('view');
            }}
          >
            <IconContainer src={viewIcon} alt={'view-details'} />
            {showButtonText && <div>{BUTTON_TEXTS.EDIT}</div>}
          </button>
        );
      case TableActions.SendRiskAnalysisReport:
        return (
          <button
            key={key}
            title={!showButtonText ? BUTTON_TEXTS.SEND_RISK_ANALYSIS_REPORT : undefined}
            className={`svg-icon ${showButtonText ? 'with-text' : ''}`}
            onClick={() => {
              props.onClick('sendRiskAnalysisReport');
            }}
          >
            <IconContainer src={sendRiskAnalysisReportIcon} alt={'send-risk-analysis-report'} />
            {showButtonText && <div>{BUTTON_TEXTS.SEND_RISK_ANALYSIS_REPORT}</div>}
          </button>
        );
      case TableActions.GenerateCustomerReport:
        return (
          <button
            key={key}
            title={!showButtonText ? BUTTON_TEXTS.GENERATE_CUSTOMER_REPORT : undefined}
            className={`svg-icon ${showButtonText ? 'with-text' : ''}`}
            onClick={() => {
              props.onClick('generateCustomerReport');
            }}
          >
            <IconContainer src={sendRiskAnalysisReportIcon} alt={'generate-customer-report'} />
            {showButtonText && <div>{BUTTON_TEXTS.GENERATE_CUSTOMER_REPORT}</div>}
          </button>
        );
      case TableActions.GenerateCustomsReport:
        return (
          <button
            key={key}
            title={!showButtonText ? BUTTON_TEXTS.GENERATE_CUSTOMS_REPORT : undefined}
            className={`svg-icon ${showButtonText ? 'with-text' : ''}`}
            onClick={() => {
              props.onClick('generateCustomsReport');
            }}
          >
            <IconContainer src={reportIcon} alt={'generate-report'} />
            {showButtonText && <div>{BUTTON_TEXTS.GENERATE_CUSTOMS_REPORT}</div>}
          </button>
        );
      case TableActions.GenerateInvoice:
        return (
          <button
            key={key}
            title={!showButtonText ? BUTTON_TEXTS.GENERATE_INVOICE : undefined}
            className={`svg-icon ${showButtonText ? 'with-text' : ''}`}
            onClick={() => {
              props.onClick('generateInvoice');
            }}
          >
            <IconContainer src={reportIcon} alt={'generate-invoice'} />
            {showButtonText && <div>{BUTTON_TEXTS.GENERATE_INVOICE}</div>}
          </button>
        );
      case TableActions.ExportScreenshots:
        return (
          <button
            key={key}
            title={!showButtonText ? BUTTON_TEXTS.EXPORT_SCREENSHOTS : undefined}
            className={`svg-icon ${showButtonText ? 'with-text' : ''}`}
            onClick={() => {
              props.onClick('exportScreenshots');
            }}
          >
            <IconContainer src={downloadImageIcon} alt={'download-image'} />
            {showButtonText && <div>{BUTTON_TEXTS.EXPORT_SCREENSHOTS}</div>}
          </button>
        );
      case TableActions.GenerateRiskAnalysisReport:
        return (
          <button
            key={key}
            title={!showButtonText ? BUTTON_TEXTS.GENERATE_RISK_ANALYSIS_REPORT : undefined}
            className={`svg-icon ${showButtonText ? 'with-text' : ''}`}
            onClick={() => {
              props.onClick('generateRiskAnalysisReport');
            }}
          >
            <IconContainer src={riskAnalysisReportIcon} alt={'generate-risk-analysis-report'} />
            {showButtonText && <div>{BUTTON_TEXTS.GENERATE_RISK_ANALYSIS_REPORT}</div>}
          </button>
        );
      case TableActions.Archive:
        return (
          <button
            key={key}
            title={!showButtonText ? BUTTON_TEXTS.ARCHIVE : undefined}
            className={`svg-icon ${showButtonText ? 'with-text' : ''}`}
            onClick={() => {
              props.onClick('archive');
            }}
          >
            <IconContainer src={archiveIcon} alt={'archive'} />
            {showButtonText && <div>{BUTTON_TEXTS.ARCHIVE}</div>}
          </button>
        );
      case TableActions.Unarchive:
        return (
          <button
            key={key}
            title={!showButtonText ? BUTTON_TEXTS.UNARCHIVE : undefined}
            className={`svg-icon ${showButtonText ? 'with-text' : ''}`}
            onClick={() => {
              props.onClick('archive');
            }}
          >
            <IconContainer src={archiveIcon} alt={'archive'} />
            {showButtonText && <div>{BUTTON_TEXTS.UNARCHIVE}</div>}
          </button>
        );
      case TableActions.DownloadFile:
        return (
          <button
            key={key}
            title={!showButtonText ? BUTTON_TEXTS.DOWNLOAD_FILE : undefined}
            className={`svg-icon ${showButtonText ? 'with-text' : ''}`}
            onClick={() => {
              props.onClick('download');
            }}
          >
            <IconContainer src={downloadIcon} alt={'download'} />
            {showButtonText && <div>{BUTTON_TEXTS.DOWNLOAD_FILE}</div>}
          </button>
        );
      case TableActions.Delete:
        return (
          <button
            key={key}
            title={!showButtonText ? BUTTON_TEXTS.DELETE : undefined}
            className={`svg-icon ${showButtonText ? 'with-text' : ''}`}
            onClick={() => {
              props.onClick('delete');
            }}
          >
            <IconContainer src={trashIcon} alt={'delete'} />
            {showButtonText && <div>{BUTTON_TEXTS.DELETE}</div>}
          </button>
        );
      case TableActions.RiskAnalysisIteration:
        return (
          <button
            key={key}
            title={!showButtonText ? BUTTON_TEXTS.RISK_ANALYSIS_ITERATOR : undefined}
            className={`svg-icon ${showButtonText ? 'with-text' : ''}`}
            disabled={false}
            onClick={() => {
              // setSendDisabled(true);
              props.onClick('executeRiskAnalysis');
            }}
          >
            <IconContainer src={Icons.EditIcon} alt={'execute-action'} />
            {showButtonText && <div>{BUTTON_TEXTS.SEND}</div>}
          </button>
        );
      case TableActions.ExecuteAction:
        return (
          <button
            key={key}
            title={!showButtonText ? BUTTON_TEXTS.SEND : undefined}
            className={`svg-icon ${showButtonText ? 'with-text' : ''}`}
            disabled={sendDisabled}
            onClick={() => {
              props.onClick('executeAction');
            }}
          >
            <IconContainer src={actionIcon} alt={'execute-action'} />
            {showButtonText && <div>{BUTTON_TEXTS.SEND}</div>}
          </button>
        );
      case TableActions.ReSendAction:
        return (
          <button
            key={key}
            title={!showButtonText ? BUTTON_TEXTS.SEND : undefined}
            className={`svg-icon ${showButtonText ? 'with-text' : ''}`}
            disabled={sendDisabled}
            onClick={() => {
              setSendDisabled(true);
              props.onClick(TableActions.ReSendAction);
            }}
          >
            <IconContainer src={actionIcon} alt={'execute-action'} />
            {showButtonText && <div>{BUTTON_TEXTS.SEND}</div>}
          </button>
        );
      case TableActions.Regularization:
        return (
          <button
            key={key}
            title={!showButtonText ? BUTTON_TEXTS.REGULARIZATION : undefined}
            className={`svg-icon ${showButtonText ? 'with-text' : ''}`}
            disabled={sendDisabled}
            onClick={() => {
              props.onClick(TableActions.Regularization);
            }}
          >
            <IconContainer src={Icons.CopyIcon} alt={'regularization-action'} />
            {showButtonText && <div>{BUTTON_TEXTS.REGULARIZATION}</div>}
          </button>
        );
      case TableActions.DuplicateDeclarations:
        return (
          <button
            key={key}
            title={!showButtonText ? BUTTON_TEXTS.DUPLICATE_DECLARATIONS : undefined}
            className={`svg-icon ${showButtonText ? 'with-text' : ''}`}
            disabled={sendDisabled}
            onClick={() => {
              props.onClick(TableActions.DuplicateDeclarations);
            }}
          >
            <IconContainer src={Icons.CopyIcon} alt={'duplicate-declarations-action'} />
            {showButtonText && <div>{BUTTON_TEXTS.DUPLICATE_DECLARATIONS}</div>}
          </button>
        );
      case TableActions.SetCustomsControl:
        return (
          <button
            key={key}
            title={!showButtonText ? BUTTON_TEXTS.SET_CUSTOMS_CONTROL : undefined}
            className={`svg-icon ${showButtonText ? 'with-text' : ''}`}
            disabled={sendDisabled}
            onClick={() => {
              props.onClick(TableActions.SetCustomsControl);
            }}
          >
            <IconContainer src={Icons.DangerIcon} alt={'set-customs-control-action'} />
            {showButtonText && <div>{BUTTON_TEXTS.SET_CUSTOMS_CONTROL}</div>}
          </button>
        );
      case TableActions.ReExecuteAction:
        return (
          <button
            key={key}
            title={!showButtonText ? BUTTON_TEXTS.RESEND : undefined}
            className={`svg-icon ${showButtonText ? 'with-text' : ''}`}
            onClick={() => {
              props.onClick('executeAction');
            }}
          >
            <IconContainer src={refreshIcon} alt={'execute-action'} />
            {showButtonText && <div>{BUTTON_TEXTS.RESEND}</div>}
          </button>
        );
      case TableActions.Update:
        return (
          <button
            key={key}
            title={!showButtonText ? BUTTON_TEXTS.UPDATE : undefined}
            className={`svg-icon ${showButtonText ? 'with-text' : ''}`}
            onClick={() => {
              props.onClick('update');
            }}
          >
            <IconContainer src={updateIcon} alt={'update'} />
            {showButtonText && <div>{BUTTON_TEXTS.UPDATE}</div>}
          </button>
        );
      case TableActions.Finalize:
        return (
          <button
            key={key}
            title={!showButtonText ? BUTTON_TEXTS.FINALIZE : undefined}
            className={`svg-icon ${showButtonText ? 'with-text' : ''}`}
            onClick={() => {
              props.onClick('finalize');
            }}
          >
            <IconContainer src={finalizeIcon} alt={'finalize'} />
            {showButtonText && <div>{BUTTON_TEXTS.FINALIZE}</div>}
          </button>
        );
      case TableActions.CustomsSync:
        return (
          <button
            key={key}
            title={!showButtonText ? BUTTON_TEXTS.CUSTOMS_SYNC : undefined}
            className={`svg-icon ${showButtonText ? 'with-text' : ''}`}
            onClick={() => {
              props.onClick('customs-sync');
            }}
          >
            <IconContainer src={customsSyncIcon} alt={'customs-sync'} />
            {showButtonText && <div>{BUTTON_TEXTS.CUSTOMS_SYNC}</div>}
          </button>
        );
      case TableActions.RiskAnalysisCalculations:
        return (
          <button
            key={key}
            title={!showButtonText ? BUTTON_TEXTS.RISK_ANALYSIS_CALCULATIONS : undefined}
            className={`svg-icon ${showButtonText ? 'with-text' : ''}`}
            onClick={() => {
              props.onClick(BatchesActions.RISK_ANALYSIS_CALCULATIONS);
            }}
          >
            <IconContainer src={customsSyncIcon} alt={'risk-analysis-calculations'} />
            {showButtonText && <div>{BUTTON_TEXTS.RISK_ANALYSIS_CALCULATIONS}</div>}
          </button>
        );
      case TableActions.Cleanup:
        return (
          <button
            key={key}
            disabled={cleanupDisabled}
            title={!showButtonText ? BUTTON_TEXTS.CLEANUP : undefined}
            className={`svg-icon ${showButtonText ? 'with-text' : ''}`}
            onClick={() => {
              setCleanupDisabled(true);
              props.onClick('cleanup');
            }}
          >
            <IconContainer src={cleanupIcon} alt={'cleanup'} />
            {showButtonText && <div>{BUTTON_TEXTS.CLEANUP}</div>}
          </button>
        );
      case TableActions.DownloadImage:
        return (
          <button
            key={key}
            title={!showButtonText ? BUTTON_TEXTS.DOWNLOAD_IMAGE : undefined}
            className={`svg-icon ${showButtonText ? 'with-text' : ''}`}
            onClick={() => {
              setCleanupDisabled(true);
              props.onClick('downloadImage');
            }}
          >
            <IconContainer src={downloadImageIcon} alt={'download-image'} />
            {showButtonText && <div>{BUTTON_TEXTS.DOWNLOAD_IMAGE}</div>}
          </button>
        );
      case TableActions.DownloadImageDisabled:
        return (
          <DownloadImageButton
            webScrapingDone={props.webScrapingDone}
            isButtonDisabled={action === TableActions.DownloadImageDisabled}
            key={key}
            title={!showButtonText ? BUTTON_TEXTS.DOWNLOAD_IMAGE : undefined}
            className={`svg-icon ${showButtonText ? 'with-text' : ''}`}
            onClick={() => {
              setCleanupDisabled(true);
              props.onClick('downloadImageDisabled');
            }}
          >
            <IconContainer src={downloadImageIcon} alt={'download-image-disabled'} />
            {showButtonText && <div>{BUTTON_TEXTS.DOWNLOAD_IMAGE}</div>}
          </DownloadImageButton>
        );
      default:
        break;
    }
  };

  return (
    <div>
      <Container noSpaceBetweenButtons={props.noSpaceBetweenButtons} smallIcons={props.smallIcons}>
        {props.actions.map((action, index) => generateButton(action, `primary-${index}-${action}`, false))}

        {props.secondaryActions && props.secondaryActions.length > 0 && (
          <Menu
            menuClassName={`contextual-menu ${props.moveMenuUpwards ? 'move-it-up' : ''} ${
              props.inColumnView ? 'column-view' : ''
            }`.trim()}
            align="center"
            direction="left"
            overflow={'auto'}
            position="anchor"
            arrow
            viewScroll="auto"
            menuButton={
              <button className="svg-icon" ref={contextMenuRef}>
                <IconContainer src={contextualIcon} alt={'contextual'} />
              </button>
            }
            onMenuChange={(ev) =>
              contextMenuRef.current?.closest('.rdt_TableRow')?.classList[ev.open ? 'add' : 'remove']('menu-open')
            }
            transition
          >
            {props.secondaryActions.map((action, index) => (
              <MenuItem key={`secondary-${index}-${action}`}>{generateButton(action, undefined, true)}</MenuItem>
            ))}
          </Menu>
        )}
        {/* NL DECO/DMS testing - to be removed - START */}
        {config?.COUNTRY === EnvironmentCountries.NL && !config?.IS_PROD && props.showDebugMenu && (
          <Menu
            menuClassName={`contextual-menu ${props.moveMenuUpwards ? 'move-it-up' : ''} ${
              props.inColumnView ? 'column-view' : ''
            }`.trim()}
            align="center"
            direction="left"
            overflow={'auto'}
            position="anchor"
            arrow
            viewScroll="auto"
            menuButton={
              <button
                className="svg-icon"
                ref={contextMenuRef}
                style={{ filter: 'invert(0.5) sepia(1) saturate(5) hue-rotate(180deg)' }}
              >
                <IconContainer src={contextualIcon} alt={'contextual'} />
              </button>
            }
            onMenuChange={(ev) =>
              contextMenuRef.current?.closest('.rdt_TableRow')?.classList[ev.open ? 'add' : 'remove']('menu-open')
            }
            transition
          >
            {[
              ...(props.batchView === BatchTypeEnum.LOW_VALUES
                ? [
                    { type: 'CC410A', label: 'CC410A - Invalidation' },
                    { type: 'CC426A', label: 'CC426A - Registration' },
                    { type: 'CC456A', label: 'CC456A - Rejection' },
                    { type: 'CC460A', label: 'CC460A - In control' },
                    { type: 'CC451A', label: 'CC451A - No release' },
                    { type: 'CC428A', label: 'CC428A - Accepted' },
                    { type: 'CC429A', label: 'CC429A - Release' },
                    { type: 'XMLCNT', label: 'XMLCNT - Rejection' },
                    { type: 'CCTAXA', label: 'CCTAXA - Tax' },
                  ]
                : []),
              ...(props.batchView === BatchTypeEnum.HIGH_VALUES
                ? [
                    { type: 'XMLCNT', label: 'XMLCNT - Technical Rejection' },
                    { type: 'CCRCVA', label: 'CCRCVA - Received message' },
                    { type: 'CCCPIA', label: 'CCCPIA - Coverage pay-up instruction' },
                    { type: 'CCCPRA', label: 'CCCPRA - Coverage pay-up reminder' },
                    { type: 'CC426A', label: 'CC426A - Registration' },
                    { type: 'CC429A', label: 'CC429A - Release' },
                    { type: 'CC438A', label: 'CC438A - Reminder for providing additional documents' },
                    { type: 'CC410A', label: 'CC410A - Invalidation decision' },
                    { type: 'CC451A', label: 'CC451A - No release' },
                    { type: 'CC460A', label: `CC460A - In control` },
                  ]
                : []),
            ].map(({ type, label }) => (
              <MenuItem key={type}>
                <button
                  className="svg-icon with-text"
                  onClick={() => props.onClick(`generateNlDecoFiles-${type}`)}
                  style={{ filter: 'invert(0.5) sepia(1) saturate(5) hue-rotate(180deg)' }}
                >
                  <IconContainer src={downloadIcon} alt={'download generated zip to simulate customs response'} />
                  <div>{label}</div>
                </button>
              </MenuItem>
            ))}
          </Menu>
        )}
        {/* NL DECO/DMS testing - to be removed - END */}

        {/* IDMS HV testing - to be removed - START */}
        {config?.COUNTRY === EnvironmentCountries.BE &&
          !config?.IS_PROD &&
          props.showDebugMenu &&
          [BatchTypeEnum.HIGH_VALUES_EXPORT, BatchTypeEnum.HIGH_VALUES].includes(props.batchView) && (
            <Menu
              menuClassName={`contextual-menu ${props.moveMenuUpwards ? 'move-it-up' : ''} ${
                props.inColumnView ? 'column-view' : ''
              }`.trim()}
              align="center"
              direction="left"
              overflow={'auto'}
              position="anchor"
              arrow
              viewScroll="auto"
              menuButton={
                <button
                  className="svg-icon"
                  ref={contextMenuRef}
                  style={{ filter: 'invert(0.5) sepia(1) saturate(5) hue-rotate(180deg)' }}
                >
                  <IconContainer src={contextualIcon} alt={'contextual'} />
                </button>
              }
              onMenuChange={(ev) =>
                contextMenuRef.current?.closest('.rdt_TableRow')?.classList[ev.open ? 'add' : 'remove']('menu-open')
              }
              transition
            >
              {(props.batchView === BatchTypeEnum.HIGH_VALUES_EXPORT
                ? [
                    { type: 'CC504C', label: 'CC504C - Amendment Accepted' },
                    { type: 'CC509C', label: 'CC509C - Invalidation Decision' },
                    { type: 'CC528C', label: 'CC528C - Accepted' },
                    { type: 'CC560C', label: 'CC560C - In control' },
                    { type: 'CC529C', label: 'CC529C - Released (without goods)' },
                    { type: 'CC551C', label: 'CC551C - Not Released (without goods)' },
                    { type: 'CC599C', label: 'CC599C - Exported (without goods)' },
                  ]
                : [
                    { type: 'IE460B', label: `IE460B - In control` },
                    { type: 'IE429B', label: `IE429B - Released` },
                    { type: 'IE410B', label: `IE410B - Invalidation decision` },
                    { type: 'IE451B', label: `IE451B - Non release notification` },
                    { type: 'IE404B', label: `IE404B - Amendment acceptance` },
                  ]
              ).map(({ type, label }) => (
                <MenuItem key={type}>
                  <button
                    className="svg-icon with-text"
                    onClick={() => props.onClick(`generateIDMSHVResponse-${type}`)}
                    style={{ filter: 'invert(0.5) sepia(1) saturate(5) hue-rotate(180deg)' }}
                  >
                    <IconContainer src={downloadIcon} alt={'imulate idms response for testing purpose'} />
                    <div>{label}</div>
                  </button>
                </MenuItem>
              ))}
            </Menu>
          )}
        {/* IDMS HV testing - to be removed - END */}
      </Container>
    </div>
  );
};
