import { useMemo } from 'react';
import DataTable, { IDataTableColumn } from 'react-data-table-component';

import { TableActionsIcons } from '../../../../../assets';
import { CustomTableActionsComponent } from '../../../../../shared/components';
import { IdentificationStatusIcon } from '../identification-status-icon/identification-status-icon.component';
import * as Style from '../importer-details.style';

export interface IIdentificationDocumentItem {
  importer: any;
}
export const ImporterIdentification: React.FC<IIdentificationDocumentItem> = ({ importer }) => {
  const importerDocumentsList = useMemo(
    () =>
      [
        { document: 'ID Card', type: 'ID_CARD', relatedResource: importer?.idCard.relatedResources[0] },
        {
          document: 'Chinese business license',
          type: 'BUSINESS_LICENSE',
          relatedResource: importer?.businessLicense.relatedResources[0],
        },
        { document: 'EORI certificate', type: 'EORI', relatedResource: importer?.eori.relatedResources[0] },
        { document: 'VAT certificate', type: 'VAT', relatedResource: importer?.vat.relatedResources[0] },
        {
          document: 'Tax return paperwork',
          type: 'TAX',
          relatedResource: importer?.taxReturn?.relatedResources[0],
        },
      ].map((doc) => ({
        document: doc.document,
        type: doc.type,
        ...doc.relatedResource,
      })),
    [importer],
  );

  const columns: IDataTableColumn[] = [
    {
      name: 'Identification document',
      cell: (row) => <div style={{ textAlign: 'start' }}>{row.document}</div>,
      sortable: false,
    },
    {
      name: 'File name',
      cell: (row) => <div style={{ textAlign: 'start' }}>{row.fileName}</div>,
    },
    {
      name: 'Format',
      cell: (row) => <div style={{ textAlign: 'start' }}>{row.mediaType?.toUpperCase()}</div>,
    },
    {
      name: 'Status',
      selector: (row) => <IdentificationStatusIcon key={row.type} rowData={row} />,
    },
    {
      name: 'Actions',
      right: true,
      cell: (row) => (
        <CustomTableActionsComponent
          actions={[
            {
              icon: TableActionsIcons.VIEW,
              text: 'Download',
              onClick: () => {
                window.open(row.signedUrl, '_blank');
              },
            },
          ]}
        />
      ),
    },
  ];

  return (
    <div style={{ marginTop: 20 }}>
      <Style.DocumentsContainer>
        <DataTable noHeader columns={columns} data={importerDocumentsList} pagination />
      </Style.DocumentsContainer>
    </div>
  );
};
