/* eslint-disable max-statements */
import { range } from 'lodash';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';

import { Button, Dropdown } from '../../../../../shared/components';
import { PageTitle } from '../../../../../styles/common';
import * as Style from './vat-report.style';

interface VatReportProps {
  downloadReport: (selectedYear: number, selectedMonth: number | null, selectedQuarter: number | null) => Promise<void>;
  title: string;
}

export const VatReport: React.FC<VatReportProps> = ({ title, downloadReport }) => {
  const months = useRef(
    range(12).map((index) => ({
      label: moment(`${index + 1}-01-2023`).format('MMMM'),
      value: index + 1,
    })),
  );
  const quarters = useRef(
    range(4).map((index) => ({
      label: `Q${index + 1}`,
      value: index + 1,
    })),
  );

  const years = useRef(
    range(5).map((index) => ({
      label: moment(`01-01-${moment().year() - index}`).format('YYYY'),
      value: moment().year() - index,
    })),
  );

  const [selectedMonth, setSelectedMonth] = useState({ label: '', value: 0 });
  const [selectedQuarter, setSelectedQuarter] = useState({ label: '', value: 0 });
  const [selectedYear, setSelectedYear] = useState({ label: '', value: 0 });

  const [monthMode, setMonthMode] = useState(true);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const currentMonth = moment().month();
    const currentQuarter = moment().quarter() - 1;

    setSelectedMonth(months.current[currentMonth]);
    setSelectedQuarter(quarters.current[currentQuarter]);
    setSelectedYear(years.current[0]);
  }, []);

  const monthModeClickHandler = () => {
    setMonthMode(!monthMode);
  };

  const monthChangeHandler = (selectedOption) => {
    setSelectedMonth(selectedOption);
  };

  const quarterChangeHandler = (selectedOption) => {
    setSelectedQuarter(selectedOption);
  };

  const yearChangeHandler = (selectedOption) => {
    setSelectedYear(selectedOption);
  };

  const handleDownloadReport = async () => {
    setIsLoading(true);
    await downloadReport(
      selectedYear.value,
      monthMode ? selectedMonth.value : null,
      !monthMode ? selectedQuarter.value : null,
    );
    setIsLoading(false);
  };

  return (
    <Style.Container>
      <PageTitle>{title}</PageTitle>
      <Style.BasicButton onClick={monthModeClickHandler}>{monthMode ? 'Use quarter' : 'Use month'}</Style.BasicButton>
      {monthMode ? (
        <Style.DropdownContainer>
          <Dropdown
            label="Select a month"
            onChange={monthChangeHandler}
            options={months.current}
            placeholder={selectedMonth.label}
            value={selectedMonth}
            width={300}
            maxMenuHeight={200}
          />
        </Style.DropdownContainer>
      ) : (
        <Style.DropdownContainer>
          <Dropdown
            label="Select a quarter"
            onChange={quarterChangeHandler}
            options={quarters.current}
            placeholder={selectedQuarter.label}
            value={selectedQuarter}
            width={300}
            maxMenuHeight={200}
          />
        </Style.DropdownContainer>
      )}

      <Style.DropdownContainer>
        <Dropdown
          label="Select a year"
          onChange={yearChangeHandler}
          options={years.current}
          placeholder={selectedYear.label}
          value={selectedYear}
          width={300}
          maxMenuHeight={200}
        />
      </Style.DropdownContainer>

      <Style.DownloadButton>
        <Button showLoading={isLoading} primary onClick={handleDownloadReport} disabled={isLoading}>
          {isLoading ? 'Generating report..' : 'Download report'}
        </Button>
      </Style.DownloadButton>
    </Style.Container>
  );
};
