import styled from 'styled-components';

import { FormRow } from '../../../../../../../../styles/common';

export const RiskAnalysisTranslationContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #1870a3;

  & > * {
    margin: 0 8px;
    font-size: 20px;
  }

  & > a {
    font-size: inherit;
    margin: inherit;
  }
`;

export const ChipHeaderText = styled.span`
  font-size: 14px;
  font-weight: 600;
  height: 25px;
`;

export const ChipsContainer = styled.span`
  display: flex;
  flex-wrap: wrap;
`;

export const RiskAnalysisNewDescription = styled.div`
  border: 1px solid #727272;
  border-radius: 6px;
  padding: 20px;
  margin-bottom: 15px;

  ${FormRow} {
    margin-bottom: 0 !important;
  }

  ${ChipsContainer} {
    margin-bottom: 15px;
  }
`;
