/* eslint-disable max-statements */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { toast } from 'react-toastify';

/* eslint-disable no-param-reassign */
import { RequestFilters, Template } from '@e-origin/shared';

import { request } from '../../utils';
import { AppThunk, RootState } from './index';

interface TemplatesState {
  list: Template[];
  loadingDetails: boolean;
  loadingList: boolean;
  selectedTemplate: Template;
  totalTemplates: number;
}

const initialState: TemplatesState = {
  list: [],
  loadingList: false,
  loadingDetails: false,
  selectedTemplate: {} as Template,
  totalTemplates: 0,
};

export const TemplatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    setList: (state: TemplatesState, action: PayloadAction<Template[]>) => {
      state.list = action.payload.map((item: any) => ({
        ...item,
        updatedAt: moment(item.updatedAt).format('DD-MM-YY @ HH:mm'),
      }));
      state.loadingList = false;
    },
    setTotalItems: (state: TemplatesState, action: PayloadAction<number>) => {
      state.totalTemplates = action.payload;
    },
    setLoadingList: (state: TemplatesState, action: PayloadAction<boolean>) => {
      state.loadingList = action.payload;
    },
    clearTemplateDetails: (state: TemplatesState) => {
      state.selectedTemplate = {} as Template;
    },
    setTemplateDetails: (state: TemplatesState, action: PayloadAction<Template>) => {
      state.selectedTemplate = action.payload;
    },
    setLoadingDetails: (state: TemplatesState, action: PayloadAction<boolean>) => {
      state.loadingDetails = action.payload;
    },
  },
});

export const { clearTemplateDetails, setTemplateDetails, setList, setLoadingDetails, setLoadingList, setTotalItems } =
  TemplatesSlice.actions;

export const selectTemplates = (state: RootState) => state.templates.list;

export const selectTemplateDetails = (state: RootState) => state.templates.selectedTemplate;

export const selectTotalTemplatesCount = (state: RootState) => state.templates.totalTemplates;

export const selectTemplatesLoading = (state: RootState) => state.templates.loadingList;

export const selectTemplatesNamesAndIds = (state: RootState) =>
  state.templates.list.map((template: any) => {
    return {
      label: template.name || '',
      value: template._id || '',
    };
  });

export const selectTemplateById = (state: RootState, id: string) =>
  state.templates.list.find((template: any) => template._id === id);

export const setTemplatesLoading = (): AppThunk<Promise<void>> => async (dispatch: any) => {
  dispatch(setLoadingList(true));
};

export const fetchTemplates =
  (filters?: RequestFilters, page?: number, pageSize?: number): AppThunk =>
  async (dispatch: any) => {
    try {
      const dataObject = { page, pageSize };
      filters = filters || {};

      const {
        data: { list, totalTemplates },
      } = await request({
        path: `partial-templates`,
        method: 'GET',
        authenticate: true,
        dataObject: filters ? { ...dataObject, filters } : dataObject,
      });
      dispatch(setList(list));
      dispatch(setTotalItems(totalTemplates));
      dispatch(setLoadingList(false));
    } catch (error) {
      dispatch(setLoadingList(false));
      toast.error('Error fetching the templates!');
    }
  };

export const fetchTemplateDetails =
  (templateId: string): AppThunk<Promise<void>> =>
  async (dispatch: any) => {
    dispatch(setLoadingDetails(true));
    try {
      const { data } = await request({
        path: `partial-templates/${templateId}`,
        method: 'GET',
        authenticate: true,
      });
      dispatch(setLoadingDetails(false));
      dispatch(setTemplateDetails(data));
    } catch (error) {
      dispatch(setLoadingDetails(false));
      toast.error('Error fetching the template!');
    }
  };

export const editTemplateDetails =
  (templateData: Partial<Template>, templateId: Template['_id']): AppThunk =>
  async (dispatch: any) => {
    try {
      const { data } = await request({
        path: `partial-templates/${templateId}`,
        method: 'PATCH',
        authenticate: true,
        dataObject: templateData,
      });

      dispatch(setTemplateDetails(data));
      dispatch(fetchTemplates());

      toast.success('Template was updated!');
    } catch (error) {
      toast.error('Error updating the template!');
    }
  };

export const deleteTemplate =
  (templateId: string): AppThunk =>
  async (dispatch: any) => {
    try {
      await request({
        path: `templates/${templateId}`,
        method: 'DELETE',
        authenticate: true,
      });

      dispatch(setTemplatesLoading());
      dispatch(fetchTemplates());
      toast.success('Template successfully deleted!');
    } catch (error) {
      toast.error('Error deleting the Template!');
    }
  };

export default TemplatesSlice.reducer;
