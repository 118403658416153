import { Button, Input, InputHeader } from '../';
import trashIcon from '../../../assets/icons/trash-icon.svg';
import * as Style from './multiple-containers.style';

interface MultipleContainersProps {
  compact?: boolean;
  containers: string[];
  onChange: (newContainers: string[]) => void;
  onHeaderChange?: (active: boolean) => void;
  headerValue?: boolean;
  editDisabled?: boolean;
  headerDisabled?: boolean;
  label: string;
  width?: number;
  /**
   * widthUnit can be "px" or "%"
   */
  widthUnit?: 'px' | '%' | 'em' | 'rem';
}

export const MultipleContainers: React.FC<MultipleContainersProps> = (props) => {
  const appendContainer = () => {
    props.onChange([...props.containers, '']);
  };

  const removeContainer = (containerIdx: number) => {
    const newContainerList = [...props.containers];
    newContainerList.splice(containerIdx, 1);
    props.onChange(newContainerList);
  };

  const updateContainer = (containerIdx: number, value: string) => {
    const newContainerList = [...props.containers];
    newContainerList[containerIdx] = value;
    props.onChange(newContainerList);
  };

  return (
    <Style.Container width={props.width} widthUnit={props.widthUnit}>
      {props.containers.map((container, containerIdx) => (
        <Style.Item key={containerIdx}>
          {props.onHeaderChange ? (
            <InputHeader
              compact={props.compact}
              placeholder={`${props.label} ${containerIdx + 1}`}
              onChange={(evt) => {
                updateContainer(containerIdx, evt.target.value);
              }}
              onBlur={(evt) => {
                updateContainer(containerIdx, evt.target.value);
              }}
              value={container}
              width={100}
              widthUnit="%"
              disabled={props.editDisabled}
              defaultBehaviour
              onHeaderChange={props.onHeaderChange}
              headerValue={props.headerValue}
              headerDisabled={props.headerDisabled}
            />
          ) : (
            <Input
              compact={props.compact}
              placeholder={`${props.label} ${containerIdx + 1}`}
              onChange={(evt) => {
                updateContainer(containerIdx, evt.target.value);
              }}
              onBlur={(evt) => {
                updateContainer(containerIdx, evt.target.value);
              }}
              value={container}
              width={100}
              widthUnit="%"
              disabled={props.editDisabled}
              defaultBehaviour
            />
          )}
          {!props.editDisabled && props.containers.length > 1 && (
            <Style.DeleteButton
              onClick={(e) => {
                e.preventDefault();
                removeContainer(containerIdx);
              }}
            >
              <img src={trashIcon} style={{ color: 'red', fill: 'red' }} />
            </Style.DeleteButton>
          )}
        </Style.Item>
      ))}
      {!props.editDisabled && (
        <Style.Item>
          <Button
            outline
            onClick={(e) => {
              e.preventDefault();
              appendContainer();
            }}
          >
            Add {props.label}
          </Button>
        </Style.Item>
      )}
    </Style.Container>
  );
};
