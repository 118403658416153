/* eslint-disable complexity */
import { FormikProps } from 'formik';
import { useSelector } from 'react-redux';

import { TransitGroup, TransitHouseConsignment } from '@e-origin/shared';

import { Input } from '../../../../../../../../../../shared/components';
import { selectTransit } from '../../../../../../../../../../shared/stores/transitsSlice';
import { FormContainer, FormRow, FormSection, FormSectionTitle } from '../../../../../../../../../../styles/common';
import { TransitDuplicateRow } from '../../../../../../transit-duplicate-row';
import { GoodsItemFormInitializerReturn } from '../goods-item-form-initializer';

interface GoodsItemStakeholdersProps {
  form: FormikProps<GoodsItemFormInitializerReturn>;
  houseConsignment: TransitHouseConsignment;
  disabled: boolean;
}

export const GoodsItemStakeholders: React.FC<GoodsItemStakeholdersProps> = (props) => {
  const { form } = props;

  const transit = useSelector(selectTransit);
  const isArrival = transit.generalInfo?.group === TransitGroup.ARRIVAL;
  const additionalSupplyChainActorHeaders = [
    ...(transit.Consignment?.AdditionalSupplyChainActor || []),
    ...(props.houseConsignment?.AdditionalSupplyChainActor || []),
  ];

  return (
    <FormContainer>
      {!isArrival && (
        <FormSection verticalPadding={0}>
          <FormSectionTitle style={{ border: 'none' }}>Additional supply chain actor</FormSectionTitle>
          {additionalSupplyChainActorHeaders.map((doc, docIdx) => (
            <FormRow key={docIdx}>
              <Input width={50} widthUnit="%" placeholder="Role" value={doc.role} compact disabled />
              <Input
                width={50}
                widthUnit="%"
                placeholder="Identification number"
                value={doc.identificationNumber}
                compact
                disabled
              />
            </FormRow>
          ))}
          <TransitDuplicateRow
            addButtonLabel="Add new additional supply chain actor"
            form={form}
            inputs={[
              {
                type: 'MultiLang',
                label: 'Role',
                name: 'role',
                code: 'CL704',
              },
              {
                type: 'Input',
                label: 'Identification number',
                name: 'identificationNumber',
              },
            ]}
            arrayPath="AdditionalSupplyChainActor"
            compact
            disabled={props.disabled}
          />
        </FormSection>
      )}
    </FormContainer>
  );
};
