/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable max-classes-per-file */
import { prop } from '@typegoose/typegoose';

export class DeliveryTerms {
  @prop()
  incotermCode: string;

  @prop()
  location: string;

  @prop()
  country: string;
}

export class Charges {
  @prop({ default: 0 })
  charges: number;

  @prop()
  currency: string;
}

export class ChargesImport {
  @prop({ _id: false, type: Charges })
  CustomsCharges: Charges;

  @prop({ _id: false, type: Charges })
  VATcharges: Charges;
}

export class ValuationAdjustment {
  @prop({ type: String })
  valuationIndicators: string;
}

export class ValuationInformationTaxes {
  @prop({ _id: false, type: DeliveryTerms })
  deliveryTerms: DeliveryTerms;

  @prop()
  valuationMethod: string;

  @prop({ _id: false, type: ChargesImport })
  chargesimport: ChargesImport;

  @prop({ _id: false, type: ChargesImport })
  globalChargesimport?: ChargesImport;

  @prop({ _id: false, type: ValuationAdjustment })
  valuationAdjustment?: ValuationAdjustment;
}
