/* eslint-disable complexity */
import Cookies from 'js-cookie';
import moment from 'moment';
import { useEffect, useRef } from 'react';
import { IDataTableColumn } from 'react-data-table-component';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  BatchTypeEnum,
  Declaration,
  DeclarationCustomsType,
  DeclarationStatus,
  EORIGIN_DATE_FORMAT,
} from '@e-origin/shared';

import { TableActionsIcons } from '../../../../../assets';
import { DeclarationFlowBackgroundColors } from '../../../../../enums';
import { CustomTableActionsComponent } from '../../../../../shared/components';
import { useConfig } from '../../../../../shared/hooks/use-config';
import { sendBatchToBeGate } from '../../../../../shared/stores/batchesSlice';
import { sendAllDeclarationsWithSameLRN, sendOneHighValue } from '../../../../../shared/stores/declarationsSlice';
import { BadgeWrapper, TableCellInLineContent } from '../../../../../styles/common';
import { STORAGE_KEYS } from '../../../../../utils';
import { canBeDuplicated, canBeRegularized } from '../../../helpers/declaration.helper';
import { DeclarationModalEnum } from '../../declaration-modal-factory/declaration-modal-factory.component';
import { DeclarationStatusBadge } from './declaration-status-badge/declaration-status-badge.component';

export const useDeclarationGroupsColumns = ({ openModal, fetchData, onSelectedRow }) => {
  const history = useHistory();
  const { config } = useConfig();
  const dispatch = useDispatch();
  const columns = useRef<IDataTableColumn<Declaration>[]>([]);

  const buildColumns = () => {
    return [
      {
        name: 'Batch Id',
        width: '100px',
        selector: 'messageInfo.batchId',
        sortable: true,
      },
      {
        name: 'Batch',
        selector: 'declarationGlobal.batchName',
        minWidth: '200px',
        sortable: true,
        cell: (row) => <TableCellInLineContent>{row.declarationGlobal?.batchName}</TableCellInLineContent>,
      },
      {
        name: 'Flow/System',
        width: '130px',
        cell: (row) => (
          <div className="whitespace-nowrap overflow-hidden overflow-ellipsis">
            {row.customsState?.sentTo && (
              <BadgeWrapper
                style={{ backgroundColor: DeclarationFlowBackgroundColors[row.customsState?.flow ?? 'POST'] }}
              >
                {row.customsState?.flow?.toLowerCase() || ''}
                {row.customsState?.sentTo}
              </BadgeWrapper>
            )}
          </div>
        ),
      },
      {
        name: 'Status',
        width: '200px',
        selector: 'generalInfo.status',
        sortable: true,
        cell: (row) => <DeclarationStatusBadge status={row.generalInfo.status} />,
      },
      {
        name: 'LRN',
        width: '230px',
        selector: 'messageInfo.LRN',
      },
      {
        name: 'Customer',
        selector: 'declarationGlobal.customerName',
        minWidth: '200px',
        cell: (row) => <TableCellInLineContent>{row.declarationGlobal?.customerName}</TableCellInLineContent>,
        sortable: true,
      },
      {
        name: 'UCR',
        selector: 'consignment.referenceNumberUCR',
        minWidth: '200px',
        sortable: true,
      },
      {
        name: 'BE-GATE Days left',
        selector: 'customsState.begate.beGateSentDate',
        cell: (row) =>
          row.customsState?.begate?.beGateClearanceDate
            ? Math.max(
                0,
                10 -
                  moment().utc().diff(moment(row.customsState.begate.beGateClearanceDate, EORIGIN_DATE_FORMAT), 'days'),
              )
            : undefined,
        minWidth: '200px',
        sortable: true,
      },
      {
        name: 'BE-GATE Clearance Date',
        selector: (row) => row.customsState?.begate?.beGateClearanceDate,
        minWidth: '200px',
      },
      {
        name: 'MRN',
        selector: 'customsState.movementReference',
        minWidth: '200px',
        cell: (row) => <TableCellInLineContent>{row.customsState?.movementReference}</TableCellInLineContent>,
        sortable: true,
      },
      {
        name: 'Release date',
        selector: 'customsState.releaseForImport.dateOfRelease',
        minWidth: '200px',
        cell: (row) => (
          <TableCellInLineContent>{row.customsState?.releaseForImport?.dateOfRelease}</TableCellInLineContent>
        ),
        sortable: true,
      },
      {
        name: 'Exporter',
        selector: 'stakeholders.exporter.name',
        minWidth: '200px',
        sortable: true,
      },
      { name: 'Importer', selector: (row) => row.stakeholders.importer.name },
      { name: 'Importer Country', selector: (row) => row.stakeholders.importer.address.country },
      {
        name: 'Total packages',
        selector: 'declarationGlobal.totalPackages',
        minWidth: '200px',
        cell: (row) => <TableCellInLineContent>{row.declarationGlobal?.totalPackages}</TableCellInLineContent>,
        sortable: true,
      },
      {
        name: 'Total Quantity',
        selector: 'declarationGlobal.totalQuantity',
        cell: (row) => <TableCellInLineContent>{row.declarationGlobal?.totalQuantity}</TableCellInLineContent>,
        sortable: true,
      },
      {
        name: 'Gross Mass',
        selector: 'declarationGlobal.grossMass',
        cell: (row) => <TableCellInLineContent>{row.declarationGlobal?.grossMass}</TableCellInLineContent>,
        sortable: true,
      },
      {
        name: 'Net Mass',
        selector: 'declarationGlobal.netMass',
        cell: (row) => <TableCellInLineContent>{row.declarationGlobal?.netMass}</TableCellInLineContent>,
        sortable: true,
      },
      {
        name: 'Number Articles',
        selector: 'declarationGlobal.numberOfArticles',
        cell: (row) => <TableCellInLineContent>{row.declarationGlobal?.numberOfArticles}</TableCellInLineContent>,
        sortable: true,
      },
      {
        name: 'Duties A00',
        selector: (row) =>
          row.customsState?.calculationResults?.total?.find((item) => item.taxType === 'A00')?.payableTaxAmount,
      },
      {
        name: 'Duties 100',
        selector: (row) =>
          row.customsState?.calculationResults?.total?.find((item) => item.taxType === '100')?.payableTaxAmount,
      },
      {
        name: 'Total VAT',
        selector: (row) =>
          row.customsState?.calculationResults?.total?.find((item) => item.taxType === 'B00')?.payableTaxAmount,
      },
      {
        name: 'Actions',
        right: true,
        cell: (row) => (
          <CustomTableActionsComponent
            actions={[
              {
                if: !config?.IS_FORWARDER && canBeRegularized(row),
                icon: TableActionsIcons.COPY,
                text: `${
                  row?.customsState?.sentTo === DeclarationCustomsType.PLDA ? 'Regularization' : 'Amendment'
                } or Duplication`,
                onClick: () => openModal(row, DeclarationModalEnum.REGULARIZATION),
              },
              {
                if: !config?.IS_FORWARDER && canBeDuplicated(row),
                icon: TableActionsIcons.COPY,
                text: 'Duplicate because of customs invalidation',
                onClick: () => openModal(row, DeclarationModalEnum.DUPLICATE),
              },
              {
                icon: TableActionsIcons.VIEW,
                text: 'View declarations',
                onClick: () => {
                  Cookies.set(
                    STORAGE_KEYS.COOKIES.BATCH_VIEW,
                    row.generalInfo.isExport ? BatchTypeEnum.HIGH_VALUES_EXPORT : BatchTypeEnum.HIGH_VALUES,
                  );
                  history.push(`/declarations?lrn=${row.messageInfo.LRN}&tab=${row.generalInfo.status}`);
                },
              },
              {
                if:
                  !config?.IS_FORWARDER &&
                  [
                    DeclarationStatus.NOT_SENT,
                    DeclarationStatus.ANALYSIS,
                    DeclarationStatus.BEGATE_NOT_RELEASED,
                    DeclarationStatus.BEGATE_RELEASED,
                  ].includes(row.generalInfo.status),
                icon: TableActionsIcons.SEND,
                text: 'Send',
                onClick: () => openModal(row, DeclarationModalEnum.SEND),
              },
              {
                if:
                  !config?.IS_FORWARDER &&
                  [DeclarationStatus.REJECTED, DeclarationStatus.BEGATE_SENT].includes(row.generalInfo.status),
                icon: TableActionsIcons.SEND,
                text: 'Send',
                onClick: () => {
                  if (row.customsState?.sentTo === DeclarationCustomsType.BE_GATE) {
                    (dispatch(sendBatchToBeGate(row.messageInfo.batch, row.messageInfo.LRN)) as any).then(() =>
                      fetchData(),
                    );
                    return;
                  }

                  if (
                    [DeclarationCustomsType.AES, DeclarationCustomsType.IDMS, DeclarationCustomsType.DMS].includes(
                      row.customsState?.sentTo as DeclarationCustomsType,
                    )
                  ) {
                    sendOneHighValue(row.messageInfo.LRN);
                    return;
                  }

                  sendAllDeclarationsWithSameLRN(row.messageInfo.batch, '', [row.messageInfo.LRN]).then(() =>
                    fetchData(),
                  );
                },
              },
            ]}
            secondaryActions={[]}
            onClick={() => onSelectedRow(row)}
          />
        ),
      },
    ];
  };

  useEffect(() => {
    columns.current = buildColumns();
  }, [config]);

  return columns;
};
