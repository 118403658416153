import { useFormik } from 'formik';
import { FC } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';

import { Declarant, DeclarantPayments } from '@e-origin/shared';

import { Button, Dropdown, Input, NotificationModal, PaymentsFormModal } from '../../../../shared/components';
import usePayments from '../../../../shared/hooks/use-payments';
import { selectDeclarantPayments, updateDeclarantBasicData } from '../../../../shared/stores/declarantSlice';
import { DataTableControls, FormContainer, FormSection, FormSectionTitle } from '../../../../styles/common';
import { getMethodOfPaymentTypes } from '../../../../utils';
import { Container, DataTableContainer } from '../goods-location/goods-location.style';
import {
  AdditionalFiscalReferenceHeader,
  AdditionalFiscalReferenceTitle,
  PaymentsFormRow,
} from './payment-form.styles';

const SAVE_BUTTON_TITLE = 'Save';
const ADD_NEW_ADDITIONAL_FISCAL_REFERENCE = 'Add new';
const DEFERRED_PAYMENT_PLACEHOLDER = 'Deferred payment';
const DELETE_ADDITIONAL_FISCAL_REFERENCE_BUTTON_TEXT = 'Delete';
const DELETE_ADDITIONAL_FISCAL_REFERENCE_MODAL_TEXT =
  "Are you sure you want to delete the additional fiscal reference? Action can't be undone!";
const DELETE_ADDITIONAL_FISCAL_REFERENCE_TITLE = 'Confirm additional fiscal reference delete';
const INPUT_WIDTH = 387;
const METHOD_OF_PAYMENT_PLACEHOLDER = 'Select method of payment type';
const METHOD_OF_PAYMENT_TITLE = 'Method of payment type';
const PAYMENT_INFO = 'Payment info';
const TABLE_LABEL = 'Additional fiscal reference';
const METHOD_OF_PAYMENT_VAT_TITLE = 'Method of payment vat type';
const METHOD_OF_PAYMENT_VAT_PLACEHOLDER = 'Select method of payment vat type';
const DEFERRED_PAYMENT_VAT_PLACEHOLDER = 'Deferred payment vat';

const methodOfPaymentTypes = getMethodOfPaymentTypes();

const PaymentsForm: FC = () => {
  const dispatch = useDispatch();
  const payments = useSelector(selectDeclarantPayments);

  const handleUpdate = (updateData: Partial<Declarant>) => {
    dispatch(updateDeclarantBasicData(updateData));
  };

  const formik = useFormik({
    initialValues: {
      methodOfPayment: payments?.methodOfPayment || null,
      deferredPayment: payments?.deferredPayment || '',
      methodOfPaymentVAT: payments?.methodOfPaymentVAT,
      deferredPaymentVAT: payments?.deferredPaymentVAT,
    } as DeclarantPayments,
    onSubmit: (values) => {
      const { deferredPayment, methodOfPayment, deferredPaymentVAT, methodOfPaymentVAT } = values;

      const declarant: Partial<Declarant> = {
        payments: {
          ...payments,
          methodOfPayment,
          deferredPayment,
          deferredPaymentVAT,
          methodOfPaymentVAT,
        },
      };

      handleUpdate(declarant);
    },
  });

  const {
    values: { methodOfPayment, deferredPayment, deferredPaymentVAT, methodOfPaymentVAT },
    handleBlur,
    handleChange,
    setFieldValue,
  } = formik;

  const {
    columns,
    handleAdditionalFiscalReferenceDelete,
    handleSavingAdditionalFiscalReference,
    isAddNewAdditionalFiscalReferenceDisable,
    methodOfPaymentValue,
    methodOfPaymentVATValue,
    selectedReference,
    showAdditionalFiscalReferenceDeleteNotificationModal,
    showModal,
    toggleDeleteAdditionalFiscalReferenceNotificationModal,
    toggleShowReferenceModal,
  } = usePayments({ handleUpdate, isCustomerFlow: false, methodOfPayment, methodOfPaymentVAT, payments });

  return (
    <Container>
      <AdditionalFiscalReferenceHeader>
        <AdditionalFiscalReferenceTitle>{TABLE_LABEL}</AdditionalFiscalReferenceTitle>
        <DataTableControls>
          <Button disabled={isAddNewAdditionalFiscalReferenceDisable} outline={true} onClick={toggleShowReferenceModal}>
            {ADD_NEW_ADDITIONAL_FISCAL_REFERENCE}
          </Button>
        </DataTableControls>
      </AdditionalFiscalReferenceHeader>
      <DataTableContainer>
        <DataTable noHeader columns={columns} data={payments?.additionalFiscalReference || []} pagination />
      </DataTableContainer>
      <FormContainer onSubmit={formik.handleSubmit}>
        <FormSection>
          <FormSectionTitle>{PAYMENT_INFO}</FormSectionTitle>
          <PaymentsFormRow>
            <Dropdown
              label={METHOD_OF_PAYMENT_TITLE}
              onChange={(selectedOption) => {
                setFieldValue('methodOfPayment', selectedOption?.value);
              }}
              options={methodOfPaymentTypes}
              placeholder={METHOD_OF_PAYMENT_PLACEHOLDER}
              value={methodOfPaymentValue}
              width={INPUT_WIDTH}
              maxMenuHeight={200}
            />
            <Input
              name="deferredPayment"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={DEFERRED_PAYMENT_PLACEHOLDER}
              value={deferredPayment}
              width={INPUT_WIDTH}
            />
          </PaymentsFormRow>
          <PaymentsFormRow>
            <Dropdown
              label={METHOD_OF_PAYMENT_VAT_TITLE}
              onChange={(selectedOption) => {
                setFieldValue('methodOfPaymentVAT', selectedOption?.value);
              }}
              options={methodOfPaymentTypes}
              placeholder={METHOD_OF_PAYMENT_VAT_PLACEHOLDER}
              value={methodOfPaymentVATValue}
              width={INPUT_WIDTH}
              maxMenuHeight={200}
            />
            <Input
              name="deferredPaymentVAT"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={DEFERRED_PAYMENT_VAT_PLACEHOLDER}
              value={deferredPaymentVAT}
              width={INPUT_WIDTH}
            />
          </PaymentsFormRow>
        </FormSection>
        {showModal && (
          <PaymentsFormModal
            additionalFiscalReferenceEdit={selectedReference}
            isShow={showModal}
            onHide={toggleShowReferenceModal}
            onSave={handleSavingAdditionalFiscalReference}
          />
        )}
        <NotificationModal
          confirmButtonText={DELETE_ADDITIONAL_FISCAL_REFERENCE_BUTTON_TEXT}
          isDelete={true}
          onConfirm={handleAdditionalFiscalReferenceDelete}
          onHide={toggleDeleteAdditionalFiscalReferenceNotificationModal}
          show={showAdditionalFiscalReferenceDeleteNotificationModal}
          title={DELETE_ADDITIONAL_FISCAL_REFERENCE_TITLE}
        >
          <div>{DELETE_ADDITIONAL_FISCAL_REFERENCE_MODAL_TEXT}</div>
        </NotificationModal>
        <div>
          <Button type="submit" primary>
            {SAVE_BUTTON_TITLE}
          </Button>
        </div>
      </FormContainer>
    </Container>
  );
};

export default PaymentsForm;
