import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import { Header, Navigation } from '../../shared/components';
import { useConfig } from '../../shared/hooks/use-config';
import { getUserInfo, refreshToken, selectUserRole, signOutUser } from '../../shared/stores/userSlice';
import Batches from '../batches/components/batches.component';
import CustomerDetails from '../customers/components/customer-details/customer-details.component';
import Customers from '../customers/components/customers/customers.component';
import DeclarationDetails from '../declarations/components/declaration-details/declaration-details.component';
import DeclarationGroups from '../declarations/components/declaration-groups/declaration-groups.component';
import Declarations from '../declarations/components/declarations/declarations.component';
import { Help } from '../help/Help';
import ImportersDetails from '../importers/components/importer-details/importer-details.component';
import Importers from '../importers/components/importers/importers.component';
import ManageAccount from '../manage-account/components/manage-account.component';
import { MongoDashboard } from '../mongo-dashboard/components/mongo-dashboard';
import ManageTemplates from '../templates/components/manage-templates.component';
import { TransitDetails } from '../transits/components/transit-details/transit-details.component';
import Transits from '../transits/components/transits/transits.component';
import { Body, Container, NavigationContainer, PageContainer, PageWrapper } from './layout.style';

const Layout = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { config } = useConfig();

  const roleName = useSelector(selectUserRole);

  useEffect(() => {
    dispatch(getUserInfo());
  }, []);

  useEffect(() => {
    dispatch(refreshToken());
  }, []);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (config) {
      const navigationCallback = () => {
        history.push('/');
      };

      const timeout = setTimeout(() => {
        dispatch(signOutUser(navigationCallback));
      }, 1000 * config.JWT_EXPIRATION_TIME);

      return () => clearTimeout(timeout);
    }
  }, [config]);

  return (
    <Container>
      <Header />
      <Body>
        <NavigationContainer>
          <Navigation />
        </NavigationContainer>
        <PageContainer>
          <PageWrapper>
            <Switch>
              <Route path="/batches">
                <Batches />
              </Route>
              <Route path="/declarations/:declarationId">
                <DeclarationDetails />
              </Route>
              <Route path="/declarations">
                <Declarations />
              </Route>

              {/* {transitEnabled && ( */}
              <Route path="/transits">
                <Transits />
              </Route>
              {/* )} */}

              {/* {transitEnabled && ( */}
              <Route path="/transits-details/:id">
                <TransitDetails />
              </Route>
              {/* )} */}

              <Route path={['/declaration-groups']}>
                <DeclarationGroups />
              </Route>

              {/* {walletEnabled && ( */}
              <Route path="/importers/:id">
                <ImportersDetails />
              </Route>
              {/* )} */}

              {/* {walletEnabled && ( */}
              <Route path="/importers">
                <Importers />
              </Route>
              {/* )} */}

              <Route path="/customers/:customerId/batches">
                <Batches />
              </Route>
              <Route path="/customers/:customerId/declarations">
                <Declarations />
              </Route>
              <Route path="/customers/:customerId">
                <CustomerDetails />
              </Route>
              <Route path="/customers/add">
                <CustomerDetails />
              </Route>
              <Route path="/customers">
                <Customers />
              </Route>
              <Route path="/manage-account">
                <ManageAccount />
              </Route>
              {roleName === 'admin' && (
                <Route path="/manage-templates">
                  <ManageTemplates />
                </Route>
              )}
              <Route path="/mongo-dashboard">
                <MongoDashboard />
              </Route>
              <Route path="/help">
                <Help />
              </Route>

              <Route>
                <Redirect to="/batches" />
              </Route>
            </Switch>
          </PageWrapper>
        </PageContainer>
      </Body>
    </Container>
  );
};

export default Layout;
