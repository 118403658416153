import { useEffect, useState } from 'react';
import DataTable, { IDataTableColumn } from 'react-data-table-component';

import trashIcon from '../../../../assets/icons/trash-icon.svg';
import { Button, Input } from '../../../../shared/components';
// todo: do not the style from the components
import { IconContainer } from '../../../../shared/components/table-action-buttons/table-action-buttons.style';
import { ExcludedListInputWrapper, ExcludedListWrapper } from './excluded-list.style';

interface IExcludedListProps {
  name: string;
  inputPlaceholder: string;
  list: string[];
  onUpdate(updatedList: string[]): void;
}

const ExcludedList: React.FC<IExcludedListProps> = (props) => {
  const [newValue, setNewValue] = useState('');
  const [rows, setRows] = useState(props.list.slice());
  useEffect(() => {
    setRows(rows);
  }, [rows]);

  const removeItem = (row: string) => {
    const newRows = rows.filter((r) => r !== row);
    setRows(newRows);
    props.onUpdate(newRows);
  };

  const columns: IDataTableColumn<any>[] = [
    {
      name: props.name,
      selector: (row) => row,
    },
    {
      name: 'Actions',
      cell: (row) => (
        <Button type="button" onClick={() => removeItem(row)}>
          <IconContainer src={trashIcon} alt={'delete'} />
        </Button>
      ),
    },
  ];

  const onChange = (event: any) => {
    setNewValue(event.target.value);
  };

  const addItem = () => {
    if (rows.findIndex((r) => r === newValue) !== -1) {
      return;
    }

    const newRows = [...rows, newValue];
    props.onUpdate(newRows);
    setRows(newRows);
    setNewValue('');
  };

  return (
    <ExcludedListWrapper>
      <DataTable columns={columns} data={rows || []} noHeader={true} />
      <ExcludedListInputWrapper>
        <Input
          placeholder={props.inputPlaceholder}
          defaultBehaviour={true}
          onChange={onChange}
          value={newValue}
          width={80}
          widthUnit="%"
        />
        <Button type="button" onClick={addItem} disabled={!newValue.length} primary>
          +
        </Button>
      </ExcludedListInputWrapper>
    </ExcludedListWrapper>
  );
};

export default ExcludedList;
