/* eslint-disable complexity */
import { ConsignmentItem, RecursivePartial } from '@e-origin/shared';
export interface GoodsItemFormInitializerReturn extends RecursivePartial<ConsignmentItem> {
  documents: {
    PreviousDocument: any[];
    SupportingDocument: any[];
    TransportDocument?: any[];
    AdditionalReference: any[];
    AdditionalInformation: any[];
  };
}

export const goodsItemFormIntializer = (item: ConsignmentItem): GoodsItemFormInitializerReturn => ({
  goodsItemNumber: item?.goodsItemNumber,
  declarationGoodsItemNumber: item?.declarationGoodsItemNumber,
  declarationType: item?.declarationType,
  countryOfDispatch: item?.countryOfDispatch,
  countryOfDestination: item?.countryOfDestination,
  referenceNumberUCR: item?.referenceNumberUCR,
  Commodity: {
    descriptionOfGoods: item?.Commodity?.descriptionOfGoods || '',
    DangerousGoods: item?.Commodity?.DangerousGoods || [{ UNNumber: '' }],
    CUSCode: item?.Commodity?.CUSCode || '',
    CommodityCode: {
      hsCode: item?.Commodity?.CommodityCode?.hsCode || '',
      initialAmountToPayForDuties: item?.Commodity?.CommodityCode?.initialAmountToPayForDuties || '',
      initialAmountToPayForVat: item?.Commodity?.CommodityCode?.initialAmountToPayForVat || '',
    },
    GoodsMeasure: {
      grossMass: item?.Commodity?.GoodsMeasure?.grossMass || '',
      netMass: item?.Commodity?.GoodsMeasure?.netMass || '',
      supplementaryUnits: item?.Commodity?.GoodsMeasure?.supplementaryUnits || '',
    },
  },

  Packaging: item?.Packaging || [{ typeOfPackages: '', numberOfPackages: '', shippingMarks: '' }],
  AdditionalSupplyChainActor: item?.AdditionalSupplyChainActor?.length
    ? item?.AdditionalSupplyChainActor
    : [{ role: '', identificationNumber: '' }],
  documents: {
    PreviousDocument: item?.PreviousDocument?.length ? item.PreviousDocument : [],
    SupportingDocument: item?.SupportingDocument?.length ? item.SupportingDocument : [],
    AdditionalReference: item?.AdditionalReference.length ? item.AdditionalReference : [],
    AdditionalInformation: item?.AdditionalInformation.length ? item.AdditionalInformation : [],
  },
});
