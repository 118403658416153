/* eslint-disable complexity */
/* eslint-disable max-statements */
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import DataTable, { IDataTableColumn, IDataTableProps } from 'react-data-table-component';

import { SortDirection } from '@e-origin/shared';

import { Spinner } from '../';
import { useConfig } from '../../hooks/use-config';
import * as Style from './custom-table.style';

interface CustomTableCriteria {
  sorting?: any;
  pagination: {
    page: number;
    size: number;
    direction?: number;
  };
}

interface CustomTableProps {
  className?: string;
  columns: IDataTableColumn[];
  sorting: CustomTableCriteria['sorting'];
  pagination: CustomTableCriteria['pagination'];
  onPaginationChange?: (pagination: CustomTableCriteria['pagination']) => void;
  onSortingChange?: (sorting: any) => void;
  data: any[];
  total: number;
  isLoading: boolean;
  hideOverlay?: boolean;
  disableJumpToLastPage?: boolean;
  disablePagination?: boolean;
  customStyles?: IDataTableProps['customStyles'];
}

export const CustomTable: React.FC<CustomTableProps> = (props) => {
  const { columns, className, customStyles } = props;

  const [pagination, setPagination] = useState<CustomTableCriteria['pagination']>({ page: 1, size: 10, direction: 1 });
  const [sorting, setSorting] = useState<CustomTableCriteria['sorting']>({});
  const { config } = useConfig();

  useEffect(() => {
    if (props.sorting) {
      if (!isEqual(sorting, props.sorting)) {
        setSorting(props.sorting);
      }
    }

    if (props.pagination) {
      const newPagination = {
        page: props.pagination.page,
        size: props.pagination.size,
        direction: props.pagination.direction,
      };

      if (!isEqual(pagination, newPagination)) {
        setPagination(newPagination);
      }
    }

    if (!isEqual(sorting, props.sorting)) {
      setSorting(props.sorting);
    }
  }, [props.sorting, props.pagination]);

  const handlePagination = (page: number, size?: number) => {
    const updatedPagination = {
      page,
      size: size || pagination.size,
      direction: Math.sign(page - pagination.page) || 1,
    };

    if (!isEqual(updatedPagination, pagination)) {
      setPagination(updatedPagination);
      props.onPaginationChange?.(updatedPagination);
    }
  };

  const handlePageChange: IDataTableProps['onChangePage'] = (page) => {
    handlePagination(page);
  };

  const handlePerRowsChange: IDataTableProps['onChangeRowsPerPage'] = (size, page) => {
    handlePagination(page, size);
  };

  const handleSort: IDataTableProps['onSort'] = async (column, sortDirection) => {
    const updatedSorting = {
      field: column.selector,
      direction: sortDirection === 'asc' ? SortDirection.ASC : SortDirection.DESC,
    };

    if (!isEqual(updatedSorting, sorting)) {
      setSorting(updatedSorting);
      props.onSortingChange?.(updatedSorting);
    }
  };

  const PaginationSpinner = () => (
    <Style.LoadingPaginationContainer>
      <Style.LoadingPaginationSpinnerWrapper>
        <Spinner />
      </Style.LoadingPaginationSpinnerWrapper>
    </Style.LoadingPaginationContainer>
  );

  return (
    <>
      {pagination && sorting && (
        <Style.TableWrapper>
          {props.isLoading && (
            <Style.LoadingOverlay>
              <Style.LoadingContainer>
                <Spinner />
              </Style.LoadingContainer>
            </Style.LoadingOverlay>
          )}
          <Style.OverrideDataTableWrapper disableJumpToLastPage={props.disableJumpToLastPage}>
            <DataTable
              className={className}
              noHeader
              columns={columns}
              data={props.data}
              pagination
              paginationServer
              paginationDefaultPage={pagination.page}
              paginationPerPage={pagination.size}
              paginationTotalRows={props.total}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              paginationRowsPerPageOptions={config?.DATA_TABLE_PAGE_SIZES}
              sortServer
              onSort={handleSort}
              paginationComponent={!props.total || props.disablePagination ? PaginationSpinner : undefined}
              customStyles={customStyles || {}}
            />
          </Style.OverrideDataTableWrapper>
        </Style.TableWrapper>
      )}
    </>
  );
};
