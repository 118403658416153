import { CustomModal, Spinner } from '../../../../../../../../shared/components';
import { FormContainer, FormSection, SpinnerWrapper } from '../../risk-analysis-modal.styles';

export const WaitingAiAnalysisModal: React.FC = () => {
  return (
    <CustomModal show={true} onHide={() => null}>
      <FormContainer>
        <FormSection verticalPadding={20}>
          <div className="flex flex-col w-100 items-center">
            <h2 className="text-gray-400 font-bold py-4">Please wait, AI risk analysis is running</h2>
            <SpinnerWrapper className="py-4 pb-8">
              <Spinner />
            </SpinnerWrapper>
          </div>
        </FormSection>
      </FormContainer>
    </CustomModal>
  );
};
