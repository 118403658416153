import { useSelector } from 'react-redux';

import { selectTransit } from '../../../../../../shared/stores/transitsSlice';
import * as Style from './transit-details-incident-customs.style';
import { TransitDetailsIncident } from './transit-details-incident.component';

export const TransitDetailsIncidentsCustoms = () => {
  const transit = useSelector(selectTransit);
  const incidents = transit?.customsState?.unloadPermission?.Incident || [];

  return (
    <Style.Container>
      {incidents.map((incident, incidentIdx) => (
        <TransitDetailsIncident incident={incident} index={incidentIdx} key={incidentIdx} />
      ))}
    </Style.Container>
  );
};
