import styled from 'styled-components';
import tw from 'twin.macro';

export const Container = styled.div`
  ${tw`
    flex
    flex-col
    flex-shrink-0
  `}

  label {
    font-size: 13px;
    letter-spacing: 0.26px;
    line-height: 15px;
    color: ${({ theme }) => theme.colors.input.placeholder};
  }

  p {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.32px;
    line-height: 19px;
  }
`;

export const HeaderRow = styled.div`
  ${tw`
    flex
    items-center
    py-4
  `}

  & > div {
    margin-left: 50px;

    &:first-of-type {
      margin-left: 0;
    }
  }
`;

export const RejectionInfo = styled.div`
  ${tw`
    flex
    flex-col
    mt-5
  `}
`;

export const ErrorSection = styled.div`
  ${tw`
    flex
    flex-col
  `}
`;

export const BusinessRejectionRow = styled.div`
  ${tw`
    flex
  `};
  align-items: flex-start;
  margin-bottom: 30px;

  & > div {
    margin-left: 50px;

    &:first-of-type {
      margin-left: 0;
    }
  }
`;

export const ErrorRow = styled.div`
  ${tw`
    flex
    flex-col
  `};
  border-bottom: 1px solid #dedede;
`;

export const ErrorTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.36px;
  line-height: 21px;
  opacity: 0.75;
  padding: 20px 0;
`;

export const ErrorItem = styled.div`
  margin-right: 30px;
  margin-bottom: 30px;
  display: flex;
  & > div {
    margin-left: 50px;

    &:first-of-type {
      margin-left: 0;
    }
  }
`;

export const RequestReleaseContainer = styled.div`
  ${tw`
  flex
  items-start
`}

  & > div {
    margin-left: 50px;

    &:first-of-type {
      margin-left: 0;
      width: 50%;
    }
  }
`;

export const RequestReleaseForm = styled.div`
  ${tw`
    flex
    flex-col
  `}

  textarea {
    margin: 10px 0;
    border: 1px solid ${({ theme }) => theme.colors.input.border};
    border-radius: 5px;
    padding: 10px;
  }

  button {
    margin-top: 15px;
  }
`;

interface ISectionTitleProps {
  noTopBorder?: boolean;
  spaceBetween?: boolean;
}

export const SectionTitle = styled.div<ISectionTitleProps>`
  ${tw`
    font-extrabold
  `}
  border-top: ${({ noTopBorder }) => (noTopBorder ? 'none' : '1px solid #dedede')};
  padding: ${({ noTopBorder }) => (noTopBorder ? '10px 0 10px 0' : '25px 0 10px 0')};
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.36px;
  justify-content: ${({ spaceBetween }) => (spaceBetween ? 'space-between' : 'unset')};
`;
