/* eslint-disable consistent-return */
/* eslint-disable max-statements */
/* eslint-disable complexity */

import { useFormik } from 'formik';
import { merge } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Transit, TransitStatus } from '@e-origin/shared';

import Icons from '../../../../../../assets';
import { Button, CustomModal, Spinner, Tab, Tabs } from '../../../../../../shared/components';
import {
  clearTransit,
  fetchTransit,
  fetchTransits,
  selectTransit,
  sendOne,
  updateTransit,
} from '../../../../../../shared/stores/transitsSlice';
import ModalStyle from '../../../../../../styles/modal';
import * as Style from './finalize-transit-modal.style';
import { ReportTab } from './report-tab/report-tab';
import { SummaryTab } from './summary-tab/summary-tab';

interface FinalizeTransitModalProps {
  transit: Transit;
  onHide(): void;
}

export const FinalizeTransitModal: React.FC<FinalizeTransitModalProps> = (props) => {
  const dispatch = useDispatch();
  const transit = useSelector(selectTransit);
  const [editDisabled, setEditDisabled] = useState(null);
  const [isSending, setIsSending] = useState(null);
  const [stateOfSealsRequired, setStateOfSealsRequired] = useState(null);
  const { onHide } = props;

  const computeUnloadingRemark = (t: Transit) => ({
    unloadingCompletion: t.UnloadingRemark?.unloadingCompletion || '1',
    unloadingDate: `${moment(t.UnloadingRemark?.unloadingDate || new Date()).format('YYYY-MM-DD')}T00:00:00`,
    stateOfSeals: t.UnloadingRemark?.stateOfSeals,
    unloadingRemark: t.UnloadingRemark?.unloadingRemark,
    conform: Number(t.UnloadingRemark?.stateOfSeals !== '0' && !t.trace?.length).toString(),
  });

  const form = useFormik<Partial<Transit>>({
    initialValues: {
      UnloadingRemark: {},
      trace: [],
    },
    onSubmit: async (values) => {
      if (stateOfSealsRequired && !values.UnloadingRemark.stateOfSeals) {
        toast.error('State of seals is required because there are seals included in this transit!');
        return;
      }

      setIsSending(true);
      dispatch(updateTransit(merge(transit, values), { refreshCustomerData: false }));
    },
    enableReinitialize: false,
  });

  useEffect(() => {
    if (!transit) {
      return;
    }

    if (isSending) {
      sendOne(transit._id).then(() => {
        dispatch(fetchTransits());
        onHide();
      });
    } else {
      const UnloadingRemark = computeUnloadingRemark(transit);
      form.setFieldValue('UnloadingRemark', UnloadingRemark);
      form.setFieldValue('trace', transit.trace);
      setEditDisabled(transit.generalInfo.status !== TransitStatus.UNLOAD_PERMISSION);
      setStateOfSealsRequired(transit.customsState.unloadPermission.stateOfSealsRequired);
    }
  }, [transit]);

  useEffect(() => {
    if (props.transit) {
      dispatch(fetchTransit(props.transit._id));
      return () => {
        dispatch(clearTransit());
      };
    }
  }, [props.transit]);

  return (
    <CustomModal width={55} show={true} onHide={onHide}>
      <ModalStyle.Header>
        <Style.TitleContainer>
          <ModalStyle.Title>Finalize transit</ModalStyle.Title>
          <Style.TransitName>{transit?.name}</Style.TransitName>
        </Style.TitleContainer>

        <ModalStyle.HeaderActions>
          <button onClick={onHide}>
            <img src={Icons.CloseIcon} alt="" />
          </button>
        </ModalStyle.HeaderActions>
      </ModalStyle.Header>
      {!transit ? (
        <Style.SpinnerWrapper>
          <Spinner />
        </Style.SpinnerWrapper>
      ) : (
        <Tabs style={{ margin: '0 15px 15px 15px' }} contentStyle={{ padding: '30px 50px', overflow: 'auto' }}>
          <Tab label="Summary">
            <SummaryTab form={form} editDisabled={editDisabled} stateOfSealsRequired={stateOfSealsRequired} />
          </Tab>
          <Tab label="Report">
            <ReportTab form={form} />
          </Tab>
        </Tabs>
      )}
      <ModalStyle.Footer>
        <Button type="button" onClick={onHide}>
          Cancel
        </Button>
        {!editDisabled && (
          <Button type="button" primary onClick={form.handleSubmit} disabled={isSending}>
            {isSending ? (
              <>
                <Spinner isContrast />
                Sending
              </>
            ) : (
              'Send'
            )}
          </Button>
        )}
      </ModalStyle.Footer>
    </CustomModal>
  );
};
