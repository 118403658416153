import styled from 'styled-components';
import tw from 'twin.macro';

export const IconWrapper = styled.div`
  ${tw`
    flex
    items-center
    justify-between
  `}
  width: 24px;
  height: 24px;
`;
