/* eslint-disable no-unused-expressions */
/* eslint-disable max-statements */
import { FormikProps } from 'formik';
import { FC, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useParams } from 'react-router-dom';

import { Customer, CustomerPayments } from '@e-origin/shared';

import { EditCustomerType } from '../../../../../enums';
import { Button, Dropdown, Input, NotificationModal, PaymentsFormModal } from '../../../../../shared/components';
import usePayments from '../../../../../shared/hooks/use-payments';
import { DataTableControls, FormSection, FormSectionTitle } from '../../../../../styles/common';
import { getMethodOfPaymentTypes } from '../../../../../utils';
import {
  Container,
  DataTableContainer,
} from '../../../../manage-account/components/goods-location/goods-location.style';
import { CustomerDetailsParamsType, CustomerDetailsTabType } from '../customer-details.component';
import * as Style from './customer-details-payments.styles';

interface CustomerDetailsPaymentsFormType extends CustomerDetailsTabType {
  handleUpdatePayments(updatedPayments: Customer['payments']): void;
  form: FormikProps<Customer>;
}

const INPUT_WIDTH = 387;

const methodOfPaymentTypes = getMethodOfPaymentTypes();

const CustomerDetailsPayments: FC<CustomerDetailsPaymentsFormType> = (props) => {
  const { handleSubmitCustomer, handleUpdatePayments, isButtonDisabled, form } = props;

  const { setFieldValue, handleBlur, handleChange } = form;

  const { payments, personPayingTheCustomDuty } = form.values;

  const { customerId } = useParams<CustomerDetailsParamsType>();
  const context = customerId === 'add' ? 'CREATE' : 'UPDATE';

  const { methodOfPayment = null, methodOfPaymentVAT = null } = payments;

  const handleUpdate = (updateData: CustomerPayments) => {
    handleUpdatePayments(updateData);
  };

  const {
    columns,
    handleAdditionalFiscalReferenceDelete,
    handleSavingAdditionalFiscalReference,
    isAddNewAdditionalFiscalReferenceDisable,
    methodOfPaymentValue,
    methodOfPaymentVATValue,
    selectedReference,
    showAdditionalFiscalReferenceDeleteNotificationModal,
    showModal,
    toggleDeleteAdditionalFiscalReferenceNotificationModal,
    toggleShowReferenceModal,
  } = usePayments({ handleUpdate, isCustomerFlow: true, methodOfPayment, methodOfPaymentVAT, payments });

  const [showSavingPaymentsModal, setShowSavingPaymentsModal] = useState(false);
  const toggleSavingPaymentsModal = () => {
    if (
      !form.values.payments.methodOfPayment ||
      !form.values.payments.deferredPayment ||
      !form.values.payments.methodOfPaymentVAT
    ) {
      setShowSavingPaymentsModal(true);
    } else {
      handleSubmitCustomer(EditCustomerType.PAYMENTS);
    }
  };
  return (
    <Container>
      <Style.AdditionalFiscalReferenceHeader>
        <Style.AdditionalFiscalReferenceTitle>Additional fiscal reference</Style.AdditionalFiscalReferenceTitle>
        <DataTableControls>
          <Button disabled={isAddNewAdditionalFiscalReferenceDisable} outline={true} onClick={toggleShowReferenceModal}>
            Add new
          </Button>
        </DataTableControls>
      </Style.AdditionalFiscalReferenceHeader>
      <DataTableContainer>
        <DataTable noHeader columns={columns} data={payments?.additionalFiscalReference || []} pagination />
      </DataTableContainer>
      <Style.Container>
        <FormSection>
          <FormSectionTitle>Payment info</FormSectionTitle>
          <Style.PaymentsFormRow>
            <Dropdown
              label="Method of payment type"
              onChange={(selectedOption) => {
                setFieldValue && setFieldValue('payments.methodOfPayment', selectedOption?.value);
              }}
              options={methodOfPaymentTypes}
              placeholder="Select method of payment type"
              value={methodOfPaymentValue}
              width={INPUT_WIDTH}
              maxMenuHeight={200}
              // invalid={!methodOfPaymentValue}
            />
            <Input
              name="payments.deferredPayment"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="Deferred payment"
              value={payments.deferredPayment}
              width={INPUT_WIDTH}
            />
          </Style.PaymentsFormRow>
          <Style.PaymentsFormRow>
            <Dropdown
              label="Method of payment vat type"
              onChange={(selectedOption) => {
                setFieldValue('payments.methodOfPaymentVAT', selectedOption?.value);
              }}
              options={methodOfPaymentTypes}
              placeholder="Select method of payment vat type"
              value={methodOfPaymentVATValue}
              width={INPUT_WIDTH}
              maxMenuHeight={200}
            />
            <Input
              name="payments.deferredPaymentVAT"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="Deferred payment vat"
              value={payments.deferredPaymentVAT}
              width={INPUT_WIDTH}
            />
          </Style.PaymentsFormRow>
        </FormSection>
        <FormSection>
          <FormSectionTitle noTopBorder>Person paying the customs duty</FormSectionTitle>
          <Style.PaymentsFormRow>
            <Input
              name="personPayingTheCustomDuty.identificationNumber"
              placeholder="Identification number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={personPayingTheCustomDuty.identificationNumber}
              width={INPUT_WIDTH}
            />
          </Style.PaymentsFormRow>
        </FormSection>
        {showModal && (
          <PaymentsFormModal
            additionalFiscalReferenceEdit={selectedReference}
            isShow={showModal}
            onHide={toggleShowReferenceModal}
            onSave={handleSavingAdditionalFiscalReference}
          />
        )}
        <NotificationModal
          confirmButtonText="Delete"
          isDelete={true}
          onConfirm={handleAdditionalFiscalReferenceDelete}
          onHide={toggleDeleteAdditionalFiscalReferenceNotificationModal}
          show={showAdditionalFiscalReferenceDeleteNotificationModal}
          title="Confirm additional fiscal reference delete"
        >
          <div>Are you sure you want to delete the additional fiscal reference? Action can't be undone!</div>
        </NotificationModal>
        <NotificationModal
          onConfirm={() => {
            handleSubmitCustomer(EditCustomerType.PAYMENTS);
            setShowSavingPaymentsModal(false);
          }}
          confirmButtonText="Yes"
          cancelButtonText="No"
          onHide={() => {
            setShowSavingPaymentsModal(false);
          }}
          show={showSavingPaymentsModal}
          title="Confirm saving payment details"
        >
          <div>
            Are you sure you want to save without any Deferred payment. Deferred payment is usually mandatory for
            Import.
          </div>
        </NotificationModal>
        <Style.SubmitSection>
          <Button disabled={isButtonDisabled} onClick={toggleSavingPaymentsModal} primary type="submit">
            {context === 'CREATE' ? 'Create' : 'Save'}
          </Button>
        </Style.SubmitSection>
      </Style.Container>
    </Container>
  );
};

export default CustomerDetailsPayments;
