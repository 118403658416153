import { FormikProps } from 'formik';
import { useSelector } from 'react-redux';

import { TransitGroup, TransitHouseConsignment } from '@e-origin/shared';

import { selectTransit } from '../../../../../../../../../../../shared/stores/transitsSlice';
import { FormRow, FormSection, FormSectionTitle } from '../../../../../../../../../../../styles/common';
import { TransitInputHeader } from '../../../../../transit-input-header.component';

interface TransitConsigneeProps {
  form: FormikProps<any>;
  houseConsignment?: TransitHouseConsignment;
  editDisabled: boolean;
}
/* eslint-disable complexity */
export const TransitConsignee: React.FC<TransitConsigneeProps> = ({ form, houseConsignment, editDisabled }) => {
  const transit = useSelector(selectTransit);
  const consigneeParent = transit?.Consignment?.Consignee || houseConsignment?.Consignee;
  const isConsigneeDefined = !!consigneeParent && (!!consigneeParent.name || !!consigneeParent?.identificationNumber);
  const isArrival = transit.generalInfo?.group === TransitGroup.ARRIVAL;

  return (
    <FormSection verticalPadding={0} noTopPadding>
      <FormSectionTitle noTopBorder>Consignee</FormSectionTitle>
      <FormRow>
        <TransitInputHeader
          form={form}
          name="Consignee.identificationNumber"
          placeholder="Identification Number"
          parents={isConsigneeDefined ? [consigneeParent.identificationNumber] : []}
          disabled={editDisabled || !!isConsigneeDefined}
        />
        {!isArrival && (
          <TransitInputHeader
            form={form}
            name="Consignee.name"
            placeholder="Name"
            parents={isConsigneeDefined ? [consigneeParent.name] : []}
            disabled={editDisabled || !!isConsigneeDefined}
          />
        )}
      </FormRow>
      <FormRow>
        <TransitInputHeader
          form={form}
          name="Consignee.Address.streetAndNumber"
          placeholder="Street and Number"
          parents={isConsigneeDefined ? [consigneeParent.Address?.streetAndNumber] : []}
          disabled={editDisabled || !!isConsigneeDefined}
        />

        <TransitInputHeader
          form={form}
          name="Consignee.Address.postcode"
          placeholder="Postcode"
          parents={isConsigneeDefined ? [consigneeParent.Address?.postcode] : []}
          disabled={editDisabled || !!isConsigneeDefined}
        />

        <TransitInputHeader
          form={form}
          name="Consignee.Address.city"
          placeholder="City"
          parents={isConsigneeDefined ? [consigneeParent.Address?.city] : []}
          disabled={editDisabled || !!isConsigneeDefined}
        />

        <TransitInputHeader
          form={form}
          name="Consignee.Address.country"
          placeholder="Country"
          parents={isConsigneeDefined ? [consigneeParent.Address?.country] : []}
          disabled={editDisabled || !!isConsigneeDefined}
        />
      </FormRow>
    </FormSection>
  );
};
