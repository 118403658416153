import { useDispatch, useSelector } from 'react-redux';

import { Button } from '../../../../shared/components';
import { createWallet, selectDeclarant } from '../../../../shared/stores/declarantSlice';
import { PageTitle } from '../../../../styles/common';
import * as Style from './broker-wallet.style';

export const BrokerWallet = () => {
  const dispatch = useDispatch();
  const declarant = useSelector(selectDeclarant);

  return (
    <Style.Container>
      <PageTitle>Broker Wallet</PageTitle>
      <Style.Content>
        {declarant.hasWallet ? (
          <Button outline type="button" disabled>
            Wallet linked to account
          </Button>
        ) : (
          <Button outline type="button" onClick={async () => dispatch(createWallet())}>
            Create wallet
          </Button>
        )}
      </Style.Content>
    </Style.Container>
  );
};
