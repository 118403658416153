import { FormikProps } from 'formik';
import { useSelector } from 'react-redux';

import { Autocomplete, Input, Switcher } from '../../../../shared/components';
import { selectDeclarant } from '../../../../shared/stores/declarantSlice';
import { retrieveCodes } from '../../../../shared/stores/settingsSlice';
import { FormRow, FormSection, FormSectionTitle } from '../../../../styles/common';

interface IBuyerFormProps {
  formik: FormikProps<any>;
  editDisabled: boolean;
}

export const BuyerForm: React.FC<IBuyerFormProps> = ({ formik, editDisabled }) => {
  const declarant = useSelector(selectDeclarant);

  return (
    <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
      <FormSectionTitle noTopBorder spaceBetween>
        Buyer{' '}
        <Switcher
          onChange={(active) => formik.setFieldValue('dv1.buyer.header', active)}
          active={formik.values.dv1.buyer.header}
          label="Header"
          disabled={editDisabled}
        />
      </FormSectionTitle>
      <FormRow>
        <Input
          name="dv1.buyer.name"
          placeholder="Name"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.dv1.buyer.name}
          width={50}
          widthUnit="%"
          disabled={editDisabled}
        />
        <Input
          name="dv1.buyer.identificationNumber"
          placeholder="Identification Number"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.dv1.buyer.identificationNumber}
          width={50}
          widthUnit="%"
          disabled={editDisabled}
        />
      </FormRow>
      <FormRow>
        <Input
          name="dv1.buyer.address.street"
          placeholder="Street"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.dv1.buyer.address.street}
          width={40}
          widthUnit="%"
          disabled={editDisabled}
        />
        <Input
          name="dv1.buyer.address.city"
          placeholder="City"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.dv1.buyer.address.city}
          width={30}
          widthUnit="%"
          disabled={editDisabled}
        />
        <Input
          name="dv1.buyer.address.postcode"
          placeholder="Postcode"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.dv1.buyer.address.postcode}
          width={30}
          widthUnit="%"
          disabled={editDisabled}
        />
        <Autocomplete
          width={30}
          widthUnit="%"
          placeholder="Country"
          fetchOptions={(search: string) => retrieveCodes('CL199', search, declarant.language)}
          onChange={(selectedOption) => formik.setFieldValue('dv1.buyer.address.country', selectedOption?.value)}
          value={{
            value: formik.values.dv1.buyer.address.country,
            label: formik.values.dv1.buyer.address.country,
          }}
          disabled={editDisabled}
        />
      </FormRow>
    </FormSection>
  );
};
