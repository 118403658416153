import SendIcon from './icons/action-icon.svg';
import YellowSuccessIcon from './icons/anomalies-handled-icon.svg';
import YellowWarningIcon from './icons/anomaly-hs-code-icon.svg';
import RedWarningIcon from './icons/anomaly-value-icon.svg';
import ArrowDown from './icons/arrow-down.svg';
import ArrowUp from './icons/arrow-up.svg';
import BlueCheckMarkIcon from './icons/blue-check-mark.svg';
import CheckMarkIcon from './icons/check-mark.svg';
import CloseIcon from './icons/close-icon.png';
import CopyIcon from './icons/copy.svg';
import CustomerProcessingIcon from './icons/customs-processing-icon.svg';
import DangerIcon from './icons/danger-action.svg';
import ErrorIcon from './icons/error-icon.svg';
import finalizeIcon from './icons/finalize-batch.svg';
import GreenRejectedIcon from './icons/green-rejected-icon.svg';
import LayerMultiple from './icons/layer-multiple.svg';
import GreenSuccessIcon from './icons/light-green.svg';
import GreenCircleIcon from './icons/not-sent-no-anomalies-icon.svg';
import NotesIcon from './icons/notes.svg';
import EditIcon from './icons/refactor.svg';
import RefreshIcon from './icons/refresh-icon.svg';
import GenerateReport from './icons/report-icon.svg';
import SearchIcon from './icons/search-icon.svg';
import FailedIcon from './icons/status-failed-icon.svg';
import InvalidatedIcon from './icons/status-invalidated.svg';
import NotReleasedIcon from './icons/status-not-released-icon.svg';
import NotSentIcon from './icons/status-not-sent-icon.svg';
import CustomsProcessingIcon from './icons/status-processing-icon.svg';
import RejectedIcon from './icons/status-rejected-icon.svg';
import ReleasedIcon from './icons/status-released-icon.svg';
import TranslationIcon from './icons/translation-icon.svg';
import TrashIcon from './icons/trash-icon.svg';
import ViewIcon from './icons/view-icon.svg';

export const TableActionsIcons = {
  VIEW: ViewIcon,
  COPY: CopyIcon,
  REFRESH: RefreshIcon,
  DANGEROUS: DangerIcon,
  SEND: SendIcon,
  DELETE: TrashIcon,
  LAYERS: LayerMultiple,
  REPORT: GenerateReport,
  FINALIZE: finalizeIcon,
};

export const StatusIcons = {
  GREEN_CIRCLE: GreenCircleIcon,
  YELLOW_WARNING: YellowWarningIcon,
  RED_WARNING: RedWarningIcon,
  YELLOW_SUCCESS: YellowSuccessIcon,
  GREEN_SUCCESS: GreenSuccessIcon,
  CUSTOMER_PROCESSING: CustomerProcessingIcon,
  CUSTOMS_PROCESSING: CustomsProcessingIcon,
  NOT_SENT: NotSentIcon,
  REJECTED: RejectedIcon,
  RELEASED: ReleasedIcon,
  NOT_RELEASED: NotReleasedIcon,
  INVALIDATED: InvalidatedIcon,
  FAILED: FailedIcon,
  GREEN_REJECTED: GreenRejectedIcon,
};

const Icons = {
  ErrorIcon,
  TranslationIcon,
  SearchIcon,
  EditIcon,
  CloseIcon,
  ArrowUp,
  ArrowDown,
  NotesIcon,
  CheckMarkIcon,
  CopyIcon,
  DangerIcon,
  BlueCheckMarkIcon,
};

export default Icons;
